import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 22,
    },
    checkboxContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 16,
    },
    description: {
        ...typography.h5,
        marginTop: 16,
        marginBottom: 8,
    },
    child: {
        flexBasis: '50%',
        padding: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    bottomMargin: {
        marginBottom: 16,
    },
    buttonContainer: {
        padding: 10,
        marginTop: 16,
        alignItems: 'flex-end',
    },
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.lychee,
        marginTop: 8,
    },
    modal: {
        height: 400,
        marginTop: 'auto',
    },
    modalDesktop: {
        margin: 'auto',
        paddingBottom: 20,
    },
    controlContainer: {
        alignItems: 'flex-end',
    },
    error: {
        ...typography.body2,
        color: defaultTheme.colors.error,
        marginVertical: 8,
    },
});

export default styles;
