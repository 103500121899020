import styles from './styles';
import IconList from '@Components/IconList';
import SvgProfile from '@Icon/Profile';
import { useGetStudentDetailsQuery } from '@Redux/services/student';
import { ScrollView, StyleProp, Text, View, ViewStyle } from 'react-native';
import i18n from '@I18n/index';
import { ActivityIndicator } from 'react-native-paper';
import { compact } from 'lodash';
import StudentInfo from './components/StudentInfo';
import SvgGuardian from '@Icon/Guardian';
import SvgIconHome from '@Icon/IconHome';
import SvgIconCall from '@Icon/IconCall';
import { formatContactAddress } from '@Utilities/index';
import BulletPoint from '@Components/BulletPoint';
import { defaultTheme, typography } from '@Hooks/useTheme';
import { useContext } from 'react';
import DeviceContext from '@Contexts/DeviceContext';
import { StudentDetails } from '@Redux/types';
import SvgPentagon from '@Icon/Pentagon';

interface IProps {
    studentId: string;
}

interface ContactSectionProps {
    data: StudentDetails;
    style?: StyleProp<ViewStyle>;
}

function ContactSection({ data, style }: ContactSectionProps) {
    return (
        <>
            <View style={style}>
                <IconList
                    icon={<SvgIconHome />}
                    label={i18n.t('homeAddress')}
                    body={formatContactAddress(data.HomeAddress)}
                />
            </View>

            <View style={style}>
                <IconList
                    icon={<SvgIconHome />}
                    label={i18n.t('mailingAddress')}
                    body={formatContactAddress(data.MailingAddress)}
                />
            </View>

            <View style={style}>
                <IconList
                    icon={<SvgIconCall fill={defaultTheme.colors.watermelon} />}
                    label={i18n.t('phone')}
                    body={data.HomeAddress.ContactPhone ? data.HomeAddress.ContactPhone : '-'}
                />
            </View>
        </>
    );
}

const Personal: React.FC<IProps> = ({ studentId }) => {
    const { data: studentDetails } = useGetStudentDetailsQuery({ studentId });
    const { isDesktop } = useContext(DeviceContext);

    if (!studentDetails) {
        return <ActivityIndicator />;
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.margin}>
                {isDesktop ? (
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexBasis: '33%', flexDirection: 'row' }}>
                            <View style={{ marginRight: 24 }}>
                                <SvgProfile />
                            </View>
                            <Text style={typography.h5}>
                                {compact([
                                    studentDetails.LegalFirst,
                                    studentDetails.MiddleName,
                                    studentDetails.LegalLast,
                                ]).join(' ')}
                            </Text>
                        </View>
                        <View style={{ flexBasis: '67%' }}>
                            <StudentInfo studentDetails={studentDetails} />
                        </View>
                    </View>
                ) : (
                    <IconList
                        icon={<SvgProfile />}
                        header={compact([
                            studentDetails.LegalFirst,
                            studentDetails.MiddleName,
                            studentDetails.LegalLast,
                        ]).join(' ')}
                    >
                        <StudentInfo studentDetails={studentDetails} />
                    </IconList>
                )}
            </View>

            <View style={styles.margin}>
                {isDesktop ? (
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexBasis: '33%', flexDirection: 'row' }}>
                            <View style={{ marginRight: 24 }}>
                                <SvgGuardian />
                            </View>
                            <Text style={typography.h5}>{i18n.t('guardian')}</Text>
                        </View>
                        <View style={{ flexBasis: '67%', flexDirection: 'row' }}>
                            {studentDetails.Guardians.map((guardian, i) => {
                                if (i === 0) {
                                    return <Text style={typography.body}>{guardian.Name}</Text>;
                                } else {
                                    return (
                                        <>
                                            <SvgPentagon width={11} style={{ marginHorizontal: 12 }} />
                                            <Text style={typography.body}>{guardian.Name}</Text>
                                        </>
                                    );
                                }
                            })}
                        </View>
                    </View>
                ) : (
                    <IconList icon={<SvgGuardian />} header={i18n.t('guardian')}>
                        {studentDetails.Guardians.map((guardian, i) => (
                            <BulletPoint key={i} label={guardian.Name} />
                        ))}
                    </IconList>
                )}
            </View>

            {isDesktop ? (
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    <ContactSection data={studentDetails} style={[styles.margin, styles.itemDesktop]} />
                </View>
            ) : (
                <ContactSection data={studentDetails} style={styles.margin} />
            )}
        </ScrollView>
    );
};

export default Personal;
