import useTheme from '@Hooks/useTheme';
import SvgPlus from '@Icon/Plus';
import SvgBuilding6 from '@Svg/Building6';
import React from 'react';
import { Text, View } from 'react-native';
import RegisterChild from '../../screens/Student/components/RegisterChild';
import LinkStudent from '../../screens/Student/components/LinkChild';
import { styles } from './styles';
import i18n from '@I18n';
import { Brush } from '@Icon/index';

export const StudentListEmpty = () => {
    const theme = useTheme();

    return (
        <View style={styles.container}>
            <View style={styles.messageContianer}>
                <View>
                    <Text style={styles.header}>{i18n.t('getStarted')}</Text>
                    <Text style={styles.body}>{i18n.t('addRegisterStudent')}</Text>
                    <View style={styles.buttonMargin}>
                        <LinkStudent
                            buttonColor={theme.colors.watermelon}
                            buttonLabelStyle={styles.buttonLabel}
                            buttonLabel={i18n.t('emptyLinkChildCta')}
                            buttonLeftIcon={<SvgPlus />}
                        />
                    </View>
                    <RegisterChild
                        buttonLabelStyle={styles.buttonLabel}
                        buttonLabel={i18n.t('emptyRegisterChildCta')}
                        buttonColor={theme.colors.grape}
                        buttonLeftIcon={<Brush fill={theme.colors.grape} />}
                    />
                </View>
                <View>
                    <SvgBuilding6 />
                </View>
            </View>
        </View>
    );
};

export default StudentListEmpty;
