import HtmlView from '@Components/HtmlView/HtmlView';
import { defaultTheme } from '@Hooks/useTheme';
import { FC } from 'react';
import { Text, View } from 'react-native';
import styles from '../styles';
import i18n from '@I18n';
import { Policy } from '@Icon/index';

interface IProps {
    EventName: string;
    ShowDisaclaimer: boolean;
    Disclaimer?: string;
}

const Disclaimer: FC<IProps> = ({ ShowDisaclaimer, Disclaimer, EventName }) => (
    <>
        {ShowDisaclaimer && Disclaimer && (
            <View style={[styles.iconContainer, styles.sectionMargin]}>
                <Policy fill={defaultTheme.colors.lychee} style={styles.iconSpace} />
                <View style={{ flex: 1 }}>
                    <Text style={styles.subheader}>{i18n.t('consent')}</Text>
                    <HtmlView showReadMore={true} htmlString={Disclaimer.replace('<EVENTNAME>', EventName)} />
                </View>
            </View>
        )}
    </>
);

export default Disclaimer;
