import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgMenu = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M.53 19.14v-3.234h23.03v3.234H.53Zm0-5.518v-3.234h23.03v3.234H.53Zm0-5.518V4.87h23.03v3.234H.53Z'
            fill='#373639'
        />
    </Svg>
);
export default SvgMenu;
