import { ComponentProps } from 'react';
import { Pressable, Text, View } from 'react-native';

import i18n from '@I18n';
import Eye from '@Icon/Eye';

import { styles } from './styles';

type ShowPasswordProps = ComponentProps<typeof Pressable>;

function ShowPassword(props: ShowPasswordProps) {
    return (
        <Pressable {...props}>
            <View style={styles.iconContainer}>
                <Eye />
                <Text style={styles.iconText} accessibilityLabel={i18n.t('show')}>
                    {i18n.t('show')}
                </Text>
            </View>
        </Pressable>
    );
}

export default ShowPassword;
