import { Platform, Pressable, Text, View } from 'react-native';
import styles from './styles';
import { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import { AppleLogoWhite, AndroidLogoWhite, Close } from '@Icon/index';

const MobileAppBanner = () => {
    const allowBanner = useMediaQuery({ maxWidth: 600 })
    const inset = useSafeAreaInsets();
    const [opacity, setOpacity] = useState(0);
    const [visible, setVisible] = useState(false);
    const [storeUrl, setStoreUrl] = useState('')

    useEffect(() => {
        if (Platform.OS !== 'web') {
            return;
        }

        const lastTime = localStorage.getItem('bannerTimestamp');
        if (lastTime !== null) {
            const lastTimestamp = Number(lastTime);
            if (new Date().getTime() - lastTimestamp < 86400000) {
                return;
            }
        }

        // User agent detection is generally fragile, however there's currently no better way to do this
        if (/android/i.test(window.navigator?.platform) || /android/i.test(window.navigator?.userAgent)) {
            setStoreUrl('https://play.google.com/store/apps/details?id=com.ucdsb.myfamilyroom');
        }
        if (
            (/iPad|iPhone|iPod/.test(window.navigator?.platform) ||
                /iPad|iPhone|iPod/.test(window.navigator?.userAgent)) &&
            !window.MSStream
        ) {
            setStoreUrl('https://apps.apple.com/ca/app/my-family-room-ucdsb/id1064091979');
        }

        setVisible(true);
        setTimeout(() => {
            setOpacity(1);
        }, 500);
    }, []);

    const handlePress = () => {
        window.location.href = storeUrl;
    };

    const handleClose = () => {
        localStorage.setItem('bannerTimestamp', new Date().getTime().toString());
        setOpacity(0);
        setTimeout(() => setVisible(false), 1500);
    };

    return !visible || !allowBanner ? (
        <></>
    ) : (
        <div
            style={{
                width: '100%',
                opacity: opacity,
                paddingBottom: inset.bottom,
                transition: 'opacity 1.45s',
            }}
        >
            <View style={styles.innerContainer}>
                <Pressable onPress={handlePress} >
                    <View style={styles.messageContainer}>
                        <AndroidLogoWhite style={{ marginRight: 4 }} />
                        <AppleLogoWhite style={{ marginRight: 8 }} />
                        <Text style={styles.message}>
                            It's best to{' '}
                            <Text style={[styles.message, {borderBottomColor: '#FFFFFF', borderBottomWidth: 1, borderStyle: 'dashed' }]} >use the app</Text>
                        </Text>
                    </View>
                </Pressable>
                <Pressable onPress={handleClose}>
                    <Close fill={'#FFFFFF'} width={15} />
                </Pressable>
            </View>
        </div>
    );
};

export default MobileAppBanner;
