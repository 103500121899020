import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        margin: 24,
        marginTop: 32,
        paddingBottom: 48,
    },
    title: {
        ...typography.h2,
    },
    subtitle: {
        ...typography.body,
        marginTop: 16,
    },
    fieldContainer: {
        marginTop: 32,
        marginBottom: 64,
    },
    controlContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 36,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    forgotTextContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    forgotText: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    lock: {
        fontSize: 24,
        marginLeft: 8,
    },
    error: {
        ...typography.body2,
        color: defaultTheme.colors.error,
        marginVertical: 8,
    },
});
