import React, { FC, useCallback, useEffect } from 'react';
import { View, Text } from 'react-native';
import i18n from '@I18n';
import { cloneDeep } from 'lodash';

import ContactInfo, { ContactInfoMasks, ContactInfoValidator } from './components/ContactInfo';
import SwitchInput from '@Components/SwitchInput';

import type { PartialDeep } from 'type-fest';
import { CreateStudentRegistrationBody, StudentRegistrationContact } from '@Redux/services/StudentRegistrationApi';

import { StepProps, ManageSwitchInputProps } from '../../types';
import { styles } from '../../styles';
import useTheme, { typography } from '@Hooks/useTheme';
import Button from '@Components/Button';
import SvgPlus from '@Icon/Plus';

const EmergenctContactsInfo: FC<StepProps> = ({
    setStudentRegistration,
    manageFieldPropFactory,
    studentRegistration,
    textFieldPropFactory,
}) => {
    const theme = useTheme();
    const handleAddContact = useCallback(() => {
        const newStudentRegistration = cloneDeep(studentRegistration);
        newStudentRegistration.EmergencyContactInformation ??= {};
        newStudentRegistration.EmergencyContactInformation.EmergencyContacts ??= [];
        newStudentRegistration?.EmergencyContactInformation?.EmergencyContacts.push({} as StudentRegistrationContact);
        setStudentRegistration(newStudentRegistration);
    }, [studentRegistration, setStudentRegistration]);

    useEffect(() => {
        if (studentRegistration?.EmergencyContactInformation?.IsParentEmergencyContact !== null) {
            return;
        }
        setStudentRegistration({
            ...studentRegistration,
            EmergencyContactInformation: {
                ...studentRegistration?.EmergencyContactInformation,
                IsParentEmergencyContact: true,
            },
        });
    }, [studentRegistration, setStudentRegistration]);

    useEffect(() => {
        if (studentRegistration?.EmergencyContactInformation?.Parent?.CanPickUpChild !== null) {
            return;
        }
        setStudentRegistration({
            ...studentRegistration,
            EmergencyContactInformation: {
                ...studentRegistration?.EmergencyContactInformation,
                Parent: {
                    ...studentRegistration?.EmergencyContactInformation.Parent,
                    CanPickUpChild: true,
                },
            },
        });
    }, [studentRegistration, setStudentRegistration]);

    const handleRemoveContactFactory = useCallback(
        (idx: number) => () => {
            const newStudentRegistration = cloneDeep(studentRegistration);
            newStudentRegistration?.EmergencyContactInformation?.EmergencyContacts?.splice(idx, 1);
            setStudentRegistration(newStudentRegistration);
        },
        [studentRegistration, setStudentRegistration]
    );

    return (
        <View>
            <Text style={[typography.h5, { marginBottom: 24 }]}>{i18n.t('studentRegisterContactDetails')}</Text>
            <View style={styles.formGroup}>
                <ContactInfo
                    accessor={['EmergencyContactInformation', 'Parent']}
                    manageFieldPropFactory={manageFieldPropFactory}
                    textFieldPropFactory={textFieldPropFactory}
                />
                <SwitchInput
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['EmergencyContactInformation', 'IsParentEmergencyContact'],
                        'onChange',
                        'checked'
                    )}
                    reverse
                    viewStyle={styles.checkbox}
                    label={i18n.t('studentRegisterIsParentEmergencyContact')}
                />
            </View>
            {studentRegistration?.EmergencyContactInformation?.EmergencyContacts?.map((_, idx) => (
                <View style={styles.formGroup} key={`emergency-concact-${idx}`}>
                    <Button
                        labelStyle={typography.buttonMedium}
                        style={{ marginBottom: 24 }}
                        onPress={handleRemoveContactFactory(idx)}
                        label={i18n.t('studentRegisterRemoveContact')}
                    />
                    <ContactInfo
                        accessor={['EmergencyContactInformation', 'EmergencyContacts', idx]}
                        manageFieldPropFactory={manageFieldPropFactory}
                        textFieldPropFactory={textFieldPropFactory}
                    />
                    <SwitchInput
                        {...manageFieldPropFactory<ManageSwitchInputProps>(
                            ['EmergencyContactInformation', 'EmergencyContacts', idx, 'CanPickUpChild'],
                            'onChange',
                            'checked'
                        )}
                        viewStyle={styles.checkbox}
                        label={i18n.t('studentRegisterCanPickUpChild')}
                        reverse
                    />
                </View>
            ))}
            {(studentRegistration?.EmergencyContactInformation?.EmergencyContacts?.length || 0) < 4 && (
                <Button
                    leftIcon={<SvgPlus />}
                    style={{ marginBottom: 24 }}
                    labelStyle={typography.buttonMedium}
                    onPress={handleAddContact}
                    label={i18n.t('studentRegisterAddContact')}
                    underlineColor={theme.colors.watermelon}
                />
            )}
        </View>
    );
};

export const EmergencyContactsInfoValidator = (studentRegistration: PartialDeep<CreateStudentRegistrationBody>) => {
    const EmergencyContactsInfoValidatorResult = [...ContactInfoValidator(['EmergencyContactInformation', 'Parent'])];
    studentRegistration?.EmergencyContactInformation?.EmergencyContacts?.forEach((_, idx) => {
        EmergencyContactsInfoValidatorResult.push(
            ...ContactInfoValidator(['EmergencyContactInformation', 'EmergencyContacts', idx])
        );
    });
    return EmergencyContactsInfoValidatorResult;
};

export const EmergencyContactsInfoMasks = (studentRegistration: PartialDeep<CreateStudentRegistrationBody>) => {
    const EmergencyContactsInfoMasksResult = [...ContactInfoMasks(['EmergencyContactInformation', 'Parent'])];
    studentRegistration?.EmergencyContactInformation?.EmergencyContacts?.forEach((_, idx) => {
        EmergencyContactsInfoMasksResult.push(
            ...ContactInfoMasks(['EmergencyContactInformation', 'EmergencyContacts', idx])
        );
    });
    return EmergencyContactsInfoMasksResult;
};

export default EmergenctContactsInfo;
