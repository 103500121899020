import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    buttonLabel: {
        ...typography.buttonSmall,
    },
    container: {
        flexDirection: 'row',
    },
    noButtonMargin: {
        marginLeft: 16,
    },
});
