import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { View, Text, Pressable, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import TextInput from '@Components/TextInput';
import { styles } from './styles';
import i18n from '@I18n';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { provinceList } from '@App/constants';
import { RootStackParamList } from '@App/rootNavigation';
import Picker from '@Components/Picker';
import { useRegisterMutation } from '@Redux/services/account';
import ArrowRight from '@Icon/ArrowRight';
import { withLayoutSm } from '@Screens/common';
import {
    getGoogleMapsPlaceByAutocomplete,
    getGoogleMapsPlaceDetail,
    phoneMask,
    PlaceSuggestion,
    postalMask,
} from '@Utilities';
import uuid from 'react-native-uuid';
import { debounce } from 'lodash';
import AutoComplete from '@Components/AutoComplete';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getItemAsync } from 'expo-secure-store';
import CheckboxWithLabel from '@Components/SwitchInput/components/CheckboxWithLabel';
import ErrorContext from '@Contexts/ErrorContext';

type RegisterScreenProp = NativeStackScreenProps<RootStackParamList, 'register'>;

function generateSessionId() {
    return uuid.v4().toString();
}

async function getPlaceSuggestion(
    val: string,
    mapSessionId: string,
    setMapSessionId: Dispatch<SetStateAction<string>>,
    setPlaceSuggestions: Dispatch<SetStateAction<PlaceSuggestion[]>>,
    errorCallback: (arg: string) => void
) {
    let sessionToken;
    if (!mapSessionId) {
        sessionToken = generateSessionId();
        setMapSessionId(sessionToken);
    } else {
        sessionToken = mapSessionId;
    }
    const { data, error } = await getGoogleMapsPlaceByAutocomplete(val, sessionToken);
    if (data) {
        setPlaceSuggestions(data.predictions);
        return;
    }
    if (error) {
        errorCallback(error);
        setPlaceSuggestions([]);
        return;
    }
}

const debouncedGetPlaceSuggestion = debounce(getPlaceSuggestion, 1000, { trailing: true });

function RegisterScreen({ navigation, route }: RegisterScreenProp) {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const [register] = useRegisterMutation();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [province, setProvince] = useState('951030000');
    const [postalCode, setPostalCode] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [isConsent, setIsConsent] = useState(false);

    const [mapSessionId, setMapSessionId] = useState('');
    const [placeSuggestions, setPlaceSuggestions] = useState<PlaceSuggestion[]>([]);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

    async function handleRegister() {
        let password;
        if (!phone.match(/\d{3}-\d{3}-\d{4}/)) {
            setPhoneErrorMessage(i18n.t('phoneInvalidFormat'));
            return;
        } else {
            setPhoneErrorMessage('');
        }
        try {
            if (Platform.OS === 'web') {
                password = await AsyncStorage.getItem('tempPassword');
            } else {
                password = await getItemAsync('tempPassword');
            }
        } catch (e) {
            // TODO: fallback mechanism
            console.log('Error managing password');
        }
        if (!password) {
            return;
        }
        const data = {
            FirstName: firstName,
            LastName: lastName,
            Email: route.params.email,
            Password: password,
            Phone: phone,
            Address: address,
            City: city,
            Province: province,
            PostalCode: postalCode,
            Country: 'Canada',
        };

        try {
            await register(data).unwrap();
            navigation.navigate('signin');
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackRegistrationErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        }
    }

    useEffect(() => {
        if (isConsent && firstName && lastName && phone && address && city && province && postalCode) {
            setValidForm(true);
        } else {
            setValidForm(false);
        }
    }, [firstName, lastName, phone, address, city, province, postalCode, isConsent]);

    // TODO: Proper error handling for map API
    const handleError = (error: string) => {
        console.log(error);
    };

    const handleAddress = (val: string) => {
        debouncedGetPlaceSuggestion(val, mapSessionId, setMapSessionId, setPlaceSuggestions, handleError);
        setAddress(val);
    };

    const getPlaceDetail = async (placeId: string) => {
        const { data, error } = await getGoogleMapsPlaceDetail(placeId, mapSessionId);
        if (error) {
            handleError(error);
            return;
        }
        if (data) {
            const addressComponent = data.result.address_components;
            const pc = addressComponent.find((i) => i.types.includes('postal_code'))?.long_name || '';
            setPostalCode(pc);
            const prov =
                addressComponent.find((i) => i.types.includes('administrative_area_level_1'))?.short_name || 'ON';
            const provinceCode = provinceList.find((i) => i.shortCode === prov)?.value || '951030000';
            setProvince(provinceCode);
            const loc = addressComponent.find((i) => i.types.includes('locality'))?.long_name || '';
            setCity(loc);
            const streetNumber = addressComponent.find((i) => i.types.includes('street_number'))?.long_name || '';
            const routeAddress = addressComponent.find((i) => i.types.includes('route'))?.long_name || '';
            if (streetNumber && routeAddress) {
                setAddress(`${streetNumber} ${routeAddress}`);
            } else if (routeAddress) {
                setAddress(routeAddress);
            }
        }
        setMapSessionId('');
    };

    const handleAutoComplete = (item: PlaceSuggestion) => {
        setPlaceSuggestions([]);
        setAddress(item.description);
        getPlaceDetail(item.place_id);
    };

    return (
        <KeyboardAwareScrollView
            extraHeight={300}
            enableOnAndroid={true}
            nestedScrollEnabled={true}
            keyboardShouldPersistTaps='handled'
        >
            {withLayoutSm(
                <View style={styles.container}>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('register1')}>
                            {i18n.t('register1')}
                        </Text>
                    </View>
                    <View style={styles.fieldContainer}>
                        <TextInput
                            label={i18n.t('firstName')}
                            accessibilityLabel={i18n.t('firstName')}
                            autoCorrect={false}
                            autoCapitalize='words'
                            mode='outlined'
                            value={firstName}
                            onChangeText={(val) => setFirstName(val)}
                        />
                        <TextInput
                            label={i18n.t('lastName')}
                            accessibilityLabel={i18n.t('lastName')}
                            autoCorrect={false}
                            autoCapitalize='words'
                            mode='outlined'
                            value={lastName}
                            onChangeText={(val) => setLastName(val)}
                        />
                        <TextInput
                            label={i18n.t('phone')}
                            accessibilityLabel={i18n.t('phone')}
                            keyboardType='numeric'
                            autoCorrect={false}
                            mode='outlined'
                            value={phone}
                            onChangeText={(val) => setPhone(phoneMask(val))}
                            error={Boolean(phoneErrorMessage)}
                        />
                        {Boolean(phoneErrorMessage) && (
                            <View style={styles.errorLabelContainer}>
                                <Text style={styles.errorLabel}>{phoneErrorMessage}</Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.addressContainer}>
                        <AutoComplete
                            data={placeSuggestions}
                            value={address}
                            onChangeText={handleAddress}
                            flatListProps={{
                                nestedScrollEnabled: true,
                                style:
                                    Platform.OS === 'android'
                                        ? styles.autoCompleteListAndroid
                                        : styles.autoCompleteList,
                                keyboardShouldPersistTaps: 'always',
                                keyExtractor: (item: PlaceSuggestion) => item.place_id,
                                renderItem: ({ item }: { item: PlaceSuggestion }) => (
                                    <Pressable onPress={() => handleAutoComplete(item)}>
                                        <View style={styles.autoCompleteDescriptionContainer}>
                                            <Text style={styles.autoCompleteDescription}>{item.description}</Text>
                                        </View>
                                    </Pressable>
                                ),
                            }}
                            inputLabel={i18n.t('address')}
                            placeholder={i18n.t('address')}
                        />
                        <TextInput
                            label={i18n.t('city')}
                            accessibilityLabel={i18n.t('city')}
                            autoCorrect={false}
                            mode='outlined'
                            value={city}
                            onChangeText={(val) => setCity(val)}
                        />
                        <Picker
                            inputLabel={i18n.t('province')}
                            value={province}
                            onValueChange={(val) => setProvince(val)}
                            items={provinceList}
                            placeholder={{}}
                            disabled={placeSuggestions.length > 0}
                        />
                        <TextInput
                            label={i18n.t('postalCode')}
                            accessibilityLabel={i18n.t('postalCode')}
                            autoCorrect={false}
                            autoCapitalize='characters'
                            mode='outlined'
                            value={postalCode}
                            onChangeText={(val) => setPostalCode(postalMask(val))}
                        />
                    </View>
                    <CheckboxWithLabel
                        styleOverride={styles.consentContainer}
                        checked={isConsent}
                        onChange={setIsConsent}
                        label={i18n.t('register2')}
                    />
                    <View style={styles.controlContainer}>
                        {validForm && (
                            <Pressable onPress={handleRegister}>
                                <View style={styles.buttonContainer}>
                                    <View style={styles.registerContainer}>
                                        <Text style={styles.registerText} accessibilityLabel={i18n.t('completeSignup')}>
                                            {i18n.t('completeSignup')}
                                        </Text>
                                    </View>
                                    <ArrowRight style={styles.arrowRight} />
                                </View>
                            </Pressable>
                        )}
                    </View>
                </View>
            )}
        </KeyboardAwareScrollView>
    );
}

export default RegisterScreen;
