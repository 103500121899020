import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconEventTypeSchoolboard = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M20.95 17.375v-6.85L12 15.4.275 9 12 2.625 23.725 9v8.375H20.95ZM12 21.35 4.65 17.4v-4.625l7.35 4 7.35-4V17.4L12 21.35Z'
            fill='#D77A7F'
        />
    </Svg>
);
export default SvgIconEventTypeSchoolboard;
