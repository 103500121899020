import React, { FC } from 'react';
import { format } from 'date-fns';

import SummaryItem, { SummaryItemProps } from '../SummaryItem';

type SummaryItemDateProps = SummaryItemProps & {
    date?: string | Date;
    pattern?: string;
};

const SummaryItemDate: FC<SummaryItemDateProps> = ({ date, pattern = 'LLL dd, yyyy', ...rest }) => {
    if (!date) {
        return null;
    }

    const formattedDate = format(new Date(date), pattern);

    return <SummaryItem {...rest} value={formattedDate} />;
};

export default SummaryItemDate;
