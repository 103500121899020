import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgScissor = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.515 0h4.97L12 3.515v4.97l-.14.141 15.84 15.84V26.5H23l-9.133-9.133-2.008 2.007.141.14v4.971L8.485 28h-4.97L0 24.485v-4.97L3.515 16h4.97l.008.007L10.5 14l-2.007-2.007-.008.007h-4.97L0 8.485v-4.97L3.515 0Zm5.09 6.005a2.6 2.6 0 1 1-5.2 0 2.6 2.6 0 0 1 5.2 0Zm9.995 6.662L15.2 9.3 23 1.5h4.733v2.033L18.6 12.667Zm-5.152 0h1.104l.781.78v1.105l-.78.781h-1.105l-.781-.78v-1.105l.78-.781ZM6.005 24.605a2.6 2.6 0 1 0 0-5.2 2.6 2.6 0 0 0 0 5.2Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgScissor;
