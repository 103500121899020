import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    calendarWrapper: {
        flex: 1,
        paddingTop: 15,
    },
    eventFlatList: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    eventList: {
        flex: 1,
    },
    desktopPeriodNavigator: {
        height: 50,
        width: 50,
        justifyContent: 'center',
        position: 'relative',
        zIndex: 1,
    },
});
