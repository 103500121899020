import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgCapitalize = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M5.9 25.5v-4.2h20.2v4.2H5.9Zm2.933-6.333L14.3 4.5h3.667l5.466 14.667h-4.166l-1-3.134h-4.5l-1.034 3.134h-3.9ZM14.5 13.2h3.067L16.1 8.6h-.133L14.5 13.2Z'
            fill='#37363987'
        />
    </Svg>
);
export default SvgCapitalize;
