import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    calendarWrapper: {
        flex: 1,
        paddingTop: 15,
        flexDirection: 'column',
    },
    weekWrapper: {
        flex: 1,
        flexDirection: 'row',
        minHeight: 60,
    },
    dayWrapper: {
        aspectRatio: 1 / 1,
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
        padding: 8,
    },
    weekdayHeaderWrapper: {
        height: 36,
        flexDirection: 'row',
    },
    weekdayHeader: {
        flex: 1,
        paddingHorizontal: 8,
    },
    weekdayHeaderInner: {
        borderBottomColor: 'black',
        borderBottomWidth: 2,
    },
    weekdayHeaderLabel: {
        ...typography.h5,
        paddingBottom: 12,
    },
});
