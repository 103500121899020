import { Notification } from '@Redux/types';
import { store } from '@Redux';
import React, { useContext, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { format } from 'date-fns';
import styles from './styles';
import Button from '@Components/Button';
import Checkmark from '@Icon/Checkmark';
import Close from '@Icon/Close';
import useTheme, { defaultTheme } from '@Hooks/useTheme';
import i18n from '@I18n';
import { ABSENT_REASONS } from '@App/constants';
import { useMarkAsReadMutation } from '@Redux/services/notification';
import useToggle from '@Hooks/useToggle';
import UnPlannedAbsenceForm from '@Screens/Dashboard/Absence/UnPlanned';
import { alert } from '@Redux/alert';
import ContactModal from '@Screens/Dashboard/Absence/Contact';
import { useRespondToAbsenceAsNotExcusedMutation } from '@Redux/services/notification';
import ErrorContext from '@Contexts/ErrorContext';

interface IProps {
    notification: Notification;
    onMarkedRead: () => void;
    onIsReviewedChange: () => void;
}

const NotificationItem: React.FC<IProps> = ({ onIsReviewedChange, onMarkedRead, notification }) => {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const [isRespondToAbsenceLoading, setIsRespondToAbsenceLoading] = useState(false);
    const { show, toggle } = useToggle(false);
    const [showContact, setShowContact] = useState(false);
    const [respondToAbsenceAsNotExcused] = useRespondToAbsenceAsNotExcusedMutation();
    const theme = useTheme();

    const [markasRead] = useMarkAsReadMutation();

    const subjectStyle = notification.IsReviewed ? styles.subject : styles.subjectBold;

    const handleNotificationPress = async () => {
        if (!notification.IsReviewed && notification.IsActionable && notification.Classification === 4) {
            onIsReviewedChange();
            try {
                await markasRead({ UUID: notification.UUID }).unwrap();
                onMarkedRead();
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackMarkNotificationAsReadErrorMessage');
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            }
        }
    };

    const handleAcknowledge = () => {
        toggle();
        store.dispatch(alert({ showAlert: true, message: i18n.t('successAcknowledgement') }));
    };

    const handleRespondNoPress = async () => {
        if (notification.ActivityId && !isRespondToAbsenceLoading) {
            setIsRespondToAbsenceLoading(true);
            try {
                await respondToAbsenceAsNotExcused({ activityId: notification.ActivityId }).unwrap();
                setShowContact(true);
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackNotExcusedAbsenceErrorMessage');
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            } finally {
                setIsRespondToAbsenceLoading(false);
            }
        }
    };

    const toggleShowContactModal = () => {
        setShowContact(!showContact);
    };

    if (
        !notification.IsReviewed &&
        notification.IsActionable &&
        notification.Classification === 0 &&
        notification.AbsenceDate
    ) {
        return null;
    }

    return (
        <Pressable onPress={handleNotificationPress}>
            <View style={styles.container}>
                <View style={styles.dateContainer}>
                    <Text style={[styles.dateTime, subjectStyle]}>
                        {format(new Date(notification.Date), 'E MMM dd, HH:mm')}
                    </Text>
                    {!notification.IsReviewed && <View style={styles.unreadIcon} />}
                </View>
                <Text minimumFontScale={1} style={subjectStyle}>
                    {notification.Subject}
                </Text>
                {!notification.IsReviewed &&
                    notification.IsActionable &&
                    notification.Classification === 0 &&
                    notification.AbsenceDate && (
                        <>
                            <Text style={[styles.responseText, subjectStyle]}>{i18n.t('confirmAbsence')}</Text>
                            <View style={styles.actionContainer}>
                                <Button
                                    label={i18n.t('no')}
                                    underlineColor={theme.colors.lychee}
                                    style={styles.buttonNo}
                                    onPress={handleRespondNoPress}
                                    leftIcon={<Close width={18} height={18} fill={defaultTheme.colors.lychee} />}
                                />
                                <Button
                                    underlineColor={theme.colors.watermelon}
                                    label={i18n.t('yes')}
                                    onPress={() => !isRespondToAbsenceLoading && toggle()}
                                    leftIcon={<Checkmark fill={defaultTheme.colors.watermelon} />}
                                />
                            </View>
                        </>
                    )}

                {notification.IsReviewed && notification.NotificationAnswer === 0 && (
                    <Text style={styles.responseText}>
                        {i18n.t('notificationAbsenceAckowledgeMessage', {
                            date: notification.ReviewDateString,
                            reason: notification.ProvidedReasonCode
                                ? ABSENT_REASONS[notification.ProvidedReasonCode]
                                : null,
                        })}
                    </Text>
                )}
                {notification.IsReviewed && notification.NotificationAnswer === 1 && (
                    <Text style={[styles.responseText, styles.subject]}>
                        {i18n.t('notificationAbsenceNotAckowledgeMessage')}
                    </Text>
                )}
            </View>
            {show && (
                <UnPlannedAbsenceForm
                    onAcknowledge={handleAcknowledge}
                    onDismiss={toggle}
                    activityId={notification.ActivityId}
                    show={show}
                />
            )}
            <ContactModal
                visible={showContact}
                subject={notification.Subject}
                schoolPhone={notification.SchoolPhone}
                schoolName={notification.SchoolName}
                onDismiss={toggleShowContactModal}
            />
        </Pressable>
    );
};

export default NotificationItem;
