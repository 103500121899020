import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBuildingGovernment = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path d='M11.6 6h10.8l4.2 12H7.4l4.2-12Z' fill='#D77A7F' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.716 15.058h-4v15.217H14.51v-4.764H19.5v4.764h13.785V15.058H4.715Zm6.153 7.594c0 1.425-.832 2.58-1.86 2.58-1.026 0-1.858-1.155-1.858-2.58 0-1.426.832-2.581 1.859-2.581 1.027 0 1.859 1.155 1.859 2.58Zm14.14 2.58c1.027 0 1.86-1.155 1.86-2.58 0-1.426-.832-2.581-1.86-2.581-1.026 0-1.858 1.155-1.858 2.58 0 1.426.832 2.581 1.859 2.581Z'
            fill='#D77A7F'
        />
        <Path d='M27.455 4.86h-6.91v4.28h6.91V4.86Z' fill='#D77A7F' />
    </Svg>
);
export default SvgBuildingGovernment;
