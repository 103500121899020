import Picker from '@Components/Picker';
import SwitchInput from '@Components/SwitchInput';
import useToggle from '@Hooks/useToggle';
import { currencyFormat } from '@Utilities';
import React, { useEffect, useMemo } from 'react';
import { FC } from 'react';
import { Text, View } from 'react-native';
import { CostGroup } from '../Interfaces';
import styles from './styles';
import i18n from '@I18n';
import { Divider } from 'react-native-paper';
import CheckboxWithLabel from '@Components/SwitchInput/components/CheckboxWithLabel';

interface IProps {
    costGroup: CostGroup;
    type: string;
    expanded?: boolean;
    disabled: boolean;
    index: number;
    showDivider: boolean;
    onChange: (value: any, attribute: string) => void;
    onCollapse: (
        type: string,
        costGroup: CostGroup,
        index: number,
        quantity: number,
        defaultSelectedGroups?: Record<string | number, any>
    ) => void;
}
const CostItem: FC<IProps> = ({ costGroup, disabled, showDivider, expanded, type, index, onChange, onCollapse }) => {
    const { show, toggle } = useToggle(false);

    const getOptions = (possibleOptions: number[]) => {
        const options = [];
        for (const possibleOption of possibleOptions) {
            if (type === 'required' && possibleOption !== 1) {
                options.push({ label: possibleOption.toString(), value: possibleOption.toString() });
            } else if (type !== 'required' && possibleOption !== 0) {
                options.push({ label: possibleOption.toString(), value: possibleOption.toString() });
            }
        }
        return options;
    };

    useEffect(() => {
        onCollapse(type, costGroup, index, 0, show ? costGroup.defaultSelectedGroups : {});
    }, [show]);

    const handlePickerChange = (value: any, values: any, selectedGroups: any) => {
        const groupValue = values.find((val: any) => val.value === value);
        if (!groupValue) return;
        onChange({ ...selectedGroups, [groupValue.name]: groupValue }, 'selectedGroups');
    };

    const handleSwitchChange = (value: any, group: any, selectedGroups: any) => {
        onChange(
            { ...selectedGroups, [group.selectedLabel]: { selectedLabel: group.selectedLabel, value: value } },
            'selectedGroups'
        );
    };

    const hasMoreThanOneSelectField = useMemo(
        () => costGroup.groups.filter((group) => group.type !== 'label').length > 1,
        [costGroup]
    );

    return (
        <View style={styles.inputs}>
            <View style={[styles.costItem]}>
                {type === 'required' ? (
                    <Text style={styles.costItemLabel}>{costGroup.ucdsb_title}</Text>
                ) : (
                    <SwitchInput
                        label={costGroup.ucdsb_title}
                        checked={expanded ? expanded : show}
                        onChange={toggle}
                        viewStyle={styles.switchContainer}
                        pressableStyle={styles.switchPressable}
                    />
                )}
                <Text style={styles.costItemLabel}>
                    {i18n.t('costPerItem', { amount: currencyFormat(costGroup.ucdsb_unitcost) })}
                </Text>
            </View>

            {(expanded ? expanded : show) && (
                <>
                    <Picker
                        disabled={disabled}
                        style={styles.inputs}
                        inputLabel={i18n.t('quantity')}
                        placeholder={type !== 'required' ? { label: '0', value: 0 } : { label: '1', value: 1 }}
                        items={getOptions(costGroup.maxQuantity ? costGroup.maxQuantity : [])}
                        value={costGroup.ucdsb_quantity ? costGroup.ucdsb_quantity.toString() : undefined}
                        onValueChange={(value) => onChange(value, 'ucdsb_quantity')}
                        placeHolderStyle={styles.pickerDefaultLabel}
                    />
                    {costGroup.groups.map((group, i) => (
                        <View key={`event-group-${group.name}-${i}`}>
                            {group.type !== 'label' && (
                                <Picker
                                    style={styles.inputs}
                                    key={`cost-item-picker-${i}`}
                                    disabled={disabled}
                                    inputLabel={i18n.t('optionLabel', {
                                        value: hasMoreThanOneSelectField ? ` ${group.number}` : '',
                                    })}
                                    placeholder={{ ...group.values[0] }}
                                    placeHolderStyle={styles.pickerDefaultLabel}
                                    value={
                                        costGroup.selectedGroups &&
                                        costGroup.selectedGroups[group.name] &&
                                        costGroup.selectedGroups[group.name]?.selectedLabel
                                    }
                                    items={'values' in group ? group.values.slice(1) : []}
                                    onValueChange={(value) =>
                                        handlePickerChange(value, group.values, costGroup.selectedGroups)
                                    }
                                />
                            )}
                        </View>
                    ))}
                    <View style={styles.checkboxContainer}>
                        {costGroup.groups.map(
                            (group) =>
                                group.type === 'label' && (
                                    <CheckboxWithLabel
                                        styleOverride={styles.checkbox}
                                        checked={
                                            costGroup.selectedGroups &&
                                            costGroup.selectedGroups[group.selectedLabel] &&
                                            costGroup.selectedGroups[group.selectedLabel]?.value
                                        }
                                        onChange={(checked) =>
                                            handleSwitchChange(checked, group, costGroup.selectedGroups)
                                        }
                                        disabled={disabled}
                                        label={group.name}
                                    />
                                )
                        )}
                    </View>
                </>
            )}
            {showDivider && (show || expanded) && <Divider style={styles.divider} />}
        </View>
    );
};

export default CostItem;
