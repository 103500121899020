import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgCalendar = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.355-.33h-3.21V3.7H.2v19.643h19.643V3.7h-2.988V-.33h-3.21V3.7h-7.29V-.33ZM18.637 8.9H1.363v10.7h17.274V8.9ZM8.622 14.453c-.34-.36-.833-.58-1.48-.66l1.7-2.02v-.87h-4.77v1.09h3.18l-1.59 1.89v.89h.64c.513 0 .893.093 1.14.28.253.18.38.433.38.76a.915.915 0 0 1-.4.78c-.26.186-.62.28-1.08.28a3.61 3.61 0 0 1-1.13-.18 2.91 2.91 0 0 1-.92-.51l-.56 1.01c.327.253.72.453 1.18.6.467.14.95.21 1.45.21.6 0 1.107-.097 1.52-.29.42-.2.733-.467.94-.8.207-.334.31-.704.31-1.11 0-.54-.17-.99-.51-1.35Zm2.78 3.13c.44.28.94.42 1.5.42s1.06-.14 1.5-.42c.447-.287.797-.7 1.05-1.24.254-.547.38-1.194.38-1.94 0-.747-.127-1.39-.38-1.93-.253-.547-.603-.96-1.05-1.24-.44-.287-.94-.43-1.5-.43s-1.06.143-1.5.43c-.44.28-.786.693-1.04 1.24-.253.54-.38 1.183-.38 1.93 0 .746.127 1.393.38 1.94.254.54.6.953 1.04 1.24Zm2.68-1.33a1.36 1.36 0 0 1-1.18.62c-.493 0-.886-.207-1.18-.62-.287-.414-.43-1.03-.43-1.85 0-.82.143-1.437.43-1.85.293-.414.687-.62 1.18-.62.5 0 .894.206 1.18.62.293.413.44 1.03.44 1.85 0 .82-.146 1.436-.44 1.85Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgCalendar;
