import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBuilding1 = (props: SvgProps) => (
    <Svg width={99} height={100} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path d='M49.382 9.04 23.26 28.022l9.978 30.708h32.29l9.978-30.708L49.382 9.04Z' fill='#A28AC7' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.647 45.173h-12v45.654H40.86V70.24h17.122v20.587H98.353V45.173H12.647Zm18.46 22.782c0 4.276-2.497 7.742-5.577 7.742s-5.577-3.466-5.577-7.742c0-4.275 2.497-7.741 5.577-7.741s5.576 3.466 5.576 7.742Zm42.423 7.742c3.08 0 5.576-3.466 5.576-7.742 0-4.275-2.496-7.741-5.576-7.741-3.08 0-5.577 3.466-5.577 7.742 0 4.275 2.497 7.741 5.577 7.741Z'
            fill='#F99F9B'
        />
        <Path d='M80.865 14.58h-20.73v12.84h20.73V14.58Z' fill='#FF5F4D' />
    </Svg>
);
export default SvgBuilding1;
