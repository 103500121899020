import { useMemo } from 'react';
import useToggle from '@Hooks/useToggle';
import i18n from '@I18n';
import SvgIconChevronRight from '@Icon/IconChevronRight';
import SvgNeutralFace from '@Icon/NeutralFace';
import { Absence } from '@Redux/types';
import { differenceInCalendarDays, differenceInHours, format, isPast } from 'date-fns';
import { Pressable, Text, View } from 'react-native';
import AbsenceModal from '../AbsenceModal';
import styles from './styles';

interface IProps {
    absence: Absence;
    parseDateTime: (date: string) => Date;
    onSave: () => void;
    parentId: string;
    onlySubmittingParentEditsAttendance: boolean;
}

const AbsenceItem = ({ absence, parseDateTime, onSave, parentId, onlySubmittingParentEditsAttendance }: IProps) => {
    const { show, toggle } = useToggle(false);
    const start = parseDateTime(absence.Start);
    const end = parseDateTime(absence.End);
    const isSingleDay = format(start, 'yyyyMMdd') === format(end, 'yyyyMMdd');

    const isEditable = useMemo(
        () => (onlySubmittingParentEditsAttendance ? parentId === absence.SubmittedBy && !isPast(end) : !isPast(end)),
        [absence, parentId, onlySubmittingParentEditsAttendance]
    );

    return (
        <>
            <Pressable onPress={toggle}>
                <View style={styles.base}>
                    <View style={styles.container}>
                        <SvgNeutralFace style={styles.icon} />
                        <View>
                            <Text style={styles.header}>{i18n.t('plannedAbsenceHeader')}</Text>
                            <Text style={styles.body2}>
                                {isSingleDay
                                    ? format(start, 'EEE MMM dd')
                                    : `${format(start, 'EEE MMM dd')} - ${format(end, 'EEE MMM dd')}`}
                            </Text>
                            <Text style={styles.body2}>
                                {isSingleDay
                                    ? `${format(start, 'hh:mm')} (${differenceInHours(end, start)}h)`
                                    : `${i18n.t('allDay')} (${differenceInCalendarDays(end, start) + 1}d)`}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <SvgIconChevronRight />
                    </View>
                </View>
            </Pressable>
            <AbsenceModal
                absence={absence}
                showModal={show}
                onEdit={onSave}
                onDismiss={toggle}
                parseDateTime={parseDateTime}
                isEditable={isEditable}
            />
        </>
    );
};

export default AbsenceItem;
