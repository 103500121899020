import Avatar from '@Components/Avatar';
import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

interface IProps {
    firstName: string;
    photo?: string;
}
const HeaderTitle: React.FC<IProps> = ({ firstName, photo }) => (
    <View style={styles.container}>
        <Avatar label={firstName} picture={photo} size={30} style={styles.avatar}></Avatar>
        <Text style={styles.label}>{firstName}</Text>
    </View>
);

export default HeaderTitle;
