import useToggle from '@Hooks/useToggle';
import TextInput from '@Components/TextInput';
import { Pressable, View } from 'react-native';
import Choices from './Choices';
import { format } from 'date-fns';
import { useState } from 'react';

interface IProps {
    value: Date;
    customText?: string;
    header: string;
    label: string;
    disabled?: boolean;
    onDateChange: (date: Date | string) => void;
    firstChoiceLabel: string;
}

const TimePicker: React.FC<IProps> = ({
    value,
    customText,
    header,
    firstChoiceLabel,
    label,
    disabled,
    onDateChange,
}) => {
    const { show, toggle } = useToggle(false);
    const [initialSelection, setInitialSelection] = useState(0);
    const getTime = (date: Date) => {
        return format(date, 'hh:mm a');
    };
    return (
        <View>
            <Pressable onPress={() => toggle()}>
                <TextInput
                    disabled={disabled}
                    mode='outlined'
                    autoFocus={false}
                    showSoftInputOnFocus={false}
                    label={label}
                    value={customText ? customText : getTime(value)}
                    onPressOut={() => toggle()}
                />
            </Pressable>
            {show && (
                <Choices
                    value={value}
                    header={header}
                    firstChoicelabel={firstChoiceLabel}
                    onDismiss={() => toggle()}
                    initialSelection={initialSelection}
                    setInitialSelection={setInitialSelection}
                    onDateChange={onDateChange}
                />
            )}
        </View>
    );
};

export default TimePicker;
