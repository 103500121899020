import React from 'react';
import { SafeAreaView, LayoutChangeEvent, View, ViewStyle, ScrollView, LayoutRectangle } from 'react-native';
import Button from '@Components/Button';
import useTheme from '@Hooks/useTheme';
import styles from './styles';
import { StyleProp } from 'react-native';

interface TabItem {
    label: string;
    component: React.ReactNode;
    highlightTab?: boolean;
}

export interface IProps {
    tabs: TabItem[];
    rootStyle?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    onChangeIndex?: (index: number) => void;
    defaultIndex?: number;
    enableScroll?: boolean;
}

const Tabs: React.FC<IProps> = ({ tabs, enableScroll, defaultIndex, rootStyle, containerStyle, onChangeIndex }) => {
    const [index, setIndex] = React.useState<number>(defaultIndex || 0);
    const theme = useTheme();
    const buttonRefs = React.useRef(new Array(tabs.length));
    const scrollViewRef = React.useRef<ScrollView>(null);
    const [buttonlayouts, setButtonLayouts] = React.useState<LayoutRectangle[]>(new Array(tabs.length));

    const goTo = React.useCallback(
        (ind: number) => {
            setIndex(ind);
            onChangeIndex && onChangeIndex(ind);
        },
        [setIndex, onChangeIndex]
    );

    const handleTabChange = (tabIndex: number) => {
        scrollViewRef.current?.scrollTo({
            x: buttonlayouts[tabIndex]?.x - 100,
            animated: true,
        });
        goTo(tabIndex);
    };

    const handleLayoutChange = (event: LayoutChangeEvent, tabIndex: number) => {
        const newButtonLayouts = [...buttonlayouts];
        newButtonLayouts[tabIndex] = event.nativeEvent.layout;
        setButtonLayouts(newButtonLayouts);
    };

    return (
        <SafeAreaView style={{ height: '100%' }}>
            <View style={[styles.root, rootStyle]}>
                <ScrollView horizontal={true} ref={scrollViewRef} showsHorizontalScrollIndicator={false}>
                    {tabs.map((tab, tabIndex) => (
                        <View
                            ref={(el) => (buttonRefs.current[tabIndex] = el)}
                            key={tabIndex}
                            onLayout={(e) => handleLayoutChange(e, tabIndex)}
                            style={{ marginRight: 16 }}
                        >
                            <Button
                                label={tab.label}
                                onPress={() => handleTabChange(tabIndex)}
                                underlineColor={index == tabIndex ? theme.colors.grape : undefined}
                            />
                            {tab.highlightTab && <View style={styles.highlight}></View>}
                        </View>
                    ))}
                </ScrollView>
            </View>
            {enableScroll ? (
                <ScrollView
                    scrollEnabled={true}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={[styles.childContainer, containerStyle]}
                >
                    {tabs[index].component}
                </ScrollView>
            ) : (
                tabs[index].component
            )}
        </SafeAreaView>
    );
};

export default Tabs;
