import React, { FC, useEffect, useMemo } from 'react';

import { View, Text } from 'react-native';
import i18n from '@I18n';

import { StepProps } from '../../types';
import { styles } from '../../styles';
import {
    BasicLookup,
    Province,
    useGetAboriginalSelfIdsQuery,
    useGetCountriesQuery,
    useGetGendersQuery,
    useGetGradesQuery,
    useGetLanguageTypesQuery,
    useGetLanguagesQuery,
    useGetPreferredGendersQuery,
    useGetProvincesQuery,
    useGetRelationshipsQuery,
} from '@Redux/services/LookupApi';
import { createLookupMap } from '@Utilities';

import {
    SummaryItem,
    SummaryItemBoolean,
    SummaryItemLocalAddress,
    SummaryItemMailingAddress,
    SummaryItemDate,
} from './components';
import { typography } from '@Hooks/useTheme';
import SummaryHeader from './components/SummaryHeader';
import { useGetSchoolsLocationsQuery } from '@Redux/services/StudentRegistrationApi';

const Summary: FC<StepProps> = ({ studentRegistration, setActiveStepIdx, setStudentRegistration }) => {
    const preferredName = useMemo(
        () =>
            [
                studentRegistration?.ChildInformation?.PreferredFirstName,
                studentRegistration?.ChildInformation?.PreferredSurname,
            ]
                .join(' ')
                .trim(),
        [studentRegistration]
    );

    useEffect(() => {
        if (studentRegistration.ChildInformation?.Grade === 951030015) {
            setStudentRegistration({
                ...studentRegistration,
                ChildInformation: {
                    ...studentRegistration.ChildInformation,
                    Grade: 951030000,
                },
            });
        }
    }, [studentRegistration, setStudentRegistration]);

    const { data: provinces } = useGetProvincesQuery();
    const provinceMap = useMemo(() => createLookupMap<Province>(provinces), [provinces]);

    const { data: languages } = useGetLanguagesQuery();
    const languagesMap = useMemo(() => createLookupMap<BasicLookup>(languages), [languages]);

    const { data: languageTypes } = useGetLanguageTypesQuery();
    const languageTypesMap = useMemo(() => createLookupMap<BasicLookup>(languageTypes), [languageTypes]);

    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);

    const countryOfBirth = countryMap[studentRegistration?.CitizenshipInformation?.CountryOfBirth as number];
    const countryOfCitizenship =
        countryMap[studentRegistration?.CitizenshipInformation?.CountryOfCitizenship as number];
    const motherTongue = languagesMap[studentRegistration?.CitizenshipInformation?.FirstLanguage as number];
    const languageAtHome = languagesMap[studentRegistration?.CitizenshipInformation?.LanguageAtHome as number];

    const { data: grades } = useGetGradesQuery();
    const gradesMap = useMemo(() => createLookupMap<BasicLookup>(grades), [grades]);

    const { data: genders } = useGetGendersQuery();
    const gendersMap = useMemo(() => createLookupMap<BasicLookup>(genders), [genders]);

    const { data: preferredGenders } = useGetPreferredGendersQuery();
    const preferredGendersMap = useMemo(() => createLookupMap<BasicLookup>(preferredGenders), [preferredGenders]);

    const { data: relationships } = useGetRelationshipsQuery();
    const relationshipsMap = useMemo(() => createLookupMap<BasicLookup>(relationships), [relationships]);

    const { data: aboriginalSelfIds } = useGetAboriginalSelfIdsQuery();
    const aboriginalSelfIdMap = useMemo(() => createLookupMap<BasicLookup>(aboriginalSelfIds), [aboriginalSelfIds]);

    const { data: schoolsLocations } = useGetSchoolsLocationsQuery();
    const schoolInfo = useMemo(() => {
        if (schoolsLocations && studentRegistration?.SchoolInformation?.Id) {
            for (const schoolLocation of schoolsLocations) {
                if (schoolLocation.Id === studentRegistration?.SchoolInformation?.Id) {
                    return schoolLocation;
                }
            }
        }
        return { SchoolName: '-', Address: '' };
    }, [schoolsLocations, studentRegistration]);
    return (
        <View style={{ paddingTop: 24 }}>
            <View>
                <SummaryHeader
                    label={i18n.t('studentRegisterSummaryStudentInfo')}
                    onEditClick={() => setActiveStepIdx(1)}
                />
                <View style={[styles.formGroup, styles.summary]}>
                    <SummaryItem
                        label={i18n.t('firstName')}
                        value={studentRegistration?.ChildInformation?.LegalFirstName}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterMiddleName')}
                        value={studentRegistration?.ChildInformation?.MiddleName}
                    />
                    <SummaryItem
                        label={i18n.t('lastName')}
                        value={studentRegistration?.ChildInformation?.LegalSurname}
                    />
                    <SummaryItem label={i18n.t('studentRegisterPreferredName')} value={preferredName} />
                    <SummaryItem
                        label={i18n.t('studentRegisterPhoneNumber')}
                        value={studentRegistration?.ChildInformation?.PhoneNumber}
                    />
                    <SummaryItemDate
                        label={i18n.t('studentRegisterDOB')}
                        date={studentRegistration?.ChildInformation?.DateOfBirth}
                    />
                    {studentRegistration?.ChildInformation?.PreferredGender ? (
                        <SummaryItem
                            label={i18n.t('studentRegisterPreferredGender')}
                            value={
                                studentRegistration?.ChildInformation?.PreferredGender &&
                                preferredGendersMap[studentRegistration?.ChildInformation?.PreferredGender]?.Value
                            }
                        />
                    ) : (
                        <SummaryItem
                            label={i18n.t('studentRegisterPreferredGender')}
                            value={
                                studentRegistration?.ChildInformation?.Gender &&
                                gendersMap[studentRegistration?.ChildInformation?.Gender]?.Value
                            }
                        />
                    )}
                    <SummaryItem
                        label={i18n.t('studentRegisterGrade')}
                        value={
                            studentRegistration?.ChildInformation?.Grade &&
                            gradesMap[studentRegistration?.ChildInformation?.Grade]?.Value
                        }
                    />
                    <SummaryItemDate
                        label={i18n.t('startDate')}
                        date={studentRegistration?.ChildInformation?.SchoolStartDate}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterGeneralComments')}
                        value={studentRegistration?.ChildInformation?.GeneralComments}
                    />
                    <SummaryItemBoolean
                        label={i18n.t('studentRegisterIsInternational')}
                        display={studentRegistration?.ChildInformation?.IsInternational}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterAllergies')}
                        value={studentRegistration?.ChildInformation?.Allergies}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterLifeThreateningMedicalDetails')}
                        value={studentRegistration?.ChildInformation?.LifeThreateningMedicalDetails}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterNonLifeThreateningMedicalDetails')}
                        value={studentRegistration?.ChildInformation?.NonLifeThreateningMedicalDetails}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterAccessibilityChallengesDetails')}
                        value={
                            studentRegistration?.ChildInformation?.IsChildHasAccessibilityNeeds
                                ? studentRegistration?.ChildInformation?.AccessibilityChallengesDetails
                                : undefined
                        }
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterGeneralComments')}
                        value={studentRegistration?.ChildInformation?.GeneralComments}
                    />
                </View>
            </View>
            <View>
                <SummaryHeader
                    label={i18n.t('studentRegisterSummaryAddressInfo')}
                    onEditClick={() => setActiveStepIdx(2)}
                />
                <SummaryItemLocalAddress {...studentRegistration.AddressInformation?.ChildAddress} />
                <SummaryItemMailingAddress
                    label={i18n.t('studentRegisterParentAddress')}
                    {...studentRegistration.AddressInformation?.ParentAddress}
                    display={studentRegistration?.AddressInformation?.IsParentAddressDifferent}
                />

                <SummaryItemMailingAddress
                    label={i18n.t('studentMailingAddress')}
                    {...studentRegistration.AddressInformation?.ChildMailingAddress}
                    display={studentRegistration?.AddressInformation?.IsMailingAddressDifferent}
                />
            </View>
            <View>
                <SummaryHeader
                    label={i18n.t('studentRegisterTitleTransportationInfo')}
                    onEditClick={() => setActiveStepIdx(3)}
                />
                <View style={styles.formGroup}>
                    <SummaryItemBoolean
                        label={i18n.t('studentRegisterNoTransportRequired')}
                        display={
                            !studentRegistration?.TransportationInformation?.IsPickupNeeded &&
                            !studentRegistration?.TransportationInformation?.IsDropOffNeeded
                        }
                    />
                    <SummaryItemLocalAddress
                        label={i18n.t('studentRegisterPickUpAddress')}
                        {...studentRegistration?.TransportationInformation?.PickUpAddress}
                        display={Boolean(studentRegistration?.TransportationInformation?.IsPickupNeeded)}
                    />
                    <View style={[styles.formGroup, styles.summary]}>
                        <SummaryItem
                            label={i18n.t('studentRegisterContactName')}
                            value={studentRegistration?.TransportationInformation?.PickUpContactName}
                        />

                        <SummaryItem
                            label={i18n.t('studentRegisterContactPhone')}
                            value={studentRegistration?.TransportationInformation?.PickUpContactPhone}
                        />
                    </View>
                    <SummaryItemLocalAddress
                        label={i18n.t('studentRegisterDropOffAddress')}
                        {...studentRegistration?.TransportationInformation?.DropOffAddress}
                        display={Boolean(studentRegistration?.TransportationInformation?.IsDropOffNeeded)}
                    />
                    <View style={[styles.formGroup, styles.summary]}>
                        <SummaryItem
                            label={i18n.t('studentRegisterContactName')}
                            value={studentRegistration?.TransportationInformation?.DropOffContactName}
                        />
                        <SummaryItem
                            label={i18n.t('studentRegisterContactPhone')}
                            value={studentRegistration?.TransportationInformation?.DropOffContactPhone}
                        />
                    </View>
                </View>
            </View>
            <View>
                <SummaryHeader
                    label={i18n.t('studentRegisterTitleAdmissionInfo')}
                    onEditClick={() => setActiveStepIdx(4)}
                />
                <View style={[styles.formGroup, styles.summary]}>
                    <SummaryItem
                        label={i18n.t('studentRegisterPrevSchoolDistrict')}
                        value={studentRegistration?.AdmissionInformation?.PreviousDistrictName}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterPrevSchoolName')}
                        value={studentRegistration?.AdmissionInformation?.PreviousSchool}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterPrevSchoolAddress')}
                        value={studentRegistration?.AdmissionInformation?.Address}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterPrevSchoolLanguage')}
                        value={
                            languageTypesMap[studentRegistration?.AdmissionInformation?.PreviousLanguageType as number]
                                ?.Value
                        }
                    />
                </View>
            </View>
            <View>
                <SummaryHeader
                    label={i18n.t('studentRegisterTitleCitizenshipInfo')}
                    onEditClick={() => setActiveStepIdx(5)}
                />
                <View style={[styles.formGroup, styles.summary]}>
                    <SummaryItem label={i18n.t('studentRegisterCountryOfBirth')} value={countryOfBirth?.Value} />
                    <SummaryItem
                        label={i18n.t('studentRegisterProvinceOfBirth')}
                        value={
                            studentRegistration?.CitizenshipInformation?.ProvinceOfBirth &&
                            provinceMap[studentRegistration?.CitizenshipInformation?.ProvinceOfBirth]?.Value
                        }
                        display={countryOfBirth?.Value === 'Canada'}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterCountryOfCitizenship')}
                        value={countryOfCitizenship?.Value}
                    />
                    <SummaryItem
                        label={i18n.t('studentRegisterAboriginalSelfId')}
                        value={
                            aboriginalSelfIdMap[studentRegistration?.CitizenshipInformation?.AboriginalSelfId as number]
                                ?.Value
                        }
                        display={Boolean(studentRegistration?.CitizenshipInformation?.AboriginalSelfId)}
                    />
                    <SummaryItem label={i18n.t('studentRegisterMotherTounge')} value={motherTongue?.Value} />
                    <SummaryItem label={i18n.t('studentRegisterLanguageAtHome')} value={languageAtHome?.Value} />
                    <SummaryItemDate
                        label={i18n.t('studentRegisterProvinceEntry')}
                        date={studentRegistration?.CitizenshipInformation?.DateOfEntryIntoProvince}
                    />
                    <SummaryItemDate
                        label={i18n.t('studentRegisterCountryEntry')}
                        date={studentRegistration?.CitizenshipInformation?.DateOfEntryIntoCanada}
                    />
                </View>
            </View>
            <View style={styles.formGroup}>
                <SummaryHeader label={i18n.t('studentRegisterTitleSchool')} onEditClick={() => setActiveStepIdx(6)} />
                <Text style={styles.summaryLabelBold}>{schoolInfo?.SchoolName}</Text>
                <Text style={[typography.body2, { marginTop: 8 }]}>{schoolInfo?.Address}</Text>
            </View>

            <View style={styles.formGroup}>
                <SummaryHeader
                    label={i18n.t('studentRegisterSummaryGuardianContactInfo')}
                    onEditClick={() => setActiveStepIdx(7)}
                />
                <View style={styles.summary}>
                    <SummaryItemBoolean
                        label={i18n.t('studentRegisterIsParentEmergencyContact')}
                        display={studentRegistration?.EmergencyContactInformation?.IsParentEmergencyContact}
                    />
                </View>
                {studentRegistration?.EmergencyContactInformation?.IsParentEmergencyContact && (
                    <View style={styles.summary}>
                        <SummaryItem
                            label={i18n.t('studentRegisterGuardianEmailAddress')}
                            value={studentRegistration?.EmergencyContactInformation?.ParentEmailAddress}
                        />
                        <SummaryItem
                            label={i18n.t('studentRegisterRelationshipToStudent')}
                            value={
                                relationshipsMap[
                                    studentRegistration?.EmergencyContactInformation?.Parent
                                        ?.RelationshipToStudent as number
                                ]?.Value
                            }
                        />
                        <SummaryItem
                            label={i18n.t('firstName')}
                            value={studentRegistration?.EmergencyContactInformation?.Parent?.FirstName}
                        />
                        <SummaryItem
                            label={i18n.t('lastName')}
                            value={studentRegistration?.EmergencyContactInformation?.Parent?.Surname}
                        />

                        <SummaryItem
                            label={i18n.t('studentRegisterContactPhoneNumberPrimary')}
                            value={studentRegistration?.EmergencyContactInformation?.Parent?.PrimaryPhoneNumber}
                        />
                        <SummaryItem
                            label={i18n.t('studentRegisterContactPhoneNumberMobile')}
                            value={studentRegistration?.EmergencyContactInformation?.Parent?.MobilePhoneNumber}
                        />
                        <SummaryItem
                            label={i18n.t('studentRegisterContactPhoneNumberWork')}
                            value={studentRegistration?.EmergencyContactInformation?.Parent?.WorkPhoneNumber}
                        />
                        <SummaryItem
                            label={i18n.t('studentRegisterContactPhoneNumberWorkExtension')}
                            value={studentRegistration?.EmergencyContactInformation?.Parent?.WorkExtension}
                        />
                        <SummaryItem
                            label={i18n.t('studentRegisterContactLanguage')}
                            value={
                                languagesMap[
                                    studentRegistration?.EmergencyContactInformation?.Parent?.Language as number
                                ]?.Value
                            }
                        />
                        <SummaryItem
                            label={i18n.t('studentRegisterCanPickUpChild')}
                            value={
                                studentRegistration?.EmergencyContactInformation?.Parent?.CanPickUpChild
                                    ? i18n.t('yes')
                                    : i18n.t('no')
                            }
                        />
                    </View>
                )}
            </View>
            <View style={styles.formGroup}>
                {studentRegistration?.EmergencyContactInformation?.EmergencyContacts?.map((contact, idx) => (
                    <>
                        <Text style={[typography.buttonMedium, { marginBottom: 12 }]}>
                            {i18n.t('emergencyContact', { index: idx + 1 })}
                        </Text>
                        <View style={styles.summary}>
                            <SummaryItem label={i18n.t('firstName')} value={contact.FirstName} />
                            <SummaryItem label={i18n.t('lastName')} value={contact.Surname} />

                            <SummaryItem
                                label={i18n.t('studentRegisterContactPhoneNumberPrimary')}
                                value={contact.PrimaryPhoneNumber}
                            />
                            <SummaryItem
                                label={i18n.t('studentRegisterContactPhoneNumberMobile')}
                                value={contact.MobilePhoneNumber}
                            />
                            <SummaryItem
                                label={i18n.t('studentRegisterContactPhoneNumberWork')}
                                value={contact.WorkPhoneNumber}
                            />
                            <SummaryItem
                                label={i18n.t('studentRegisterContactPhoneNumberWorkExtension')}
                                value={contact.WorkExtension}
                            />
                            <SummaryItem
                                label={i18n.t('studentRegisterContactLanguage')}
                                value={languagesMap[contact.Language as number]?.Value}
                            />
                            <SummaryItem
                                label={i18n.t('studentRegisterCanPickUpChild')}
                                value={contact.CanPickUpChild ? i18n.t('yes') : i18n.t('no')}
                            />
                        </View>
                    </>
                ))}
            </View>
        </View>
    );
};

export default Summary;
