import { useContext, useEffect, useState } from 'react';
import { View, Text, Pressable, Linking } from 'react-native';

import { TextInput as PaperTextInput } from 'react-native-paper';
import { RootState, store } from '@Redux';
import { loginWithEmail } from '@Redux/auth';
import { useLoginWithPasswordMutation } from '@Redux/services/account';
import i18n from '@I18n';
import { styles } from './styles';
import { Person, ArrowRight, IconMoney, Brush, Calendar, Home, NeutralFace, IconVideo } from '@Icon';

import { Building1, Building2, Building3, Building5 } from '@Svg';

import ShowPassword from '@Components/ShowPassword';
import TextInput from '@Components/TextInput';
import { RootStackParamList } from '@App/rootNavigation';
import { useSelector } from 'react-redux';

import SocialLoginIcons from '@Components/SocialLoginIcons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Header from '@Components/Header';
import useTheme, { defaultTheme } from '@Hooks/useTheme';
import Header2 from '@Components/Header2';
import DeviceContext from '@Contexts/DeviceContext';
import { IntroDesktopModal } from '@Screens/Intro';
import SignInReminder from '@Components/SignInReminder';
import { AccountLockedHelpURL } from '@App/constants';
import { useMediaQuery } from 'react-responsive';

type SignInWebScreenProp = {
    navigation: NativeStackNavigationProp<RootStackParamList, 'signin', undefined>;
};

const desktopListItems = [
    {
        icon: () => <Brush fill={defaultTheme.colors.grape} />,
        label: i18n.t('registerNewStudent'),
    },
    {
        icon: () => <NeutralFace />,
        label: i18n.t('desktopListItem1'),
    },
    {
        icon: () => <IconMoney />,
        label: i18n.t('desktopListItem2'),
    },
    {
        icon: () => <Home fill={defaultTheme.colors.persimmon} />,
        label: i18n.t('desktopListItem3'),
    },
    {
        icon: () => <Calendar />,
        label: i18n.t('desktopListItem4'),
    },
];

function DesktopComponent() {
    const [showIntro, setShowIntro] = useState(false);
    return (
        <>
            <Building5 />
            <View>
                <View style={styles.section}>
                    <Text style={styles.greetings}>Hi there!</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.label}>Welcome to My Family Room.</Text>
                    <Text style={styles.label}>The place to connect and communicate with your schools.</Text>
                </View>
                <View style={styles.section}>
                    {desktopListItems.map((i, index) => (
                        <View style={styles.listItem} key={`list-${index}`}>
                            <View style={styles.icon}>
                                <i.icon />
                            </View>
                            <View style={styles.labelContainer}>
                                <Text style={styles.label}>{i.label}</Text>
                            </View>
                        </View>
                    ))}
                    <Pressable onPress={() => setShowIntro(true)}>
                        <View style={styles.listItem}>
                            <View style={styles.icon}>
                                <IconVideo />
                            </View>
                            <View style={styles.labelContainer}>
                                <Text style={styles.label}>{i18n.t('clickQuickTour')}</Text>
                            </View>
                        </View>
                    </Pressable>
                </View>
                <View style={styles.section}>
                    <Text style={styles.label}>And much more.</Text>
                    <Text style={styles.label}>Stay connected with My Family Room.</Text>
                </View>
            </View>
            {showIntro && <IntroDesktopModal visible={showIntro} onDismiss={() => setShowIntro(false)} />}
        </>
    );
}

function FormComponent({ navigation }: SignInWebScreenProp) {
    const [hidePassword, setHidePassword] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginWithPassword] = useLoginWithPasswordMutation();
    const lastLogin = useSelector((state: RootState) => state.auth.lastLogin);
    const lastEmail = useSelector((state: RootState) => state.auth.email);
    const socialProvider = useSelector((state: RootState) => state.auth.socialProvider);
    const { isDesktop } = useContext(DeviceContext);

    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [isAccountLocked, setIsAccountLocked] = useState(false);
    const theme = useTheme();

    const heightSmall = useMediaQuery({
        maxHeight: 788,
    });

    const hideNavigation = useMediaQuery({
        maxHeight: 707,
    });

    const heightExtraSmall = useMediaQuery({
        maxHeight: 650,
    });

    useEffect(() => {
        if (lastLogin === 'email') {
            setEmail(lastEmail);
        }
    }, [lastLogin]);

    function handleForgotPassword() {
        navigation.navigate('forgot');
    }

    function validateField() {
        if (!email) {
            setEmailErrorMessage('Email address is required');
            return false;
        } else if (!email.match(/^\S+@\S+\.\S+$/)) {
            setEmailErrorMessage('Email address is invalid');
            return false;
        } else {
            setEmailErrorMessage('');
        }

        if (!password) {
            setPasswordErrorMessage('Password is required');
            return false;
        } else {
            setPasswordErrorMessage('');
        }

        return true;
    }

    async function pressLogin(): Promise<void> {
        const isValid = validateField();
        if (!isValid) {
            return;
        }
        try {
            const response = await loginWithPassword({
                email,
                password,
            }).unwrap();
            store.dispatch(loginWithEmail({ userId: response.UserId, email: email, password: password }));
        } catch (e) {
            setIsAccountLocked(e.data.AccountIsLocked);
            if (e.data.AccountIsLocked) {
                // TODO: Custom error component for email link
                setLoginErrorMessage(i18n.t('loginErrorLocked'));
            } else {
                setLoginErrorMessage(i18n.t('loginErrorGeneric'));
            }
        }
    }

    function handleSignUp() {
        navigation.navigate('signup');
    }

    function BuildingImage() {
        if (isDesktop) {
            return <></>;
        }
        switch (lastLogin) {
            case 'social':
                return <Building3 />;
            case 'email':
                return <Building2 />;
            default:
                return <Building1 />;
        }
    }

    const handlePasswordPressIn = () => {
        if (!isDesktop) {
            setHidePassword(false);
        }
    };

    const handlePasswordPressOut = () => {
        if (!isDesktop) {
            setHidePassword(true);
        }
    };

    const handlePasswordPress = () => {
        if (isDesktop) {
            setHidePassword(!hidePassword);
        }
    };

    const greetings = lastLogin ? i18n.t('greetingsRepeat') : i18n.t('greetings');
    const signUpTitle = heightSmall ? i18n.t('signUpTitleShort') : i18n.t('signUpTitle');

    return (
        <View style={!isDesktop && { flex: 1, justifyContent: 'space-between' }}>
            <View>
                {!isDesktop && !hideNavigation && <Header />}
                {!isDesktop && (
                    <>
                        <View
                            style={[
                                styles.buildingContainer,
                                heightExtraSmall && styles.buildingContainerExtraSmall,
                                hideNavigation && styles.buildingContainerSmall,
                            ]}
                        >
                            <BuildingImage />
                        </View>
                        <Text style={styles.greetings} accessibilityLabel={greetings}>
                            {greetings}
                        </Text>
                        {!lastLogin && (
                            <Text style={styles.greetings2} accessibilityLabel={i18n.t('greetingsSubtitle')}>
                                {i18n.t('greetingsSubtitle')}
                            </Text>
                        )}
                    </>
                )}
                <View>
                    {lastLogin === 'email' && <SignInReminder inverted />}
                    <View style={styles.fieldContainer}>
                        <TextInput
                            label={i18n.t('email')}
                            accessibilityLabel={i18n.t('email')}
                            testID='email-field'
                            keyboardType='email-address'
                            autoCorrect={false}
                            autoCapitalize='none'
                            mode='outlined'
                            value={email}
                            onChangeText={(val) => setEmail(val)}
                            error={Boolean(loginErrorMessage || emailErrorMessage)}
                        />
                        <TextInput
                            onSubmitEditing={pressLogin}
                            label={i18n.t('password')}
                            accessibilityLabel={i18n.t('password')}
                            testID='password-field'
                            secureTextEntry={hidePassword}
                            mode='outlined'
                            right={
                                <PaperTextInput.Icon
                                    name={() => (
                                        <ShowPassword
                                            onPressIn={handlePasswordPressIn}
                                            onPressOut={handlePasswordPressOut}
                                            onPress={handlePasswordPress}
                                        />
                                    )}
                                    style={styles.iconWrapper}
                                />
                            }
                            value={password}
                            onChangeText={(val) => setPassword(val)}
                            error={Boolean(loginErrorMessage || passwordErrorMessage)}
                        />
                        {Boolean(emailErrorMessage || passwordErrorMessage || loginErrorMessage) && (
                            <View style={styles.errorLabelContainer}>
                                <Text style={styles.errorLabel}>
                                    {emailErrorMessage || passwordErrorMessage || loginErrorMessage}
                                    {isAccountLocked && (
                                        <Pressable
                                            style={{ borderBottomWidth: 1, borderBottomColor: theme.colors.backdrop }}
                                            onPress={() => Linking.openURL(AccountLockedHelpURL)}
                                        >
                                            <Text>{i18n.t('accountLockLinkLabel')}</Text>
                                        </Pressable>
                                    )}
                                </Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.controlContainer}>
                        <Pressable testID='forgot-password-button' onPress={handleForgotPassword}>
                            <View style={styles.forgotTextContainer}>
                                <Text style={styles.forgot} accessibilityLabel={i18n.t('forgotPassword')}>
                                    {i18n.t('forgotPassword')}
                                </Text>
                            </View>
                        </Pressable>
                        <Pressable testID='sign-in-button' onPress={pressLogin}>
                            <View style={styles.buttonContainer}>
                                <View style={styles.signInTextContainer}>
                                    <Text style={styles.signIn} accessibilityLabel={i18n.t('signIn')}>
                                        {i18n.t('signIn')}
                                    </Text>
                                </View>
                                <ArrowRight style={styles.arrowRight} />
                            </View>
                        </Pressable>
                    </View>
                    <SocialLoginIcons
                        socialProviderReminder={lastLogin === 'social' && socialProvider}
                        errorCallback={() => setLoginErrorMessage(i18n.t('loginErrorGeneric'))}
                        style={heightSmall && styles.socialLoginContainerSmall}
                    />
                </View>
            </View>
            <View style={[styles.signUpContainer, heightSmall && styles.signUpContainerSmall]}>
                <Text style={styles.signUpTitle} accessibilityLabel={signUpTitle}>
                    {signUpTitle}
                </Text>
                <Pressable testID='sign-up-button' onPress={handleSignUp}>
                    <View style={styles.buttonContainer}>
                        <View style={styles.signUpTextContainer}>
                            <Text style={styles.signUp} accessibilityLabel={i18n.t('signUp')}>
                                {i18n.t('signUp')}
                            </Text>
                        </View>
                        <Person style={styles.signUpIcon} />
                    </View>
                </Pressable>
            </View>
        </View>
    );
}

function SignInWebScreen(props: SignInWebScreenProp) {
    const { isDesktop } = useContext(DeviceContext);

    return (
        <>
            {isDesktop ? (
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        maxWidth: 1280,
                        width: '100%',
                        maxHeight: 756,
                        alignSelf: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <View style={{ width: 488, margin: 12 }}>
                        <DesktopComponent />
                    </View>
                    <View style={{ width: 488, margin: 12, justifyContent: 'space-between' }}>
                        <Header2 />
                        <FormComponent {...props} />
                    </View>
                </View>
            ) : (
                <FormComponent {...props} />
            )}
        </>
    );
}

export default SignInWebScreen;
