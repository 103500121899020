import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    checkbox: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,
    },
    summaryItem: {
        marginVertical: 12,
        width: '50%',
    },
    summary: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    summaryLabel: {
        ...typography.body3,
    },
    summaryLabelBold: {
        marginTop: 12,
        ...typography.buttonMedium,
    },
    summaryTitle: {
        ...typography.h5,
    },
    summaryText: {
        ...typography.body2,
    },
    picker: {
        marginBottom: 12,
    },
    formGroup: {
        marginBottom: 20,
    },
    formGroupInner: {
        marginTop: 20,
    },
    inner: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    screen: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    textInput: {
        marginBottom: 8,
    },
    cta: {
        ...typography.buttonMedium,
    },
    desktopModal: {
        height: '90%',
        width: 600,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
        overflow: 'hidden',
    },
});
