import React, { useContext, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Button as PaperButton, Text } from 'react-native-paper';
import { View, TextStyle } from 'react-native';

import DeviceContext from '@Contexts/DeviceContext';
import AbsenceForm from '@Screens/Dashboard/Absence/Planned';
import Button from '@Components/Button';
import Close from '@Icon/Close';
import Modal from '@Components/Modal';
import SvgAlarm from '@Icon/Alarm';
import SvgNeutralFace from '@Icon/NeutralFace';
import Warning from '@Icon/Warning';
import i18n from '@I18n';

import useTheme, { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    buttonText: {
        ...typography.buttonLarge,
        color: 'white',
    },
    button: {
        padding: 8,
    },
    buttonText2: {
        ...typography.buttonMedium,
        color: 'white',
    },
    notifyButton: {
        position: 'absolute',
        bottom: 18,
        right: 16,
        backgroundColor: defaultTheme.colors.watermelon,
        borderRadius: 30,
        height: 48,
        justifyContent: 'center',
    },
    notifyText: {
        ...typography.body2,
        fontWeight: '600',
        color: defaultTheme.colors.background,
    },
    desktopModal: {
        minHeight: 400,
        minWidth: 400,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
    },
});

interface NotifyProps {
    onPress: () => void;
}

function NotifyButton({ onPress }: NotifyProps) {
    const theme = useTheme();
    return (
        <PaperButton
            style={styles.notifyButton}
            icon={() => <Warning />}
            color={theme.colors.background}
            onPress={onPress}
        >
            <Text style={styles.notifyText}>{i18n.t('notify')}</Text>
        </PaperButton>
    );
}

interface IProps {
    reverseIcon: boolean;
    labelStyle?: TextStyle;
}

function useNotify({ reverseIcon, labelStyle }: IProps = { reverseIcon: false }) {
    const { isDesktop } = useContext(DeviceContext);
    const [showModal, setShowModal] = useState(false);
    const inset = useSafeAreaInsets();

    const [showAbsenceForm, setShowAbsenceForm] = useState(false);
    const [isReportingLate, setIsReportingLate] = useState(false);
    function handleLate() {
        setIsReportingLate(true);
        setShowAbsenceForm(true);
        setShowModal(false);
    }
    function handleAbsence() {
        setIsReportingLate(false);
        setShowAbsenceForm(true);
        setShowModal(false);
    }
    const notifyButtonModal = (
        <Modal
            visible={showModal}
            header={<></>}
            safeArea={false}
            style={{ marginTop: 'auto', marginLeft: 'auto', marginRight: 32 }}
        >
            <View
                style={{
                    marginBottom: inset.bottom,
                    paddingBottom: 68,
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    height: 240,
                }}
            >
                <Button
                    onPress={handleLate}
                    rightIcon={<SvgAlarm />}
                    label={i18n.t('reportLateButton')}
                    labelStyle={styles.buttonText}
                    style={styles.button}
                />
                <Button
                    onPress={handleAbsence}
                    rightIcon={<SvgNeutralFace />}
                    label={i18n.t('addAbsencedButton')}
                    labelStyle={styles.buttonText}
                    style={styles.button}
                />
                <Button
                    onPress={() => setShowModal(false)}
                    leftIcon={<Close fill='#FFFFFF' />}
                    label={'Close'}
                    labelStyle={styles.buttonText2}
                    style={styles.button}
                />
            </View>
        </Modal>
    );
    const notifyButton = <NotifyButton onPress={() => setShowModal(true)} />;

    const absenceForm = (
        <AbsenceForm
            isReportingLate={isReportingLate}
            onDismiss={() => setShowAbsenceForm(false)}
            show={showAbsenceForm}
            style={isDesktop ? [styles.desktopModal, { width: 400 }] : { height: '100%' }}
            animationType='none'
        />
    );

    const lateButton = (
        <Button
            onPress={handleLate}
            leftIcon={!reverseIcon && <SvgAlarm />}
            rightIcon={reverseIcon && <SvgAlarm />}
            label={i18n.t('reportLateButton')}
            underlineColor={defaultTheme.colors.persimmon}
            labelStyle={labelStyle}
        />
    );

    const absentButton = (
        <Button
            onPress={handleAbsence}
            leftIcon={!reverseIcon && <SvgNeutralFace />}
            rightIcon={reverseIcon && <SvgNeutralFace />}
            label={i18n.t('addAbsencedButton')}
            labelStyle={labelStyle}
            underlineColor={defaultTheme.colors.raspberry}
        />
    );

    return {
        absenceForm,
        lateButton,
        absentButton,
        notifyButton,
        notifyButtonModal,
    };
}

export default useNotify;
