import { FC } from 'react';
import useTheme from '@Hooks/useTheme';
import { ActivityIndicator } from 'react-native-paper';
import { FlatList, View } from 'react-native';
import NotificationItem from './components/NotificationItem';
import Empty from './components/Empty';
import { Notification as NotificationType } from '@Redux/types';
import { cloneDeep } from 'lodash';

interface IProps {
    notifications: NotificationType[];
    onMarkedRead: () => void;
    setNotifications: (notifications: NotificationType[]) => void;
}

const Notification: FC<IProps> = ({ setNotifications, notifications, onMarkedRead }) => {
    const theme = useTheme();

    const handleIsReviewedChange = (index: number) => {
        const newNotifications = cloneDeep(notifications);
        newNotifications[index].IsReviewed = true;
        setNotifications(newNotifications);
    };

    return !notifications ? (
        <ActivityIndicator color={theme.colors.lychee} />
    ) : (
        <>
            {notifications && (
                <FlatList
                    showsVerticalScrollIndicator={false}
                    data={notifications}
                    renderItem={({ item, index }) => (
                        <NotificationItem
                            onMarkedRead={onMarkedRead}
                            notification={item}
                            onIsReviewedChange={() => handleIsReviewedChange(index)}
                        />
                    )}
                    ListFooterComponent={() => <View style={{ height: 80 }} />}
                    ListEmptyComponent={<Empty />}
                />
            )}
        </>
    );
};

export default Notification;
