import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    contactModalContainer: {
        paddingHorizontal: 24,
    },
    contactModal: {
        position: 'absolute',
        height: '45%',
        width: '100%',
        borderRadius: 12,
        bottom: 0,
    },
    contactModalSubheader: {
        ...typography.h5,
        marginBottom: 8,
    },
    contactSubject: {
        ...typography.body2,
        marginBottom: 16,
    },
    contactActionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    desktopModal: {
        height: '45%',
        maxWidth: 400,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
    },
});

export default styles;
