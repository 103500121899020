import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    guardian: {
        flexDirection: 'row',
        marginBottom: 8,
    },
    icon: {
        marginRight: 12,
    },
    guardianName: {
        ...typography.body,
    },
    margin: {
        marginBottom: 24,
    },
    itemDesktop: {
        flexBasis: '33%',
    },
});

export default styles;
