import { StyleSheet, Text, View } from 'react-native';
import { useContext, useEffect, useState } from 'react';
import { ContactPreferencesModalData, ContactPreferencesModalFieldOptions } from '../ContactPreferencesModal';
import useTheme, { defaultTheme, typography } from '@Hooks/useTheme';
import i18n from '@I18n';
import { MobileValues } from '../index';
import Checkmark from '@Icon/Checkmark';
import Button from '@Components/Button';
import DeviceContext from '@Contexts/DeviceContext';
import CheckboxWithLabel from '@Components/SwitchInput/components/CheckboxWithLabel';

type ContactPreferencesFormProps = {
    initialValue: ContactPreferencesModalData;
    disabled: ContactPreferencesModalFieldOptions[];
    onSave: (newValue: ContactPreferencesModalData) => void;
    required?: boolean;
    onCancel: () => void;
};

function ContactPreferencesForm({ initialValue, required, disabled, onSave, onCancel }: ContactPreferencesFormProps) {
    const [home, setHome] = useState(initialValue.home);
    const [work, setWork] = useState(initialValue.work);
    const [mobile, setMobile] = useState(['Both', 'Voice'].includes(initialValue.mobile));
    const [text, setText] = useState(['Both', 'SMS'].includes(initialValue.mobile));
    const [email, setEmail] = useState(initialValue.email);
    const [error, setError] = useState<string>();

    const theme = useTheme();

    const { isDesktop } = useContext(DeviceContext);

    useEffect(() => {
        if (required && !home && !work && !email && !mobile && !text) {
            setError(i18n.t('urgentContactPreferenceError'));
        } else {
            setError(undefined);
        }
    }, [home, work, email, mobile, text]);

    function getNewValue(): ContactPreferencesModalData {
        let mobileVal: MobileValues;
        if (mobile && text) {
            mobileVal = 'Both';
        } else if (mobile) {
            mobileVal = 'Voice';
        } else if (text) {
            mobileVal = 'SMS';
        } else {
            mobileVal = 'None';
        }
        return {
            home: home,
            email: email,
            mobile: mobileVal,
            work: work,
        };
    }

    function handleSave() {
        const newValue = getNewValue();
        if (
            required &&
            !Object.values(newValue).some(
                (value) => (typeof value === 'boolean' && value) || (typeof value === 'string' && value !== 'None')
            )
        ) {
            return;
        }
        onSave(newValue);
    }

    function handleCancel() {
        onCancel();
    }

    return (
        <View style={styles.formContainer}>
            <View style={isDesktop ? styles.checkboxContainerDesktop : styles.checkboxContainer}>
                {error && <Text style={styles.error}>{error}</Text>}
                {!disabled.includes('Home') && (
                    <CheckboxWithLabel
                        checked={home}
                        onChange={(val) => setHome(val)}
                        disabled={disabled.includes('Home')}
                        label={i18n.t('home')}
                        styleOverride={isDesktop ? styles.childDesktop : styles.child}
                    />
                )}
                {!disabled.includes('Work') && (
                    <CheckboxWithLabel
                        checked={work}
                        onChange={(val) => setWork(val)}
                        disabled={disabled.includes('Work')}
                        label={i18n.t('work')}
                        styleOverride={isDesktop ? styles.childDesktop : styles.child}
                    />
                )}
                {!disabled.includes('Mobile') && (
                    <>
                        <CheckboxWithLabel
                            checked={mobile}
                            onChange={(val) => setMobile(val)}
                            disabled={disabled.includes('Mobile')}
                            label={i18n.t('mobile')}
                            styleOverride={isDesktop ? styles.childDesktop : styles.child}
                        />

                        <CheckboxWithLabel
                            checked={text}
                            onChange={(val) => setText(val)}
                            disabled={disabled.includes('Mobile')}
                            label={i18n.t('text')}
                            styleOverride={isDesktop ? styles.childDesktop : styles.child}
                        />
                    </>
                )}
                {!disabled.includes('Email') && (
                    <CheckboxWithLabel
                        checked={email}
                        onChange={(val) => setEmail(val)}
                        disabled={disabled.includes('Email')}
                        label={i18n.t('email')}
                        styleOverride={isDesktop ? styles.childDesktop : styles.child}
                    />
                )}
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button label={i18n.t('cancel')} onPress={handleCancel} underlineColor={theme.colors.raspberry} />

                <Button
                    label={i18n.t('save')}
                    onPress={handleSave}
                    underlineColor={theme.colors.watermelon}
                    leftIcon={<Checkmark fill={theme.colors.watermelon} />}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    formContainer: {
        flexDirection: 'column',
        marginBottom: 16,
        width: '100%',
    },
    checkboxContainer: {
        flexDirection: 'row',
        marginBottom: 16,
        width: '100%',
        flexWrap: 'wrap',
    },
    checkboxContainerDesktop: {
        flexDirection: 'column',
        marginBottom: 16,
        width: '100%',
    },
    child: {
        padding: 16,
        flexDirection: 'row',
        alignItems: 'center',
        flexBasis: '50%',
    },
    childDesktop: {
        padding: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    error: {
        ...typography.body2,
        color: defaultTheme.colors.error,
        marginVertical: 8,
    },
});

export default ContactPreferencesForm;
