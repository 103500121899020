import React, { useEffect, useState, useContext, useMemo } from 'react';

import useNotify from '@Hooks/useNotify';

import { View, Text, ScrollView } from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Header from './Header';
import styles from './styles';
import withLayout from '@Screens/common';
import DeviceContext from '@Contexts/DeviceContext';
import i18n from '@I18n';
import ActionItems from './ActionItems';
import Notification from './Notification';
import Tabs from '@Components/Tabs';
import { useGetAllStudentActivitiesQuery } from '@Redux/services/notification';
import { isUndefined } from 'lodash';
import { Notification as NotificationType } from '@Redux/types';
import { ActivityIndicator } from 'react-native-paper';

const Dashboard = () => {
    const { isDesktop } = useContext(DeviceContext);
    const inset = useSafeAreaInsets();
    const [hasUnread, setHasUnread] = useState(false);
    const [notifications, setNotifications] = useState<NotificationType[]>();

    const { data: studentActivities, refetch, isLoading } = useGetAllStudentActivitiesQuery();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (!studentActivities) return;
        setHasUnread(studentActivities?.Notifications?.some((notification) => !notification.IsReviewed));
        setNotifications(studentActivities.Notifications);
    }, [studentActivities]);

    const tabs = useMemo(
        () => [
            {
                label: i18n.t('actionItems'),
                component: <ActionItems />,
                highlightTab: false,
            },
            {
                label: i18n.t('notificationsButtonLabel'),
                component: (
                    <Notification
                        onMarkedRead={() => refetch()}
                        notifications={!isUndefined(notifications) ? notifications : ([] as NotificationType[])}
                        setNotifications={setNotifications}
                    />
                ),
                highlightTab: hasUnread,
            },
        ],
        [hasUnread, notifications]
    );

    const { lateButton, absentButton, absenceForm, notifyButtonModal, notifyButton } = useNotify();

    return isDesktop ? (
        withLayout(
            <View style={{ height: '100%', flex: 1, flexDirection: 'row' }}>
                <View style={{ paddingLeft: 60, paddingRight: 32, width: '100%' }}>
                    <Header lateButton={lateButton} absentButton={absentButton} />
                    <View style={{ width: '100%', flex: 1, flexDirection: 'row', height: '100%' }}>
                        <View style={[{ flexBasis: '30%' }, styles.eventsContainer]}>
                            <Text style={styles.eventsContainerHeader}>{i18n.t('actionItems')}</Text>
                            <ScrollView
                                contentContainerStyle={{ height: '100%' }}
                                horizontal={false}
                                style={{ flex: 1, height: '100%' }}
                                showsVerticalScrollIndicator={false}
                            >
                                <ActionItems />
                            </ScrollView>
                        </View>
                        <View style={[{ flexBasis: '70%', paddingLeft: 16 }]}>
                            <Text style={styles.eventsContainerHeader}>{i18n.t('notificationsButtonLabel')}</Text>
                            {isLoading ? (
                                <ActivityIndicator />
                            ) : (
                                <Notification
                                    onMarkedRead={() => refetch()}
                                    notifications={
                                        !isUndefined(notifications) ? notifications : ({} as NotificationType[])
                                    }
                                    setNotifications={setNotifications}
                                />
                            )}
                        </View>
                    </View>
                </View>
                {absenceForm}
            </View>
        )
    ) : (
        <>
            {notifyButtonModal}
            <View style={[styles.container, { paddingTop: inset.top }]}>
                <Header />
                <Tabs containerStyle={{ height: '150%' }} tabs={tabs} />
                {notifyButton}
            </View>
            {absenceForm}
        </>
    );
};

export default Dashboard;
