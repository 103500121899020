import React from 'react';
import { Text, TextStyle, View, ViewStyle } from 'react-native';
import { styles } from './styles';
import i18n from '@I18n';
import { format, parseISO } from 'date-fns';
import { DailySeriesEvent, OfferStatus } from '@Redux/services/CalendarApi';

interface TimeLabelProps {
    event: DailySeriesEvent;
    isAllDay: boolean;
    date: Date;
}

const formatTime = (date: Date) => {
    return format(date, 'h:mm');
};

const offerStatusViewStyleMap: Record<OfferStatus, ViewStyle | null> = {
    accepted: null,
    expired: styles.timeLabelContainerOfferStatusExpired,
    invited: styles.timeLabelContainerOfferStatusInvited,
};

const offerStatusTextStyleMap: Record<OfferStatus, TextStyle | null> = {
    accepted: null,
    expired: styles.timeLabelOfferStatusExpired,
    invited: null,
};

function DateTimeLabel({ event, isAllDay, date }: TimeLabelProps) {
    const viewStyle: ViewStyle[] = [styles.timeLabelContainer];

    if (event.offerStatus && offerStatusViewStyleMap[event.offerStatus]) {
        viewStyle.push(offerStatusViewStyleMap[event.offerStatus] as ViewStyle);
    }

    const row1Style: TextStyle[] = [styles.row1];

    if (event.offerStatus && offerStatusTextStyleMap[event.offerStatus]) {
        row1Style.push(offerStatusTextStyleMap[event.offerStatus] as TextStyle);
    }

    let row1 = formatTime(parseISO(event.start));

    if (event.isFirstInDailySeries) {
        row1 = formatTime(parseISO(event.start));
    } else if (event.isLastInDailySeries) {
        row1 = formatTime(parseISO(event.end));
    }

    return (
        <View style={[viewStyle]}>
            <View style={styles.date}>
                <Text style={styles.dateLabel}>{format(date, 'EEE MMM d')}</Text>
            </View>
            <Text style={row1Style}>{isAllDay ? i18n.t('allDay') : row1}</Text>
        </View>
    );
}

export default DateTimeLabel;
