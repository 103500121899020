import { useState, useEffect } from 'react';
import { View, Text, Pressable, Platform } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import { TextInput as PaperTextInput } from 'react-native-paper';
import i18n from '@I18n';
import { styles } from './styles';
import ShowPassword from '@Components/ShowPassword';
import TextInput from '@Components/TextInput';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import Capitalize from '@Icon/Capitalize';
import Checkmark from '@Icon/Checkmark';
import Glyphs from '@Icon/Glyphs';
import Hash from '@Icon/Hash';
import ArrowRight from '@Icon/ArrowRight';
import { defaultTheme } from '@Hooks/useTheme';
import { withLayoutSm } from '@Screens/common';
import { setItemAsync } from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useRegisterWithInviteMutation } from '@Redux/services/account';
import CheckboxWithLabel from '@Components/SwitchInput/components/CheckboxWithLabel';

type PasswordScreenProp = NativeStackScreenProps<RootStackParamList, 'password'>;

function PasswordScreen({ navigation, route }: PasswordScreenProp) {
    const [registerWithInvite] = useRegisterWithInviteMutation();
    const [hidePassword, setHidePassword] = useState(true);
    const [password, setPassword] = useState('');
    const [validLength, setValidLength] = useState(false);
    const [validCapital, setValidCapital] = useState(false);
    const [validNumberOrSpecial, setValidNumberOrSpecial] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [error, setError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isConsent, setIsConsent] = useState(!route.params?.gwak);

    function validatePassword(input: string) {
        if (input.length >= 8) {
            setValidLength(true);
        } else {
            setValidLength(false);
        }

        if (input.match(/(?=.*[a-z])(?=.*[A-Z])/)) {
            setValidCapital(true);
        } else {
            setValidCapital(false);
        }

        if (input.match(/[!#$%^&@*_0-9]/)) {
            setValidNumberOrSpecial(true);
        } else {
            setValidNumberOrSpecial(false);
        }
    }

    useEffect(() => {
        validatePassword(password);
    }, [password]);

    async function nextScreen() {
        if (route.params?.gwak) {
            const data = {
                Password: password,
                Gwak: route.params.gwak,
            };
            setDisabled(true);
            try {
                await registerWithInvite(data).unwrap();
                setRegistrationSuccess(true);
                setTimeout(() => {
                    navigation.replace('signin');
                }, 3000);
            } catch (e) {
                setError(e?.data?.Message || 'Error registering');
                console.log(e);
                setDisabled(false);
            }
        } else if (route.params?.email) {
            // Consider making a wrapper if this becomes a common pattern.
            // No secure way of storing password even if temporarily on web
            try {
                if (Platform.OS === 'web') {
                    await AsyncStorage.setItem('tempPassword', password);
                } else {
                    await setItemAsync('tempPassword', password);
                }
            } catch (e) {
                // TODO: fallback mechanism
                console.log('Error managing password');
            }
            navigation.navigate('register', { email: route.params?.email });
        } else {
            setError('No email set. Returning to sign in screen');
            setTimeout(() => {
                navigation.replace('signin');
            }, 3000);
        }
    }

    function handleSetPassword(value: string) {
        setPassword(value.replace(/\s/g, ''));
    }

    return (
        <ScrollView keyboardShouldPersistTaps='handled'>
            {withLayoutSm(
                <View style={styles.container}>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('password1')}>
                            {i18n.t('password1')}
                        </Text>
                    </View>
                    <View style={styles.fieldContainer}>
                        <TextInput
                            label={i18n.t('password')}
                            accessibilityLabel={i18n.t('password')}
                            testID='password-field'
                            secureTextEntry={hidePassword}
                            mode='outlined'
                            right={
                                <PaperTextInput.Icon
                                    name={() => (
                                        <ShowPassword
                                            onPressIn={() => setHidePassword(false)}
                                            onPressOut={() => setHidePassword(true)}
                                        />
                                    )}
                                    style={styles.iconWrapper}
                                />
                            }
                            value={password}
                            onChangeText={handleSetPassword}
                        />
                    </View>
                    <View style={styles.descriptionContainer}>
                        {validLength ? (
                            <Checkmark style={styles.descriptionIcon} fill={defaultTheme.colors.watermelon} />
                        ) : (
                            <Hash style={styles.descriptionIcon} />
                        )}
                        <Text style={styles.descriptionText} accessibilityLabel={i18n.t('password2')}>
                            {i18n.t('password2')}
                        </Text>
                    </View>
                    <View style={styles.descriptionContainer}>
                        {validCapital ? (
                            <Checkmark style={styles.descriptionIcon} fill={defaultTheme.colors.watermelon} />
                        ) : (
                            <Capitalize style={styles.descriptionIcon} />
                        )}
                        <Text style={styles.descriptionText} accessibilityLabel={i18n.t('password3')}>
                            {i18n.t('password3')}
                        </Text>
                    </View>
                    <View style={styles.descriptionContainer}>
                        {validNumberOrSpecial ? (
                            <Checkmark style={styles.descriptionIcon} fill={defaultTheme.colors.watermelon} />
                        ) : (
                            <Glyphs style={styles.descriptionIcon} />
                        )}
                        <Text style={styles.descriptionText} accessibilityLabel={i18n.t('password4')}>
                            {i18n.t('password4')}
                        </Text>
                    </View>
                    {Boolean(route.params?.gwak) && (
                        <CheckboxWithLabel
                            styleOverride={styles.consentContainer}
                            checked={isConsent}
                            onChange={setIsConsent}
                            label={i18n.t('register2')}
                        />
                    )}
                    <View>
                        {Boolean(error) && <Text style={styles.error}>{error}</Text>}
                        {registrationSuccess && <Text style={styles.success}>{i18n.t('registrationSuccessAndRedirect')}</Text>}
                    </View>
                    {validLength && validCapital && validNumberOrSpecial && isConsent && (
                        <View style={styles.controlContainer}>
                            <Pressable onPress={nextScreen} disabled={disabled}>
                                <View style={styles.buttonContainer}>
                                    <View style={styles.confirmPasswordContainer}>
                                        <Text style={styles.confirmPassword} accessibilityLabel={i18n.t('password5')}>
                                            {i18n.t('password5')}
                                        </Text>
                                    </View>
                                    <ArrowRight style={styles.arrowRight} />
                                </View>
                            </Pressable>
                        </View>
                    )}
                </View>
            )}
        </ScrollView>
    );
}

export default PasswordScreen;
