import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        padding: 24,
    },
    header: {
        ...typography.h3,
        paddingBottom: 16,
    },
    sectionMargin: {
        paddingBottom: 24,
    },
    iconContainer: {
        flexDirection: 'row',
    },
    dueDate: {
        ...typography.body3,
        marginBottom: 4,
    },
    body: {
        ...typography.body2,
        marginBottom: 4,
    },
});

export default styles;
