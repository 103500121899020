import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgAppleLogoWhite = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M20.385 7.5c-.128.1-2.38 1.37-2.38 4.192 0 3.265 2.866 4.42 2.952 4.449-.013.07-.456 1.582-1.512 3.122-.941 1.355-1.925 2.708-3.421 2.708-1.496 0-1.881-.869-3.608-.869-1.684 0-2.282.898-3.65.898-1.369 0-2.324-1.254-3.422-2.794-1.272-1.809-2.3-4.618-2.3-7.285 0-4.277 2.782-6.546 5.52-6.546 1.453 0 2.666.955 3.579.955.869 0 2.224-1.012 3.879-1.012.627 0 2.88.057 4.363 2.183Zm-5.149-3.993c.685-.812 1.169-1.938 1.169-3.065 0-.156-.014-.314-.042-.442-1.114.042-2.438.741-3.237 1.668-.627.713-1.212 1.84-1.212 2.981 0 .172.029.343.042.398.07.014.185.029.299.029.999 0 2.255-.669 2.981-1.569Z'
            fill='#fff'
        />
    </Svg>
);
export default SvgAppleLogoWhite;
