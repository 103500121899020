import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgTaskAlt = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m0 11.942 16 9.864 16-9.864-6.363-3.922-1.79 1.79 3.486 2.15L16 18.945 4.667 11.96 16 4.973l3.035 1.871 1.79-1.79L16 2.08 0 11.942Zm10.575.408 4.075 4.05L25.625 5.425l-1.3-1.275-9.675 9.7-2.775-2.775-1.3 1.275Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgTaskAlt;
