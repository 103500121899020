import DeviceContext from '@Contexts/DeviceContext';
import { useContext } from 'react';
import { View } from 'react-native';

function withLayout(children) {
    const { isDesktop } = useContext(DeviceContext);

    return isDesktop ? (
        <View style={{ maxWidth: 1280, width: '100%', alignSelf: 'center', flex: 1 }}>{children}</View>
    ) : (
        <>{children}</>
    );
}

export function withLayoutSm(children: JSX.Element) {
    const { isDesktop } = useContext(DeviceContext);
    return isDesktop ? (
        <View
            style={{
                maxWidth: 528,
                alignSelf: 'center',
                maxHeight: '100%',
                width: '100%',
            }}
        >
            {children}
        </View>
    ) : (
        <>{children}</>
    );
}

export default withLayout;
