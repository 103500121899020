import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    calendarWrapper: {
        flex: 1,
        paddingTop: 15,
    },
    currentIntervalMonth: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 7,
        paddingLeft: 11,
    },
    currentIntervalMonthText: {
        ...typography.h3,
    },
});
