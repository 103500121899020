import { StyleSheet } from 'react-native';
import { defaultTheme } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    checkboxBase: {
        width: 16,
        height: 25,
        justifyContent: 'center',
        borderWidth: 2,
        borderColor: defaultTheme.colors.textPrimary,
        backgroundColor: 'transparent',
    },
    checkboxChecked: {
        backgroundColor: defaultTheme.colors.textPrimary,
    },
});
