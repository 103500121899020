import { Answer } from '@Redux/services/EventOfferApi/types';
import { isBoolean, isString } from 'lodash';
import { FC } from 'react';
import { Text, View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Question as YesNoQuestion } from '@Components/Question';

import styles from '../styles';
import i18n from '@I18n';

interface IProps {
    qa: Answer;
    disabled: boolean;
    expanded?: boolean;
    onSwitchChange: (checked: boolean) => void;
    onCommentChange: (value: string) => void;
    onInputChange: (value: string) => void;
}

const Question: FC<IProps> = ({ qa, disabled, expanded, onSwitchChange, onCommentChange, onInputChange }) => {
    return (
        <View style={[styles.inputs, { marginBottom: 8 }]}>
            {qa.ucdsb_yesnotypeofquestion && (
                <>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexBasis: '65%' }}>
                            <Text style={styles.questionLabel}>{isString(qa.ucdsb_text) ? qa.ucdsb_text : ''}</Text>
                        </View>
                        <View style={{ flexBasis: '30%', alignItems: 'flex-end' }}>
                            <YesNoQuestion
                                onChange={onSwitchChange}
                                choice={isBoolean(qa.ucdsb_answer) ? qa.ucdsb_answer : undefined}
                                disabled={disabled}
                            />
                        </View>
                    </View>
                    {expanded
                        ? expanded
                        : ((qa.ucdsb_iscommentrequired_no && qa.ucdsb_answer === false) ||
                              (qa.ucdsb_iscommentrequired && qa.ucdsb_answer === true)) && (
                              <>
                                  <TextInput
                                      mode={'outlined'}
                                      disabled={disabled}
                                      label={i18n.t('answer')}
                                      value={isString(qa.ucdsb_comment) ? qa.ucdsb_comment : ''}
                                      onChangeText={onCommentChange}
                                  />
                              </>
                          )}
                    {qa.answer_error && <Text style={styles.error}>{qa.answer_error}</Text>}
                </>
            )}
            {!qa.ucdsb_yesnotypeofquestion && (
                <>
                    <TextInput
                        disabled={disabled}
                        mode='outlined'
                        label={isString(qa.ucdsb_text) ? qa.ucdsb_text : ''}
                        value={isString(qa.ucdsb_comment) ? qa.ucdsb_comment : ''}
                        onChangeText={onCommentChange}
                    />
                    {qa.answer_error && <Text style={styles.error}>{qa.answer_error}</Text>}
                </>
            )}
        </View>
    );
};

export default Question;
