import {
    AttendanceSummary,
    StudentDetails,
    StudentIdProp,
    StudentMedical,
    StudentSchool,
    TransportationSchool,
} from '@Redux/types';
import { baseApi } from './base.ts';

const studentApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStudentAttendance: builder.query<AttendanceSummary[], StudentIdProp>({
            query: (data) => ({
                url: `studentApi/${data.studentId}/GetAttendance`,
                method: 'GET',
            }),
        }),
        getStudentSchool: builder.query<StudentSchool, StudentIdProp>({
            query: (data) => ({
                url: `studentApi/${data.studentId}/GetSchool`,
                method: 'GET',
            }),
        }),
        getStudentTransportation: builder.query<TransportationSchool, StudentIdProp>({
            query: (data) => ({
                url: `studentApi/${data.studentId}/GetTransportation`,
                method: 'GET',
            }),
        }),
        getStudentDetails: builder.query<StudentDetails, StudentIdProp>({
            query: (data) => ({
                url: `studentApi/${data.studentId}/GetDetail`,
                method: 'GET',
            }),
        }),
        getStudentMedical: builder.query<StudentMedical, StudentIdProp>({
            query: (data) => ({
                url: `studentApi/${data.studentId}/GetMedical`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetStudentAttendanceQuery,
    useGetStudentSchoolQuery,
    useGetStudentTransportationQuery,
    useGetStudentDetailsQuery,
    useGetStudentMedicalQuery,
} = studentApi;
