import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    labelContainer: {
        marginTop: 10,
    },
    addressConatiner: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    address: {
        flexBasis: '80%',
    },
});

export default styles;
