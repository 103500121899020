import { Pressable, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { IconChevronRight } from '@Icon';
import { RootNavigationProp, RootStackParamList } from '@App/rootNavigation';
import styles from './styles';
import type { ValueOf } from 'type-fest';

export interface ListLinkProps {
    icon: React.ReactNode;
    destination?: keyof RootStackParamList;
    args?: ValueOf<RootStackParamList>;
    text: string;
    subtext?: string;
    variant?: 'small' | 'medium' | 'large';
    onPress?: () => void;
    children?: React.ReactNode;
}

const ListLink: React.FC<ListLinkProps> = ({ icon, destination, args, text, subtext, variant, children, onPress }) => {
    const navigation = useNavigation<RootNavigationProp>();
    function itemPressHandler() {
        destination && navigation.navigate(destination, args);
        onPress && onPress();
    }

    const iconContainerStyle = [styles.iconContainer, variant === 'large' && styles.iconContainerLarge];

    const iconStyle = [styles.icon, variant === 'large' && styles.iconLarge, variant === 'small' && styles.iconSmall];

    const textLabelStyle = [styles.label, variant === 'small' && styles.labelSmall];

    const displayNone = subtext ? {} : styles.displayNone;

    return (
        <Pressable onPress={itemPressHandler} style={({ pressed }) => pressed && styles.pressed}>
            <View style={styles.listItem}>
                <View style={iconContainerStyle}>
                    <View style={iconStyle}>{icon}</View>
                </View>
                <View style={styles.labelContainer}>
                    <Text style={textLabelStyle}>{text}</Text>
                    <Text style={[styles.subtext, displayNone]}>{subtext}</Text>
                    {children}
                </View>
                <View style={styles.chevron}>
                    <IconChevronRight />
                </View>
            </View>
        </Pressable>
    );
};

export default ListLink;
