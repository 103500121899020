import Button from '@Components/Button';
import { IconWarning2 } from '@Icon/index';
import { FC } from 'react';
import { View, Text } from 'react-native';
import { Dialog } from 'react-native-paper';
import { styles } from './styles';
import useTheme from '@Hooks/useTheme';
import i18n from '@I18n';

interface IProps {
    show: boolean;
    onDismiss: () => void;
    onStayPress: () => void;
    onLeavePress: () => void;
    message: string;
}
const WarningDialog: FC<IProps> = ({ show, message, onLeavePress, onStayPress, onDismiss }) => {
    const theme = useTheme();
    return (
        <>
            <Dialog visible={show} onDismiss={onDismiss}>
                <Dialog.Content>
                    <View style={styles.content}>
                        <IconWarning2 />
                        <View style={styles.textContainer}>
                            <Text style={styles.warning}>{message}</Text>
                            <Dialog.Actions style={{ justifyContent: 'flex-start' }}>
                                <Button
                                    label={i18n.t('leave')}
                                    style={styles.button}
                                    underlineColor={theme.colors.lychee}
                                    onPress={onLeavePress}
                                />
                                <Button
                                    label={i18n.t('stay')}
                                    underlineColor={theme.colors.watermelon}
                                    onPress={onStayPress}
                                />
                            </Dialog.Actions>
                        </View>
                    </View>
                </Dialog.Content>
            </Dialog>
        </>
    );
};

export default WarningDialog;
