import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    signInTextContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    signIn: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    socialButtonContainer: {
        marginTop: 12,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 60,
    },
    socialIcon: {
        padding: 8,
    },
});
