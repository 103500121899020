import i18n from '@I18n';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 'en';

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        changeLanguage: (state, action: PayloadAction<string>) => {
            const locale = action.payload;
            // TODO: implement with async storage
            // localStorage.setItem('locale', locale);
            i18n.locale = locale;
            state = locale;
        },
    },
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
