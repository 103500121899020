import { useEffect, useState } from 'react';
import { View, Text, Pressable, Platform, Linking } from 'react-native';

import { RootState, store } from '@Redux';
import { loginWithEmail } from '@Redux/auth';
import { useLoginWithPasswordMutation } from '@Redux/services/account';
import i18n from '@I18n';
import { styles } from './styles';
import { hasHardwareAsync, isEnrolledAsync, authenticateAsync } from 'expo-local-authentication';
import { ArrowRight, Biometrics } from '@Icon';
import { getItemAsync } from 'expo-secure-store';
import { useSelector } from 'react-redux';
import { getGreetingByTimeofDay } from '@Utilities';
import { RootStackParamList } from '@App/rootNavigation';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useTheme from '@Hooks/useTheme';
import { AccountLockedHelpURL } from '@App/constants';
import Header from '@Components/Header';

type BiometricScreenProp = {
    navigation: NativeStackNavigationProp<RootStackParamList, 'signin', undefined>;
    setActiveScreen: (screen: string) => void;
};

function BiometricScreen({ setActiveScreen }: BiometricScreenProp) {
    const userId = useSelector((state: RootState) => state.auth.lastUserId);
    const email = useSelector((state: RootState) => state.auth?.email);
    const firstName =
        useSelector((state: RootState) => userId && state.auth?.storedAccountsPreference?.[userId]?.firstName) || '';
    const [loginWithPassword] = useLoginWithPasswordMutation();
    const greetings = getGreetingByTimeofDay();
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [isAccountLocked, setIsAccountLocked] = useState(false);

    const theme = useTheme();

    async function checkHardware() {
        const hasHardware = await hasHardwareAsync();
        const hasAuthMode = await isEnrolledAsync();
        if (hasHardware && hasAuthMode) {
            return true;
        } else {
            return false;
        }
    }

    async function biometricAuthenticate() {
        const authenticate = await authenticateAsync();
        return authenticate.success;
    }

    async function checkBiometricOrSignin() {
        const allowBiometric = await checkHardware();
        if (Platform.OS === 'web' || !allowBiometric) {
            handleDefaultSignin();
        }
    }

    useEffect(() => {
        checkBiometricOrSignin();
    }, []);

    function handleDefaultSignin() {
        setActiveScreen('signin');
    }

    async function doLogin(password: string): Promise<void> {
        try {
            const response = await loginWithPassword({
                email,
                password,
            }).unwrap();
            store.dispatch(loginWithEmail({ userId: response.UserId, email: email, password: password }));
        } catch (e) {
            setIsAccountLocked(e.data.AccountIsLocked);
            if (e.data.AccountIsLocked) {
                // TODO: Custom error component for email link
                setLoginErrorMessage(i18n.t('loginErrorLocked'));
            } else {
                setLoginErrorMessage(i18n.t('loginErrorBiometric'));
            }
        }
    }

    async function handleSignin() {
        if (userId === undefined) {
            return;
        }

        const allowBiometric = await checkHardware();
        if (!allowBiometric) {
            return;
        }

        const isAuthenticated = await biometricAuthenticate();
        if (!isAuthenticated) {
            return;
        }

        const password = await getItemAsync(userId.toString());
        if (password) {
            doLogin(password);
        }
    }

    return (
        <>
            <Header />
            <View style={{ flex: 1, justifyContent: 'space-between' }}>
                <View>
                    <View style={styles.fingerprintContainer}>
                        <Biometrics width={144} height={144} />
                    </View>
                    <Text style={styles.greetings} accessibilityLabel={greetings}>
                        {greetings}
                    </Text>
                    <Text style={styles.greetings2} accessibilityLabel={firstName || 'Parent'}>
                        {firstName || 'Parent'}
                    </Text>
                    <View style={[styles.controlContainer, styles.controlContainerBiometric]}>
                        <Pressable onPress={handleSignin}>
                            <View style={styles.buttonContainer}>
                                <View style={styles.biometricTextContainer}>
                                    <Text style={styles.biometric} accessibilityLabel={i18n.t('biometric1')}>
                                        {i18n.t('biometric1')}
                                    </Text>
                                </View>
                                <ArrowRight style={styles.arrowRight} />
                            </View>
                        </Pressable>
                    </View>
                    {Boolean(loginErrorMessage) && (
                        <View style={styles.errorLabelContainer}>
                            <Text style={styles.errorLabel}>{loginErrorMessage}</Text>
                            {isAccountLocked && (
                                <Pressable
                                    style={{ borderBottomWidth: 1, borderBottomColor: theme.colors.backdrop }}
                                    onPress={() => Linking.openURL(AccountLockedHelpURL)}
                                >
                                    <Text>{i18n.t('accountLockLinkLabel')}</Text>
                                </Pressable>
                            )}
                        </View>
                    )}
                </View>
                <View style={[styles.controlContainer, styles.controlContainerBottom]}>
                    <Pressable onPress={handleDefaultSignin}>
                        <View style={styles.switchTextContainer}>
                            <Text style={styles.switch} accessibilityLabel={i18n.t('switchAccount')}>
                                {i18n.t('switchAccount')}
                            </Text>
                        </View>
                    </Pressable>
                </View>
            </View>
        </>
    );
}

export default BiometricScreen;
