import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Alert = {
    showAlert: boolean;
    message?: string;
    isError?: boolean;
};

const initialState: Alert = { isError: false, showAlert: false, message: undefined };

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        alert: (state, action: PayloadAction<Alert>) => {
            Object.assign(state, action.payload);
        },
    },
});

export const { alert } = alertSlice.actions;

export default alertSlice.reducer;
