import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgMicrosoftLogo = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path d='M2.042 1.043h10.434v10.435H2.042V1.043Z' fill='#F35325' />
        <Path d='M13.52 1.043h10.435v10.435H13.52V1.043Z' fill='#81BC06' />
        <Path d='M2.042 12.522h10.434v10.434H2.042V12.522Z' fill='#05A6F0' />
        <Path d='M13.52 12.522h10.435v10.434H13.52V12.522Z' fill='#FFBA08' />
    </Svg>
);
export default SvgMicrosoftLogo;
