import React, { FC, useMemo } from 'react';

import { formatAddress } from '../../../../util';
import SummaryItem, { SummaryItemProps } from '../SummaryItem';

import { useGetCountriesQuery, useGetProvincesQuery, Province, BasicLookup } from '@Redux/services/LookupApi';
import { createLookupMap } from '@Utilities';

type SummaryItemMailingAddressProps = SummaryItemProps & {
    ApartmentNumber?: string | null;
    CityFreeForm?: string;
    Country?: number;
    POBox?: string | null;
    PostalCode?: string;
    Province?: number;
    RRNumber?: string | null;
    StreetName?: string;
};

const SummaryItemMailingAddress: FC<SummaryItemMailingAddressProps> = ({
    CityFreeForm,
    Country,
    Province,
    display,
    label,
    ...rest
}) => {
    const { data: provinces } = useGetProvincesQuery();
    const provinceMap = useMemo(() => createLookupMap<Province>(provinces), [provinces]);

    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);

    return (
        <SummaryItem
            value={formatAddress({
                ...rest,
                Country: countryMap[Country as number]?.Value,
                Province: provinceMap[Province as number]?.Abbreviation,
                City: CityFreeForm,
            })}
            display={display}
            label={label}
            bold
        />
    );
};

export default SummaryItemMailingAddress;
