import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconWarning2 = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.051 21.408 12.001.712l11.948 20.696H.051ZM13 15h-2v-4.725h2V15Zm-.212 2.587a1.08 1.08 0 0 1-.788.338c-.3 0-.562-.113-.787-.338a1.077 1.077 0 0 1-.338-.787c0-.3.113-.558.338-.775.225-.216.487-.325.787-.325.3 0 .563.109.788.325.225.217.337.475.337.775 0 .3-.112.563-.337.787Z'
            fill='#EEAB00'
        />
    </Svg>
);
export default SvgIconWarning2;
