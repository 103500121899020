import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setItemAsync } from 'expo-secure-store';
import { Platform } from 'react-native';

export type Auth = {
    userId: number;
    isLoggedIn: boolean;
    lastUserId: number;
    lastLogin: string;
    email: string;
    socialProvider: string;
    storedAccountsPreference: { [id: number]: UserAccount };
    showAppIntro: boolean;
};

type EmailLoginAction = {
    email: string;
    password: string;
    userId: number;
};

type SocialLoginAction = {
    email: string;
    userId: number;
    socialProvider: string;
};

type AppIntroAction = {
    showAppIntro: boolean;
};

type UserAccount = {
    firstName?: string;
    biometricEnabled?: boolean;
    profilePictureUri?: string;
};

// TODO: Refactor account preference out of auth
type UpdateAccountPreferenceAction = {
    userId: number;
    firstName?: string;
    biometricEnabled?: boolean;
    profilePictureUri?: string;
};

const initialState: Auth = {
    isLoggedIn: false,
    userId: 0,
    lastUserId: 0,
    lastLogin: '',
    email: '',
    socialProvider: '',
    storedAccountsPreference: {},
    showAppIntro: true,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginWithEmail: (state, action: PayloadAction<EmailLoginAction>) => {
            const { userId, email, password } = action.payload;
            state.isLoggedIn = true;
            state.userId = userId;
            state.lastUserId = userId;
            state.lastLogin = 'email';
            state.email = email;

            // App return and biometric disabled for web
            if (Platform.OS !== 'web') {
                const key = userId.toString();
                setItemAsync(key, password);
            }
        },
        loginWithSocial: (state, action: PayloadAction<SocialLoginAction>) => {
            const { userId, email, socialProvider } = action.payload;
            state.isLoggedIn = true;
            state.userId = userId;
            state.lastUserId = userId;
            state.lastLogin = 'social';
            state.email = email;
            state.socialProvider = socialProvider;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.userId = 0;
        },
        reset: (state) => {
            state.isLoggedIn = false;
            state.userId = 0;
            state.lastLogin = '';
            state.email = '';
            state.socialProvider = '';
        },
        updateAccountPreference: (state, action: PayloadAction<UpdateAccountPreferenceAction>) => {
            const { userId, firstName, biometricEnabled, profilePictureUri } = action.payload;
            const newState = {
                ...state.storedAccountsPreference,
                [userId]: {
                    ...state.storedAccountsPreference?.[userId],
                    ...(firstName !== undefined && { firstName }),
                    ...(biometricEnabled !== undefined && { biometricEnabled }),
                    ...(profilePictureUri !== undefined && { profilePictureUri }),
                },
            };
            state.storedAccountsPreference = newState;
        },
        setShowAppIntro: (state, action: PayloadAction<AppIntroAction>) => {
            state.showAppIntro = action.payload.showAppIntro;
        },
    },
});

export const { loginWithEmail, loginWithSocial, logout, reset, updateAccountPreference, setShowAppIntro } =
    authSlice.actions;

export default authSlice.reducer;
