import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgHealthAndSafety = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 30 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M29.578 17.432 15.066 27.977.552 17.432 6.095.372h17.94l5.543 17.06ZM17 17.666h-4v-3.333H9.667v-4H13V7h4v3.333h3.333v4H17v3.333Z'
            fill='#EEAB00'
        />
    </Svg>
);
export default SvgHealthAndSafety;
