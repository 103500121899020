import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconEventTypeOther = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.723 1.107H12v3.44H2.863v14.97H12v3.469H.723V1.106Zm10.918 4.375L22.931 12l-11.29 6.518V5.482Z'
            fill='#D77A7F'
        />
    </Svg>
);
export default SvgIconEventTypeOther;
