import { View, Text, Pressable, StyleSheet } from 'react-native';
import Modal from '@Components/Modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { defaultTheme, typography } from '@Hooks/useTheme';
import { useContext } from 'react';
import DeviceContext from '@Contexts/DeviceContext';

type ContactOptionsModalProps = {
    visible: boolean;
    onCancel: () => void;
    onSubmit: (prefType: string) => void;
};

function ContactOptionsModal({ visible, onCancel, onSubmit }: ContactOptionsModalProps) {
    const insets = useSafeAreaInsets();
    const { isDesktop } = useContext(DeviceContext);

    function handleUrgent() {
        onSubmit('urgentNotifications');
    }
    function handleAbsence() {
        onSubmit('absences');
    }
    function handleBus() {
        onSubmit('busCancellations');
    }
    function handleSchool() {
        onSubmit('schoolAndBoardNews');
    }

    return (
        <Modal
            visible={visible}
            header={<></>}
            safeArea={false}
            style={{
                width: 250,
                marginTop: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: isDesktop ? 'auto' : insets.bottom,
                padding: 12,
                borderRadius: 8,
                backgroundColor: 'white',
            }}
            animationType={isDesktop ? 'none' : 'slide'}
        >
            <Pressable onPress={handleUrgent} style={styles.button}>
                <View>
                    <Text style={typography.b1}>Edit all urgent preferences</Text>
                </View>
            </Pressable>
            <Pressable onPress={handleAbsence} style={styles.button}>
                <View>
                    <Text style={typography.b1}>Edit all absences preferences</Text>
                </View>
            </Pressable>
            <Pressable onPress={handleBus} style={styles.button}>
                <View>
                    <Text style={typography.b1}>Edit all bus preferences</Text>
                </View>
            </Pressable>
            <Pressable onPress={handleSchool} style={styles.button}>
                <View>
                    <Text style={typography.b1}>Edit all school &amp; board preferences</Text>
                </View>
            </Pressable>
            <Pressable onPress={onCancel} style={{ width: '100%', paddingVertical: 12 }}>
                <View>
                    <Text style={typography.b1}>Cancel</Text>
                </View>
            </Pressable>
        </Modal>
    );
}

export default ContactOptionsModal;

const styles = StyleSheet.create({
    button: {
        width: '100%',
        paddingVertical: 12,
        borderBottomColor: defaultTheme.colors.inputOutline,
        borderBottomWidth: 2,
    },
});
