import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        padding: 24,
    },
    spacing: {
        marginBottom: 24,
    },
    header: {
        ...typography.h5,
        marginBottom: 8,
    },
    body2: {
        ...typography.body2,
        marginBottom: 4,
    },
    actionsContaner: {
        marginTop: 32,
        alignItems: 'flex-end',
    },
    editButton: {
        marginBottom: 24,
    },
    buttonSmall: {
        ...typography.buttonSmall,
    },
    timeAlign: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    desktopModal: {
        minHeight: 400,
        minWidth: 400,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
    },
});

export default styles;
