import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconWarning = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.068 28.544 16 .949l15.932 27.595H.068ZM17.333 20h-2.666v-6.3h2.666V20Zm-.282 3.45c-.3.3-.65.45-1.051.45-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05s.15-.744.45-1.033c.3-.29.65-.434 1.05-.434s.75.145 1.05.434c.3.289.45.633.45 1.033 0 .4-.15.75-.45 1.05Z'
            fill='#FF5F4D'
        />
    </Svg>
);
export default SvgIconWarning;
