import React, { useState, useCallback, useMemo } from 'react';
import { LayoutChangeEvent, Pressable, View } from 'react-native';

import { groupBy } from 'lodash';
import { formatISO } from 'date-fns';

import { DailySeriesEvent, DailySeriesEventDateMap, dateRep } from '@Redux/services/CalendarApi';

import OwnerAvatar from '@Components/OwnerAvatar';
import styles from './styles';
import { EventDetailsModal } from '@Components/EventDetails';
import DateTimeLabel from './components/DateTimeLabel';
import EventTitle from './components/EventTitle';

interface DateEntryProps {
    dailySeriesEventMap: DailySeriesEventDateMap;
    item: Date;
    reportHeight: (date: Date, height: number) => void;
    onEventAccepted?: () => Promise<void>;
}

function DateEntry({ dailySeriesEventMap, item: date, onEventAccepted, reportHeight }: DateEntryProps) {
    const dateString = useMemo(() => formatISO(date, dateRep), [date]);
    const dailyEvents = dailySeriesEventMap?.[dateString];

    const eventsGroupedByAllDayStatus = useMemo(() => groupBy(dailyEvents, 'allDay'), [dailyEvents]);
    const handleLayout = useCallback(
        (object: LayoutChangeEvent) => {
            reportHeight(date, object.nativeEvent.layout.height);
        },
        [reportHeight]
    );

    const [selectedEvent, setSelectedEvent] = useState<DailySeriesEvent>();

    const handleShowEventDetailsPress = useCallback((eventId: DailySeriesEvent) => {
        setSelectedEvent(eventId);
    }, []);

    const dismissEventDetail = useCallback(() => {
        setSelectedEvent(undefined);
    }, [setSelectedEvent]);

    return (
        <>
            <View onLayout={handleLayout} style={styles.dateWrapper}>
                <>
                    {Object.entries(eventsGroupedByAllDayStatus).map(([isAllDay, events]) => (
                        <View key={`is-all-day-${isAllDay}`}>
                            {events.map((event) => (
                                <View style={styles.event} key={`event-id-${event.id}`}>
                                    <DateTimeLabel date={date} event={event} isAllDay={isAllDay === 'true'} />
                                    <Pressable
                                        onPress={() => handleShowEventDetailsPress(event)}
                                        style={styles.eventDetail}
                                    >
                                        <View style={styles.eventIcon}>
                                            <OwnerAvatar style={{ marginLeft: 12 }} event={event} size={26} />
                                        </View>
                                        <EventTitle event={event} />
                                    </Pressable>
                                </View>
                            ))}
                        </View>
                    ))}
                </>
            </View>
            {selectedEvent && (
                <EventDetailsModal
                    event={selectedEvent}
                    eventResponseId={selectedEvent?.id}
                    onDismiss={dismissEventDetail}
                    show={true}
                    onEventAccepted={onEventAccepted}
                />
            )}
        </>
    );
}

export default DateEntry;
