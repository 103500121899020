import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgHome1 = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.17.976H4.83L.81 5.66h18.38L15.17.976ZM2.873 7.349h14.293v9.844H8.994v-4.952H5.926v4.952H2.874V7.35Zm11.593 2.477h-1.959l-.98 1.696.98 1.696h1.959l.979-1.696-.98-1.696Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgHome1;
