import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    itemContainer: {
        flexDirection: 'row',
        paddingHorizontal: 8,
        marginVertical: 16,
        alignItems: 'center',
    },
    icon: {
        marginRight: 8,
    },
    title: {
        ...typography.buttonMedium,
    },
});
