import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBuilding5 = (props: SvgProps) => (
    <Svg width={480} height={270} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M424.177 145.701c29.663 0 53.709-24.447 53.709-54.604s-24.046-54.604-53.709-54.604c-29.662 0-53.708 24.447-53.708 54.604s24.046 54.604 53.708 54.604Z'
            fill='#5DA638'
        />
        <Path fill='#373639' d='M420 91h8v142h-8z' />
        <Path d='M106.474 68.2H18.148V158h88.326V68.2Z' fill='#5DA638' />
        <Path fill='#373639' d='M59 113h8v120h-8z' />
        <Path d='m236.724 44.093-60.951 44.292 23.281 71.651h75.347l23.281-71.65-60.958-44.293Z' fill='#A28AC7' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M151.009 128.404h-28v106.525h93.831v-48.036h39.95v48.036h94.202V128.404H151.009Zm43.073 53.159c0 9.976-5.826 18.064-13.012 18.064-7.186 0-13.012-8.088-13.012-18.064 0-9.977 5.826-18.065 13.012-18.065 7.186 0 13.012 8.088 13.012 18.065Zm98.988 18.064c7.186 0 13.012-8.088 13.012-18.064 0-9.977-5.826-18.065-13.012-18.065-7.186 0-13.012 8.088-13.012 18.065 0 9.976 5.826 18.064 13.012 18.064Z'
            fill='#F99F9B'
        />
        <Path d='M310.185 57.02h-48.37v29.96h48.37V57.02Z' fill='#FF5F4D' />
        <Path
            d='M385.052 144.92h-25.086l-12.54 22.086 12.54 22.087h25.086l12.543-22.087-12.543-22.086Z'
            fill='#73CC49'
        />
        <Path fill='#373639' d='M369 167h8v66h-8zM362 233h118v2H362zM0 233h118v2H0z' />
    </Svg>
);
export default SvgBuilding5;
