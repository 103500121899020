import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgError = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.444 0h9.112L22 6.444v9.112L15.556 22H6.444L0 15.556V6.444L6.444 0Zm6.131 12.05h-3.15V5.475h3.15v6.575Zm-.35 4.3c-.333.333-.742.5-1.225.5-.483 0-.892-.167-1.225-.5a1.666 1.666 0 0 1-.5-1.225c0-.483.167-.896.5-1.238A1.646 1.646 0 0 1 11 13.375c.483 0 .892.17 1.225.512.333.342.5.755.5 1.238s-.167.892-.5 1.225Z'
            fill='#FF5F4D'
        />
    </Svg>
);
export default SvgError;
