import React from 'react';
import { Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';

import { Switch, Checkbox } from './components';

import styles from './styles';

interface IProps {
    checked: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
    isCheckbox?: boolean;
    label: string | React.ReactNode;
    onChange?: (checked: boolean) => void;
    reverse?: boolean;
    style?: ViewStyle;
    viewStyle?: ViewStyle;
    pressableStyle?: StyleProp<ViewStyle>;
}

const SwitchInput: React.FC<IProps> = ({
    icon = null,
    label = null,
    disabled,
    checked,
    onChange = () => null,
    viewStyle,
    pressableStyle,
    reverse = false,
    isCheckbox = false,
}) => {
    const handleSwitchPress = () => {
        if (disabled) return;
        onChange && onChange(!checked);
    };

    const wrapperStyles = [styles.switchContainer, viewStyle];

    const labelInnerds = [<Text style={[styles.label, disabled && styles.disabledLabel]}> {label}</Text>, icon];

    if (reverse) {
        labelInnerds.reverse();
    }

    const innerds = [];

    if (isCheckbox) {
        innerds.push(<Checkbox disabled={disabled} checked={checked} onChange={onChange} />);
    } else {
        innerds.push(<Switch disabled={disabled} checked={checked} onChange={onChange} />);
    }

    innerds.push(<View style={styles.labelContainer}>{labelInnerds}</View>);

    if (reverse) {
        innerds.reverse();
    }

    return (
        <Pressable style={pressableStyle} onPress={handleSwitchPress}>
            <View style={wrapperStyles}>{innerds}</View>
        </Pressable>
    );
};

export default SwitchInput;
