import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        margin: 24,
        paddingBottom: 48,
    },
    triangleContainer: {
        marginTop: 40,
        marginBottom: 72,
        alignItems: 'center',
    },
    title: {
        ...typography.h3,
    },
    bodyContainer: {
        marginTop: 16,
    },
    body: {
        ...typography.body,
    },
    controlContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 36,
        marginTop: 72,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    resendEmailTextContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 2,
    },
    resendEmail: {
        ...typography.buttonSmall,
        lineHeight: 20,
    },
    nextPageContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    nextPage: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
});
