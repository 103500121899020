import { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import ListButton from './components/ListButton';
// import ListSocial from './components/ListSocial';
import {
    // AppleLogo,
    // AppleLogoDisabled,
    // FacebookLogo,
    // FacebookLogoDisabled,
    Fingerprint2,
    // GoogleLogo,
    // GoogleLogoDisabled,
    // MicrosoftLogo,
    // MicrosoftLogoDisabled,
} from '@Icon';
import i18n from '@I18n';

import { styles } from './styles';
import { hasHardwareAsync, isEnrolledAsync } from 'expo-local-authentication';
import { useSelector } from 'react-redux';
import { RootState, store } from '@Redux';
import { updateAccountPreference } from '@Redux/auth';
// import useSocialAuth from '@Hooks/useSocialAuth';
// import {
//     useAddSocialLoginMutation,
//     useGetSocialLoginQuery,
//     useRemoveSocialLoginMutation,
// } from '@Redux/services/parent';

async function checkHardware() {
    const hasHardware = await hasHardwareAsync();
    const hasAuthMode = await isEnrolledAsync();
    if (hasHardware && hasAuthMode) {
        return true;
    } else {
        return false;
    }
}

// export function SocialLinkComponent({ wrapperStyle }: { wrapperStyle?: StyleProp<ViewStyle> }) {
//     const { data: socialLogins, refetch, isFetching } = useGetSocialLoginQuery();
//     const [addSocialLogin] = useAddSocialLoginMutation();
//     const [removeSocialLogin] = useRemoveSocialLoginMutation();
//     const [googleLogin, facebookLogin, outlookLogin, appleLogin] = useSocialAuth();
//     const [googleEnabled, setGoogleEnabled] = useState(false);
//     const [microsoftEnabled, setMicrosoftEnabled] = useState(false);
//     const [facebookEnabled, setFacebookEnabled] = useState(false);
//     const [appleEnabled, setAppleEnabled] = useState(false);

//     useEffect(() => {
//         const providerList = socialLogins?.map((i) => i.provider.toLowerCase());
//         setFacebookEnabled(providerList?.includes('facebook') || false);
//         setGoogleEnabled(providerList?.includes('google') || false);
//         setMicrosoftEnabled(providerList?.includes('microsoft') || false);
//         setAppleEnabled(providerList?.includes('apple') || false);
//     }, [socialLogins]);

//     useEffect(() => {
//         refetch();
//     }, []);

//     async function socialLoginCallback(providerUserId: string, provider: string) {
//         await addSocialLogin({ provider, providerUserId });
//         refetch();
//     }

//     async function handleGoogle(val: boolean) {
//         if (val) {
//             try {
//                 await googleLogin(socialLoginCallback);
//             } catch (e) {
//                 console.log(e);
//             }
//         } else {
//             try {
//                 const googleLogin = socialLogins?.find((i) => i.provider.toLowerCase() === 'google');
//                 if (googleLogin) {
//                     removeSocialLogin(googleLogin);
//                     refetch();
//                 }
//             } catch (e) {
//                 console.log(e);
//             }
//         }
//     }

//     function handleFacebook(val: boolean) {
//         if (val) {
//             try {
//                 facebookLogin(socialLoginCallback);
//             } catch (e) {
//                 console.log(e);
//             }
//         } else {
//             try {
//                 const facebookLogin = socialLogins?.find((i) => i.provider.toLowerCase() === 'facebook');
//                 if (facebookLogin) {
//                     removeSocialLogin(facebookLogin);
//                     refetch();
//                 }
//             } catch (e) {
//                 console.log(e);
//             }
//         }
//     }

//     function handleApple(val: boolean) {
//         if (val) {
//             try {
//                 appleLogin(socialLoginCallback);
//             } catch (e) {
//                 console.log(e);
//             }
//         } else {
//             try {
//                 const appleLogin = socialLogins?.find((i) => i.provider.toLowerCase() === 'apple');
//                 if (appleLogin) {
//                     removeSocialLogin(appleLogin);
//                     refetch();
//                 }
//             } catch (e) {
//                 console.log(e);
//             }
//         }
//     }

//     function handleMicrosoft(val: boolean) {
//         if (val) {
//             try {
//                 outlookLogin(socialLoginCallback);
//             } catch (e) {
//                 console.log(e);
//             }
//         } else {
//             try {
//                 const microsoftLogin = socialLogins?.find((i) => i.provider.toLowerCase() === 'microsoft');
//                 if (microsoftLogin) {
//                     removeSocialLogin(microsoftLogin);
//                     refetch();
//                 }
//             } catch (e) {
//                 console.log(e);
//             }
//         }
//     }

//     return (
//         <>
//             <View style={wrapperStyle}>
//                 <ListSocial
//                     icon={GoogleLogo}
//                     inactiveIcon={GoogleLogoDisabled}
//                     onChange={handleGoogle}
//                     text='Google'
//                     active={googleEnabled}
//                     disabled={isFetching}
//                 />
//             </View>
//             <View style={wrapperStyle}>
//                 <ListSocial
//                     icon={MicrosoftLogo}
//                     inactiveIcon={MicrosoftLogoDisabled}
//                     onChange={handleMicrosoft}
//                     text='Microsoft'
//                     active={microsoftEnabled}
//                     disabled={isFetching}
//                 />
//             </View>
//             <View style={wrapperStyle}>
//                 <ListSocial
//                     icon={FacebookLogo}
//                     inactiveIcon={FacebookLogoDisabled}
//                     onChange={handleFacebook}
//                     text='Facebook'
//                     active={facebookEnabled}
//                     disabled={isFetching}
//                 />
//             </View>
//             <View style={wrapperStyle}>
//                 <ListSocial
//                     icon={AppleLogo}
//                     inactiveIcon={AppleLogoDisabled}
//                     onChange={handleApple}
//                     text='Apple'
//                     active={appleEnabled}
//                     disabled={isFetching}
//                 />
//             </View>
//         </>
//     );
// }

function SecurityPreferences() {
    const userId = useSelector((state: RootState) => state.auth.userId);
    const biometricEnabled = useSelector(
        (state: RootState) => (userId && state.auth?.storedAccountsPreference?.[userId]?.biometricEnabled) || false
    );
    const [hasBiometric, setHasBiometric] = useState(false);

    async function initializeBiometric() {
        const biometricAvailable = await checkHardware();
        if (biometricAvailable) {
            setHasBiometric(true);
        }
    }

    useEffect(() => {
        initializeBiometric();
    }, []);

    function handleBiometric(val: boolean) {
        store.dispatch(updateAccountPreference({ userId: userId, biometricEnabled: val }));
    }

    // const wrapperStyle: StyleProp<ViewStyle> =
    //     Platform.OS === 'web' ? { alignSelf: 'flex-start', marginVertical: 16 } : { alignSelf: 'flex-start' };

    return (
        <ScrollView style={styles.screen}>
            <ListButton
                icon={Fingerprint2}
                onChange={handleBiometric}
                text={i18n.t('enableBiometric1')}
                checked={biometricEnabled}
                disabled={!hasBiometric}
            />
            <Text style={styles.description}>{i18n.t('enableBiometric2')}</Text>
            {/* <SocialLinkComponent wrapperStyle={wrapperStyle} /> */}
        </ScrollView>
    );
}

export default SecurityPreferences;
