import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgHome = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 33 32' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.172 11.568 16.684 1.022l14.514 10.546-5.543 17.06h-5.1V17.884h-7.61v10.744h-5.23l-5.543-17.06Z'
        />
    </Svg>
);
export default SvgHome;
