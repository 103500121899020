import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@App/hooks/useTheme';

export const styles = StyleSheet.create({
    claimText: {
        ...typography.body3,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 24,
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: 12,
        borderWidth: 1,
        borderColor: defaultTheme.colors.persimmon,
        borderRadius: 4,
    },
    infoIcon: {
        marginRight: 16,
    },
    infoText: {
        flex: 1,
        flexWrap: 'wrap',
        ...typography.body2,
    },
    introText: {
        ...typography.body,
    },
});
