import React, { useContext, useEffect, useRef, useState } from 'react';
import { Platform, Pressable, Text, View } from 'react-native';
import Modal from '@Components/Modal';
import styles from './styles';
import { ActivityIndicator } from 'react-native-paper';
import { defaultTheme } from '@Hooks/useTheme';
import Button from '@Components/Button';
import PaypalLogo from '@Icon/PaypalLogo';
import { currencyFormat } from '@Utilities';
import ModalHeader from '@Components/ModalHeader';
import DeviceContext from '@Contexts/DeviceContext';
import { useGetPaymentConfigurationQuery, useMakeDepositMutation } from '@Redux/services/PaymentApi';
import TextInput from '@Components/TextInput';
import i18n from '@I18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { openAuthSessionAsync, maybeCompleteAuthSession } from 'expo-web-browser';
import { BASEURL } from '@Redux/services/base';
import { WebView } from 'react-native-webview';

function calculateFee(amount: number, staticFee: number, percentageFee: number) {
    return (amount + staticFee) / (1 - percentageFee) - amount;
}

interface IProps {
    onDismiss: () => void;
    show: boolean;
    onSubmit: () => void;
    options?: number[];
    amountToLoad?: string;
    isLoadFunds: boolean;
}

const defaultOptions = [10, 20, 50, 100];

maybeCompleteAuthSession();

type NavigationState = {
    url?: string;
    title?: string;
    loading?: boolean;
    canGoBack?: boolean;
    canGoForward?: boolean;
};

const AddBalanceModal: React.FC<IProps> = ({ isLoadFunds, amountToLoad, show, onDismiss, onSubmit, options }) => {
    const { data } = useGetPaymentConfigurationQuery();
    const [amount, setAmount] = useState('');
    const [balance, setBalance] = useState(0);
    const [fee, setFee] = useState(0);
    const [isSubmitting] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const { isDesktop } = useContext(DeviceContext);
    const [balanceError, setBalanceError] = useState('');
    const [approvalUrl, setApprovalUrl] = useState('');
    const wvRef = useRef<WebView>(null);

    function handleWebViewNavigationStateChange(navigationState: NavigationState) {
        const { url } = navigationState;
        if (url && url.match(/payment-close.html/)) {
            setTimeout(() => {
                setApprovalUrl('');
                onSubmit();
            }, 3000);
        }
    }

    const [makeDeposit] = useMakeDepositMutation();

    const overrideOptions = options ? options : defaultOptions;

    const handlePress = (index: number) => {
        setActiveIndex(index);
    };

    function initialize() {
        setAmount(amountToLoad ? amountToLoad : '');
        setBalance(data?.CurrentBalance || 0);
        setBalanceError('');
        setActiveIndex(-1);
    }

    useEffect(() => {
        if (show) {
            initialize();
        }
    }, [show]);

    useEffect(() => {
        if (activeIndex !== -1) {
            setAmount(overrideOptions[activeIndex].toString());
        }
    }, [activeIndex]);

    useEffect(() => {
        if (!data) {
            return;
        }
        if (Number.isNaN(Number(amount))) {
            return;
        }
        setFee(calculateFee(Number(amount), data.StaticFee, data.PercentageFee));
    }, [amount, data]);

    function handleChangeAmount(value: string) {
        setActiveIndex(-1);
        if (/^\d*\.?\d{0,2}$/.exec(value)) {
            setAmount(value);
        }
    }

    async function handleSubmit() {
        if (!fee || !data) {
            return;
        }
        if (!amount || Number.isNaN(Number(amount))) {
            setBalanceError('Please select an amount');
            return;
        }
        if (balance + Number(amount) > data.MaxEWalletBalance) {
            setBalanceError('Maximum wallet balance exceeded. Please select a lower amount');
            return;
        }
        if (!data.EWalletId) {
            setBalanceError('E-wallet not configured.');
            return;
        }

        const paymentData = {
            Description: 'Add balance',
            CustomId: data.EWalletId,
            Amount: Number(amount),
            HandlingFee: fee,
            CancelUrl: `${BASEURL}/api/PaymentApi/Cancel`,
            ReturnUrl: `${BASEURL}/api/PaymentApi/Return`,
        };

        let url;
        try {
            const paymentInfo = await makeDeposit(paymentData).unwrap();
            if (paymentInfo.Success && paymentInfo.ApprovalUrl) {
                url = paymentInfo.ApprovalUrl;
            }
        } catch (e) {
            console.log(e);
            setBalanceError('Add balance failed.');
        }

        if (!url) {
            return;
        }
        const browserOption = {
            windowFeatures: "width='100%',height='100%'",
        };
        if (Platform.OS === 'web') {
            try {
                const res = await openAuthSessionAsync(url, null, browserOption);
                if (res.type === 'dismiss' || res.type === 'cancel') {
                    onSubmit();
                }
            } catch (e) {
                console.log(e);
                setBalanceError('Add balance failed, please ensure that pop-ups are not blocked or use the My Family Room app');
            }
        } else {
            setApprovalUrl(url);
        }
    }

    return (
        <Modal
            style={[isDesktop ? styles.modalDesktop : styles.modal, approvalUrl && styles.modalWebview]}
            visible={show}
            header={<ModalHeader header={i18n.t('addBalanceHeader')} onDismiss={onDismiss} />}
        >
            {approvalUrl ? (
                <WebView
                    source={{ uri: approvalUrl }}
                    onNavigationStateChange={handleWebViewNavigationStateChange}
                    ref={wvRef}
                />
            ) : (
                <KeyboardAwareScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                    <Text style={styles.description}>
                        {i18n.t(isLoadFunds ? 'loadBalanceSubHeader' : 'addBalanceSubHeader')}
                    </Text>
                    <Text style={styles.subDescription}>
                        {i18n.t(isLoadFunds ? 'loadBalanceSubHeaderDescription' : 'addBalanceSubHeaderDescription', {
                            amount: amountToLoad,
                        })}
                    </Text>
                    <View style={styles.amountContainer}>
                        {overrideOptions.map((value, index) => (
                            <Pressable
                                key={`buttonbar-${index}`}
                                style={styles.child}
                                onPress={() => handlePress(index)}
                            >
                                <Text style={styles.buttonBarText}>${value}</Text>
                                {activeIndex === index ? (
                                    <View style={styles.buttonBar} />
                                ) : (
                                    <View style={styles.buttonEmpty} />
                                )}
                            </Pressable>
                        ))}
                    </View>
                    <View style={styles.inputContainer}>
                        <TextInput
                            label={i18n.t('amount')}
                            accessibilityLabel={i18n.t('amount')}
                            autoCorrect={false}
                            mode='outlined'
                            keyboardType='numeric'
                            value={amount}
                            onChangeText={handleChangeAmount}
                        />
                    </View>
                    {Boolean(balanceError) && (
                        <View style={styles.errorLabelContainer}>
                            <Text style={styles.errorLabel}>{balanceError}</Text>
                        </View>
                    )}
                    <View></View>
                    <View style={styles.controlContainer}>
                        <View>
                            <Text>Fee {currencyFormat(fee)}</Text>
                        </View>
                        <View>
                            <Button
                                onPress={handleSubmit}
                                leftIcon={isSubmitting ? <ActivityIndicator /> : <PaypalLogo />}
                                label={i18n.t(isLoadFunds ? 'loadEWalletLabel' : 'eWalletLabel', { amount: amount })}
                                underlineColor={defaultTheme.colors.blueberry}
                            />
                            <View style={styles.newBalanceTextContainer}>
                                <Text style={styles.newBalanceText}>
                                    New balance {currencyFormat(balance + Number(amount))}
                                </Text>
                            </View>
                        </View>
                    </View>
                </KeyboardAwareScrollView>
            )}
        </Modal>
    );
};

export default AddBalanceModal;
