import React, { FC, useMemo } from 'react';
import { View, Text } from 'react-native';

import i18n from '@I18n/index';
import { createLookupMap } from '@Utilities';
import { typography } from '@Hooks/useTheme';
import { useGetCitiesQuery, BasicLookup } from '@Redux/services/LookupApi';

import SummaryItem, { SummaryItemProps } from '../SummaryItem';
import { styles } from '../../../../styles';

type SummaryItemLocalAddress = SummaryItemProps & {
    StreetName?: string;
    ApartmentNumber?: string | null;
    RRNumber?: string | null;
    PostalCode?: string | null;
    City?: number;
    PoBox?: string | null;
};

const SummaryItemLocalAddress: FC<SummaryItemLocalAddress> = ({
    label,
    display = true,
    StreetName,
    ApartmentNumber,
    RRNumber,
    PostalCode,
    City,
    POBox,
}) => {
    if (!display) {
        return null;
    }
    const { data: cities } = useGetCitiesQuery();
    const cityMap = useMemo(() => createLookupMap<BasicLookup>(cities), [cities]);

    return (
        <>
            {label && <Text style={[typography.buttonMedium, { marginTop: 12 }]}>{label}</Text>}
            <View style={[styles.formGroup, styles.summary]}>
                <SummaryItem
                    label={i18n.t('studentRegisterStreetName')}
                    value={StreetName}
                    display={Boolean(StreetName)}
                />
                <SummaryItem
                    label={i18n.t('studentRegisterApartmentNumber')}
                    value={ApartmentNumber}
                    display={Boolean(ApartmentNumber)}
                />
                <SummaryItem label={i18n.t('studentRegisterRRNumber')} value={RRNumber} display={Boolean(RRNumber)} />
                <SummaryItem
                    label={i18n.t('studentRegisterPostalCode')}
                    value={PostalCode}
                    display={Boolean(PostalCode)}
                />
                <SummaryItem
                    label={i18n.t('studentRegisterCity')}
                    value={City && cityMap[City]?.Value}
                    display={Boolean(City)}
                />
                <SummaryItem label={i18n.t('studentRegisterPOBox')} value={POBox} display={Boolean(POBox)} />
            </View>
        </>
    );
};

export default SummaryItemLocalAddress;
