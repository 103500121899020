import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgFolder = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.6 0H4.4L2.596 5.156H.645v19.022h30.71V5.156h-17.95L11.6 0Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgFolder;
