import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    label: {
        ...typography.body3,
        marginBottom: 4,
    },
    body: {
        ...typography.body,
        marginBottom: 8,
    },
});

export default styles;
