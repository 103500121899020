import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 22,
    },
    checkboxContainer: {
        flexDirecton: 'row',
        flexWrap: 'wrap',
        marginBottom: 16,
    },
    child: {
        flexBasis: '50%',
    },
    bottomMargin: {
        marginBottom: 16,
    },
    checkboxLabel: {
        ...typography.body2,
        marginLeft: 16,
        alignSelf: 'center',
    },
    buttonContainer: {
        paddingBottom: 12,
        marginTop: 16,
        alignItems: 'flex-end',
    },
    switchContainer: {
        borderColor: defaultTheme.colors.inputOutline,
        borderWidth: 1,
        marginBottom: 16,
        minHeight: 56,
        padding: 10,
    },
    desktopModal: {
        minHeight: 400,
        minWidth: 400,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
    },
});

export default styles;
