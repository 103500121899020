import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgInfo = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='#A28AC7' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.067 1.066H31V31H1.067V1.066Zm16.766 13.6v8.5h-3.666v-8.5h3.666Zm-.4-2.584c-.378.39-.855.584-1.433.584a1.925 1.925 0 0 1-1.416-.584A1.925 1.925 0 0 1 14 10.666c0-.577.195-1.055.584-1.433.388-.378.86-.567 1.416-.567.578 0 1.055.19 1.433.567.378.378.567.856.567 1.433 0 .556-.189 1.028-.567 1.416Z'
        />
    </Svg>
);
export default SvgInfo;
