import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        paddingVertical: 16,
        borderBottomWidth: 2,
        borderBottomColor: '#373639',
    },
    listItem: {
        paddingBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    avatar: {
        marginRight: 24,
    },
    labelContainer: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    label: {
        ...typography.consentForm,
    },
    chevron: {
        width: 20,
    },
    pressed: {
        opacity: 0.75,
    },
});

export default styles;
