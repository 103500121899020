import {
    AcknowledgeAbsenceForm,
    Activity,
    AttendanceSummary,
    PlannedAbsenceForm,
    PlannedAbsenceParams,
    AbsenceIdParam,
    EditPlannedAbsenceForm,
    StudentAbsences,
} from '../types';
import { baseApi } from './base.ts';

const absenceApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAcknowledgeAbsenceForm: builder.mutation<AcknowledgeAbsenceForm, Activity>({
            query: (data) => ({
                url: `AbsenceApi/GetAcknowledgeAbsenceForm/${data.activityId}`,
                method: 'POST',
            }),
        }),
        getPlannedAbsenceForm: builder.mutation<PlannedAbsenceForm, void>({
            query: () => ({
                url: `AbsenceApi/GetPlannedAbsenceForm`,
                method: 'POST',
            }),
        }),
        addPlannedAbsence: builder.mutation<void, PlannedAbsenceParams>({
            query: (data) => ({
                url: 'AbsenceApi/AddPlannedAbsence',
                method: 'POST',
                data,
            }),
        }),
        addLateness: builder.mutation<void, PlannedAbsenceParams>({
            query: (data) => ({
                url: 'AbsenceApi/AddLateness',
                method: 'POST',
                data,
            }),
        }),
        getAttendenceSummary: builder.mutation<AttendanceSummary[], void>({
            query: () => ({
                url: `AbsenceApi/GetAttendenceSummary`,
                method: 'POST',
            }),
        }),
        getStudentAbsences: builder.mutation<StudentAbsences, void>({
            query: () => ({
                url: `AbsenceApi/GetStudentAbsences`,
                method: 'POST',
            }),
        }),
        getStudentPlannedAbsencesForm: builder.mutation<EditPlannedAbsenceForm, AbsenceIdParam>({
            query: (data) => ({
                url: `AbsenceApi/GetPlannedAbsenceForm/${data.absenceId}`,
                method: 'POST',
            }),
        }),
        editPlannedAbsence: builder.mutation<void, PlannedAbsenceParams>({
            query: (data) => ({
                url: `AbsenceApi/EditPlannedAbsence`,
                method: 'POST',
                data,
            }),
        }),
        deletePlannedAbsence: builder.mutation<void, AbsenceIdParam>({
            query: (data) => ({
                url: `AbsenceApi/DeletePlannedAbsence/${data.absenceId}`,
                method: 'POST',
                data,
            }),
        }),
    }),
});

export const {
    useGetAcknowledgeAbsenceFormMutation,
    useGetPlannedAbsenceFormMutation,
    useAddPlannedAbsenceMutation,
    useAddLatenessMutation,
    useGetAttendenceSummaryMutation,
    useGetStudentAbsencesMutation,
    useGetStudentPlannedAbsencesFormMutation,
    useEditPlannedAbsenceMutation,
    useDeletePlannedAbsenceMutation,
} = absenceApi;
