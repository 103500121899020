import { useState } from 'react';
import { View, Text, Pressable } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import i18n from '@I18n';
import { styles } from './styles';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import TriangleExclamation from '@Icon/TriangleExclamation';
import { useSignUpMutation } from '@Redux/services/account';
import { withLayoutSm } from '@Screens/common';
import AlertBox from '@Components/AlertBox';

type EmailConfirmationScreenProp = NativeStackScreenProps<RootStackParamList, 'confirm'>;

function EmailConfirmationScreen({ route }: EmailConfirmationScreenProp) {
    const [showAlert, setShowAlert] = useState(false);
    const email = route.params.email;
    const [signUp] = useSignUpMutation();

    async function resendEmail() {
        if (!email || !email.match(/^\S+@\S+\.\S+$/)) {
            return;
        }
        try {
            setShowAlert(false);
            await signUp({ email }).finally(() => {
                setShowAlert(true);
            });
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <ScrollView keyboardShouldPersistTaps='handled'>
            {withLayoutSm(
                <View style={styles.container}>
                    <AlertBox show={showAlert} isError={false} message={i18n.t('resendEmailMessage')} />
                    <View style={styles.triangleContainer}>
                        <TriangleExclamation width={128} height={128} />
                    </View>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('emailConfirmation1')}>
                            {i18n.t('emailConfirmation1')}
                        </Text>
                    </View>
                    <View style={styles.bodyContainer}>
                        <Text style={styles.body} accessibilityLabel={i18n.t('emailConfirmation2')}>
                            {i18n.t('emailConfirmation2')}
                        </Text>
                    </View>
                    <View style={styles.bodyContainer}>
                        <Text style={styles.body} accessibilityLabel={i18n.t('emailConfirmation3')}>
                            {i18n.t('emailConfirmation3')}
                        </Text>
                    </View>
                    <View style={styles.controlContainer}>
                        <Pressable onPress={resendEmail}>
                            <View style={styles.resendEmailTextContainer}>
                                <Text style={styles.resendEmail} accessibilityLabel={i18n.t('emailConfirmation4')}>
                                    {i18n.t('emailConfirmation4')}
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                </View>
            )}
        </ScrollView>
    );
}

export default EmailConfirmationScreen;
