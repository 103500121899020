import { useGetStudentAbsencesMutation } from '@Redux/services/absence';
import { StudentAbsences } from '@Redux/types';
import { ScrollView, Text, View } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';

import { ActivityIndicator } from 'react-native-paper';
import IconList from '@Components/IconList';
import SvgAlarm from '@Icon/Alarm';
import { format, parseISO, setHours, setMinutes } from 'date-fns';
import styles from './styles';

import AbsenceItem from './components/AbsenceItem';
import useNotify from '@Hooks/useNotify';
import DeviceContext from '@Contexts/DeviceContext';
import ErrorContext from '@Contexts/ErrorContext';
import i18n from '@I18n';

interface IProps {
    studentId: string;
}

const AbsencesList: React.FC<IProps> = ({ studentId }) => {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const [studentAbsences, setAbsences] = useState<StudentAbsences>();
    const { isDesktop } = useContext(DeviceContext);

    const { notifyButtonModal, notifyButton, absenceForm } = useNotify();
    const [getStudentAbsences] = useGetStudentAbsencesMutation();
    useEffect(() => {
        getAbsences();
    }, []);

    const getAbsences = async () => {
        try {
            const response = await getStudentAbsences().unwrap();
            setAbsences(response);
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackGetErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        }
    };

    function parseDateTime(date: string) {
        const dateTimeString = date.split(' ');
        let newDate = parseISO(dateTimeString[0]);
        const time = dateTimeString[1].split(':');
        newDate = setHours(newDate, parseInt(time[0], 10));
        newDate = setMinutes(newDate, parseInt(time[1], 10));
        return newDate;
    }

    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false}>
                {!studentAbsences && <ActivityIndicator />}
                {studentAbsences?.Absences?.map((absence) => {
                    const start = parseDateTime(absence.End);
                    if (absence.StudentId !== studentId) return;
                    return (
                        <View key={absence.Id}>
                            {absence.Code === 'L' ? (
                                <View key={absence.Id} style={{ paddingVertical: 16 }}>
                                    <IconList icon={<SvgAlarm />} header={'Late'}>
                                        <Text style={styles.dateLabel}>{format(start, 'EEE MMM dd')}</Text>
                                    </IconList>
                                </View>
                            ) : (
                                <AbsenceItem
                                    absence={absence}
                                    parentId={studentAbsences.ParentId}
                                    onlySubmittingParentEditsAttendance={
                                        studentAbsences.OnlySubmittingParentEditsAttendance
                                    }
                                    parseDateTime={parseDateTime}
                                    onSave={getAbsences}
                                />
                            )}
                        </View>
                    );
                })}
            </ScrollView>
            {!isDesktop && (
                <>
                    {notifyButtonModal}
                    {notifyButton}
                    {absenceForm}
                </>
            )}
        </>
    );
};

export default AbsencesList;
