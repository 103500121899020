import SvgIconCall from '@Icon/IconCall';
import { useGetStudentSchoolQuery } from '@Redux/services/student';
import { compact } from 'lodash';
import React from 'react';
import { Linking, ScrollView, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import styles from './styles';
import i18n from '@I18n/index';
import SvgEmail from '@Icon/Email';
import IconList from '@Components/IconList';
import { defaultTheme } from '@Hooks/useTheme';

interface IProps {
    studentId: string;
}

const School: React.FC<IProps> = ({ studentId }) => {
    const { data: school } = useGetStudentSchoolQuery({ studentId });

    function formatAddress() {
        const lines = [school?.Address, compact([school?.City, school?.StateProvince, school?.PostalCode]).join(' ')];
        return compact(lines).join('\n');
    }

    if (!school) {
        return <ActivityIndicator />;
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.margin}>
                <IconList
                    header={school.Name}
                    body={formatAddress()}
                    buttonLabel={school.Telephone}
                    buttonIcon={<SvgIconCall fill={defaultTheme.colors.watermelon} />}
                    onButtonPress={() => Linking.openURL(`tel:${school.Telephone}`)}
                />
            </View>
            <View style={styles.margin}>
                <IconList
                    label={i18n.t('labelHomeTeacher')}
                    header={school.HomeroomTeacher ? school.HomeroomTeacher : '-'}
                />
            </View>
            <View style={styles.margin}>
                <IconList label={i18n.t('labelHomeroom')} header={school.Homeroom} />
            </View>

            <View style={styles.margin}>
                <IconList
                    label={i18n.t('labelPrincipal')}
                    header={school.Principal}
                    buttonLabel={school.PrincipalEmail}
                    buttonIcon={<SvgEmail />}
                    onButtonPress={() => Linking.openURL(`mailto:${school.PrincipalEmail}`)}
                />
            </View>

            {school.VicePrincipal.length > 0 && (
                <View style={styles.margin}>
                    <IconList
                        label={i18n.t('labelVicePrincipal')}
                        header={school.VicePrincipal[0]}
                        buttonLabel={school.VicePrincipalEmail[0]}
                        buttonIcon={<SvgEmail />}
                        onButtonPress={() => Linking.openURL(`mailto:${school.PrincipalEmail}`)}
                    />
                </View>
            )}
        </ScrollView>
    );
};

export default School;
