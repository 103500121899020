import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import i18n from '@I18n';
import { addYears, differenceInYears, startOfYear } from 'date-fns';
import type { PartialDeep } from 'type-fest';

import { CreateStudentRegistrationBody } from '@Redux/services/StudentRegistrationApi';
import Picker from '@Components/Picker';
import DatePicker from '@Components/DatePicker';
import SwitchInput from '@Components/SwitchInput';
import TextInput from '@Components/TextInput';
import { lookupToOptions, phoneMask } from '@Utilities';
import {
    useGetGradesQuery,
    useGetPreferredGendersQuery,
    useGetGendersQuery,
    BasicLookup,
} from '@Redux/services/LookupApi';

import { styles } from '../../styles';
import {
    StepProps,
    ManageTextProps,
    ManagePickerProps,
    ManageSwitchInputProps,
    ManageDatePickerProps,
} from '../../types';

const StudentInfo: FC<StepProps> = ({
    manageFieldPropFactory,
    studentRegistration,
    setStudentRegistration,
    textFieldPropFactory,
}) => {
    const { data: grades } = useGetGradesQuery();
    const gradeOptions = useMemo(() => lookupToOptions<BasicLookup>(grades), [grades]);

    const { data: genders } = useGetGendersQuery();
    const genderOptions = useMemo(() => lookupToOptions<BasicLookup>(genders), [genders]);

    const { data: preferredGenders } = useGetPreferredGendersQuery();
    const preferredGenderOptions = useMemo(() => lookupToOptions<BasicLookup>(preferredGenders), [preferredGenders]);

    const [hasCondition, setHasCondition] = useState(
        Boolean(
            studentRegistration?.ChildInformation?.Allergies ||
                studentRegistration?.ChildInformation?.NonLifeThreateningMedicalDetails ||
                studentRegistration?.ChildInformation?.LifeThreateningMedicalDetails
        )
    );

    const toggleCondition = (val: boolean) => {
        if (val === false) {
            setStudentRegistration({
                ...studentRegistration,
                ChildInformation: {
                    ...studentRegistration.ChildInformation,
                    Allergies: null,
                    NonLifeThreateningMedicalDetails: null,
                    IsLifeThreateningMedical: false,
                    LifeThreateningMedicalDetails: null,
                },
            });
        }
        setHasCondition(val);
    };

    useEffect(() => {
        const schoolStartDate = studentRegistration?.ChildInformation?.SchoolStartDate?.toString();
        if (!schoolStartDate?.includes('Z')) {
            return;
        }

        // Format time as yyyy/MM/dd
        const nextSchoolStartDate = new Date(schoolStartDate?.replace(/-/g, '/')?.replace(/T.+/, ''));

        setStudentRegistration({
            ...studentRegistration,
            ChildInformation: {
                ...studentRegistration.ChildInformation,
                SchoolStartDate: nextSchoolStartDate,
                IsChildPreferredNameDifferent:
                    studentRegistration?.ChildInformation?.PreferredFirstName ||
                    studentRegistration?.ChildInformation?.PreferredFirstName
                        ? true
                        : false,
            },
        });
    }, []);

    const dobManageFieldProps = manageFieldPropFactory<ManageDatePickerProps>(
        ['ChildInformation', 'DateOfBirth'],
        'onDateChange',
        'value'
    );

    dobManageFieldProps.onDateChange = useCallback(
        (value: Date) => {
            if (!grades) {
                return;
            }

            const currentYear = new Date().getFullYear();
            const lastDayYear = new Date(currentYear + 1, 0, 1);
            const age = differenceInYears(lastDayYear, value);
            const gradeIdx = Math.min(Math.max(0, age - 4), grades.length - 1);
            const grade = grades?.[gradeIdx];

            const newVal = new Date(`${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`);

            setStudentRegistration({
                ...studentRegistration,
                ChildInformation: {
                    ...studentRegistration?.ChildInformation,
                    Grade: grade?.Key,
                    DateOfBirth: newVal,
                },
            });
        },
        [grades, setStudentRegistration, studentRegistration]
    );

    const schoolStartManageFieldProps = manageFieldPropFactory<ManageDatePickerProps>(
        ['ChildInformation', 'SchoolStartDate'],
        'onDateChange',
        'value'
    );

    schoolStartManageFieldProps.onDateChange = useCallback(
        (value: Date) => {
            const newVal = new Date(`${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`);
            setStudentRegistration({
                ...studentRegistration,
                ChildInformation: {
                    ...studentRegistration?.ChildInformation,
                    SchoolStartDate: newVal,
                },
            });
        },
        [setStudentRegistration, studentRegistration]
    );

    return (
        <View>
            <TextInput
                {...textFieldPropFactory(i18n.t('firstName'))}
                {...manageFieldPropFactory<ManageTextProps>(
                    ['ChildInformation', 'LegalFirstName'],
                    'onChangeText',
                    'value'
                )}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterMiddleName'))}
                {...manageFieldPropFactory<ManageTextProps>(
                    ['ChildInformation', 'MiddleName'],
                    'onChangeText',
                    'value'
                )}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('lastName'))}
                {...manageFieldPropFactory<ManageTextProps>(
                    ['ChildInformation', 'LegalSurname'],
                    'onChangeText',
                    'value'
                )}
            />
            <View style={styles.formGroup}>
                <SwitchInput
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['ChildInformation', 'IsChildPreferredNameDifferent'],
                        'onChange',
                        'checked'
                    )}
                    viewStyle={styles.checkbox}
                    label={i18n.t('studentRegisterPreferredNameDisplay')}
                    reverse
                />
                {studentRegistration?.ChildInformation?.IsChildPreferredNameDifferent && (
                    <View>
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterPreferredFirstName'))}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['ChildInformation', 'PreferredFirstName'],
                                'onChangeText',
                                'value'
                            )}
                        />
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterPreferredLastName'))}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['ChildInformation', 'PreferredSurname'],
                                'onChangeText',
                                'value'
                            )}
                        />
                    </View>
                )}
            </View>
            <View style={styles.formGroup}>
                <TextInput
                    {...textFieldPropFactory(i18n.t('studentRegisterPhoneNumber'))}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['ChildInformation', 'PhoneNumber'],
                        'onChangeText',
                        'value'
                    )}
                />
                <SwitchInput
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['ChildInformation', 'IsPhoneNumberUnlisted'],
                        'onChange',
                        'checked'
                    )}
                    viewStyle={styles.checkbox}
                    label={i18n.t('studentRegisterIsPhoneNumberUnlisted')}
                />
            </View>
            <View style={styles.formGroup}>
                <DatePicker label={i18n.t('studentRegisterDOB')} style={styles.picker} {...dobManageFieldProps} />

                <Picker
                    inputLabel={i18n.t('studentRegisterGender')}
                    {...manageFieldPropFactory<ManagePickerProps>(
                        ['ChildInformation', 'Gender'],
                        'onValueChange',
                        'value'
                    )}
                    items={genderOptions}
                    style={styles.picker}
                />

                <Picker
                    inputLabel={i18n.t('studentRegisterPreferredGender')}
                    {...manageFieldPropFactory<ManagePickerProps>(
                        ['ChildInformation', 'PreferredGender'],
                        'onValueChange',
                        'value'
                    )}
                    items={preferredGenderOptions}
                    style={styles.picker}
                />
            </View>
            <View style={styles.formGroup}>
                <Picker
                    inputLabel={i18n.t('studentRegisterGrade')}
                    {...manageFieldPropFactory<ManagePickerProps>(
                        ['ChildInformation', 'Grade'],
                        'onValueChange',
                        'value'
                    )}
                    items={gradeOptions}
                    style={styles.picker}
                />

                <DatePicker
                    minimumDate={startOfYear(new Date())}
                    maximumDate={addYears(startOfYear(new Date()), 2)}
                    label={i18n.t('startDate')}
                    {...schoolStartManageFieldProps}
                />

                <TextInput
                    {...textFieldPropFactory(i18n.t('studentRegisterGeneralComments'))}
                    {...manageFieldPropFactory<ManageTextProps>(
                        ['ChildInformation', 'GeneralComments'],
                        'onChangeText',
                        'value'
                    )}
                />
            </View>
            <SwitchInput
                {...manageFieldPropFactory<ManageSwitchInputProps>(
                    ['ChildInformation', 'IsInternational'],
                    'onChange',
                    'checked'
                )}
                viewStyle={styles.checkbox}
                label={i18n.t('studentRegisterIsInternational')}
                reverse
            />
            <View style={styles.formGroup}>
                <SwitchInput
                    viewStyle={styles.checkbox}
                    label={i18n.t('studentRegisterShowMedicalFields')}
                    reverse
                    checked={hasCondition}
                    onChange={(e) => toggleCondition(e)}
                />
                {hasCondition && (
                    <View>
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterAllergies'))}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['ChildInformation', 'Allergies'],
                                'onChangeText',
                                'value'
                            )}
                            maxLength={2000}
                        />
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterNonLifeThreateningMedicalDetails'))}
                            {...manageFieldPropFactory<ManageTextProps>(
                                ['ChildInformation', 'NonLifeThreateningMedicalDetails'],
                                'onChangeText',
                                'value'
                            )}
                            maxLength={2000}
                        />
                        <View style={styles.formGroupInner}>
                            <SwitchInput
                                reverse
                                viewStyle={styles.checkbox}
                                label={i18n.t('studentRegisterIsLifeThreateningMedical')}
                                {...manageFieldPropFactory<ManageSwitchInputProps>(
                                    ['ChildInformation', 'IsLifeThreateningMedical'],
                                    'onChange',
                                    'checked'
                                )}
                            />
                            {studentRegistration?.ChildInformation?.IsLifeThreateningMedical && (
                                <TextInput
                                    {...textFieldPropFactory(i18n.t('studentRegisterLifeThreateningMedicalDetails'))}
                                    {...manageFieldPropFactory<ManageTextProps>(
                                        ['ChildInformation', 'LifeThreateningMedicalDetails'],
                                        'onChangeText',
                                        'value'
                                    )}
                                    maxLength={2000}
                                />
                            )}
                        </View>
                    </View>
                )}
            </View>
            <View style={styles.formGroup}>
                <SwitchInput
                    viewStyle={styles.checkbox}
                    label={i18n.t('studentRegisterIsChildHasAccessibilityNeeds')}
                    reverse
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['ChildInformation', 'IsChildHasAccessibilityNeeds'],
                        'onChange',
                        'checked'
                    )}
                />
                {studentRegistration?.ChildInformation?.IsChildHasAccessibilityNeeds && (
                    <TextInput
                        {...textFieldPropFactory(i18n.t('studentRegisterAccessibilityChallengesDetails'))}
                        {...manageFieldPropFactory<ManageTextProps>(
                            ['ChildInformation', 'AccessibilityChallengesDetails'],
                            'onChangeText',
                            'value'
                        )}
                    />
                )}
            </View>
        </View>
    );
};

export const StudentInfoValidator = (studentRegistration: PartialDeep<CreateStudentRegistrationBody>) => {
    return [
        { accessorPath: ['ChildInformation', 'Gender'], validator: Boolean },
        { accessorPath: ['ChildInformation', 'Grade'], validator: Boolean },
        { accessorPath: ['ChildInformation', 'LegalFirstName'], validator: Boolean },
        { accessorPath: ['ChildInformation', 'LegalSurname'], validator: Boolean },
        {
            accessorPath: ['ChildInformation', 'PhoneNumber'],
            validator: (phoneNumber: string) => {
                if (phoneNumber?.match(/\d{3}-\d{3}-\d{4}/)) {
                    return true;
                }
                return false;
            },
        },
        {
            accessorPath: ['ChildInformation', 'PreferredFirstName'],
            validator: (preferredFirstName: string) => {
                if (studentRegistration?.ChildInformation?.IsChildPreferredNameDifferent) {
                    return Boolean(preferredFirstName);
                }

                return true;
            },
        },
        {
            accessorPath: ['ChildInformation', 'PreferredSurname'],
            validator: (PreferredSurname: string) => {
                if (studentRegistration?.ChildInformation?.IsChildPreferredNameDifferent) {
                    return Boolean(PreferredSurname);
                }

                return true;
            },
        },
    ];
};

export const StudentInfoMasks = () => {
    return [{ accessorPath: ['ChildInformation', 'PhoneNumber'], mask: phoneMask }];
};

export default StudentInfo;
