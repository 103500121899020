import { View } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import { useEditProfilePictureMutation, useGetParentQuery } from '@Redux/services/parent';
import ContactWithLabel from './components/ContactWithLabel';
import ContactFullAddress from './components/ContactFullAddress';
import i18n from '@I18n/index';
import { styles } from '../styles';
import Avatar from '@Components/Avatar';
import { ScrollView } from 'react-native-gesture-handler';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import Button from '@Components/Button';
import useTheme from '@Hooks/useTheme';
import EditProfileModal from './components/EditProfileModal';
import { ActivityIndicator } from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import DeviceContext from '@Contexts/DeviceContext';
import { Parent } from '@Redux/types';
import ErrorContext from '@Contexts/ErrorContext';

type ContactInformationScreenProp = NativeStackScreenProps<RootStackParamList, 'ContactInformation'>;

type ContactComponentProps = {
    parent?: Parent;
    onEdit: () => void;
};

export function EditAvatarComponent() {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const theme = useTheme();
    const { data: parent, refetch } = useGetParentQuery();
    const [editProfilePicture] = useEditProfilePictureMutation();

    const handleEditImagePress = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            base64: true,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled && result.base64) {
            try {
                await editProfilePicture({ Data: result.base64, FileType: 'image/png' }).unwrap();
                refetch();
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackEditProfilePictureErrorMessage');
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            }
        }
    };

    return (
        <View style={styles.profileImageContainer}>
            <Avatar
                style={styles.buttonMargin}
                label={parent?.FirstName ? parent?.FirstName : '-'}
                picture={parent?.Photo}
                size={140}
            />
            <Button
                style={styles.buttonMargin}
                label={i18n.t('editPhoto')}
                underlineColor={theme.colors.watermelon}
                labelStyle={styles.button}
                onPress={handleEditImagePress}
            />
        </View>
    );
}

export function ContactInformationComponent({ parent, onEdit }: ContactComponentProps) {
    const { isDesktop } = useContext(DeviceContext);
    return isDesktop ? (
        <>
            <View style={{ flexBasis: '33%' }}>
                <ContactFullAddress style={styles.addressInfoLines} {...parent} onPress={onEdit} />
            </View>
            <View style={{ flexBasis: '66%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                <View style={{ width: '50%' }}>
                    <ContactWithLabel
                        style={styles.addressInfoLines}
                        label={i18n.t('phoneHome')}
                        value={parent?.HomePhone}
                    />
                </View>
                <View style={{ width: '50%' }}>
                    <ContactWithLabel
                        style={styles.addressInfoLines}
                        label={i18n.t('phoneMobile')}
                        value={parent?.MobilePhone}
                    />
                </View>
                <View style={{ width: '50%' }}>
                    <ContactWithLabel
                        style={styles.addressInfoLines}
                        label={i18n.t('phoneWork')}
                        value={parent?.WorkPhone}
                    />
                </View>
                <View style={{ width: '50%' }}>
                    <ContactWithLabel
                        style={styles.addressInfoLines}
                        label={i18n.t('workPhoneExtension')}
                        value={parent?.WorkPhoneExtension}
                    />
                </View>
                <View style={{ width: '50%' }}>
                    <ContactWithLabel style={styles.addressInfoLines} label={i18n.t('email')} value={parent?.Email} />
                </View>
                <View style={{ width: '50%' }}>
                    <ContactWithLabel
                        style={styles.addressInfoLines}
                        label={i18n.t('alternateEmail')}
                        value={parent?.Email2}
                    />
                </View>
            </View>
        </>
    ) : (
        <>
            <ContactFullAddress style={styles.addressInfoLines} {...parent} onPress={onEdit} />
            <ContactWithLabel style={styles.addressInfoLines} label={i18n.t('phoneHome')} value={parent?.HomePhone} />
            <ContactWithLabel
                style={styles.addressInfoLines}
                label={i18n.t('phoneMobile')}
                value={parent?.MobilePhone}
            />
            <ContactWithLabel style={styles.addressInfoLines} label={i18n.t('phoneWork')} value={parent?.WorkPhone} />
            <ContactWithLabel
                style={styles.addressInfoLines}
                label={i18n.t('workPhoneExtension')}
                value={parent?.WorkPhoneExtension}
            />
            <ContactWithLabel style={styles.addressInfoLines} label={i18n.t('email')} value={parent?.Email} />
            <ContactWithLabel style={styles.addressInfoLines} label={i18n.t('alternateEmail')} value={parent?.Email2} />
        </>
    );
}

function ContactInformation({ navigation }: ContactInformationScreenProp) {
    const { data: parent, refetch } = useGetParentQuery();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        navigation.setOptions({
            headerTitle: parent?.FirstName + ' ' + parent?.LastName,
            headerTitleAlign: 'center',
        });
    }, [parent])

    const handleEditPress = () => {
        setShowModal(!showModal);
    };

    const handleDismissPress = () => {
        setShowModal(false);
        refetch();
    };

    if (!parent) {
        return <ActivityIndicator />;
    }

    return (
        <ScrollView>
            <View style={styles.screen}>
                <EditAvatarComponent />
                <ContactInformationComponent parent={parent} onEdit={handleEditPress} />
            </View>
            {showModal && <EditProfileModal show={showModal} onDismiss={handleDismissPress} parent={parent} />}
        </ScrollView>
    );
}

export default ContactInformation;
