import React, { FC } from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../../styles';

export interface SummaryItemProps {
    label?: string;
    value?: string | number | null;
    display?: boolean;
    bold?: boolean;
}

const SummaryItem: FC<SummaryItemProps> = ({ label, value, display = true, bold = false }) => {
    if (!display) {
        return null;
    }

    return (
        <View style={styles.summaryItem}>
            {label && <Text style={bold ? styles.summaryLabelBold : styles.summaryLabel}>{label}</Text>}
            <Text style={styles.summaryText}>
                {value === null || value === '' || typeof value === 'undefined' ? '-' : value}
            </Text>
        </View>
    );
};

export default SummaryItem;
