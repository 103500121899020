import React, { useMemo, useCallback, useEffect, useState, useContext } from 'react';
import { Text, View, ViewStyle, StyleProp } from 'react-native';
import CheckBoxProfile from '@Screens/Dashboard/components/CheckBoxProfile';
import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';
import styles from './styles';
import DatePicker from '@Components/DatePicker';
import TimePicker from '@Components/TimePicker';
import i18n from '@I18n';
import {
    useAddPlannedAbsenceMutation,
    useAddLatenessMutation,
    useGetPlannedAbsenceFormMutation,
    useGetStudentPlannedAbsencesFormMutation,
    useEditPlannedAbsenceMutation,
} from '@Redux/services/absence';
import { PlannedAbsenceForm, PlannedAbsenceParams } from '@Redux/types';
import TextInput from '@Components/TextInput';
import { ActivityIndicator, TextInput as PaperTextInput } from 'react-native-paper';
import Comments from '@Icon/Comments';
import Button from '@Components/Button';
import SvgPlus from '@Icon/Plus';
import { compareAsc, eachWeekendOfInterval, format, isPast, isWithinInterval } from 'date-fns';
import useTheme from '@Hooks/useTheme';
import SwitchInput from '@Components/SwitchInput';
import { store } from '@Redux';
import { alert } from '@Redux/alert';
import SvgSchedule from '@Icon/Schedule';
import SvgDone from '@Icon/Done';
import CheckboxWithLabel from '@Components/SwitchInput/components/CheckboxWithLabel';
import Picker from '@Components/Picker';
import DeviceContext from '@Contexts/DeviceContext';
import { useGetStudentsQuery } from '@Redux/services/parent';
import { keyBy } from 'lodash';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import ErrorAlert from '@Components/ErrorAlert';

interface IProps {
    absenceId?: string;
    onDismiss: () => void;
    show: boolean;
    isReportingLate: boolean;
    style?: StyleProp<ViewStyle>;
    animationType?: 'none' | 'slide' | 'fade';
}

interface ValidationErrors {
    timeError?: string;
    dateError: string[];
    selectedChildrenError?: string;
    reasonError?: string;
}

const AbsenceForm: React.FC<IProps> = ({ show, absenceId, onDismiss, isReportingLate, style, animationType }) => {
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [allDay, setAllDay] = useState(false);
    const [selectedChildren, setSelectedChildren] = useState<Record<string, boolean>>({});
    const [plannedAbsenceForm, setPlannedAbsenceForm] = useState<PlannedAbsenceForm>();
    const [selectedAbsenceReason, setSelectedAbsenceReason] = useState<string>();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startBell, setStartBell] = useState<string>();
    const [endBell, setEndBell] = useState<string>();
    const [startTime, setStartTime] = useState<string>();
    const [endTime, setEndTime] = useState<string>();
    const [emailAbsence, setEmailAbsence] = useState(true);
    const [errors, setErrors] = useState<ValidationErrors>({ dateError: [] });
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [comments, setComments] = useState<string>();
    const [isBeginningOfDay, setIsBeginningOfDay] = useState(true);
    const [isEndOfDay, setIsEndOfDay] = useState(true);
    const [getPlannedAbsenceFormMutation] = useGetPlannedAbsenceFormMutation();
    const [addPlannedAbsence] = useAddPlannedAbsenceMutation();
    const [addLateness] = useAddLatenessMutation();
    const [getStudentPlannedAbsencesForm] = useGetStudentPlannedAbsencesFormMutation();
    const [editPlannedAbsence] = useEditPlannedAbsenceMutation();
    const { data: students } = useGetStudentsQuery();
    const theme = useTheme();
    const { isDesktop } = useContext(DeviceContext);

    const studentsMap = keyBy(students, 'StudentId');

    useEffect(() => {
        setAllDay(false);
        setSelectedChildren({});
        setSelectedAbsenceReason(undefined);
        setStartTime(undefined);
        setEndTime(undefined);
        getSchoolBellTime(true);
        setEmailAbsence(true);
        setErrors({ dateError: [] });
        setComments(undefined);
        setIsBeginningOfDay(true);
        setIsEndOfDay(true);
    }, [show]);

    useEffect(() => {
        if (!show || plannedAbsenceForm) return;
        if (absenceId) {
            getPlannedAbsencesForm();
        } else {
            getAcknowledgeAbsenceData();
        }
    }, [show]);

    useEffect(() => {
        getSchoolBellTime();
    }, [plannedAbsenceForm]);

    useEffect(() => {
        startBell === format(startDate, 'hh:mm a') ? setIsBeginningOfDay(true) : setIsBeginningOfDay(false);
        endBell === format(endDate, 'hh:mm a') ? setIsEndOfDay(true) : setIsEndOfDay(false);
    }, [startBell, endBell, startDate, endDate]);

    const getPlannedAbsencesForm = async () => {
        if (!absenceId) return;
        setLoading(true);
        try {
            const data = await getStudentPlannedAbsencesForm({ absenceId }).unwrap();
            const newPlannedAbsenceForm: PlannedAbsenceForm = {
                AbsenceReasons: data.PlannedAbsenceVM.AbsenceReasons,
                ChildrenInfo: data.PlannedAbsenceVM.ChildrenInfo,
                ExceptionDays: data.PlannedAbsenceVM.ExceptionDays,
                LateReasons: data.PlannedAbsenceVM.LateReasons,
                SchoolYearEnd: data.PlannedAbsenceVM.SchoolYearEnd,
                SchoolYearStart: data.PlannedAbsenceVM.SchoolYearStart,
                NumberOfDaysToShowInFeed: 0,
            };
            setPlannedAbsenceForm(newPlannedAbsenceForm);
            const newSelectedChildren: Record<string, boolean> = {};
            for (const studentId of data.PlannedAbsenceForm.SelectedChildren) {
                newSelectedChildren[studentId] = true;
            }
            setSelectedChildren(newSelectedChildren);
            setSelectedAbsenceReason(data.PlannedAbsenceForm.ReasonId);
            const newStartDate = getDateFromTimeString(
                new Date(data.PlannedAbsenceForm.StartDate),
                data.PlannedAbsenceForm.StartTime
            );
            const newEndDate = getDateFromTimeString(
                new Date(data.PlannedAbsenceForm.EndDate),
                data.PlannedAbsenceForm.EndTime
            );
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            setEmailAbsence(data.PlannedAbsenceForm.SendEmailConfirmation);
            setComments(data.PlannedAbsenceForm.Note);
            setAllDay(data.PlannedAbsenceForm.StartDate !== data.PlannedAbsenceForm.EndDate);
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackGetErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setLoading(false);
        }
    };

    const getDateFromTimeString = (date: Date, time: string) => {
        return new Date(format(date, 'M/dd/yyy') + ' ' + time);
    };

    /**
     * Iterate each children school and get minimum StartBell time
     * and maximum EndBellTime
     */
    const getSchoolBellTime = (isInit = false) => {
        if (!plannedAbsenceForm) {
            return;
        }
        const newStartDate = isInit ? new Date() : startDate;
        const newEndDate = isInit ? new Date() : endDate;
        const startTimes = [];
        const endTimes = [];
        for (const child of plannedAbsenceForm.ChildrenInfo) {
            startTimes.push(child.SchoolInfo.StartBell);
            endTimes.push(child.SchoolInfo.EndBell);
        }
        if (startTimes.length > 0) {
            const startBellTime = startTimes.reduce((prevValue: string, curValue: string) => {
                return getDateFromTimeString(newStartDate, prevValue) < getDateFromTimeString(newStartDate, curValue)
                    ? prevValue
                    : curValue;
            });
            setStartBell(startBellTime);
            setStartDate(getDateFromTimeString(newStartDate, startBellTime));
        }
        if (endTimes.length > 0) {
            const endBellTime = endTimes.reduce((prevValue: string, curValue: string) => {
                return getDateFromTimeString(newEndDate, prevValue) > getDateFromTimeString(newEndDate, curValue)
                    ? prevValue
                    : curValue;
            });
            setEndBell(endBellTime);
            setEndDate(getDateFromTimeString(newEndDate, endBellTime));
        }
    };

    const getAcknowledgeAbsenceData = async () => {
        setLoading(true);
        try {
            const response = await getPlannedAbsenceFormMutation().unwrap();
            setPlannedAbsenceForm(response);
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackGetErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setLoading(false);
        }
    };

    const handleChildrenSelection = (checked: boolean, studentId: string) => {
        const newSelectedChildren = {
            ...selectedChildren,
            [studentId]: checked,
        };
        setSelectedChildren(newSelectedChildren);
    };

    const handleAllDayChange = (checked: boolean) => {
        setAllDay(checked);
        checked && startBell && setStartDate(getDateFromTimeString(startDate, startBell));
        endBell && setEndDate(getDateFromTimeString(startDate, endBell));
    };

    const handleStartDateChange = (date: Date) => {
        const newStartTime = isBeginningOfDay && startBell ? startBell : startTime && startTime;
        if (newStartTime) {
            setStartDate(getDateFromTimeString(date, newStartTime));
        }
        if (new Date(date).setHours(0, 0, 0, 0) > new Date(endDate).setHours(0, 0, 0, 0)) {
            const newEndTime = isEndOfDay && endBell ? endBell : endTime && endTime;
            newEndTime && setEndDate(getDateFromTimeString(date, newEndTime));
        }
    };

    const handleEndDateChange = useCallback(
        (date: Date) => {
            const newEndTime = isEndOfDay && endBell ? endBell : endTime && endTime;
            if (newEndTime) {
                setEndDate(getDateFromTimeString(date, newEndTime));
            }
        },
        [startDate, endDate, isEndOfDay, endBell]
    );

    const handleStartTimeChange = (value: Date | string) => {
        if (typeof value === 'string' && value === i18n.t('beginningOfDay') && startBell) {
            setIsBeginningOfDay(true);
            setStartDate(getDateFromTimeString(startDate, startBell));
        } else if (value instanceof Date) {
            setStartDate(value);
            setIsBeginningOfDay(false);
            setStartTime(format(value, 'hh:mm a'));
        }
    };

    const handleEndTimeChange = (value: Date | string) => {
        if (typeof value === 'string' && value === i18n.t('endOfDay') && endBell) {
            setIsEndOfDay(true);
            setEndDate(getDateFromTimeString(endDate, endBell));
        } else if (value instanceof Date) {
            setEndDate(value);
            setIsEndOfDay(false);
            setEndTime(format(value, 'hh:mm a'));
        }
    };

    const handleAbsenceReasonChange = useCallback(
        (value: string) => {
            setSelectedAbsenceReason(value);
        },
        [selectedAbsenceReason]
    );

    const validateUserInputs = (): boolean => {
        const newErrors: ValidationErrors = { ...errors, dateError: [] };
        if (Object.values(selectedChildren).every((v) => v === false)) {
            newErrors.selectedChildrenError = i18n.t('errorNoStudentSelected');
        } else {
            newErrors.selectedChildrenError = undefined;
        }

        if (!selectedAbsenceReason) {
            newErrors.reasonError = i18n.t('errorNoReasonSelected');
        } else {
            newErrors.reasonError = undefined;
        }

        if (!isReportingLate) {
            // Check if end date is less than start date
            if (allDay && compareAsc(endDate, startDate) === -1) {
                newErrors.dateError.push(i18n.t('errorInvalidDate'));
            }

            // Check if start date is an exception day
            if (
                !plannedAbsenceForm?.ExceptionDays.map((exceptionDay) => {
                    const newStartDate = new Date(startDate);
                    newStartDate.setHours(0);
                    newStartDate.setMinutes(0);
                    newStartDate.setSeconds(0);
                    return compareAsc(newStartDate, new Date(exceptionDay)) === 0;
                }).every((v) => v == false)
            ) {
                newErrors.dateError.push(i18n.t('errorDateIsExceptionDate'));
            }

            // Check if end date is an exception day
            if (
                allDay &&
                !plannedAbsenceForm?.ExceptionDays.map((exceptionDay) => {
                    const newEndDate = new Date(endDate);
                    newEndDate.setHours(0);
                    newEndDate.setMinutes(0);
                    newEndDate.setSeconds(0);
                    return compareAsc(newEndDate, new Date(exceptionDay)) === 0;
                }).every((v) => v == false)
            ) {
                newErrors.dateError.push(i18n.t('errorEndDateIsExceptionDate'));
            }
            newErrors.timeError =
                new Date(startDate) > new Date(endDate) ? i18n.t('errorEndDateLessThanStartDate') : undefined;
        } else {
            const today = new Date();
            let isNotSchoolDay = false;
            if (plannedAbsenceForm?.SchoolYearEnd && plannedAbsenceForm?.SchoolYearStart) {
                const schoolYearStart = new Date(plannedAbsenceForm?.SchoolYearStart);
                const schoolYearEnd = new Date(plannedAbsenceForm?.SchoolYearEnd);
                if (
                    schoolYearEnd < today ||
                    schoolYearStart > today ||
                    today < schoolYearStart ||
                    today > schoolYearEnd
                ) {
                    isNotSchoolDay = true;
                    newErrors.dateError.push(i18n.t('reportLateErrorNotSchoolDay'));
                }
            }

            const isExceptionDay = plannedAbsenceForm?.ExceptionDays.some((exceptionDay) => {
                const exceptionDate = new Date(exceptionDay);
                const exceptDay =
                    exceptionDate.getMonth() + 1 + '-' + exceptionDate.getDate() + '-' + exceptionDate.getFullYear();
                return exceptDay === today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
            });
            // Check if today is Saturday or Sunday or Exception day
            if (([6, 0].includes(today.getDay()) || isExceptionDay) && !isNotSchoolDay) {
                newErrors.dateError.push(i18n.t('reportLateErrorNotSchoolDay'));
            }

            if (!isWithinInterval(new Date(), {start: startDate, end: endDate})) {
                newErrors.timeError = i18n.t('reportLateErrorOutsideSchoolHours');
            }
        }
        setErrors(newErrors);
        if (
            newErrors.dateError.length !== 0 ||
            newErrors.reasonError ||
            newErrors.selectedChildrenError ||
            newErrors.timeError
        ) {
            return false;
        }

        return true;
    };

    const getdropDownItems = () => {
        if (!plannedAbsenceForm) {
            return [];
        }
        const absenceReasons = isReportingLate ? plannedAbsenceForm.LateReasons : plannedAbsenceForm.AbsenceReasons;

        return absenceReasons.map((item) => ({
            label: item.Name,
            value: item.Id,
        }));
    };

    const allChildrenSelected = () =>
        Object.keys(selectedChildren).length === plannedAbsenceForm?.ChildrenInfo.length &&
        Object.values(selectedChildren).every((v) => v === true);

    const handleAddAbsencePress = async () => {
        if (validateUserInputs() && selectedAbsenceReason) {
            const plannedAbsenceParams: PlannedAbsenceParams = {
                AllChildren: allChildrenSelected(),
                SelectedChildren: Object.keys(selectedChildren),
                ReasonId: selectedAbsenceReason,
                Note: comments,
                SendEmailConfirmation: emailAbsence,
                StartDate: format(startDate, 'M/dd/yyy'),
                EndDate: allDay ? format(endDate, 'M/dd/yyy') : format(startDate, 'M/dd/yyy'),
                StartTime: format(startDate, 'hh:mm a'),
                EndTime: format(endDate, 'hh:mm a'),
            };
            setSubmitting(true);
            if (!absenceId) {
                try {
                    await addPlannedAbsence(plannedAbsenceParams).unwrap();
                    store.dispatch(alert({ showAlert: true, message: i18n.t('successAddAbsence') }));
                    onDismiss();
                } catch (e) {
                    if (e?.data?.Message) {
                        store.dispatch(
                            alert({ isError: true, showAlert: true, message: e?.data?.Message })
                        );
                        onDismiss();
                    } else {
                        const defaultErrorMessage = i18n.t('fallbackAddAbsenceErrorMessage');
                        const errMessage = e?.data?.Message || defaultErrorMessage;
                        setMessage(errMessage);
                        setVisible(true);
                    }
                } finally {
                    setSubmitting(false);
                }
            } else {
                plannedAbsenceParams.AbsenceId = absenceId;
                try {
                    await editPlannedAbsence(plannedAbsenceParams).unwrap();
                    onDismiss();
                } catch (e) {
                    const defaultErrorMessage = i18n.t('fallbackEditAbsenceErrorMessage');
                    const errMessage = e?.data?.Message || defaultErrorMessage;
                    setMessage(errMessage);
                    setVisible(true);
                } finally {
                    setSubmitting(false);
                }
            }
        }
    };

    const handleReportLatePress = async () => {
        if (validateUserInputs() && selectedAbsenceReason) {
            setSubmitting(true);
            try {
                await addLateness({
                    AllChildren: allChildrenSelected(),
                    SelectedChildren: Object.keys(selectedChildren),
                    ReasonId: selectedAbsenceReason,
                    Note: comments,
                    SendEmailConfirmation: emailAbsence,
                }).unwrap();
                onDismiss();
                store.dispatch(alert({ showAlert: true, message: i18n.t('successAddLateness') }));
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackAddLateErrorMessage');
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            } finally {
                setSubmitting(false);
            }
        }
    };

    const getDisabledDates = useMemo(
        () =>
            plannedAbsenceForm
                ? plannedAbsenceForm.ExceptionDays.map((exceptionDay) => new Date(exceptionDay)).concat(
                      eachWeekendOfInterval({ start: new Date(), end: new Date(plannedAbsenceForm.SchoolYearEnd) })
                  )
                : [],
        [plannedAbsenceForm]
    );

    return (
        <Modal
            style={[style ? style : styles.modal, isDesktop && { height: '80%' }]}
            visible={show}
            animationType={animationType}
            header={
                <ModalHeader
                    header={isReportingLate ? i18n.t('late') : i18n.t('plannedAbsenceHeader')}
                    onDismiss={onDismiss}
                />
            }
        >
            <KeyboardAwareScrollView extraScrollHeight={30} keyboardShouldPersistTaps='handled'>
                <View style={styles.container}>
                    {loading ? (
                        <ActivityIndicator color={theme.colors.lychee} />
                    ) : (
                        <>
                            <View style={{ paddingBottom: 16 }}>
                                {isReportingLate &&
                                    errors.dateError.length > 0 &&
                                    errors.dateError.map((err, i) => (
                                        <Text key={i} style={styles.errorLabel}>
                                            {err}
                                        </Text>
                                    ))}
                                {isReportingLate && errors.timeError && <Text style={styles.errorLabel}>{errors.timeError}</Text>}
                            </View>

                            <View style={[{ flexDirection: 'row' }, styles.checkboxContainer]}>
                                {plannedAbsenceForm?.ChildrenInfo.map((child) => {
                                    const isChecked = selectedChildren[child.StudentId];
                                    return (
                                        <CheckBoxProfile
                                            studentId={child.StudentId}
                                            onChecked={handleChildrenSelection}
                                            key={child.StudentId}
                                            style={styles.child}
                                            checked={isChecked}
                                            name={child.FirstName}
                                            picture={studentsMap[child.StudentId]?.PhotoBase64}
                                        />
                                    );
                                })}
                                {errors.selectedChildrenError && (
                                    <Text style={styles.errorLabel}>{errors.selectedChildrenError}</Text>
                                )}
                            </View>
                            {!isReportingLate && (
                                <>
                                    <SwitchInput
                                        checked={allDay}
                                        icon={<SvgSchedule />}
                                        label={i18n.t('allDay')}
                                        onChange={handleAllDayChange}
                                        reverse
                                        viewStyle={styles.switchContainer}
                                    />

                                    <View style={styles.bottomMargin}>
                                        {allDay ? (<>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <View style={{ flexBasis: '45%' }}>
                                                    <DatePicker
                                                        minimumDate={new Date()}
                                                        maximumDate={
                                                            plannedAbsenceForm &&
                                                            new Date(plannedAbsenceForm?.SchoolYearEnd)
                                                        }
                                                        disabledDates={getDisabledDates}
                                                        value={startDate}
                                                        label={i18n.t('startDate')}
                                                        onDateChange={handleStartDateChange}
                                                    />
                                                </View>
                                                <View style={{ flexBasis: '45%' }}>
                                                    <DatePicker
                                                        value={endDate}
                                                        minimumDate={startDate}
                                                        maximumDate={
                                                            plannedAbsenceForm &&
                                                            new Date(plannedAbsenceForm?.SchoolYearEnd)
                                                        }
                                                        disabledDates={getDisabledDates}
                                                        label={i18n.t('endDate')}
                                                        onDateChange={handleEndDateChange}
                                                    />
                                                </View>  
                                            </View>
                                            {errors.timeError && (
                                                <Text style={styles.errorLabel}>{errors.timeError}</Text>
                                            )}
                                            </>
                                        ) : (
                                            <DatePicker
                                                value={startDate}
                                                minimumDate={new Date()}
                                                maximumDate={
                                                    plannedAbsenceForm && new Date(plannedAbsenceForm?.SchoolYearEnd)
                                                }
                                                disabledDates={getDisabledDates}
                                                label={i18n.t('date')}
                                                onDateChange={handleStartDateChange}
                                            />
                                        )}
                                        {errors.dateError.length > 0 &&
                                            errors.dateError.map((err, i) => (
                                                <Text key={i} style={styles.errorLabel}>
                                                    {err}
                                                </Text>
                                            ))}
                                    </View>

                                    {!allDay && (
                                        <View style={styles.bottomMargin}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <View style={{ flexBasis: '45%' }}>
                                                    <TimePicker
                                                        header={i18n.t('choicesHeaderStartTime')}
                                                        firstChoiceLabel={i18n.t('beginningOfDay')}
                                                        customText={
                                                            isBeginningOfDay ? i18n.t('beginningOfDay') : undefined
                                                        }
                                                        value={startDate}
                                                        label={i18n.t('startTime')}
                                                        onDateChange={handleStartTimeChange}
                                                    />
                                                </View>
                                                <View style={{ flexBasis: '45%' }}>
                                                    <TimePicker
                                                        header={i18n.t('choicesHeaderEndTime')}
                                                        firstChoiceLabel={i18n.t('endOfDay')}
                                                        label={i18n.t('endTime')}
                                                        customText={isEndOfDay ? i18n.t('endOfDay') : undefined}
                                                        value={endDate}
                                                        onDateChange={handleEndTimeChange}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    )}
                                </>
                            )}
                            <View style={styles.bottomMargin}>
                                <Picker
                                    items={getdropDownItems()}
                                    onValueChange={handleAbsenceReasonChange}
                                    value={selectedAbsenceReason}
                                    inputLabel={i18n.t('reason')}
                                />
                                {errors.reasonError && <Text style={styles.errorLabel}>{errors.reasonError}</Text>}
                            </View>
                            <View style={styles.bottomMargin}>
                                <TextInput
                                    mode={'outlined'}
                                    label={i18n.t('comments')}
                                    multiline={true}
                                    value={comments}
                                    onChangeText={(value: string) => setComments(value)}
                                    left={<PaperTextInput.Icon name={() => <Comments />} />}
                                />
                            </View>

                            <CheckboxWithLabel
                                styleOverride={{ flexDirection: 'row' }}
                                checked={emailAbsence}
                                onChange={setEmailAbsence}
                                label={isReportingLate ? i18n.t('emailLate') : i18n.t('emailAbsence')}
                            />
                            {!absenceId && (
                                <View style={styles.buttonContainer}>
                                    {!isReportingLate ? (
                                        <Button
                                            onPress={handleAddAbsencePress}
                                            leftIcon={isSubmitting ? <ActivityIndicator /> : <SvgPlus />}
                                            label={i18n.t('submit')}
                                        />
                                    ) : (
                                        <Button
                                            onPress={handleReportLatePress}
                                            leftIcon={isSubmitting ? <ActivityIndicator /> : <SvgPlus />}
                                            label={i18n.t('submit')}
                                        />
                                    )}
                                </View>
                            )}
                            {absenceId && (
                                <View style={styles.buttonContainer}>
                                    <Button
                                        onPress={handleAddAbsencePress}
                                        leftIcon={isSubmitting ? <ActivityIndicator /> : <SvgDone />}
                                        underlineColor={theme.colors.watermelon}
                                        label={i18n.t('save')}
                                        style={styles.saveButton}
                                    />
                                    <Button
                                        onPress={onDismiss}
                                        label={i18n.t('cancel')}
                                        underlineColor={theme.colors.lychee}
                                    />
                                </View>
                            )}
                        </>
                    )}
                </View>
            </KeyboardAwareScrollView>
            <ErrorAlert message={message} visible={visible} setVisible={setVisible} />
        </Modal>
    );
};

export default AbsenceForm;
