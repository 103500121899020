import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgSchedule = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M22.745 12.02c0 5.923-4.802 10.725-10.726 10.725-5.923 0-10.726-4.802-10.726-10.726 0-5.923 4.803-10.726 10.726-10.726 5.924 0 10.726 4.803 10.726 10.726Zm-11.004-.59 3.26-5.645 3.258 5.645h-6.518Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgSchedule;
