import React, { useCallback, useEffect, useState } from 'react';
import { FlatList } from 'react-native-bidirectional-infinite-scroll';
import { Platform, useWindowDimensions, View } from 'react-native';

import { formatISO } from 'date-fns';

import { wait } from '@Utilities';
import { DateEntry, LoadingIndicator, WeekdayIndicator } from './components';

import { CalendarComponentProps } from './types';
import { dateRep } from '@Redux/services/CalendarApi';

import { styles } from './styles';

function CalendarAgenda({
    captureHeight,
    eachDayOfDailySeriesEventMap,
    handleEndReached,
    handleStartReached,
    handleViewLayout,
    initialFetchComplete,
    handleScroll,
    flatListRef,
    promisedSetSkipScrollListener,
    ...rest
}: CalendarComponentProps) {
    const [retryScrollIdx, setRetryScrollIdx] = useState<number>(-1);
    const [retryScrollToIdx, setRetryScrollToIdx] = useState<number>(0);
    const { height } = useWindowDimensions();

    useEffect(() => {
        (async function () {
            await Promise.all([promisedSetSkipScrollListener(false), wait(32)]);
            flatListRef.current?.scrollToIndex({ index: retryScrollToIdx, animated: true });
            await Promise.all([wait(32), promisedSetSkipScrollListener(true)]);
        })();
    }, [retryScrollIdx, retryScrollToIdx]);

    const handleScrollToIndexFailed = useCallback(
        (error) => {
            // console.log(error);

            setRetryScrollToIdx(error.index);
            setRetryScrollIdx(retryScrollIdx + 1);
        },
        [flatListRef, retryScrollIdx]
    );

    const handleWrappedStartReached = async () => {
        await handleStartReached();
        if (Platform.OS === 'android') {
            flatListRef.current?.scrollToOffset({ offset: 15, animated: false });
        }
    };

    useEffect(() => {
        if (initialFetchComplete && Platform.OS === 'android') {
            flatListRef.current?.scrollToOffset({ offset: 15, animated: false });
        }
    }, [initialFetchComplete]);

    return (
        <View style={styles.calendarWrapper}>
            <WeekdayIndicator {...rest} />
            <View style={styles.eventList} onLayout={handleViewLayout}>
                {!initialFetchComplete ? (
                    <LoadingIndicator />
                ) : (
                    <FlatList
                        style={styles.eventFlatList}
                        contentContainerStyle={
                            Platform.OS === 'android'
                                ? { minHeight: height + 100, paddingTop: 5 }
                                : { minHeight: height + 100 }
                        }
                        {...rest}
                        onScrollToIndexFailed={handleScrollToIndexFailed}
                        data={eachDayOfDailySeriesEventMap}
                        ref={flatListRef}
                        enableAutoscrollToTop={false}
                        keyExtractor={(date) => `calendar-flatlist-${formatISO(date, dateRep)}`}
                        onEndReached={handleEndReached} // required, should return a promise
                        onEndReachedThreshold={10} // optional
                        onStartReached={handleWrappedStartReached} // required, should return a promise
                        onStartReachedThreshold={10} // optional
                        renderItem={(props) => (
                            <DateEntry
                                {...props}
                                {...rest}
                                onEventAccepted={rest.loadEvents}
                                reportHeight={captureHeight}
                            />
                        )}
                        showDefaultLoadingIndicators={true} // optional
                        FooterLoadingIndicator={LoadingIndicator}
                        HeaderLoadingIndicator={LoadingIndicator}
                        onScroll={handleScroll}
                    />
                )}
            </View>
        </View>
    );
}

export default CalendarAgenda;
