import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgLock = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.73 9.439V6.767c0-1.77.607-3.272 1.823-4.507C7.768 1.025 9.25.408 11 .408s3.232.617 4.447 1.852c1.216 1.235 1.824 2.737 1.824 4.507V9.41a10.221 10.221 0 0 1 3.985 8.108c0 5.654-4.583 10.238-10.238 10.238C5.364 27.756.78 23.172.78 17.518a10.22 10.22 0 0 1 3.95-8.08Zm3.674-1.822c.835-.22 1.71-.337 2.614-.337.89 0 1.754.114 2.578.327v-.84c0-.74-.248-1.371-.743-1.896-.496-.525-1.114-.788-1.853-.788-.739 0-1.356.263-1.851.788a2.662 2.662 0 0 0-.745 1.896v.85Zm4.783 7.705H8.822v4.365h4.365v-4.365Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgLock;
