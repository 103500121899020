import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        padding: 24,
    },
    header: {
        ...typography.h3,
        paddingBottom: 16,
    },
    button: {
        alignItems: 'flex-end',
    },
    description: {
        ...typography.body2,
    },
    subheader: {
        ...typography.h5,
        paddingBottom: 8,
    },
    safetyNote: {
        ...typography.body2,
        marginBottom: 8,
    },
    inputs: {
        paddingBottom: 16,
    },
    costLabel: {
        ...typography.body2,
    },
    iconInfo: {
        marginRight: 16,
    },
    iconSpace: {
        marginRight: 24,
    },
    iconContainer: {
        flexDirection: 'row',
    },
    dueDate: {
        ...typography.body3,
        marginBottom: 4,
    },
    body: {
        ...typography.body2,
        marginBottom: 4,
        flex: 1,
    },
    amountPaid: {
        ...typography.buttonMedium,
    },
    sectionMargin: {
        paddingBottom: 16,
    },
    alert: {
        borderColor: defaultTheme.colors.blueberry,
        borderWidth: 1,
        padding: 12,
    },
    switchContainer: {
        borderColor: defaultTheme.colors.inputOutline,
        borderWidth: 1,
        marginBottom: 16,
        minHeight: 56,
        padding: 10,
    },
    switchContainerDisabled: {
        borderColor: defaultTheme.colors.disabled,
        borderWidth: 1,
        marginBottom: 16,
        minHeight: 56,
        padding: 10,
    },
    error: {
        ...typography.body2,
        color: defaultTheme.colors.error,
        marginBottom: 8,
        marginTop: 4,
    },
    safetyMargin: {
        marginBottom: 16,
    },
    checkbox: {
        paddingTop: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    questionLabel: {
        ...typography.body2,
    },
    accept: {
        marginLeft: 48,
        paddingBottom: 24,
    },
    callSchoolContainer: {
        marginTop: 24,
        marginBottom: 16,
        alignItems: 'flex-end',
    },
    callSchoolButton: {
        marginBottom: 8,
    },
    callSchoolLabel: {
        ...typography.body3,
    },
    helpLink: {
        borderBottomWidth: 1,
        borderBottomColor: defaultTheme.colors.backdrop,
    },
});

export default styles;
