import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    screen: {
        padding: 24,
    },
    description: {
        ...typography.body2,
        marginVertical: 20,
    },
});
