import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgLeftArrow = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.224 13.336.249 7 11.224.663v5.17h8.11v2.334h-8.11v5.17Z'
            fill='#FF5F4D'
        />
    </Svg>
);
export default SvgLeftArrow;
