import React, { useCallback, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getYear, format } from 'date-fns';
import useNotify from '@Hooks/useNotify';

import { IconCalendar } from '@Icon';
import { CalendarComponentProps } from '../types';
import CalendarAgenda from '../Agenda';
import { FiltersModal } from './components';
import { useLeftEdgeSwipe } from '@Utilities/useSwipe';

import { styles } from './styles';

function MobileCalendar({ ...props }: CalendarComponentProps) {
    const inset = useSafeAreaInsets();
    const [filtersVisible, setFiltersVisible] = useState(false);

    const { absenceForm, notifyButtonModal, notifyButton } = useNotify();

    const handleOpenModal = useCallback(() => {
        setFiltersVisible(true);
    }, [setFiltersVisible]);

    const { handleTouchStart, handleTouchEnd } = useLeftEdgeSwipe(handleOpenModal);

    return (
        <View
            style={[styles.calendarWrapper, { paddingTop: inset.top + styles.calendarWrapper.paddingTop }]}
            onTouchEnd={handleTouchEnd}
            onTouchStart={handleTouchStart}
        >
            {notifyButtonModal}
            <FiltersModal filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} {...props} />
            <Pressable style={styles.currentIntervalMonth} onPress={handleOpenModal}>
                <IconCalendar height={50} width={50} />
                <Text style={styles.currentIntervalMonthText}> {format(props.currentIntervalMonth, 'MMMM')}</Text>
                {props.currentYear !== getYear(props.currentIntervalMonth) && (
                    <Text style={styles.currentIntervalMonthText}> ({format(props.currentIntervalMonth, 'yyyy')})</Text>
                )}
            </Pressable>
            <CalendarAgenda {...props} />
            {notifyButton}
            {absenceForm}
        </View>
    );
}

export default MobileCalendar;
