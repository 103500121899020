import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgEye = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.197 5.637c0-3.08-3.466-5.577-7.742-5.577S.713 2.557.713 5.637s3.466 5.576 7.742 5.576 7.742-2.497 7.742-5.576ZM8.506 8.922a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Zm-1.25-4.5H9.75v2.494H7.256V4.422Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgEye;
