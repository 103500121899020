import { createNavigationContainerRef } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export type RootStackParamList = {
    home: undefined;
    signin: undefined;
    signup: undefined;
    sociallogin: { accessToken: string; provider: 'Microsoft' | 'Facebook' | 'Google' | 'Apple' };
    paypalpay: { customId: string; paymentId: string; PayerID: string; token: string; action?: string };
    confirm: { email: string };
    password: { email?: string; gwak?: string };
    resetPassword: { token: string };
    register: { email: string };
    forgot: undefined;
    forgotConfirmation: undefined;
    terms: undefined;
    privacy: undefined;
    callback: undefined;
    suggestions: undefined;
    Dashboard: undefined;
    Profile: undefined;
    ContactInformation: undefined;
    ContactPreferences: undefined;
    ContactPreferencesDetail: { preference: string };
    Balance: undefined;
    SecurityPreferences: undefined;
    StudentList: undefined;
    StudentProfile: { studentId: string };
    RegisterChild: { registrationId?: string };
    NewUser: undefined;
    Preview: { id: string };
};

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export type RootNavigationProp = NativeStackNavigationProp<RootStackParamList>;
