import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelContainer: {
        borderBottomWidth: 4,
    },
    label: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    rightIcon: {
        marginLeft: 8,
    },
    leftIcon: {
        marginRight: 8,
    },
});
