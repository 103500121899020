import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import type { PartialDeep } from 'type-fest';

import ArrowRight from '@Icon/ArrowRight';
import Button from '@Components/Button';
import Done from '@Icon/Done';
import LeftArrow from '@Icon/LeftArrow';
import useTheme, { defaultTheme } from '@Hooks/useTheme';
import {
    useCreateStudentRegistrationMutation,
    CreateStudentRegistrationBody,
    useUpdateStudentRegistrationMutation,
} from '@Redux/services/StudentRegistrationApi';
import { styles } from './styles';
import { BasicLookup, useGetCountriesQuery } from '@Redux/services/LookupApi';
import { createLookupMap } from '@Utilities';
import { useGetRegistrationsAndLinkingsQuery } from '@Redux/services/parent';
import { RegistrationStatuses } from '@App/constants';

interface RegisterChildApplicationFooterProps {
    activeStepIdx: number;
    onNext: () => void;
    onPrev: () => void;
    lastStepIdx: number;
    studentRegistration: PartialDeep<CreateStudentRegistrationBody>;
    onSubmit: () => void;
    onError: () => void;
}

function prepareStudentRegistrationBody(
    studentRegistration: PartialDeep<CreateStudentRegistrationBody>,
    countryOfBirth?: string,
    childMailingAddressCountry?: string,
    parentAddressCountry?: string
) {
    let dateOfBirth = studentRegistration?.ChildInformation?.DateOfBirth;
    if (dateOfBirth instanceof Date) {
        dateOfBirth = `${dateOfBirth.getFullYear()}/${dateOfBirth.getMonth() + 1}/${dateOfBirth.getDate()}`;
    } else if (typeof dateOfBirth === 'string') {
        dateOfBirth = dateOfBirth.replace(/-/g, '/').replace(/T.+/, '');
    }
    return {
        ...studentRegistration,
        AddressInformation: {
            ...studentRegistration.AddressInformation,
            ChildMailingAddress: {
                ...studentRegistration?.AddressInformation?.ChildMailingAddress,
                Province:
                    childMailingAddressCountry === 'Canada'
                        ? studentRegistration?.AddressInformation?.ChildMailingAddress?.Province
                        : null,
            },
            ParentAddress: {
                ...studentRegistration?.AddressInformation?.ParentAddress,
                Province:
                    parentAddressCountry === 'Canada'
                        ? studentRegistration?.AddressInformation?.ParentAddress?.Province
                        : null,
            },
        },
        ChildInformation: {
            ...studentRegistration.ChildInformation,
            LifeThreateningMedicalDetails: studentRegistration?.ChildInformation?.IsLifeThreateningMedical
                ? studentRegistration?.ChildInformation?.LifeThreateningMedicalDetails
                : null,
            AccessibilityChallengesDetails: studentRegistration?.ChildInformation?.IsChildHasAccessibilityNeeds
                ? studentRegistration?.ChildInformation?.AccessibilityChallengesDetails
                : null,
            DateOfBirth: dateOfBirth,
        },
        CitizenshipInformation: {
            ...studentRegistration?.CitizenshipInformation,
            ProvinceOfBirth:
                countryOfBirth === 'Canada' ? studentRegistration?.CitizenshipInformation?.ProvinceOfBirth : null,
        },
    };
}

const RegisterChildApplicationFooter: FC<RegisterChildApplicationFooterProps> = ({
    activeStepIdx,
    onNext: handleNext,
    onPrev: handlePrev,
    studentRegistration,
    lastStepIdx,
    onSubmit,
    onError,
}) => {
    const [createStudentRegistrationMutation] = useCreateStudentRegistrationMutation();
    const [updateStudentRegistration] = useUpdateStudentRegistrationMutation();
    const theme = useTheme();
    const { data: studentRegistrations, refetch: getStudentRegistrationsAndLinkings } =
        useGetRegistrationsAndLinkingsQuery();
    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);
    const countryOfBirth = countryMap[studentRegistration?.CitizenshipInformation?.CountryOfBirth as number];
    const childMailingAddressCountry =
        countryMap[studentRegistration?.AddressInformation?.ChildMailingAddress?.Country as number];
    const parentAddressCountry = countryMap[studentRegistration?.AddressInformation?.ParentAddress?.Country as number];

    const handleSubmit = useCallback(async () => {
        const studentRegistrationBody = prepareStudentRegistrationBody(
            studentRegistration,
            countryOfBirth?.Value,
            childMailingAddressCountry?.Value,
            parentAddressCountry?.Value
        );
        try {
            if (studentRegistrationBody && studentRegistrationBody.Id) {
                await getStudentRegistrationsAndLinkings();
                if (
                    studentRegistrations?.some(
                        (registration) =>
                            registration.Id === studentRegistration.Id &&
                            (registration.Status === RegistrationStatuses.Submitted ||
                                registration.Status === RegistrationStatuses.Rejected)
                    )
                ) {
                    await updateStudentRegistration(studentRegistrationBody);
                } else {
                    onError();
                    return;
                }
            } else if (studentRegistrationBody) {
                await createStudentRegistrationMutation(studentRegistrationBody);
            }
        } catch (e) {
            onError();
            return;
        }
        onSubmit();
    }, [studentRegistration, countryOfBirth]);

    if (activeStepIdx === 0) {
        return (
            <View style={[styles.footer, styles.first]}>
                <Button
                    onPress={handleNext}
                    rightIcon={<ArrowRight />}
                    label='Continue'
                    underlineColor={theme.colors.watermelon}
                />
            </View>
        );
    }

    return (
        <View style={styles.footer}>
            <Button onPress={handlePrev} label='Prev' leftIcon={<LeftArrow />} underlineColor={theme.colors.lychee} />
            {activeStepIdx === lastStepIdx ? (
                <Button
                    onPress={handleSubmit}
                    rightIcon={<Done fill={defaultTheme.colors.watermelon} />}
                    label='Submit'
                    underlineColor={theme.colors.watermelon}
                />
            ) : (
                <Button
                    onPress={handleNext}
                    label='Next'
                    rightIcon={<ArrowRight />}
                    underlineColor={theme.colors.watermelon}
                />
            )}
        </View>
    );
};

export default RegisterChildApplicationFooter;
