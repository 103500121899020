import i18n from '@I18n/index';
import SvgAccordionDown from '@Icon/AccordionDown';
import SvgAccordionUp from '@Icon/AccordionUp';
import SvgAlarm from '@Icon/Alarm';
import SvgNeutralFace from '@Icon/NeutralFace';
import { AttendanceSummary } from '@Redux/types';
import React, { useCallback, useState } from 'react';
import { View, Text, Pressable } from 'react-native';

import AttendanceItem from '../AttendanceItem';
import styles from './styles';

interface IProps {
    courseAttendance: AttendanceSummary;
}
const CourseAttendance: React.FC<IProps> = ({ courseAttendance }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const filterAbsencedByCode = useCallback(
        (isLate: boolean) => {
            return courseAttendance.SchoolCourse.Absences.filter((absence) =>
                isLate ? absence.AbsentCode === 'L' : absence.AbsentCode !== 'L'
            );
        },
        [courseAttendance]
    );

    return (
        <View style={styles.root}>
            <View style={styles.courseContainer}>
                <Text style={styles.courseDescription}>{courseAttendance.SchoolCourse.CourseDescription}</Text>
                <View style={styles.iconContainer}>
                    <View style={styles.iconCount}>
                        <SvgNeutralFace width={20} />
                        <Text style={styles.count}>{filterAbsencedByCode(false).length}</Text>
                    </View>
                    <View style={styles.iconCount}>
                        <SvgAlarm width={20} />
                        <Text style={styles.count}>{filterAbsencedByCode(true).length}</Text>
                    </View>
                </View>
            </View>
            {isExpanded && <AttendanceItem absences={filterAbsencedByCode(false)} label={i18n.t('absent')} />}
            {isExpanded && <AttendanceItem absences={filterAbsencedByCode(true)} label={i18n.t('late')} />}

            <View style={styles.accordion}>
                <Pressable onPress={() => setIsExpanded(!isExpanded)}>
                    <View style={styles.accordionIcon}>
                        {!isExpanded ? <SvgAccordionDown width={30} /> : <SvgAccordionUp width={30} />}
                    </View>
                </Pressable>
            </View>
        </View>
    );
};

export default CourseAttendance;
