import { Duration } from 'date-fns';

export type StudentsFilter = Record<string, boolean>;

export const allStudentsLabel = 'All Children';
export type OfferStatus = 'invited' | 'accepted' | 'expired' | 'acceptedpast';
export type OfferStatusFilter = Record<OfferStatus, boolean>;
export type EventType = 'fieldtrip' | 'noevents' | 'offers' | 'other' | 'schoolboard' | 'schoolyear';
export type EventTypesFilter = Record<EventType, boolean>;
export interface RetrieveUsersEventsAsyncParams {
    Start: string;
    End: string;
    studentsFilter: StudentsFilter;
    eventTypesFilter: EventTypesFilter;
    offerStatusFilter: OfferStatusFilter;
}

export type ODataEvent = {
    id: string;
    subject: string;
    seriesMasterId: string;
    Owner: string;
    recurrence: string;
    isAllDay: boolean;
    isCancelled: boolean;
    type: 'singleInstance' | 'occurrence' | 'exception' | 'seriesMaster';
    location: {
        displayName: string;
    };
    start: {
        dateTime: string;
    };
    end: {
        dateTime: string;
    };
    showAs: 'tentative' | 'busy' | 'free';
};

export function isODataEvent(obj: unknown): obj is ODataEvent {
    const {
        id,
        subject,
        start: { dateTime: start } = {},
        end: { dateTime: end } = {},
        type,
        showAs,
    } = obj as ODataEvent;
    return [id, subject, start, end, type, showAs].filter((val) => !val).length === 0;
}

export type UserEvent = {
    Id: string;
    Start: string;
    Owner: { FullName: 'All Children' } | string;
    End: string;
    EventType: EventType;
    IsAllDay: boolean;
    IsFieldTrip: boolean;
    Subject: string;
    Body: {
        ContentType: string;
        Content: string;
    };
    StatusCodeDescription:
        | 'Pending'
        | 'ConfirmedandClosed'
        | 'Closed'
        | 'Draft'
        | 'Cancelled'
        | 'Confirmed'
        | 'CancelledandClosed';
    PastDue: boolean;
};

export function isUserEvent(obj: unknown): obj is UserEvent {
    const { Id, Start, End, EventType, Subject } = obj as UserEvent;
    return [Id, Start, End, EventType, Subject].filter((val) => !val).length === 0;
}

export type UnknownEvent = UserEvent | ODataEvent;

export interface CalendarEvent {
    body: string;
    recurrence: string;
    seriesMasterId: string;
    allDay: boolean;
    end: string;
    id: string;
    isCancelled: boolean;
    isTentative: boolean;
    location: string;
    owner: string[] | 'All Children';
    eventIds: string[];
    start: string;
    title: string;
    type: EventType;
    duration: Duration;
    offerStatus?: OfferStatus;
    pastDue?: boolean;
}

export type DailySeriesEventDateMap = Record<string, DailySeriesEvent[]>;
export interface DailySeriesEvent extends CalendarEvent {
    isFirstInDailySeries: boolean;
    isLastInDailySeries: boolean;
}

export interface EventAttachment {
    contentType: string;
    contentBytes: string;
}
