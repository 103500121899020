import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    expandedViewItem: {
        flexDirection: 'row',
        marginBottom: 32,
    },
    titleContainer: {
        borderTopColor: defaultTheme.colors.textPrimary,
        borderTopWidth: 1,
    },
    title: {
        ...typography.body3,
        width: 65,
        paddingRight: 10,
    },
    absenceInformation: {
        flexDirection: 'row',
        ...typography.body3,
        fontWeight: '700',
        marginLeft: 20,
    },
    margin: {
        marginRight: 10,
    },
});

export default styles;
