import React from 'react';
import { ColorValue, Pressable, Text, TextStyle, View, ViewStyle } from 'react-native';
import { styles } from './styles';

interface IProps {
    label: string;
    labelStyle?: TextStyle;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    style?: ViewStyle;
    onPress?: () => void;
    underlineColor?: ColorValue;
    underlineWidth?: number;
}

const Button: React.FC<IProps> = ({ onPress, underlineColor, label, labelStyle, leftIcon, rightIcon, style, underlineWidth = 4 }) => {
    return (
        <Pressable onPress={onPress} style={style}>
            <View style={styles.buttonContainer}>
                {leftIcon && <View style={styles.leftIcon}>{leftIcon}</View>}
                <View
                    style={{
                        borderBottomColor: underlineColor,
                        borderBottomWidth: underlineColor ? underlineWidth : 0,
                        maxWidth: '100%',
                    }}
                >
                    <Text style={labelStyle ? labelStyle : styles.label} accessibilityLabel={label}>
                        {label}
                    </Text>
                </View>
                {rightIcon && <View style={styles.rightIcon}>{rightIcon}</View>}
            </View>
        </Pressable>
    );
};

export default Button;
