import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    screen: {
        padding: 24,
        flex: 1,
    },
    topContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 120,
        borderBottomColor: defaultTheme.colors.textPrimary,
        borderBottomWidth: 2,
        marginBottom: 16,
    },
    topContainerDesktop: {
        minHeight: 80,
    },
    header: typography.h1,
    headerDesktop: typography.h2,
    alert: {
        borderColor: defaultTheme.colors.watermelon,
        borderWidth: 1,
        borderRadius: 4,
        padding: 12,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
        marginTop: 8,
    },
    alertTextContainer: {
        marginLeft: 4,
        paddingHorizontal: 8,
        flex: 1,
    },
    alertText: {
        ...typography.body2,
        flexShrink: 1,
    },
    addBalanceButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    addBalanceButtonIcon: {
        margin: 10,
    },
    addBalanceButton: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 2,
    },
    addBalanceButtonText: {
        ...typography.buttonMedium,
        lineHeight: 28,
    },
    recentTransaction: {
        ...typography.h4,
        marginBottom: 8,
    },
});
