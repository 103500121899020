import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgMedical = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m19.111 10.77-9.07 6.59-9.07-6.59L4.434.107h11.212L19.11 10.77Zm-7.86.146h-2.5V8.833H6.666v-2.5H8.75V4.25h2.5v2.083h2.084v2.5H11.25v2.083Z'
            fill='#D77A7F'
        />
    </Svg>
);
export default SvgMedical;
