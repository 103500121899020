import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgComments = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 16 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.51.96h14.981v24.182H.51V.96ZM2 8.5h12v3H2v-3Zm0 6h12v3H2v-3Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgComments;
