import Navigation from './src';
import { Provider as StoreProvider } from 'react-redux';
import { Provider as PaperProvider } from 'react-native-paper';
import {
    Montserrat_100Thin,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
    Montserrat_800ExtraBold,
    Montserrat_900Black,
    useFonts,
} from '@expo-google-fonts/montserrat';
import useTheme from '@Hooks/useTheme';
import { store } from '@Redux';
import '@expo/match-media';
import { DeviceContextProvider } from '@Contexts/DeviceContext';
import { useMediaQuery } from 'react-responsive';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ErrorContextProvider } from '@Contexts/ErrorContext';
import { useState } from 'react';
import ErrorAlert from '@Components/ErrorAlert';
// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (process?.browser) {
    window._frameTimestamp = null;
}

export default function App() {
    const theme = useTheme();
    const [fontsLoaded, error] = useFonts({
        Montserrat_100Thin,
        Montserrat_300Light,
        Montserrat_400Regular,
        Montserrat_500Medium,
        Montserrat_600SemiBold,
        Montserrat_700Bold,
        Montserrat_800ExtraBold,
        Montserrat_900Black,
    });

    const isDesktop = useMediaQuery({
        minWidth: 1024,
    });
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    // TODO: Check device orientation

    // TODO: splash screen if not loaded
    if (!fontsLoaded && !error) {
        return null;
    }

    return (
        <StoreProvider store={store}>
            <PaperProvider theme={theme}>
                <SafeAreaProvider>
                    <DeviceContextProvider value={{ isDesktop, isLandscape: false }}>
                        <ErrorContextProvider value={{ message, setMessage, visible, setVisible }}>
                            <Navigation />
                            <ErrorAlert message={message} visible={visible} setVisible={setVisible} />
                        </ErrorContextProvider>
                    </DeviceContextProvider>
                </SafeAreaProvider>
            </PaperProvider>
        </StoreProvider>
    );
}
