import { Text, View } from 'react-native';
import React, { FC } from 'react';
import { styles } from './styles';

interface IProps {
    header: string;
    icon: React.ReactNode;
}

const Header: FC<IProps> = ({ header, icon }) => {
    return (
        <View style={styles.headerContainer}>
            <Text style={styles.header}>{header}</Text>
            {icon}
        </View>
    );
};

export default Header;
