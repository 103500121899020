import IconList from '@Components/IconList';
import useTheme from '@Hooks/useTheme';
import i18n from '@I18n/index';
import SvgIconBus from '@Icon/IconBus';
import SvgInfo from '@Icon/Info';
import { useGetStudentTransportationQuery } from '@Redux/services/student';
import { formatContactAddress } from '@Utilities/index';
import React from 'react';
import { Linking, StyleProp, View, ViewStyle, ScrollView } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

interface IProps {
    studentId: string;
    style?: StyleProp<ViewStyle>;
}

const Transportation: React.FC<IProps> = ({ studentId, style }) => {
    const { data: transportation } = useGetStudentTransportationQuery({ studentId });
    const theme = useTheme();

    if (!transportation) {
        return <ActivityIndicator />;
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <View style={style}>
                <IconList
                    icon={<SvgIconBus fill={theme.colors.watermelon} />}
                    header={i18n.t('pickup')}
                    body={formatContactAddress(transportation.PickUp)}
                    buttonLabel={transportation.PickUp.ContactPhone}
                    onButtonPress={() => Linking.openURL(`tel:${transportation.PickUp.ContactPhone}`)}
                />
            </View>
            <View style={style}>
                <IconList
                    icon={<SvgIconBus fill={theme.colors.watermelon} />}
                    header={i18n.t('dropoff')}
                    body={formatContactAddress(transportation.DropOff)}
                    buttonLabel={transportation.PickUp.ContactPhone}
                    onButtonPress={() => Linking.openURL(`tel:${transportation.PickUp.ContactPhone}`)}
                />
            </View>
            <View style={style}>
                <IconList icon={<SvgInfo />} header={i18n.t('buscompany')} body={transportation.BusCompany} />
            </View>
        </ScrollView>
    );
};

export default Transportation;
