import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    container: {
        flexGrow: 1,
        margin: 24,
        paddingBottom: 48,
    },
    fingerprintContainer: {
        paddingVertical: 52,
        alignItems: 'center',
    },
    fingerprintContainerSmall: {
        paddingVertical: 26,
    },
    greetings: {
        ...typography.h2,
    },
    greetings2: {
        ...typography.h1,
        marginTop: 12,
    },
    greetingsExtraSmall: {
        ...typography.h3,
    },
    greetings2ExtraSmall: {
        ...typography.h2,
    },
    greetings3: {
        ...typography.body,
        marginTop: 32,
    },
    fieldContainer: {
        paddingVertical: 16,
    },
    fieldContainerExtraSmall: {
        paddingBottom: 0,
    },
    forgotTextContainer: {
        borderBottomColor: defaultTheme.colors.raspberry,
        borderBottomWidth: 2,
    },
    forgot: {
        ...typography.buttonSmall,
        lineHeight: 20,
    },
    signInTextContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    signIn: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    controlContainer: {
        marginTop: 12,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 36,
    },
    controlContainerAppReturn: {
        minHeight: 60,
        marginBottom: 0,
    },
    controlContainerBottom: {
        marginTop: 4,
    },
    switchTextContainer: {
        borderBottomColor: defaultTheme.colors.lychee,
        borderBottomWidth: 2,
    },
    switch: {
        ...typography.buttonMedium,
        lineHeight: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 18,
    },
    iconWrapper: {
        borderRadius: 0,
        marginRight: 24,
        width: 50,
    },
    passwordTextContainer: {
        borderBottomColor: defaultTheme.colors.persimmon,
        borderBottomWidth: 2,
        marginVertical: 18,
    },
    password: {
        ...typography.buttonSmall,
        lineHeight: 20,
    },
    biometricTextContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    biometric: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    arrowRight: {
        fontSize: 24,
        marginLeft: 8,
    },
    errorLabelContainer: {
        marginHorizontal: 8,
    },
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.error,
    },
    socialLoginContainer: {
        marginBottom: 0,
    },
});
