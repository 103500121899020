import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import i18n from '@I18n';
import Picker from '@Components/Picker';
import DatePicker from '@Components/DatePicker';
import SwitchInput from '@Components/SwitchInput';

import type { PartialDeep } from 'type-fest';
import { CreateStudentRegistrationBody } from '@Redux/services/StudentRegistrationApi';

import { createLookupMap, lookupToOptions } from '@Utilities';

import {
    useGetCountriesQuery,
    useGetProvincesQuery,
    useGetLanguagesQuery,
    useGetAboriginalSelfIdsQuery,
    Province,
    BasicLookup,
} from '@Redux/services/LookupApi';

import { styles } from '../../styles';
import { ManageDatePickerProps, ManagePickerProps, StepProps } from '../../types';
const CitizenshipInfo: FC<StepProps> = ({ manageFieldPropFactory, studentRegistration, setStudentRegistration }) => {
    const [isAboriginalSelf, setIsAboriginalSelf] = useState(
        Boolean(studentRegistration?.CitizenshipInformation?.AboriginalSelfId)
    );
    const { data: provinces } = useGetProvincesQuery();
    const provinceOptions = useMemo(() => lookupToOptions<BasicLookup>(provinces), [provinces]);
    const provinceMap = useMemo(() => createLookupMap<Province>(provinces), [provinces]);
    const provinceOfBirth = provinceMap[studentRegistration?.CitizenshipInformation?.ProvinceOfBirth as number];

    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);
    const countryOptions = useMemo(() => lookupToOptions(countries), [countries]);
    const countryOfBirth = countryMap[studentRegistration?.CitizenshipInformation?.CountryOfBirth as number];

    const { data: languages } = useGetLanguagesQuery();
    const languageOptions = useMemo(() => lookupToOptions<BasicLookup>(languages), [languages]);

    const { data: aboriginalSelfIds } = useGetAboriginalSelfIdsQuery();
    const aboriginalSelfIdOptions = useMemo(() => lookupToOptions<BasicLookup>(aboriginalSelfIds), [aboriginalSelfIds]);

    const handleAboriginalSelfChange = useCallback(
        (value: boolean) => {
            if (!value) {
                setStudentRegistration({
                    ...studentRegistration,
                    CitizenshipInformation: {
                        ...studentRegistration?.CitizenshipInformation,
                        AboriginalSelfId: null,
                    },
                });
            }

            setIsAboriginalSelf(value);
        },
        [setStudentRegistration, studentRegistration]
    );

    const countryEntryManageFieldProps = manageFieldPropFactory<ManageDatePickerProps>(
        ['CitizenshipInformation', 'DateOfEntryIntoCanada'],
        'onDateChange',
        'value'
    );

    countryEntryManageFieldProps.onDateChange = useCallback(
        (value: Date) => {
            const newVal = new Date(`${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`);
            setStudentRegistration({
                ...studentRegistration,
                CitizenshipInformation: {
                    ...studentRegistration?.CitizenshipInformation,
                    DateOfEntryIntoCanada: newVal,
                },
            });
        },
        [setStudentRegistration, studentRegistration]
    );

    const provinceEntryManageFieldProps = manageFieldPropFactory<ManageDatePickerProps>(
        ['CitizenshipInformation', 'DateOfEntryIntoProvince'],
        'onDateChange',
        'value'
    );

    provinceEntryManageFieldProps.onDateChange = useCallback(
        (value: Date) => {
            const newVal = new Date(`${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}`);
            setStudentRegistration({
                ...studentRegistration,
                CitizenshipInformation: {
                    ...studentRegistration?.CitizenshipInformation,
                    DateOfEntryIntoProvince: newVal,
                },
            });
        },
        [setStudentRegistration, studentRegistration]
    );

    return (
        <View>
            <Picker
                inputLabel={i18n.t('studentRegisterCountryOfCitizenship')}
                {...manageFieldPropFactory<ManagePickerProps>(
                    ['CitizenshipInformation', 'CountryOfCitizenship'],
                    'onValueChange',
                    'value'
                )}
                items={countryOptions}
                style={styles.picker}
            />

            <Picker
                inputLabel={i18n.t('studentRegisterCountryOfBirth')}
                {...manageFieldPropFactory<ManagePickerProps>(
                    ['CitizenshipInformation', 'CountryOfBirth'],
                    'onValueChange',
                    'value'
                )}
                items={countryOptions}
                style={styles.picker}
            />
            {countryOfBirth?.Value === 'Canada' ? (
                <>
                    <Picker
                        inputLabel={i18n.t('studentRegisterProvinceOfBirth')}
                        {...manageFieldPropFactory<ManagePickerProps>(
                            ['CitizenshipInformation', 'ProvinceOfBirth'],
                            'onValueChange',
                            'value'
                        )}
                        items={provinceOptions}
                        style={styles.picker}
                    />
                    {provinceOfBirth?.Abbreviation !== 'ON' && (
                        <DatePicker
                            label={i18n.t('studentRegisterProvinceEntry')}
                            style={styles.picker}
                            {...provinceEntryManageFieldProps}
                        />
                    )}
                </>
            ) : (
                <>
                    <DatePicker
                        label={i18n.t('studentRegisterCountryEntry')}
                        style={styles.picker}
                        {...countryEntryManageFieldProps}
                    />
                    <DatePicker
                        label={i18n.t('studentRegisterProvinceEntry')}
                        style={styles.picker}
                        {...provinceEntryManageFieldProps}
                    />
                </>
            )}

            <Picker
                inputLabel={i18n.t('studentRegisterMotherTounge')}
                {...manageFieldPropFactory<ManagePickerProps>(
                    ['CitizenshipInformation', 'FirstLanguage'],
                    'onValueChange',
                    'value'
                )}
                items={languageOptions}
                style={styles.picker}
            />
            <Picker
                inputLabel={i18n.t('studentRegisterLanguageAtHome')}
                {...manageFieldPropFactory<ManagePickerProps>(
                    ['CitizenshipInformation', 'LanguageAtHome'],
                    'onValueChange',
                    'value'
                )}
                items={languageOptions}
                style={styles.picker}
            />

            <SwitchInput
                reverse
                viewStyle={styles.checkbox}
                label={i18n.t('studentRegisterAboriginalSelf')}
                checked={isAboriginalSelf}
                onChange={handleAboriginalSelfChange}
            />
            {isAboriginalSelf && (
                <Picker
                    inputLabel={i18n.t('studentRegisterAboriginalSelfId')}
                    {...manageFieldPropFactory<ManagePickerProps>(
                        ['CitizenshipInformation', 'AboriginalSelfId'],
                        'onValueChange',
                        'value'
                    )}
                    items={aboriginalSelfIdOptions}
                    style={styles.picker}
                />
            )}
        </View>
    );
};

export const CitizenshipInfoValidator = (studentRegistration: PartialDeep<CreateStudentRegistrationBody>) => {
    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);
    const countryOfBirth = countryMap[studentRegistration?.CitizenshipInformation?.CountryOfBirth as number];

    const { data: provinces } = useGetProvincesQuery();
    const provinceMap = useMemo(() => createLookupMap<Province>(provinces), [provinces]);
    const provinceOfBirth = provinceMap[studentRegistration?.CitizenshipInformation?.ProvinceOfBirth as number];

    const CitizenshipInfoValidatorResult = [
        { accessorPath: ['CitizenshipInformation', 'CountryOfBirth'], validator: Boolean },
        {
            accessorPath: ['CitizenshipInformation', 'DateOfEntryIntoCanada'],
            validator: (dateOfEntryIntoCanada: string) => {
                if (countryOfBirth?.Value !== 'Canada') {
                    return Boolean(dateOfEntryIntoCanada);
                }

                return true;
            },
        },
        {
            accessorPath: ['CitizenshipInformation', 'DateOfEntryIntoProvince'],
            validator: (dateOfEntryIntoOntario: string) => {
                if (countryOfBirth?.Value !== 'Canada') {
                    return Boolean(dateOfEntryIntoOntario);
                }

                if (provinceOfBirth.Abbreviation !== 'ON') {
                    return Boolean(dateOfEntryIntoOntario);
                }

                return true;
            },
        },
        { accessorPath: ['CitizenshipInformation', 'CountryOfCitizenship'], validator: Boolean },
        { accessorPath: ['CitizenshipInformation', 'FirstLanguage'], validator: Boolean },
        { accessorPath: ['CitizenshipInformation', 'LanguageAtHome'], validator: Boolean },
    ];
    return CitizenshipInfoValidatorResult;
};

export default CitizenshipInfo;
