import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    base: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 12,
        marginLeft: 14,
        marginBottom: 20,
        marginRight: 24,
    },
    baseDesktop: {
        flexDirection: 'row-reverse',
        marginRight: 0,
        maxWidth: 1280,
        width: '100%',
        alignSelf: 'center',
    },
    buttonContainer: {
        flexDirection: 'row',
    },
    buttonTextContainer: {
        borderBottomColor: defaultTheme.colors.blueberry,
        borderBottomWidth: 2,
        padding: 4,
        margin: 8,
    },
    buttonText: {
        ...typography.buttonSmall,
        lineHeight: 20,
    },
    version: {
        ...typography.body3,
    },
    underlined: {
        borderBottomColor: defaultTheme.colors.blueberry,
        borderBottomWidth: 2,
        paddingBottom: 5,
    },
});
