import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    registrationContainer: {
        flexDirection: 'row',
        marginBottom: 40,
    },
    avatar: {
        marginRight: 16,
    },
    name: {
        ...typography.h4,
        marginBottom: 8,
    },
    status: {
        flexDirection: 'row',
        marginBottom: 12,
    },
    statusIcon: {
        marginRight: 8,
    },
    body2: {
        ...typography.body2,
    },
    actionsContainer: {
        flexDirection: 'row',
        marginTop: 16,
    },
    editButton: {
        marginRight: 32,
    },
    deleteButton: {
        ...typography.buttonMedium,
    },
});

export default styles;
