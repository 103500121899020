import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconLockLocked = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.833 10.787V7.733c0-2.022.695-3.739 2.084-5.15C12.306 1.173 14 .467 16 .467s3.694.705 5.083 2.116c1.389 1.411 2.084 3.128 2.084 5.15v3.022a11.681 11.681 0 0 1 4.554 9.266c0 6.462-5.238 11.7-11.7 11.7-6.462 0-11.701-5.238-11.701-11.7a11.68 11.68 0 0 1 4.513-9.234Zm4.2-2.082a11.72 11.72 0 0 1 2.988-.385c1.017 0 2.005.13 2.946.374v-.96c0-.845-.284-1.567-.85-2.167-.567-.6-1.273-.9-2.117-.9-.845 0-1.55.3-2.116.9-.567.6-.85 1.322-.85 2.166v.972Zm5.467 8.806H13.51v4.99H18.5v-4.99Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgIconLockLocked;
