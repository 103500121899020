import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        marginTop: 8,
    },
    inputContainer: {
        borderColor: defaultTheme.colors.inputOutline,
        borderWidth: 1,
    },
    input: {
        ...typography.body2,
        padding: 10,
        height: 56,
        fontSize: 16,
        outlineWidth: 0,
    },
    labelContainer: {
        position: 'absolute',
        top: -10,
        left: 0,
        backgroundColor: defaultTheme.colors.background,
        paddingHorizontal: 4,
        transform: [{ scale: 0.75 }],
    },
    label: {
        ...typography.body2,
        fontSize: 16,
    },
    inputWebError: {
        borderColor: defaultTheme.colors.error,
        color: defaultTheme.colors.error,
    },
    inputWebErrorOpen: {
        color: defaultTheme.colors.textPrimary,
    },
    viewContainerError: {
        borderColor: defaultTheme.colors.error,
    },
    labelError: {
        color: defaultTheme.colors.error,
    },
});
