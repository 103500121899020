import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    body2: {
        ...typography.body2,
    },
});

export default styles;
