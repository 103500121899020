import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootNavigationProp, RootStackParamList } from '@App/rootNavigation';
import DeviceContext from '@Contexts/DeviceContext';
import { useContext, useEffect } from 'react';
import StudentProfileScreen from '@Screens/Student/Profile';
import HeaderTitle from '@Screens/Student/Profile/HeaderTitle';
import StudentList from './StudentList';
import { useNavigation } from '@react-navigation/native';
import { useStudentListEmpty } from '@Hooks/useStudentListEmpty';
import { isUndefined } from 'lodash';

function Student() {
    const StudentStack = createNativeStackNavigator<RootStackParamList>();
    const { isDesktop } = useContext(DeviceContext);

    const navigator = useNavigation<RootNavigationProp>();
    const { hasStudents } = useStudentListEmpty();
    useEffect(() => {
        if (!isUndefined(hasStudents) && !hasStudents) {
            navigator.navigate('Dashboard');
        }
    }, [hasStudents]);

    return (
        <StudentStack.Navigator initialRouteName='StudentList'>
            <StudentStack.Screen
                name='StudentList'
                component={StudentList}
                options={{
                    headerShown: false,
                }}
            />
            <StudentStack.Screen
                name='StudentProfile'
                component={StudentProfileScreen}
                options={({ route }) => ({
                    headerShown: !isDesktop,
                    title: '',
                    headerShadowVisible: false,
                    headerTitle: () => <HeaderTitle firstName={route.params?.firstName} photo={route.params?.photo} />,
                })}
            />
        </StudentStack.Navigator>
    );
}

export default Student;
