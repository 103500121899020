import SvgPentagon from '@Icon/Pentagon';
import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

interface IProps {
    label: string;
}

const BulletPoint: React.FC<IProps> = ({ label }) => {
    return (
        <View style={styles.container}>
            <SvgPentagon width={11} style={styles.icon} />
            <Text style={styles.label}>{label}</Text>
        </View>
    );
};

export default BulletPoint;
