import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconCalMonth = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.14.56H6.86v5.373H2.933v26.191h26.191V5.934H25.14V.56h-4.28v5.373h-9.72V.56Zm16.377 12.307H4.483v14.266h23.034V12.867Zm-6.204 3.468 2.177-1.581 2.177 1.582-.832 2.558h-2.69l-.832-2.558ZM8.5 14.857 6.327 18.62h4.345L8.5 14.857Zm7.507 4.067c.77 0 1.394-.866 1.394-1.935s-.624-1.935-1.394-1.935c-.77 0-1.394.866-1.394 1.935s.624 1.935 1.394 1.935ZM9.554 21.16H7.43L6.366 23l1.063 1.841h2.125l1.063-1.84-1.063-1.841Zm3.78 1.83L16 24.636l2.666-1.644L16 21.347l-2.667 1.644Zm10.17 1.964a1.95 1.95 0 1 0 0-3.9 1.95 1.95 0 0 0 0 3.9Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgIconCalMonth;
