import React from 'react';
import { View, Text } from 'react-native';
import {} from 'date-fns';
import { DailySeriesEventDateMap, dateRep } from '@Redux/services/CalendarApi';
import { eachDayOfInterval, Interval, isToday, format, formatISO } from 'date-fns';

import { styles } from './styles';

interface WeekdayIndicatorProps {
    currentInterval: Interval;
    dailySeriesEventMap: DailySeriesEventDateMap;
}

function WeekdayIndicator({ currentInterval, dailySeriesEventMap }: WeekdayIndicatorProps) {
    // This part was removed from spec until future. Intended for week-view of calendar.
    return null;

    return (
        <View style={styles.periodSelector}>
            {eachDayOfInterval(currentInterval).map((day) => {
                const dateIndicatorStyle = { ...styles.dateIndicator };

                if (isToday(day)) {
                    Object.assign(dateIndicatorStyle, styles.dateIndicatorCurrentDay);
                } else if (dailySeriesEventMap[formatISO(day, dateRep)]?.length) {
                    Object.assign(dateIndicatorStyle, styles.dateIndicatorHasEvents);
                }

                return (
                    <View key={formatISO(day, dateRep)}>
                        <Text style={styles.dateDayOfWeek}>{format(day, 'EEE')}</Text>
                        <View style={dateIndicatorStyle} />
                        <Text style={styles.dateDayOfMonth}>{format(day, 'dd')}</Text>
                    </View>
                );
            })}
        </View>
    );
}

export default WeekdayIndicator;
