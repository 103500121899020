import * as React from 'react';
import Svg, { SvgProps, Path, G, Mask, Defs, ClipPath } from 'react-native-svg';
const SvgNotification = (props: SvgProps) => (
    <Svg width={343} height={200} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M160.653 146.375h10.261l6.193 6.193H154.46l6.193-6.193Zm-7.256 12.386v5.131l7.256 7.255h10.261l7.255-7.255v-5.131h-24.772Z'
            fill='#FF5F4D'
        />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M177.395 48.112h-23.172v14.456c-14.289 4.837-24.579 18.346-24.607 34.263h-.062v43.351h-13.315v12.386h99.089v-12.386h-13.315V99.115c.044-.735.067-1.475.067-2.22 0-15.973-10.337-29.532-24.685-34.353v-14.43Z'
            fill='#EEAB00'
        />
        <G clipPath='url(#notification_svg__a)'>
            <Mask
                id='notification_svg__b'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={205}
                y={0}
                width={70}
                height={70}
            >
                <Path fill='#D9D9D9' d='M205.8.6h68.6v68.6h-68.6z' />
            </Mask>
            <G mask='url(#notification_svg__b)'>
                <Path
                    d='M232.24 46.333h15.72v-5.145h-7.86l7.86-9.718v-5.145h-15.72v5.145h7.86l-7.86 9.718v5.145Z'
                    fill='#D77A7F'
                />
            </G>
        </G>
        <Mask
            id='notification_svg__c'
            style={{
                maskType: 'alpha',
            }}
            maskUnits='userSpaceOnUse'
            x={200}
            y={29}
            width={46}
            height={46}
        >
            <Path fill='#D9D9D9' d='M200.083 29.183h45.733v45.733h-45.733z' />
        </Mask>
        <G mask='url(#notification_svg__c)'>
            <Path
                d='M217.71 59.672h10.48v-3.43h-5.24l5.24-6.479v-3.43h-10.48v3.43h5.24l-5.24 6.48v3.43Z'
                fill='#5DA638'
            />
        </G>
        <G clipPath='url(#notification_svg__d)'>
            <Mask
                id='notification_svg__e'
                style={{
                    maskType: 'alpha',
                }}
                maskUnits='userSpaceOnUse'
                x={200}
                y={52}
                width={23}
                height={23}
            >
                <Path fill='#D9D9D9' d='M200.083 52.05h22.867v22.867h-22.867z' />
            </Mask>
            <G mask='url(#notification_svg__e)'>
                <Path
                    d='M208.897 67.294h5.24V65.58h-2.62l2.62-3.239v-1.715h-5.24v1.715h2.62l-2.62 3.24v1.715Z'
                    fill='#A28AC7'
                />
            </G>
        </G>
        <Defs>
            <ClipPath id='notification_svg__a'>
                <Path fill='#fff' transform='translate(205.8 .6)' d='M0 0h68.6v68.6H0z' />
            </ClipPath>
            <ClipPath id='notification_svg__d'>
                <Path fill='#fff' transform='translate(200.083 52.05)' d='M0 0h22.867v22.867H0z' />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgNotification;
