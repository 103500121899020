import { View, Text, StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';
import { format } from 'date-fns';
import { signedCurrencyFormat } from '@Utilities';
import { useMemo } from 'react';

export interface TransactionProps {
    Name: string;
    Date: string;
    Amount: number;
    IsTopUp: boolean;
}

function Transaction(props: TransactionProps) {
    const amount = useMemo(() => (props.IsTopUp ? props.Amount : -props.Amount), [props]);

    return (
        <View style={styles.item}>
            <View style={styles.labelContainer}>
                <Text style={typography.h6}>{props.Name}</Text>
                <Text style={typography.body3}>{format(new Date(props.Date), 'E LLL dd')}</Text>
            </View>
            <View>
                <Text style={[styles.amount, amount > 0 && styles.amountPositive]}>{signedCurrencyFormat(amount)}</Text>
            </View>
        </View>
    );
}

export default Transaction;

const styles = StyleSheet.create({
    item: {
        flexDirection: 'row',
        alignContent: 'space-between',
        marginVertical: 10,
    },
    labelContainer: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    amount: {
        ...typography.body2,
    },
    amountPositive: {
        color: defaultTheme.colors.watermelon,
    },
});
