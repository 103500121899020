import React, { ComponentType, useMemo, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Linking, Platform, Pressable, Text, View } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
import { createURL } from 'expo-linking';

import { NavigationContainer, DrawerActions } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';

import SignInScreen from '@Screens/Auth/SignIn';
import SignUpScreen from '@Screens/Auth/SignUp';
import EmailConfirmationScreen from '@Screens/Auth/EmailConfirmation';
import PasswordScreen from '@Screens/Auth/Password';
import RegisterScreen from '@Screens/Auth/Register';
import ResetPasswordScreen from '@Screens/Auth/ResetPassword';
import ForgotScreen from '@Screens/Auth/Forgot';
import Terms from '@Screens/Auth/Terms';
import Privacy from '@Screens/Auth/Privacy';
import Calendar from '@Screens/Calendar';
import Home from '@Screens/Home';

import { RootState, store } from '@Redux';
import useTheme, { defaultTheme } from '@Hooks/useTheme';
import SvgHome from '@Icon/Home';
import IconStudent from '@Icon/IconStudent';
import Menu from '@Icon/Menu';

import { styles } from './styles';
import { navigationRef, RootStackParamList } from './rootNavigation';
import Alert from './components/Alert';
import { alert as customAlert } from '@Redux/alert';
import { LinkingOptions } from '@react-navigation/native';
import Student from '@Screens/Student';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Footer from '@Components/Footer';
import Header from '@Components/Header';
import i18n from '@I18n';
import { MfrLogo, MfrLogo2 } from '@Svg';
import Avatar from '@Components/Avatar';
import Close from '@Icon/Close';
import DeviceContext from '@Contexts/DeviceContext';
import { useGetParentQuery } from '@Redux/services/parent';
import { logout } from '@Redux/auth';

import { HELP_URL } from './constants';
import SocialLoginScreen from '@Screens/SocialLogin';
import ForgotConfirmationScreen from '@Screens/Auth/ForgotConfirmation';
import { CallbackScreen } from '@Screens/Callback';
import PaypalPayScreen from '@Screens/PaypalPay';
import EventPreview from '@Screens/EventPreview';
import MobileAppBanner from '@Components/MobileAppBanner';

interface TabIconProps {
    color: string;
    size: number;
    focused: boolean;
}

interface TabScreen {
    component: ComponentType<React.ReactNode>;
    color: string;
    tabIcon: (props: { focused: boolean; color: string; size: number }) => React.ReactNode;
    drawerIcon: (props: Partial<TabIconProps>) => React.ReactNode;
}

const tabScreens: Record<string, TabScreen> = {
    Home: {
        component: Home,
        color: defaultTheme.colors.raspberry,
        tabIcon: ({ color, focused }: TabIconProps) => !focused && <SvgHome fill={color} />,
        drawerIcon: () => <SvgHome fill={defaultTheme.colors.raspberry} />,
    },
    Calendar: {
        component: Calendar,
        color: defaultTheme.colors.watermelon,
        tabIcon: ({ color, size, focused }: TabIconProps) => {
            return !focused && <Ionicons name='calendar' size={size} color={color} />;
        },
        drawerIcon: ({ size }) => {
            return <Ionicons name='calendar' size={size} color={defaultTheme.colors.watermelon} />;
        },
    },
    Student: {
        component: Student,
        color: defaultTheme.colors.grape,
        tabIcon: ({ color, focused }: TabIconProps) => !focused && <IconStudent fill={color} />,
        drawerIcon: () => <IconStudent fill={defaultTheme.colors.grape} />,
    },
};

const Stack = createNativeStackNavigator<RootStackParamList>();
const BottomTabs = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function handleDrawer() {
    navigationRef.current?.dispatch(DrawerActions.toggleDrawer());
}

// TODO: Refactor
function CustomDrawerContent(props) {
    const { data: parent } = useGetParentQuery();
    // DrawerItemList does not allow injecting onPress
    function profilePressHandler() {
        props.navigation.navigate('Home', { screen: 'Profile' });
    }

    function handleTerms() {
        navigationRef.current?.navigate('terms');
    }

    function handlePrivacy() {
        navigationRef.current?.navigate('privacy');
    }

    function handleHelp() {
        Linking.openURL(HELP_URL);
        handleDrawer();
    }

    function handleLogout() {
        store.dispatch(logout());
    }

    const otherDrawerItems = useMemo(
        () => [
            {
                label: 'Profile',
                icon: () => (
                    <Avatar label={parent?.FirstName ? parent.FirstName : '-'} size={30} picture={parent?.Photo} />
                ),
                onPress: profilePressHandler,
            },
            {
                label: i18n.t('terms'),
                onPress: handleTerms,
            },
            {
                label: i18n.t('privacy'),
                onPress: handlePrivacy,
            },
            {
                label: i18n.t('help'),
                onPress: handleHelp,
            },
            {
                label: i18n.t('signOut'),
                onPress: handleLogout,
            },
        ],
        [parent]
    );

    return (
        <View style={{ flex: 1 }}>
            <View style={{ margin: 8 }}>
                <Pressable onPress={handleDrawer} style={{ alignSelf: 'flex-start', padding: 16 }}>
                    <Close width={16} height={16} />
                </Pressable>
            </View>
            <DrawerContentScrollView {...props}>
                {props.state.routes.map((route, i) => {
                    const focused = i === props.state.index;
                    const onPress = () => {
                        const event = props.navigation.emit({
                            type: 'drawerItemPress',
                            target: route.key,
                            canPreventDefault: true,
                        });

                        if (!event.defaultPrevented) {
                            if (i === 0) {
                                props.navigation.navigate('Home', { screen: 'Dashboard' });
                            } else if (i === 1) {
                                props.navigation.navigate('Calendar');
                            } else if (i === 2) {
                                props.navigation.navigate('Student', { screen: 'StudentList' });
                            }
                        }
                    };
                    const tabName = Object.keys(tabScreens)[i];
                    const tabOptions = Object.values(tabScreens)[i];
                    return (
                        <DrawerItem
                            key={route.key}
                            focused={focused}
                            activeTintColor={tabOptions.color}
                            label={() => <Text style={styles.drawerLabel}>{tabName}</Text>}
                            icon={tabOptions.drawerIcon}
                            pressOpacity={1}
                            onPress={onPress}
                        />
                    );
                })}
                {otherDrawerItems.map((otherDrawerItem) => (
                    <DrawerItem
                        label={() => <Text style={[styles.drawerLabel]}>{otherDrawerItem.label}</Text>}
                        icon={
                            otherDrawerItem.icon
                                ? otherDrawerItem.icon
                                : () => <View style={styles.iconPlaceHolder}></View>
                        }
                        onPress={otherDrawerItem.onPress}
                    />
                ))}
            </DrawerContentScrollView>
            <View style={{ paddingHorizontal: 24, paddingVertical: 48 }}>
                <View style={{ marginBottom: 24 }}>
                    <MfrLogo2 />
                </View>
                <View style={styles.copyright}>
                    <Text>
                        ©{new Date().getFullYear() + ' '}
                        <Text
                            onPress={() => {
                                Linking.openURL('https://www.ucdsb.on.ca');
                            }}
                            style={styles.underlined}
                        >
                            {i18n.t('ucdsb')}
                        </Text>
                        {' ' + i18n.t('copyright')}
                    </Text>
                </View>
            </View>
        </View>
    );
}

function MainNavigation() {
    const insets = useSafeAreaInsets();
    const { isDesktop } = useContext(DeviceContext);

    function handlePress() {
        navigationRef.current?.navigate('Home', { screen: 'Dashboard' });
    }
    const { data: parent } = useGetParentQuery();
    function profilePressHandler() {
        navigationRef.current?.navigate('Home', { screen: 'Profile' });
    }

    const defaultDrawerOptions = {
        headerShown: true,
        headerStyle: { height: 80 },
        headerTitle: () => (
            <Pressable onPress={handlePress}>
                <View style={{ paddingHorizontal: 14 }}>
                    <MfrLogo />
                </View>
            </Pressable>
        ),
        headerLeft: () => (
            <Pressable onPress={handleDrawer}>
                <View style={{ padding: 12, marginLeft: 17 }}>
                    <Menu />
                </View>
            </Pressable>
        ),
        headerRight: () => (
            <Pressable onPress={profilePressHandler}>
                <View style={{ padding: 12, marginRight: 35 }}>
                    <Avatar label={parent?.FirstName ? parent.FirstName : '-'} size={48} picture={parent?.Photo} />
                </View>
            </Pressable>
        ),
        headerShadowVisible: false,
    };

    return isDesktop ? (
        <Drawer.Navigator drawerContent={CustomDrawerContent}>
            {Object.keys(tabScreens).map((value) => {
                const item = tabScreens[value];
                return (
                    <Drawer.Screen
                        key={value}
                        name={value}
                        component={item['component']}
                        options={defaultDrawerOptions}
                    />
                );
            })}
        </Drawer.Navigator>
    ) : (
        <BottomTabs.Navigator
            screenOptions={() => ({
                tabBarInactiveTintColor: '#373639',
                tabBarLabelPosition: 'beside-icon',
                tabBarActiveTintColor: 'white',
                tabBarStyle: { maxWidth: '100%' },
                headerRight: ({ tintColor }) => <Ionicons name='list' color={tintColor} />,
            })}
        >
            {Object.keys(tabScreens).map((value) => {
                const item = tabScreens[value];
                return (
                    <BottomTabs.Screen
                        key={value}
                        name={value}
                        component={item['component']}
                        options={{
                            tabBarActiveBackgroundColor: item['color'],
                            tabBarStyle: {
                                borderBottomColor: item['color'],
                                borderBottomWidth: insets.bottom,
                                paddingBottom: 0,
                            },
                            headerShown: false,
                            headerTitle: '',
                            tabBarLabel: ({ focused, color }) =>
                                focused && (
                                    <Text style={[styles.tabBarLabel, { color: color, marginTop: 3 }]}>{value}</Text>
                                ),
                            tabBarIcon: item['tabIcon'],
                        }}
                    />
                );
            })}
        </BottomTabs.Navigator>
    );
}

const prefix = createURL('/');

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [prefix],
    config: {
        screens: {
            signin: 'signin',
            signup: 'signup',
            callback: 'callback',
            confirm: 'confirm',
            password: 'verify',
            resetPassword: 'resetPassword',
            sociallogin: 'sociallogin',
            paypalpay: 'paypalpay',
            register: 'register',
            forgotConfirmation: 'forgotConfirmation',
            forgot: 'forgot',
            terms: 'terms',
            privacy: 'privacy',
            Preview: 'EventOffer/Preview/:id',
            home: {
                path: '',
                screens: {
                    Home: {
                        path: 'Home',
                        screens: {
                            Dashboard: 'Dashboard',
                            Profile: 'Profile',
                            SecurityPreferences: 'SecurityPreferences',
                            ContactInformation: 'ContactInformation',
                            Balance: 'Balance',
                            terms: 'terms',
                            privacy: 'privacy',
                            ContactPreferences: 'ContactPreferences',
                            ContactPreferencesDetail: 'ContactPreferencesDetail',
                        },
                    },
                    Calendar: 'Calendar',
                    Student: {
                        path: 'Student',
                        screens: {
                            StudentList: 'StudentList',
                            StudentProfile: 'StudentProfile',
                        },
                    },
                },
            },
        },
    },
};

export default function Navigation() {
    const isSignedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
    const { showAlert, message, isError } = useSelector((state: RootState) => state.alert);
    const [navigationReady, setNavigationReady] = useState(false);
    const [currentRouteName, setCurrentRouteName] = useState('signin');

    const theme = useTheme();
    const { isDesktop } = useContext(DeviceContext);

    const handleCloseAlertPress = () => {
        store.dispatch(customAlert({ showAlert: false, message: undefined }));
    };

    return (
        <>
            {!isSignedIn && isDesktop && navigationReady && currentRouteName !== 'signin' && <Header />}
            <MobileAppBanner />
            <View style={styles.container}>
                <StatusBar translucent style='auto' />
                <NavigationContainer
                    theme={theme}
                    ref={navigationRef}
                    linking={linking}
                    onReady={() => {
                        setNavigationReady(true);
                        setCurrentRouteName(navigationRef.getCurrentRoute()?.name || '');
                    }}
                    onStateChange={() => setCurrentRouteName(navigationRef.getCurrentRoute()?.name || '')}
                    documentTitle={{
                        formatter: (options, route) => `My Family Room - ${options?.title ?? route?.name}`,
                    }}
                >
                    <Stack.Navigator>
                        {isSignedIn ? (
                            <Stack.Screen
                                name='home'
                                component={MainNavigation}
                                options={{
                                    headerShown: false,
                                }}
                            />
                        ) : (
                            <Stack.Screen name='signin' component={SignInScreen} options={{ headerShown: false }} />
                        )}
                        <Stack.Group navigationKey={isSignedIn ? 'user' : 'guest'}>
                            <Stack.Screen
                                name='signup'
                                component={SignUpScreen}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='confirm'
                                component={EmailConfirmationScreen}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='password'
                                component={PasswordScreen}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='resetPassword'
                                component={ResetPasswordScreen}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='register'
                                component={RegisterScreen}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='forgotConfirmation'
                                component={ForgotConfirmationScreen}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='forgot'
                                component={ForgotScreen}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='terms'
                                component={Terms}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            <Stack.Screen
                                name='privacy'
                                component={Privacy}
                                options={{ headerShown: !isDesktop, headerTitle: '' }}
                            />
                            {Platform.OS !== 'web' && (
                                <Stack.Screen
                                    name='sociallogin'
                                    component={SocialLoginScreen}
                                    options={{ headerTitle: '', headerShown: true }}
                                />
                            )}
                            <Stack.Screen name='callback' component={CallbackScreen} options={{ headerTitle: '' }} />
                            <Stack.Screen
                                name='paypalpay'
                                component={PaypalPayScreen}
                                options={{ headerTitle: '', headerShown: true }}
                            />
                            <Stack.Screen
                                name='Preview'
                                component={EventPreview}
                                options={{ headerTitle: '', headerShown: false }}
                            />
                        </Stack.Group>
                    </Stack.Navigator>
                    <Alert onClose={handleCloseAlertPress} isError={isError} visible={showAlert} message={message} />
                </NavigationContainer>
            </View>
            {!isSignedIn && <Footer />}
        </>
    );
}
