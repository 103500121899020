import { ComponentProps } from 'react';
import { TextInput as PaperTextInput } from 'react-native-paper';
import { defaultTheme, typography } from '@Hooks/useTheme';

export type TextInputProps = ComponentProps<typeof PaperTextInput>;

function TextInput(props: TextInputProps) {
    const outlineColor = props.error ? defaultTheme.colors.error : defaultTheme.colors.inputOutline;
    const textColor = props.error
        ? { text: defaultTheme.colors.error, placeholder: defaultTheme.colors.error }
        : { text: defaultTheme.colors.textPrimary, placeholder: defaultTheme.colors.textPrimary };

    return (
        <PaperTextInput
            activeOutlineColor={outlineColor}
            outlineColor={outlineColor}
            theme={{
                roundness: 0,
                fonts: { regular: typography.body2 },
                colors: textColor,
            }}
            {...props}
        />
    );
}

export default TextInput;
