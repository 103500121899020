import useTheme from '@Hooks/useTheme';
import SvgPentagon from '@Icon/Pentagon';
import { useCallback } from 'react';
import { AttendanceSummary } from '@Redux/types';
import { View, Text } from 'react-native';
import { DataTable } from 'react-native-paper';
import styles from './styles';

interface IProps {
    attendenceSummaries?: AttendanceSummary[];
}

const Attendance: React.FC<IProps> = ({ attendenceSummaries }) => {
    const filterAbsencedByCode = useCallback(
        (isLate: boolean, courseAttendance: AttendanceSummary) => {
            return courseAttendance.SchoolCourse.Absences.filter((absence) =>
                isLate ? absence.Code === 'L' : absence.Code !== 'L'
            );
        },
        [attendenceSummaries]
    );

    if (!attendenceSummaries) {
        return null;
    }

    return (
        <DataTable style={{ marginVertical: 24 }}>
            <DataTable.Header style={{ borderWidth: 0 }}>
                <DataTable.Title>{''}</DataTable.Title>
                <DataTable.Title textStyle={styles.header} numeric>
                    Absent
                </DataTable.Title>
                <DataTable.Title textStyle={styles.header} numeric>
                    Late
                </DataTable.Title>
            </DataTable.Header>

            {attendenceSummaries.map((attendenceSummary, index) => {
                return (
                    <DataTable.Row style={{ borderWidth: 0, minHeight: 30 }} key={`attendance-${index}`}>
                        <DataTable.Cell>
                            <View style={styles.subjectDescriptionContainer}>
                                <SvgPentagon style={styles.bullet} width={8} fill={useTheme().colors.blueberry} />
                                <Text style={styles.cellValue}>{attendenceSummary.SchoolCourse.CourseDescription}</Text>
                            </View>
                        </DataTable.Cell>
                        <DataTable.Cell textStyle={styles.cellValue} numeric>
                            {filterAbsencedByCode(false, attendenceSummary).length}
                        </DataTable.Cell>
                        <DataTable.Cell textStyle={styles.body} numeric>
                            {filterAbsencedByCode(true, attendenceSummary).length}
                        </DataTable.Cell>
                    </DataTable.Row>
                );
            })}
        </DataTable>
    );
};

export default Attendance;
