import { FC, useEffect, useState } from 'react';
import { RootStackParamList } from '@App/rootNavigation';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { View } from 'react-native';
import { ActivityIndicator, Text } from 'react-native-paper';
import { styles } from './styles';
import i18n from '@I18n';
import { usePaymentCancelMutation, usePaymentReturnMutation } from '@Redux/services/PaymentApi';

type PaypalPayScreenProp = NativeStackScreenProps<RootStackParamList, 'paypalpay'>;

const PaypalPayScreen: FC<PaypalPayScreenProp> = ({ route }) => {
    const [paymentReturn] = usePaymentReturnMutation();
    const [paymentCancel] = usePaymentCancelMutation();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
    const [isPaymentCancel, setIsPaymentCancel] = useState(false);

    async function handlePayment(customId: string, paymentId: string, PayerID: string) {
        try {
            await paymentReturn({ PaymentId: paymentId, CustomId: customId, PayerId: PayerID }).unwrap();
            setIsPaymentSuccess(true);
        } catch (e) {
            console.warn(e);
            setErrorMessage(i18n.t('paypalPaymentError'));
        }
    }

    async function handleCancel(customId: string) {
        try {
            await paymentCancel({ CustomId: customId }).unwrap();
            setIsPaymentCancel(true);
        } catch (e) {
            console.warn(e);
            setErrorMessage(i18n.t('paypalPaymentCancelError'));
        }
    }

    useEffect(() => {
        if (route.params.customId && route.params.action && route.params.action === 'cancel') {
            handleCancel(route.params.customId);
        } else if (
            route.params.customId &&
            route.params.paymentId &&
            route.params.PayerID &&
            route.params.action &&
            route.params.action === 'return'
        ) {
            handlePayment(route.params.customId, route.params.paymentId, route.params.PayerID);
        }
    }, [route]);

    return (
        <View style={styles.container}>
            {!isPaymentSuccess && !errorMessage && <ActivityIndicator />}
            {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
            {isPaymentSuccess && <Text style={styles.text}>{i18n.translate('paypalPaymentSuccess')}</Text>}
            {isPaymentCancel && <Text style={styles.text}>{i18n.translate('paypalPaymentCancel')}</Text>}
        </View>
    );
};

export default PaypalPayScreen;
