import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconEventTypeOffers = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path d='M8.95 4.5h8.1l3.15 9H5.8l3.15-9Z' fill='#D77A7F' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.787 11.293h-3v11.414h10.346v-3.574h3.742v3.574H25.213V11.293H3.787Zm4.615 5.696c0 1.069-.625 1.935-1.394 1.935-.77 0-1.395-.866-1.395-1.935 0-1.07.625-1.936 1.395-1.936s1.394.867 1.394 1.936Zm10.606 1.935c.77 0 1.394-.866 1.394-1.935 0-1.07-.625-1.936-1.395-1.936s-1.394.867-1.394 1.936.625 1.935 1.395 1.935Z'
            fill='#D77A7F'
        />
        <Path d='M20.841 3.645H15.66v3.21h5.182v-3.21Z' fill='#D77A7F' />
    </Svg>
);
export default SvgIconEventTypeOffers;
