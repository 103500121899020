import React from 'react';
import { Pressable, View, Text } from 'react-native';
import { IconChevronRight } from '@Icon';
import { defaultTheme, typography } from '@Hooks/useTheme';
import { format } from 'date-fns';
import Picker from 'react-native-picker-select';

import { styles } from '../../../styles';
import { CalendarViewSelectorProps, CalendarComponentProps } from '../../../types';

// TODO: Refactor component
function CalendarViewSelector({ selectedCalendarView, setSelectedCalendarView }: CalendarViewSelectorProps) {
    return (
        <View style={{ paddingHorizontal: 32, paddingVertical: 20, alignSelf: 'flex-end' }}>
            <Picker
                useNativeAndroidPickerStyle={false}
                style={{
                    viewContainer: { borderColor: defaultTheme.colors.inputOutline, borderWidth: 1, width: 300 },
                    inputWeb: { ...typography.body2, padding: 10 },
                }}
                value={selectedCalendarView}
                onValueChange={(val) => setSelectedCalendarView(val)}
                items={[
                    { label: 'Agenda', value: 'agenda' },
                    { label: 'Month', value: 'month' },
                ]}
                placeholder={{}}
            />
        </View>
    );
}

function DesktopCalendarHeader({
    currentIntervalMonth,
    warpToPrevPeriod,
    warpToNextPeriod,
    selectedCalendarView,
    setSelectedCalendarView,
}: CalendarComponentProps) {
    return (
        <View
            style={{
                flexDirection: 'row',
                maxWidth: 1280,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', minWidth: 256 }}>
                    <Pressable onPress={warpToPrevPeriod} style={styles.desktopPeriodNavigator}>
                        <View style={{ transform: [{ scaleX: -1 }] }}>
                            <IconChevronRight />
                        </View>
                    </Pressable>
                    <View style={{ minWidth: 220 }}>
                        <Text style={{ ...typography.h2, textAlign: 'center' }}>
                            {format(currentIntervalMonth, 'LLLL')}
                        </Text>
                    </View>
                    <Pressable onPress={warpToNextPeriod} style={styles.desktopPeriodNavigator}>
                        <View>
                            <IconChevronRight />
                        </View>
                    </Pressable>
                </View>
            </View>
            <CalendarViewSelector
                selectedCalendarView={selectedCalendarView}
                setSelectedCalendarView={setSelectedCalendarView}
            />
        </View>
    );
}

export default DesktopCalendarHeader;
