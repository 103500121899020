import { FlatList, ListRenderItem } from 'react-native';
import Transaction, { TransactionProps } from './Transaction';

function TransactionsList({ transactions }: { transactions: TransactionProps[] }) {
    const renderTransationItem: ListRenderItem<TransactionProps> = ({ item }) => {
        return <Transaction {...item} />;
    };

    return (
        <FlatList
            data={transactions}
            renderItem={renderTransationItem}
            scrollEnabled={true}
            showsVerticalScrollIndicator={false}
            keyExtractor={(_, i) => `trx-${i}`}
        />
    );
}

export default TransactionsList;
