import React, { useMemo } from 'react';
import { View, Text, ViewStyle } from 'react-native';
import useTheme, { typography } from '@Hooks/useTheme';
import { Address } from '@Redux/types';
import { compact } from 'lodash';
import SvgBrush from '@Icon/Brush';
import { IconButton } from 'react-native-paper';
import styles from './styles';

function ContactFullAddress(props: Partial<Address> & { style: ViewStyle; onPress: () => void }) {
    const theme = useTheme();

    const addressLine1 = useMemo(
        () => compact([props.HomeAddress, props.ApartmentNumber, props.RuralRoute]).join(' '),
        [props]
    );

    const addressLine2 = useMemo(() => compact([props.City, props.Province, props.PostalCode]).join(' '), [props]);

    return (
        <View style={[props.style]}>
            <View style={styles.addressConatiner}>
                <View style={styles.address}>
                    <Text style={[typography.h5]}>{addressLine1}</Text>
                    <View style={styles.labelContainer}>
                        <Text style={typography.body}>{addressLine2}</Text>
                    </View>
                </View>
                <IconButton
                    onPress={props.onPress}
                    icon={() => <SvgBrush width={20} fill={theme.colors.watermelon} />}
                />
            </View>
        </View>
    );
}

export default ContactFullAddress;
