import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgManageAccounts = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.12 3.467h6.377l3.188 5.521-3.188 5.522H10.12L6.933 8.988l3.188-5.521Zm-6 13.493h10.75a10.03 10.03 0 0 0-1.328 5.005c0 2.434.864 4.667 2.301 6.409H4.12V16.96ZM21.4 26.967l.433 2.166h3.5l.434-2.166c.178-.067.366-.156.566-.267.2-.111.39-.222.567-.333l2.067.666 1.766-3-1.7-1.433c.045-.178.073-.383.084-.616.011-.234-.017-.44-.084-.617l1.7-1.467-1.766-3.033-2.067.733a3.272 3.272 0 0 0-.567-.4c-.2-.111-.388-.2-.566-.267l-.434-2.166h-3.5l-.433 2.166c-.178.067-.36.156-.55.267-.189.111-.372.222-.55.333l-2.1-.666-1.733 2.966 1.666 1.467c-.044.2-.072.416-.082.65-.012.233.016.45.082.65l-1.666 1.433 1.733 3 2.1-.666c.178.11.361.222.55.333.19.111.372.2.55.267Zm3.883-3.334c-.478.49-1.039.734-1.683.734-.644 0-1.211-.245-1.7-.734-.489-.488-.733-1.055-.733-1.7 0-.644.244-1.205.733-1.682.489-.479 1.056-.718 1.7-.718.644 0 1.205.24 1.683.718.478.477.717 1.038.717 1.682 0 .645-.24 1.212-.717 1.7Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgManageAccounts;
