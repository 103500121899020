import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBuilding3 = (props: SvgProps) => (
    <Svg width={99} height={100} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path d='m49.5 55.613-32-19.728 32-19.725 32 19.725-32 19.728Z' fill='#72BEFB' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.647 45.173h-12v45.654H40.86V70.24h17.122v20.587H98.353V45.173H12.647Zm3.64 17.12h18.426v11.414H16.287V62.294Zm66.426 0H64.287v11.414h18.426V62.294Z'
            fill='#EEAB00'
        />
        <Path d='M76.921 10.68H64.08v20.728h12.841V10.68Z' fill='#A28AC7' />
    </Svg>
);
export default SvgBuilding3;
