import { useGetStudentAttendanceQuery } from '@Redux/services/student';
import React from 'react';
import { ScrollView } from 'react-native';

import { ActivityIndicator } from 'react-native-paper';
import CourseAttendance from './components/CourseAttendance';

interface IProps {
    studentId: string;
}

const Attendance: React.FC<IProps> = ({ studentId }) => {
    const { data: attendanceSummary } = useGetStudentAttendanceQuery({ studentId });
    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            {!attendanceSummary && <ActivityIndicator />}
            {attendanceSummary?.map((courseAttendance, i) => {
                return <CourseAttendance key={`attendance-summary-${i}`} courseAttendance={courseAttendance} />;
            })}
        </ScrollView>
    );
};

export default Attendance;
