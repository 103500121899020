import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconMoney = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 34 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M33.153 4.773H5.513v17.12h27.64V4.773Zm-13.8 13.692c2.053 0 3.718-2.311 3.718-5.162 0-2.85-1.665-5.16-3.718-5.16s-3.718 2.31-3.718 5.16 1.665 5.162 3.718 5.162ZM2.847 24.56V10.107H.18v17.12h27.64V24.56H2.847Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgIconMoney;
