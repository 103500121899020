import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgSelect = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M.867 29.133v-4.2h4.2v4.2h-4.2Zm0-6.867V19.2h4.2v3.066h-4.2Zm0-5.733v-3.067h4.2v3.067h-4.2Zm0-5.734V7.734h4.2V10.8h-4.2Zm0-5.733v-4.2h4.2v4.2h-4.2Zm6.866 24.067v-4.2H10.8v4.2H7.733Zm0-24.067v-4.2H10.8v4.2H7.733Zm5.734 24.067v-4.2h3.066v4.2h-3.066Zm0-24.067v-4.2h3.066v4.2h-3.066ZM19.2 29.133v-4.2h3.067v4.2H19.2Zm0-24.067v-4.2h3.067v4.2H19.2Zm5.733 24.067v-4.2h4.2v4.2h-4.2Zm0-6.867V19.2h4.2v3.066h-4.2Zm0-5.733v-3.067h4.2v3.067h-4.2Zm0-5.734V7.734h4.2V10.8h-4.2Zm0-5.733v-4.2h4.2v4.2h-4.2Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgSelect;
