import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgArrowRight = (props: SvgProps) => (
    <Svg width={24} height={24} fill='#5DA638' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.776 7.664 23.751 14l-10.975 6.337v-5.17h-8.11v-2.334h8.11v-5.17Z'
        />
    </Svg>
);
export default SvgArrowRight;
