import { compact } from 'lodash';
import { URLSearchParams } from 'react-native-url-polyfill';
import i18n from '@I18n';
import { BasicLookup } from '@Redux/services/LookupApi';
import { ContactAddress, Coordinate } from '@Redux/types';
import axios from 'axios';
import { Buffer } from 'buffer';
import { BASEURL } from '@Redux/services/base';

export const getGreetingByTimeofDay = (): string => {
    const hour = new Date().getHours();
    if (hour < 12) {
        return i18n.t('greetingsMorning');
    } else if (hour >= 12 && hour <= 17) {
        return i18n.t('greetingsAfternoon');
    }

    return i18n.t('greetingsEvening');
};

export const getInitials = (name: string) => {
    return name && name.length > 0 ? name[0] : '-';
};

export const stringToColor = (value = '') => {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
        hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        colour += ('00' + ((hash >> (i * 8)) & 0xff).toString(16)).substr(-2);
    }
    return colour;
};

export const createLookupMap = function <Type extends BasicLookup>(lookupApiResponse?: Type[]) {
    const map = {} as Record<number, Pick<Type, Exclude<keyof Type, 'Key'>>>;
    lookupApiResponse?.forEach(({ Key, ...rest }: Type) => {
        map[Key] = rest;
    });
    return map;
};

export const lookupToOptions = function <Type extends BasicLookup>(lookupApiResponse?: Type[]) {
    return (lookupApiResponse || []).map(({ Key, Value }) => ({ value: `${Key}`, label: `${Value}` }));
};

type AccessorProperty = string | number;
export type Accessor = AccessorProperty[];
export const getMutableForAccessor = (mutable: Record<string | number, any>, accessorPath: Accessor) => {
    let finalMutable = mutable;
    accessorPath.slice(0, -1).forEach((segment) => {
        finalMutable[segment] ??= {};
        finalMutable = finalMutable[segment];
    });
    return finalMutable;
};

export function formatContactAddress(transportationDetails: ContactAddress) {
    const lines = [
        transportationDetails.ContactName,
        compact([transportationDetails.AptNumber, transportationDetails.Address]).join('-'),
        compact([transportationDetails.RuralRouteNumber, transportationDetails.PoBox]).join(' '),
        compact([transportationDetails.City, transportationDetails.Province, transportationDetails.PostalCode]).join(
            ' '
        ),
    ];
    return compact(lines).join('\n');
}

export function currencyFormat(amount: number) {
    return amount.toLocaleString('en-CA', {
        style: 'currency',
        currency: 'CAD',
    });
}

export function signedCurrencyFormat(amount: number) {
    return amount.toLocaleString('en-CA', {
        style: 'currency',
        currency: 'CAD',
        signDisplay: 'always',
    });
}

export function phoneMask(val: string) {
    const sanitizedVal = val.replace(/\D/g, '').replace(/^0+/, '').slice(0, 10);
    if (sanitizedVal.length < 4) {
        return sanitizedVal;
    } else if (sanitizedVal.length < 7) {
        return sanitizedVal.replace(/(\d{3})(\d)/, '$1-$2');
    } else {
        return sanitizedVal.replace(/(\d{3})(\d{3})(\d)/, '$1-$2-$3');
    }
}

export function postalMask(val: string) {
    const sanitizedVal = val
        .toUpperCase()
        .replace(/[^A-Z0-9]+/g, '')
        .substring(0, Math.min(val?.length, 6));

    const invalidIdx = [...sanitizedVal].findIndex(
        (character, idx) => !(idx % 2 === 0 ? Boolean(character.match(/[A-Z]/i)) : Boolean(character.match(/[0-9]/i)))
    );

    const partialPostal = invalidIdx === -1 ? sanitizedVal : sanitizedVal.substring(0, invalidIdx);
    return (partialPostal.substring(0, 3) + ' ' + partialPostal.substring(3)).trim();
}

// TODO: Refactor
export type PlaceSuggestion = {
    description: string;
    place_id: string;
    types: string[];
};

export type PlaceDetail = {
    long_name: string;
    short_name: string;
    types: string[];
};

export type GeometryDetail = {
    location: Coordinate;
};

// https://github.com/FaridSafi/react-native-google-places-autocomplete/issues/848
export const getGoogleMapsPlaceByAutocomplete = async (
    place: string,
    sessionToken: string
): Promise<{
    data?: {
        predictions: PlaceSuggestion[];
    };
    error?: string;
}> => {
    try {
        const response = await axios.post(
            `${BASEURL}/api/LookupApi/PlaceAutocomplete?input=${encodeURIComponent(place)}&sessionToken=${sessionToken}`
        );
        if (response.status !== 200) {
            return { error: 'status: ' + response.status.toString() };
        }
        if (response.data.status === 'ZERO_RESULTS') {
            return { data: { predictions: [] } };
        } else if (response.data.status === 'OK') {
            return { data: response.data };
        }
        return { error: response.status.toString() };
    } catch (e) {
        return { error: String(e) };
    }
};

export const getGoogleMapsPlaceDetail = async (
    placeId: string,
    sessionToken: string
): Promise<{
    data?: {
        result: {
            address_components: PlaceDetail[];
            geometry?: GeometryDetail;
        };
    };
    error?: string;
}> => {
    try {
        const params = new URLSearchParams({
            place_id: placeId,
            sessionToken: sessionToken,
        });
        const response = await axios.post(`${BASEURL}/api/LookupApi/PlaceDetail?${params.toString()}`);

        if (response.status !== 200) {
            return { error: 'status: ' + response.status.toString() };
        }
        if (response.data.status === 'INVALID_REQUEST') {
            return { data: { result: { address_components: [] } } };
        } else if (response.data.status === 'OK') {
            return { data: response.data };
        }
        return { error: response.status.toString() };
    } catch (e) {
        return { error: String(e) };
    }
};

export const getSchoolStartDate = () => {
    const today = new Date();
    let year = today.getFullYear();
    if (today.getMonth() + 1 < 9) {
        year -= 1;
    }
    return `${year}-09-01`;
};

export const getSchoolEndDate = () => {
    const today = new Date();
    let year = today.getFullYear();
    if (today.getMonth() + 1 >= 9) {
        year += 1;
    }
    return `${year}-10-31`;
};

export const decodeToken = (token: string): Record<string, string> => {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
};

export const charsum = (chars: string): number => [...chars].reduce((memo, char) => memo + char.charCodeAt(0), 0);

export async function wait(time = 1) {
    await new Promise((resolve) => setTimeout(resolve, time));
}
