import React from 'react';
import { View, Text } from 'react-native';
import { IconButton } from 'react-native-paper';
import { Close } from '@Icon';

import styles from './styles';
import useTheme from '@Hooks/useTheme';

interface IProps {
    header: string | React.ReactNode;
    onDismiss: () => void;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    onRightIconPress?: () => void;
}

const ModalHeader: React.FC<IProps> = ({ header, leftIcon, rightIcon, onDismiss, onRightIconPress }) => {
    const theme = useTheme();

    return (
        <View style={styles.container}>
            <IconButton
                size={24}
                style={{ zIndex: 1 }}
                icon={() => (leftIcon ? leftIcon : <Close style={{ zIndex: -1 }} fill={theme.colors.lychee} />)}
                onPress={onDismiss}
            />
            {typeof header === 'string' ? (
                <View style={styles.headerContainer}>
                    <Text style={styles.header}>{header}</Text>
                </View>
            ) : (
                header
            )}
            {rightIcon ? (
                <IconButton size={24} icon={() => rightIcon} onPress={onRightIconPress} />
            ) : (
                <View style={{ width: 46, height: 46 }} />
            )}
        </View>
    );
};

export default ModalHeader;
