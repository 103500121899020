import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconBus = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='#EEAB00' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.776 1.861H4.226v23.55h.98l1.43 4.905h3.762l1.43-4.906h8.346l1.43 4.906h3.762l1.43-4.906h.98V1.861ZM7.45 9.394h17.1v4.73h-17.1v-4.73Zm3.03 8.83h2.041l1.02 1.767-1.02 1.768h-2.04l-1.02-1.768 1.02-1.767Zm8.999 0h2.04l1.02 1.767-1.02 1.768h-2.04l-1.02-1.768 1.02-1.767Z'
        />
    </Svg>
);
export default SvgIconBus;
