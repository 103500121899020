import { Pressable, View } from 'react-native';
import { styles } from './styles';
import { MfrLogo } from '@Svg';
import { navigationRef } from '@App/rootNavigation';
import DeviceContext from '@Contexts/DeviceContext';
import { useContext } from 'react';

function Header() {
    const { isDesktop } = useContext(DeviceContext);

    function handlePress() {
        navigationRef.current?.navigate('signin');
    }

    return (
        <View style={[styles.logoContainer, isDesktop && styles.logoContainerDesktop]}>
            <Pressable onPress={handlePress} style={{ alignSelf: 'flex-start' }}>
                <MfrLogo />
            </Pressable>
        </View>
    );
}

export default Header;
