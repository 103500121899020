import { View, Text } from 'react-native';
import { styles } from '../styles';
import i18n from '@I18n/index';
import ListLink, { ListLinkProps } from '@Components/ListLink';
import { IconBus, IconSchoolBoard, IconWarning, IconCalMonth, NeutralFace, IconRegistration } from '@Icon';
import { useStudentListEmpty } from '@Hooks/useStudentListEmpty';
import StudentListEmpty from '@Components/StudentListEmpty';
import { ActivityIndicator } from 'react-native-paper';

const largeLinks: ListLinkProps[] = [
    {
        icon: <IconWarning />,
        destination: 'ContactPreferencesDetail',
        text: i18n.t('urgentNotifications'),
        args: { preference: 'urgentNotifications' },
    },
    {
        icon: <NeutralFace />,
        destination: 'ContactPreferencesDetail',
        text: i18n.t('absences'),
        args: { preference: 'absences' },
    },
    {
        icon: <IconBus />,
        destination: 'ContactPreferencesDetail',
        text: i18n.t('busCancellations'),
        args: { preference: 'busCancellations' },
    },
    {
        icon: <IconSchoolBoard />,
        destination: 'ContactPreferencesDetail',
        text: i18n.t('schoolAndBoardNews'),
        args: { preference: 'schoolAndBoardNews' },
    },
    {
        icon: <IconRegistration />,
        destination: 'ContactPreferencesDetail',
        text: i18n.t('studentRegistrations'),
        args: { preference: 'studentRegistrations' },
    },
    {
        icon: <IconCalMonth />,
        destination: 'ContactPreferencesDetail',
        text: i18n.t('eventNotifications'),
        args: { preference: 'eventNotifications' },
    },
];

function ContactPreferences() {
    const { hasStudents, isLoadingRegistrations, isLoadingStudents } = useStudentListEmpty();

    return (
        <>
            {isLoadingRegistrations || isLoadingStudents ? (
                <ActivityIndicator />
            ) : (
                <>
                    {hasStudents ? (
                        <View style={styles.screen}>
                            <Text>{i18n.t('contactPreferencesDescriptionMobile')}</Text>
                            {largeLinks.map((link, key) => (
                                <View key={key}>
                                    <ListLink
                                        icon={link.icon}
                                        destination={link.destination}
                                        text={link.text}
                                        subtext={link.subtext}
                                        args={link.args}
                                    />
                                </View>
                            ))}
                        </View>
                    ) : (
                        <StudentListEmpty />
                    )}
                </>
            )}
        </>
    );
}

export default ContactPreferences;
