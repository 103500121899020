import { baseApi } from '../base.ts';
import {
    PaymentConfiguration,
    PaymentDetail,
    PaymentRequest,
    PaymentCancelRequest,
    PaymentReturnRequest,
    RecentTransaction,
    Page,
} from './types';

const PaymentApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentConfiguration: builder.query<PaymentConfiguration, void>({
            query: () => ({
                url: 'PaymentApi/GetConfiguration',
                method: 'GET',
            }),
        }),
        getRecentTransactions: builder.query<RecentTransaction[], Page>({
            query: (data) => ({
                url: `PaymentApi/GetRecentTransactions?page=${data.page}&pageSize=${data.pageSize}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
        }),
        makeDeposit: builder.mutation<PaymentDetail, PaymentRequest>({
            query: (data) => ({
                url: 'PaymentApi/MakeDeposit',
                method: 'POST',
                data,
            }),
        }),
        paymentReturn: builder.mutation<any, PaymentReturnRequest>({
            query: (data) => ({
                url: 'PaymentApi/Return',
                method: 'POST',
                data,
            }),
        }),
        paymentCancel: builder.mutation<any, PaymentCancelRequest>({
            query: (data) => ({
                url: 'PaymentApi/Cancel',
                method: 'POST',
                data,
            }),
        }),
    }),
});

export { PaymentConfiguration, PaymentDetail, PaymentRequest, RecentTransaction };
export const {
    useGetPaymentConfigurationQuery,
    useGetRecentTransactionsQuery,
    useMakeDepositMutation,
    usePaymentReturnMutation,
    usePaymentCancelMutation,
} = PaymentApi;
