import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconStudent = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 16 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            clipRule='evenodd'
            d='m1.458 3.976 3.91 2.41L3.858 9l2.125 3.68h4.25l2.126-3.68-1.501-2.6 3.934-2.425-6.667-4.11-6.667 4.11ZM.64 13.863h14.972v9.273H.639v-9.273Z'
        />
    </Svg>
);
export default SvgIconStudent;
