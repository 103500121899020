import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Parent = {
    firstName?: string;
    lastName?: string;
    avatar?: string;
};

const initialState: Parent = { firstName: undefined, lastName: undefined, avatar: undefined };

export const parentSlice = createSlice({
    name: 'parent',
    initialState,
    reducers: {
        setParent: (state, action: PayloadAction<Parent>) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.avatar = action.payload.avatar;
        },
    },
});

export const { setParent } = parentSlice.actions;

export default parentSlice.reducer;
