import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgContactSupport = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 27 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='m12.467 31.267-.067-4.966-.433-.034c-3.29-.378-6.028-1.783-8.217-4.216C1.56 19.616.467 16.744.467 13.433c0-3.578 1.25-6.606 3.75-9.084C6.717 1.873 9.755.634 13.333.634c1.778 0 3.445.339 5 1.016 1.556.678 2.917 1.6 4.084 2.767A13.137 13.137 0 0 1 25.183 8.5c.678 1.556 1.017 3.223 1.017 5 0 2-.367 3.884-1.1 5.65a18.872 18.872 0 0 1-2.983 4.9 23.866 23.866 0 0 1-4.384 4.05 37.174 37.174 0 0 1-5.266 3.167Zm.866-10.133c.511 0 .94-.172 1.284-.517.344-.344.516-.772.516-1.283 0-.511-.172-.94-.516-1.284-.345-.344-.773-.516-1.284-.516-.51 0-.939.172-1.283.517-.345.344-.517.772-.517 1.283 0 .51.172.939.517 1.283.344.345.772.517 1.283.517Zm-1.4-4.534h2.8c0-.688.084-1.2.25-1.533.167-.333.54-.789 1.117-1.367a7.04 7.04 0 0 0 1.083-1.366c.278-.467.417-1.011.417-1.633 0-1.267-.395-2.223-1.183-2.867-.79-.645-1.795-.967-3.017-.967-1.067 0-1.995.339-2.783 1.017a5.377 5.377 0 0 0-1.65 2.45l2.633 1.033c.133-.4.339-.8.617-1.2.277-.4.65-.6 1.116-.6.467 0 .817.122 1.05.367.234.244.35.522.35.833 0 .289-.116.578-.35.867-.233.289-.45.533-.65.733-.8.711-1.3 1.306-1.5 1.783-.2.478-.3 1.295-.3 2.45Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgContactSupport;
