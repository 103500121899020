import { AcknowledgeAbsenceForm, Activity, MarkAsReadRequestParams, StudentActivities } from '../types';
import { baseApi } from './base.ts';

const notificationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        markAsRead: builder.mutation<void, MarkAsReadRequestParams>({
            query: (data) => ({
                url: `NotificationApi/GuardianActivityMarkAsRead/${data.UUID}`,
                method: 'POST',
            }),
        }),
        getAllStudentActivities: builder.query<StudentActivities, void>({
            query: () => ({
                url: 'NotificationApi/GetAllStudentActivities',
                method: 'GET',
            }),
        }),
        acknowledgeAbsence: builder.mutation<void, AcknowledgeAbsenceForm>({
            query: (data) => ({
                url: `NotificationApi/AcknowledgeAbsence`,
                method: 'POST',
                data,
            }),
        }),
        respondToAbsenceAsNotExcused: builder.mutation<void, Activity>({
            query: (data) => ({
                url: `NotificationApi/RespondToAbsenceAsNotExcused/${data.activityId}`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useMarkAsReadMutation,
    useAcknowledgeAbsenceMutation,
    useRespondToAbsenceAsNotExcusedMutation,
    useGetAllStudentActivitiesQuery,
} = notificationApi;
