import { useContext, useState } from 'react';
import Button from '@Components/Button';
import IconList from '@Components/IconList';
import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';
import useTheme from '@Hooks/useTheme';
import useToggle from '@Hooks/useToggle';
import i18n from '@I18n';
import SvgArrowRight from '@Icon/ArrowRight';
import SvgBrush from '@Icon/Brush';
import SvgBubble from '@Icon/Bubble';
import SvgComments from '@Icon/Comments';
import SvgNeutralFace from '@Icon/NeutralFace';
import { useDeletePlannedAbsenceMutation } from '@Redux/services/absence';
import { Absence } from '@Redux/types';
import AbsenceForm from '@Screens/Dashboard/Absence/Planned';
import { differenceInCalendarDays, format } from 'date-fns';
import { Text, View } from 'react-native';
import styles from './styles';
import { ActivityIndicator } from 'react-native-paper';
import DeviceContext from '@Contexts/DeviceContext';
import ErrorAlert from '@Components/ErrorAlert';
interface IProps {
    absence: Absence;
    parseDateTime: (date: string) => Date;
    showModal: boolean;
    onDismiss: () => void;
    onEdit: () => void;
    isEditable: boolean;
}

const AbsenceModal = ({ absence, showModal, isEditable, onDismiss, onEdit, parseDateTime }: IProps) => {
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const { show, toggle } = useToggle(false);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const { isDesktop } = useContext(DeviceContext);
    const start = parseDateTime(absence.Start);
    const end = parseDateTime(absence.End);
    const isSingleDay = format(start, 'yyyyMMdd') === format(end, 'yyyyMMdd');

    const [deletePlannedAbsence] = useDeletePlannedAbsenceMutation();
    const handleDeletePress = async () => {
        setLoading(true);
        try {
            await deletePlannedAbsence({ absenceId: absence.Id }).unwrap();
            onEdit();
            onDismiss();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackDeleteAbsenceErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            visible={showModal}
            style={isDesktop ? styles.desktopModal : { height: '100%' }}
            header={<ModalHeader onDismiss={onDismiss} header={i18n.t('absence')} />}
        >
            <View style={styles.container}>
                <View style={styles.spacing}>
                    <IconList icon={<SvgNeutralFace />} header={i18n.t('plannedAbsenceHeader')}>
                        <Text style={styles.body2}>
                            {isSingleDay
                                ? format(start, 'EEE MMM dd')
                                : `${format(start, 'EEE MMM dd')} - ${format(end, 'EEE MMM dd')}`}
                        </Text>
                        <View style={styles.timeAlign}>
                            {isSingleDay ? (
                                <>
                                    <Text style={styles.body2}>{format(start, 'hh:mm a')}</Text>
                                    <SvgArrowRight fill={theme.colors.textPrimary} />
                                    <Text style={styles.body2}>{format(end, 'hh:mm a')}</Text>
                                </>
                            ) : (
                                <Text style={styles.body2}>{`${i18n.t('allDay')} (${
                                    differenceInCalendarDays(end, start) + 1
                                }d)`}</Text>
                            )}
                        </View>
                    </IconList>
                </View>
                <View style={styles.spacing}>
                    <IconList icon={<SvgBubble />} header={i18n.t('reason')}>
                        <Text style={styles.body2}>{absence.Reason}</Text>
                    </IconList>
                </View>
                <View style={styles.spacing}>
                    <IconList icon={<SvgComments />} header={i18n.t('comments')}>
                        <Text style={styles.body2}>{absence.Note ? absence.Note : '-'}</Text>
                    </IconList>
                </View>
                {isEditable && (
                    <View style={styles.actionsContaner}>
                        <Button
                            label={i18n.t('edit')}
                            leftIcon={<SvgBrush fill={theme.colors.watermelon} />}
                            underlineColor={theme.colors.watermelon}
                            style={styles.editButton}
                            onPress={toggle}
                        />

                        <Button
                            label={i18n.t('delete')}
                            labelStyle={styles.buttonSmall}
                            leftIcon={loading && <ActivityIndicator size={16} />}
                            underlineColor={theme.colors.lychee}
                            onPress={handleDeletePress}
                        />
                    </View>
                )}
            </View>
            {show && (
                <AbsenceForm
                    style={isDesktop ? [styles.desktopModal, { width: 400 }] : undefined}
                    show={show}
                    onDismiss={() => {
                        toggle();
                        onEdit();
                    }}
                    isReportingLate={false}
                    absenceId={absence.Id}
                />
            )}
            <ErrorAlert message={message} visible={visible} setVisible={setVisible} />
        </Modal>
    );
};

export default AbsenceModal;
