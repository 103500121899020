import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';
const SvgIconSchool = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <G clipPath='url(#icon-school_svg__a)'>
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='m7.111 5.301 5.213 3.214-2.013 3.487 2.833 4.908h5.668l2.833-4.908-2.001-3.467L24.889 5.3 16-.178l-8.889 5.48ZM6.02 18.485H25.98v12.364H6.02V18.485Z'
                fill='#A28AC7'
            />
        </G>
        <Defs>
            <ClipPath id='icon-school_svg__a'>
                <Path fill='#fff' d='M0 0h32v32H0z' />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgIconSchool;
