import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import Constants from 'expo-constants';

import Modal from 'react-native-modal';
import { useRightEdgeSwipe } from '@Utilities/useSwipe';

import { OfferStatusFilter, EventTypesFilter, StudentsFilter } from '@Redux/services/CalendarApi';
import { Filters } from '../../../components';

interface FiltersModalProps {
    eventTypesFilter: EventTypesFilter;
    filtersVisible: boolean;
    offerStatusFilter: OfferStatusFilter;
    setEventTypesFilterValue: (v: EventTypesFilter) => void;
    setFiltersVisible: (v: boolean) => void;
    setOfferStatusFilterValue: (v: OfferStatusFilter) => void;
    setStudentsFilterValue: (v: StudentsFilter) => void;
    studentsFilter: StudentsFilter;
}

import { styles } from './styles';
function FiltersModal({
    eventTypesFilter,
    filtersVisible,
    offerStatusFilter,
    setEventTypesFilterValue,
    setFiltersVisible,
    setOfferStatusFilterValue,
    setStudentsFilterValue,
    studentsFilter,
}: FiltersModalProps) {
    const [nextEventTypesFilter, setNextEventTypesFilter] = useState<EventTypesFilter>(eventTypesFilter);
    const [nextStudentsFilter, setNextStudentsFilter] = useState<StudentsFilter>(studentsFilter);
    const [nextOfferStatusFilter, setNextOfferStatusFilterValue] = useState<OfferStatusFilter>(offerStatusFilter);

    useEffect(() => {
        setNextStudentsFilter(studentsFilter);
        setNextEventTypesFilter(eventTypesFilter);
    }, [studentsFilter, eventTypesFilter, filtersVisible]);

    const handleCloseModal = useCallback(() => {
        if (nextEventTypesFilter !== eventTypesFilter) {
            setEventTypesFilterValue(nextEventTypesFilter);
        }
        if (nextStudentsFilter !== studentsFilter) {
            setStudentsFilterValue(nextStudentsFilter);
        }

        if (nextOfferStatusFilter !== offerStatusFilter) {
            setOfferStatusFilterValue(nextOfferStatusFilter);
        }

        setFiltersVisible(false);
    }, [
        eventTypesFilter,
        nextEventTypesFilter,
        nextOfferStatusFilter,
        nextStudentsFilter,
        offerStatusFilter,
        setFiltersVisible,
        studentsFilter,
    ]);

    const { handleTouchStart: handleModalTouchStart, handleTouchEnd: handleModalTouchEnd } = useRightEdgeSwipe(
        handleCloseModal,
        1 / 9
    );
    return (
        <Modal
            isVisible={filtersVisible}
            animationIn='slideInLeft'
            style={styles.filtersModal}
            onSwipeComplete={handleCloseModal}
            animationOut='slideOutLeft'
            onBackdropPress={handleCloseModal}
        >
            <View
                onTouchStart={handleModalTouchStart}
                onTouchEnd={handleModalTouchEnd}
                style={[styles.filtersModalInner, { paddingTop: Constants.statusBarHeight }]}
            >
                <Filters
                    eventTypesFilter={nextEventTypesFilter}
                    offerStatusFilter={nextOfferStatusFilter}
                    setEventTypesFilterValue={setNextEventTypesFilter}
                    setOfferStatusFilterValue={setNextOfferStatusFilterValue}
                    setStudentsFilterValue={setNextStudentsFilter}
                    studentsFilter={nextStudentsFilter}
                />
            </View>
        </Modal>
    );
}

export default FiltersModal;
