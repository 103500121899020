import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    container: {
        flexGrow: 1,
        margin: 24,
        paddingBottom: 48,
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
});
