import { useState } from 'react';
import { View, Text, Pressable } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import i18n from '@I18n';
import { styles } from './styles';
import { Lock } from '@Icon';
import TextInput from '@Components/TextInput';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import { useRequestPasswordResetMutation } from '@Redux/services/account';
import { withLayoutSm } from '@Screens/common';

type ForgotScreenProp = NativeStackScreenProps<RootStackParamList, 'forgot'>;

function ForgotScreen({ navigation }: ForgotScreenProp) {
    const [email, setEmail] = useState('');
    const [forgotPassword] = useRequestPasswordResetMutation();
    const [isEmailError, setIsEmailError] = useState(false);

    async function handleForgotLogin() {
        const isMatch = email.match(/^\S+@\S+\.\S+$/);
        setIsEmailError(!isMatch);
        if (!email || !isMatch) {
            return;
        }
        try {
            await forgotPassword({ email }).unwrap();
        } catch (e) {
            // Do not show error for this flow and silently pass
            console.log(e);
        } finally {
            navigation.replace('forgotConfirmation');
        }
    }

    return (
        <ScrollView keyboardShouldPersistTaps='handled'>
            {withLayoutSm(
                <View style={styles.container}>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('forgot1')}>
                            {i18n.t('forgot1')}
                        </Text>
                        <Text style={styles.subtitle} accessibilityLabel={i18n.t('forgot2')}>
                            {i18n.t('forgot2')}
                        </Text>
                    </View>
                    <View style={styles.fieldContainer}>
                        <TextInput
                            label={i18n.t('email')}
                            accessibilityLabel={i18n.t('email')}
                            keyboardType='email-address'
                            mode='outlined'
                            value={email}
                            error={isEmailError}
                            onChangeText={(val) => setEmail(val)}
                        />
                        {isEmailError && <Text style={styles.error}>{i18n.t('invalidEmail')}</Text>}
                    </View>
                    <View style={styles.controlContainer}>
                        <Pressable onPress={handleForgotLogin}>
                            <View style={styles.buttonContainer}>
                                <View style={styles.forgotTextContainer}>
                                    <Text style={styles.forgotText} accessibilityLabel={i18n.t('send')}>
                                        {i18n.t('send')}
                                    </Text>
                                </View>
                                <Lock style={styles.lock} />
                            </View>
                        </Pressable>
                    </View>
                </View>
            )}
        </ScrollView>
    );
}

export default ForgotScreen;
