import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgAppleLogo = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M21.913 8.182c-.14.108-2.597 1.492-2.597 4.572 0 3.561 3.127 4.821 3.22 4.852-.014.077-.496 1.726-1.648 3.406-1.027 1.478-2.1 2.954-3.732 2.954-1.632 0-2.052-.948-3.936-.948-1.836 0-2.489.98-3.982.98-1.493 0-2.534-1.368-3.732-3.048-1.387-1.973-2.508-5.038-2.508-7.947 0-4.665 3.034-7.14 6.02-7.14 1.586 0 2.908 1.042 3.904 1.042.948 0 2.427-1.104 4.231-1.104.684 0 3.142.062 4.76 2.38Zm-5.616-4.356C17.043 2.94 17.57 1.71 17.57.482c0-.17-.014-.343-.046-.482-1.214.046-2.659.809-3.53 1.82-.684.777-1.322 2.006-1.322 3.251 0 .187.03.375.045.435.077.014.202.03.327.03 1.09 0 2.46-.729 3.252-1.71Z'
            fill='#000'
        />
    </Svg>
);
export default SvgAppleLogo;
