import { Platform } from 'react-native';

import { useAuthRequest as useAuthRequestGoogle } from 'expo-auth-session/providers/google';
import { useAuthRequest as useAuthRequestFb } from 'expo-auth-session/providers/facebook';

import { makeRedirectUri } from 'expo-auth-session';
import { maybeCompleteAuthSession, openAuthSessionAsync } from 'expo-web-browser';
import axios from 'axios';
import { socialProviders } from '@App/constants';
import { BASEURL } from '@Redux/services/base';
import { useMemo } from 'react';

type ISocialAuthCallback = (providerUserId: string, provider: string) => Promise<void>;

maybeCompleteAuthSession();

// TODO: move to config and setup dev/prod environment
let redirectUri: string;
if (Platform.OS === 'web') {
    redirectUri = `${BASEURL}/callback`;
} else {
    redirectUri = makeRedirectUri({
        useProxy: true,
        native: `${BASEURL}/callback`,
        scheme: 'myfamilyroom',
    });
}

const encodedRedirectUri = encodeURIComponent(redirectUri);
// Outlook
const outlookUrl = `https://login.live.com/oauth20_authorize.srf?client_id=${socialProviders.outlook.clientId}&scope=wl.basic&redirect_uri=${encodedRedirectUri}&response_type=token`;
// Apple
const appleRedirectUrl = encodeURIComponent(`${BASEURL}/callback`);
const appleUrl = `https://appleid.apple.com/auth/authorize?client_id=${socialProviders.apple.clientId}&redirect_uri=${appleRedirectUrl}&response_type=code%20id_token&response_mode=fragment`;

function getParameter(url: string, paramArg: string, splitChar?: string) {
    const rawParams = url.split(splitChar || '?')?.[1];
    if (!rawParams) {
        return '';
    }
    const params = rawParams.split('&');
    const param = params.find((i) => i.split('=')?.[0] === paramArg);
    if (param) {
        return param.split('=')[1];
    } else {
        return '';
    }
}

function getProviderUserUri(baseUri: string, token: string) {
    return `${baseUri}?access_token=${token}`;
}

function useSocialAuth() {
    const redirectURI = `${BASEURL}/callback`;

    const googleURL = useMemo<string>(
        () =>
            `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectURI}&client_id=${socialProviders.google.clientId}&response_type=token&state=wlDkPlESkB&scope=openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email`,
        [BASEURL, socialProviders.google.clientId]
    );

    const facebookURL = useMemo<string>(
        () =>
            `https://www.facebook.com/v16.0/dialog/oauth?response_type=token&redirect_uri=${redirectURI}&client_id=${socialProviders.facebook.clientId}`,
        [BASEURL, socialProviders.google.clientId]
    );

    const microsoftURL = useMemo<string>(
        () =>
            `https://login.live.com/oauth20_authorize.srf?client_id=${socialProviders.outlook.clientId}&scope=wl.basic&redirect_uri=${redirectURI}&response_type=token`,
        [BASEURL, socialProviders.google.clientId]
    );

    const appleURL = useMemo<string>(
        () =>
            `https://appleid.apple.com/auth/authorize?client_id=${socialProviders.apple.clientId}&redirect_uri=${redirectURI}&response_type=code%20id_token&response_mode=fragment`,
        [BASEURL, socialProviders.google.clientId]
    );

    const [, , promptAsyncGoogle] = useAuthRequestGoogle({
        clientId: socialProviders.google.clientId,
        redirectUri: redirectUri,
        responseType: 'token',
    });

    const [, , promptAsyncFb] = useAuthRequestFb({
        clientId: socialProviders.facebook.clientId,
        redirectUri: redirectUri,
    });

    async function googleLogin(callback: ISocialAuthCallback) {
        let accessToken;
        const response = await promptAsyncGoogle();
        if (response?.type === 'success') {
            accessToken = response.authentication?.accessToken;
        }

        if (!accessToken) {
            return;
        }

        const url = getProviderUserUri(socialProviders.google.tokenUrl, accessToken);
        const { data } = await axios.get(url);
        const providerUserId = data?.user_id;
        if (!providerUserId) {
            return;
        }
        await callback(providerUserId, 'Google');
    }

    async function facebookLogin(callback: ISocialAuthCallback) {
        let accessToken;
        const response = await promptAsyncFb();
        if (response?.type === 'success') {
            accessToken = response.authentication?.accessToken;
        }

        if (!accessToken) {
            return;
        }

        const url = getProviderUserUri(socialProviders.facebook.tokenUrl, accessToken);
        const { data } = await axios.get(url);
        const providerUserId = data?.id;
        if (!providerUserId) {
            return;
        }
        await callback(providerUserId, 'Facebook');
    }

    async function outlookLogin(callback: ISocialAuthCallback) {
        const res = await openAuthSessionAsync(outlookUrl, redirectUri);
        if (res?.type === 'success') {
            const accessToken = getParameter(res.url, 'access_token', '#');
            if (!accessToken) {
                return;
            }
            const res2 = await axios.get(`https://apis.live.net/v5.0/me?access_token=${accessToken}`);
            const providerUserId = res2.data?.id;
            if (providerUserId) {
                await callback(providerUserId, 'Microsoft');
            }
        }
    }

    async function appleLogin() {
        // Expo apple login does not work for android and web
        // Apple does not allow localhost redirect so local web will not work
        // TODO: Client ID to point into dev app in app store

        await openAuthSessionAsync(appleUrl, appleRedirectUrl, {
            windowFeatures: "width='100%',height='100%'",
        });
    }

    return { googleLogin, facebookLogin, outlookLogin, appleLogin, googleURL, facebookURL, microsoftURL, appleURL };
}

export default useSocialAuth;
