import React, { useState, useEffect, useContext, useMemo } from 'react';
import { ScrollView, View, Text } from 'react-native';

import IconList from '@Components/IconList';
import { parseISO, differenceInHours, format } from 'date-fns';

import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';
import i18n from '@I18n/index';
import { IconStudent, LeftArrow } from '@Icon';
import DeviceContext from '@Contexts/DeviceContext';

import { CalendarEvent, DailySeriesEvent, EventAttachment } from '@Redux/services/CalendarApi';
import { useGetStudentsQuery } from '@Redux/services/parent';

import { useLazyRetrieveEventAttachmentsAsyncQuery } from '@Redux/services/CalendarApi';

import styles from './styles';
import HtmlView from '@Components/HtmlView/HtmlView';
import useTheme from '@Hooks/useTheme';
import Avatar from '@Components/Avatar';
import EventIcon from '@Components/EventIcon';

interface IProps {
    show: boolean;
    event: CalendarEvent;
    onDismiss: () => void;
}

const BasicEventModal: React.FC<IProps> = ({ show, event, onDismiss }) => {
    const { isDesktop } = useContext(DeviceContext);
    const [getData] = useLazyRetrieveEventAttachmentsAsyncQuery();
    const { data: students } = useGetStudentsQuery();
    const [firstName, setFirstName] = useState<string>('-');
    const [avatar, setAvatar] = useState<string>();
    const [attachments, setAttachments] = useState<EventAttachment[]>([]);
    const theme = useTheme();

    useEffect(() => {
        const {
            Email: email,
            FirstName,
            PhotoBase64,
        } = students?.find(({ FullName }) => event.owner.includes(FullName)) || {};
        if (!show) {
            return;
        }
        FirstName && setFirstName(FirstName);
        setAvatar(PhotoBase64);

        getData({
            eventId: event.id,
            email,
        }).then(({ data: nextAttachments }) => {
            setAttachments(nextAttachments);
        });
    }, [show, event, students]);

    const dailySeriesEvent: DailySeriesEvent = useMemo(
        () => ({ ...event, isFirstInDailySeries: false, isLastInDailySeries: false }),
        []
    );

    return (
        <>
            <Modal
                header={
                    <ModalHeader
                        header={i18n.t('calendarEventDetails')}
                        leftIcon={<LeftArrow />}
                        onDismiss={onDismiss}
                    />
                }
                visible={show}
                style={isDesktop ? { height: '80%', margin: 'auto', width: 500 } : { height: '100%' }}
            >
                <ScrollView>
                    <View style={styles.container}>
                        <View style={styles.sectionMargin}>
                            <IconList icon={<EventIcon event={dailySeriesEvent} />} header={event.title}>
                                <View>
                                    <Text style={styles.body}>{format(parseISO(event.start), 'E MMM dd, yyyy')}</Text>
                                    <Text style={styles.body}>
                                        {format(parseISO(event.start), 'hh:mm:aaa')} -{' '}
                                        {format(parseISO(event.end), 'hh:mm:aaa')} (
                                        {differenceInHours(parseISO(event.end), parseISO(event.start))}
                                        h)
                                    </Text>
                                </View>
                                <View>
                                    <Text style={styles.body}>{event.body}</Text>
                                </View>
                            </IconList>
                        </View>
                        <HtmlView
                            htmlString={attachments?.map(
                                (attachment) =>
                                    `<img src="data:${attachment.contentType};base64,${attachment.contentBytes}" />`
                            )}
                        />
                        {firstName && (
                            <View style={[styles.iconContainer, styles.sectionMargin]}>
                                <IconList icon={<IconStudent fill={theme.colors.blueberry} />}>
                                    <Avatar size={30} style={{ marginBottom: 8 }} label={firstName} picture={avatar} />
                                    <Text style={styles.body}>{firstName}</Text>
                                </IconList>
                            </View>
                        )}
                    </View>
                </ScrollView>
            </Modal>
        </>
    );
};

export default BasicEventModal;
