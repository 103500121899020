import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    progress: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    progressIndicator: {
        marginLeft: 4,
        marginRight: 4,
    },
    wrap: {
        flexGrow: 1,
    },
    header: {
        width: '100%',
        textAlign: 'center',
        fontWeight: '800',
        ...typography.h6,
    },
});
