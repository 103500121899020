import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    iconContainer: {
        alignItems: 'center',
        width: 80,
    },
    iconText: {
        ...typography.body3,
    },
});
