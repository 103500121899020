import { useState, useEffect } from 'react';
import { View, Text, Pressable } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import { TextInput as PaperTextInput } from 'react-native-paper';
import i18n from '@I18n';
import { styles } from './styles';
import ShowPassword from '@Components/ShowPassword';
import TextInput from '@Components/TextInput';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import Capitalize from '@Icon/Capitalize';
import Checkmark from '@Icon/Checkmark';
import Glyphs from '@Icon/Glyphs';
import Hash from '@Icon/Hash';
import ArrowRight from '@Icon/ArrowRight';
import { defaultTheme } from '@Hooks/useTheme';
import { withLayoutSm } from '@Screens/common';
import { useResetPasswordMutation } from '@Redux/services/account';

type ResetPasswordScreenProp = NativeStackScreenProps<RootStackParamList, 'resetPassword'>;

function ResetPasswordScreen({ navigation, route }: ResetPasswordScreenProp) {
    const [hidePassword, setHidePassword] = useState(true);
    const [password, setPassword] = useState('');
    const [hidePassword2, setHidePassword2] = useState(true);
    const [password2, setPassword2] = useState('');
    const [validLength, setValidLength] = useState(false);
    const [validCapital, setValidCapital] = useState(false);
    const [validNumberOrSpecial, setValidNumberOrSpecial] = useState(false);
    const [resetPassword] = useResetPasswordMutation();
    const [error, setError] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    function validatePassword(input: string) {
        if (input.length >= 8) {
            setValidLength(true);
        } else {
            setValidLength(false);
        }

        if (input.match(/(?=.*[a-z])(?=.*[A-Z])/)) {
            setValidCapital(true);
        } else {
            setValidCapital(false);
        }

        if (input.match(/[!#$%^&@*_0-9]/)) {
            setValidNumberOrSpecial(true);
        } else {
            setValidNumberOrSpecial(false);
        }
    }

    useEffect(() => {
        validatePassword(password);
    }, [password]);

    async function nextScreen() {
        if (!route.params?.token) {
            return;
        }
        if (password !== password2) {
            setError(i18n.t('newPasswordError1'));
            return;
        }
        setSubmitDisabled(true);
        try {
            await resetPassword({
                PasswordResetToken: route.params.token,
                Password: password,
                RepeatedPassword: password2,
            }).unwrap();
            setResetSuccess(true);
            setTimeout(() => {
                navigation.replace('signin');
            }, 3000);
        } catch (e) {
            if (e?.data?.Message) {
                setError(e.data.Message);
            } else {
                setError('Error resetting password');
            }
            setSubmitDisabled(false);
        }
    }

    function handleSetPassword(value: string) {
        setPassword(value.replace(/\s/g, ''));
        setError('');
    }

    function handleSetPassword2(value: string) {
        setPassword2(value.replace(/\s/g, ''));
        setError('');
    }

    return (
        <ScrollView keyboardShouldPersistTaps='handled'>
            {withLayoutSm(
                <View style={styles.container}>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('newPassword1')}>
                            {i18n.t('newPassword1')}
                        </Text>
                    </View>
                    <View style={styles.fieldContainer}>
                        <TextInput
                            label={i18n.t('password')}
                            accessibilityLabel={i18n.t('password')}
                            testID='password-field'
                            secureTextEntry={hidePassword}
                            mode='outlined'
                            right={
                                <PaperTextInput.Icon
                                    name={() => (
                                        <ShowPassword
                                            onPressIn={() => setHidePassword(false)}
                                            onPressOut={() => setHidePassword(true)}
                                        />
                                    )}
                                    style={styles.iconWrapper}
                                />
                            }
                            value={password}
                            onChangeText={handleSetPassword}
                            disabled={submitDisabled}
                        />
                        <TextInput
                            label={i18n.t('newPassword2')}
                            accessibilityLabel={i18n.t('newPassword2')}
                            testID='password-field'
                            secureTextEntry={hidePassword2}
                            mode='outlined'
                            right={
                                <PaperTextInput.Icon
                                    name={() => (
                                        <ShowPassword
                                            onPressIn={() => setHidePassword2(false)}
                                            onPressOut={() => setHidePassword2(true)}
                                        />
                                    )}
                                    style={styles.iconWrapper}
                                />
                            }
                            value={password2}
                            onChangeText={handleSetPassword2}
                            disabled={submitDisabled}
                        />
                    </View>
                    <View style={styles.descriptionContainer}>
                        {validLength ? (
                            <Checkmark style={styles.descriptionIcon} fill={defaultTheme.colors.watermelon} />
                        ) : (
                            <Hash style={styles.descriptionIcon} />
                        )}
                        <Text style={styles.descriptionText} accessibilityLabel={i18n.t('password2')}>
                            {i18n.t('password2')}
                        </Text>
                    </View>
                    <View style={styles.descriptionContainer}>
                        {validCapital ? (
                            <Checkmark style={styles.descriptionIcon} fill={defaultTheme.colors.watermelon} />
                        ) : (
                            <Capitalize style={styles.descriptionIcon} />
                        )}
                        <Text style={styles.descriptionText} accessibilityLabel={i18n.t('password3')}>
                            {i18n.t('password3')}
                        </Text>
                    </View>
                    <View style={styles.descriptionContainer}>
                        {validNumberOrSpecial ? (
                            <Checkmark style={styles.descriptionIcon} fill={defaultTheme.colors.watermelon} />
                        ) : (
                            <Glyphs style={styles.descriptionIcon} />
                        )}
                        <Text style={styles.descriptionText} accessibilityLabel={i18n.t('password4')}>
                            {i18n.t('password4')}
                        </Text>
                    </View>
                    <View>
                        {Boolean(error) && <Text style={styles.error}>{error}</Text>}
                        {resetSuccess && <Text style={styles.success}>{i18n.t('newPassword3')}</Text>}
                    </View>
                    {validLength && validCapital && validNumberOrSpecial && (
                        <View style={styles.controlContainer}>
                            <Pressable onPress={nextScreen} disabled={submitDisabled}>
                                <View style={styles.buttonContainer}>
                                    <View style={styles.confirmPasswordContainer}>
                                        <Text style={styles.confirmPassword} accessibilityLabel={i18n.t('password5')}>
                                            {i18n.t('password5')}
                                        </Text>
                                    </View>
                                    <ArrowRight style={styles.arrowRight} />
                                </View>
                            </Pressable>
                        </View>
                    )}
                </View>
            )}
        </ScrollView>
    );
}

export default ResetPasswordScreen;
