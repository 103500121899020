import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconArticle = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.758.967h12.485v20.152H3.758V.966ZM5 7.25h10v2.5H5v-2.5Zm0 5h10v2.5H5v-2.5Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgIconArticle;
