import parse from 'html-react-parser';
import { isString } from 'lodash';
import { StyleProp, ViewStyle, View, Text } from 'react-native';
import { useMemo } from 'react';
import styles from './styles';
import Button from '@Components/Button';
import i18n from '@I18n';
import useToggle from '@Hooks/useToggle';
import { defaultTheme, typography } from '@Hooks/useTheme';

interface IProps {
    htmlString: string | string[];
    style?: StyleProp<ViewStyle>;
    showReadMore?: boolean;
}
const HtmlView = ({ htmlString, style, showReadMore = false }: IProps) => {
    const { show, toggle } = useToggle(false);
    const numberOfLines = useMemo(() => {
        if (!showReadMore) return 10000;

        return show ? 10000 : 5;
    }, [showReadMore, show]);
    return (
        <View style={[styles.body2, style, { marginBottom: 16 }]}>
            <Text numberOfLines={numberOfLines}>
                {isString(htmlString) ? parse(htmlString) : htmlString.map((html) => parse(html))}
            </Text>
            {showReadMore && (
                <View style={{ marginTop: 8 }}>
                    <Button
                        label={show ? i18n.t('readLessLabel') : i18n.t('readMoreLabel')}
                        onPress={toggle}
                        underlineColor={defaultTheme.colors.persimmon}
                        labelStyle={{ ...typography.buttonSmalll }}
                    />
                </View>
            )}
        </View>
    );
};

export default HtmlView;
