import { View, Text, Pressable } from 'react-native';
import Modal from '@Components/Modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useTheme, { typography } from '@Hooks/useTheme';
import { useContext } from 'react';
import DeviceContext from '@Contexts/DeviceContext';
import i18n from '@I18n';

type BalanceOptionsModalProps = {
    visible: boolean;
    onCancel: () => void;
    onSubmit: () => void;
};

function BalanceOptionsModal({ visible, onCancel, onSubmit }: BalanceOptionsModalProps) {
    const insets = useSafeAreaInsets();
    const theme = useTheme();
    const { isDesktop } = useContext(DeviceContext);

    return (
        <Modal
            visible={visible}
            header={<></>}
            safeArea={false}
            style={{
                width: 250,
                marginTop: 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: isDesktop ? 'auto' : insets.bottom,
                padding: 12,
                borderRadius: 8,
                backgroundColor: 'white',
            }}
            animationType={isDesktop ? 'none' : 'slide'}
        >
            <Pressable
                onPress={onSubmit}
                style={{
                    width: '100%',
                    paddingVertical: 12,
                    borderBottomColor: theme.colors.inputOutline,
                    borderBottomWidth: 2,
                }}
            >
                <View>
                    <Text style={typography.b1}>{i18n.t('requestCashout')}</Text>
                </View>
            </Pressable>
            <Pressable onPress={onCancel} style={{ width: '100%', paddingVertical: 12 }}>
                <View>
                    <Text style={typography.b1}>Cancel</Text>
                </View>
            </Pressable>
        </Modal>
    );
}

export default BalanceOptionsModal;
