import React, { useCallback, useMemo, useState, FC, useContext, useEffect } from 'react';
import SvgPlus from '@Icon/Plus';
import { View, TextStyle } from 'react-native';
import type { PartialDeep } from 'type-fest';

import ModalHeader from '@Components/ModalHeader';
import { lookupToOptions } from '@Utilities';
import i18n from '@I18n/index';
import useTheme from '@Hooks/useTheme';
import Button from '@Components/Button';
import Picker from '@Components/Picker';
import TextInput, { TextInputProps } from '@Components/TextInput';
import {
    CreateStudentLinkRequestBody,
    useCreateStudentLinkRequestMutation,
    useGetStudentLinkRequestQuery,
    useUpdateStudentLinkRequestMutation,
} from '@Redux/services/StudentLinkApi';

import { useGetGradesQuery, useGetRelationshipsQuery, BasicLookup } from '@Redux/services/LookupApi';

import { styles } from './styles';
import { useGetSchoolsLocationsQuery } from '@Redux/services/StudentRegistrationApi';
import { useGetRegistrationsAndLinkingsQuery, useGetStudentsQuery } from '@Redux/services/parent';
import Modal from '@Components/Modal';
import DeviceContext from '@Contexts/DeviceContext';
import { ScrollView } from 'react-native-gesture-handler';
import { isEmpty, isUndefined } from 'lodash';
import { LinkStatuses } from '@App/constants';
import AlertBox from '@Components/AlertBox';
export type TextFieldPropFactory = (label: string) => Partial<TextInputProps>;
interface IProps {
    buttonLabel: string;
    buttonLabelStyle?: TextStyle;
    linkRequestId?: string;
    buttonLeftIcon?: React.ReactNode;
    buttonColor?: string;
    onDismiss?: () => void;
    reverseIcon?: boolean;
}

const LinkStudent: FC<IProps> = ({
    linkRequestId,
    buttonLabel,
    onDismiss,
    buttonColor,
    buttonLabelStyle,
    buttonLeftIcon,
    reverseIcon = false,
}) => {
    const [visible, setVisible] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const { refetch: getStudents } = useGetStudentsQuery();
    const { data: studentRegistrations, refetch: getStudentRegistrations } = useGetRegistrationsAndLinkingsQuery();
    const { isDesktop } = useContext(DeviceContext);
    const { data, refetch } = useGetStudentLinkRequestQuery(
        { linkRequestId: linkRequestId ?? '' },
        { skip: !linkRequestId || !visible }
    );
    const theme = useTheme();

    useEffect(() => {
        if (!data) {
            return;
        }
        setLinkRequest(data);
    }, [data]);

    useEffect(() => {
        if (linkRequestId && visible) {
            refetch();
        }
    }, [linkRequestId, visible]);

    const handleCtaClick = useCallback(() => {
        setVisible(true);
    }, [setVisible]);

    const handleDismiss = useCallback(() => {
        setVisible(false);
        setLinkRequest({});
    }, [setVisible]);

    const { data: grades } = useGetGradesQuery();
    const gradeOptions = useMemo(() => lookupToOptions<BasicLookup>(grades), [grades]);
    const [linkRequest, setLinkRequest] = useState<PartialDeep<CreateStudentLinkRequestBody>>({});
    const [errors, setErrors] = useState({
        emptySchool: false,
        emptyRelationship: false,
        emptyFirstName: false,
        emptyLastName: false,
    });

    const { data: relationships } = useGetRelationshipsQuery();
    const relationshipOptions = useMemo(() => lookupToOptions<BasicLookup>(relationships), [relationships]);

    const { data: schoolsLocations } = useGetSchoolsLocationsQuery();
    const schoolOptions = useMemo(
        () => schoolsLocations?.map(({ Id, SchoolName }) => ({ value: Id, label: SchoolName })) || [],
        [schoolsLocations]
    );
    const [updateStudentLinkRequest] = useUpdateStudentLinkRequestMutation();
    const [createStudentLinkRequestMutation] = useCreateStudentLinkRequestMutation();

    const makeApiCall = async () => {
        if (linkRequestId) {
            await getStudentRegistrations();
            if (
                !studentRegistrations?.some(
                    (registration) =>
                        registration.Id === registration.Id &&
                        (registration.Status === LinkStatuses.Submitted ||
                            registration.Status === LinkStatuses.Rejected)
                )
            ) {
                return updateStudentLinkRequest(linkRequest as CreateStudentLinkRequestBody);
            } else {
                throw new Error('Unable to update');
            }
        } else {
            return createStudentLinkRequestMutation(linkRequest as CreateStudentLinkRequestBody);
        }
    };

    const handleSubmit = useCallback(async () => {
        if (
            !linkRequest.FirstName?.trim() ||
            !linkRequest.LastName?.trim() ||
            !linkRequest.SchoolId ||
            !linkRequest.RelationshipToStudent
        ) {
            setErrors({
                emptyFirstName: isEmpty(linkRequest.FirstName?.trim()) || isUndefined(linkRequest.FirstName?.trim()),
                emptyLastName: isEmpty(linkRequest.LastName?.trim()) || isUndefined(linkRequest.LastName?.trim()),
                emptyRelationship:
                    (!isUndefined(linkRequest.RelationshipToStudent) && isNaN(linkRequest.RelationshipToStudent)) ||
                    isUndefined(linkRequest.RelationshipToStudent),
                emptySchool: isEmpty(linkRequest.SchoolId) || isUndefined(linkRequest.SchoolId),
            });
            return;
        }
        try {
            await makeApiCall();
            getStudents();
            getStudentRegistrations();
            setVisible(false);
            setLinkRequest({});
            onDismiss && onDismiss();
        } catch (e) {
            setUpdateError(true);
        }
    }, [linkRequest]);

    const handleFirstNameChange = useCallback(
        (value: string) => {
            setLinkRequest({ ...linkRequest, FirstName: value });
        },
        [linkRequest]
    );

    const handleLastNameChange = useCallback(
        (value: string) => {
            setLinkRequest({ ...linkRequest, LastName: value });
        },
        [linkRequest]
    );

    const handleSchoolIdChange = useCallback(
        (value: string) => {
            setLinkRequest({ ...linkRequest, SchoolId: value });
        },
        [linkRequest]
    );

    const handleGradeChange = useCallback(
        (value: string) => {
            setLinkRequest({ ...linkRequest, Grade: parseInt(value, 10) });
        },
        [linkRequest]
    );

    const handleRelationshipToStudentChange = useCallback(
        (value: string) => {
            setLinkRequest({ ...linkRequest, RelationshipToStudent: parseInt(value, 10) });
        },
        [linkRequest]
    );

    const handleStudentNumberChange = useCallback(
        (value: string) => {
            setLinkRequest({ ...linkRequest, StudentNumber: value });
        },
        [linkRequest]
    );

    const textFieldPropFactory = useCallback<TextFieldPropFactory>(
        (label) => ({
            accessibilityLabel: label,
            autoCorrect: false,
            mode: 'outlined',
            style: styles.textInput,
            label: label,
        }),
        []
    );

    return (
        <>
            <Modal
                header={<ModalHeader header={i18n.t('linkRequestTitle')} onDismiss={handleDismiss} />}
                visible={visible}
                animationType='none'
                style={isDesktop ? styles.desktopModal : { height: '100%', display: 'flex' }}
            >
                <View style={isDesktop ? { height: 'calc(100% - 40px)' } : { height: '100%' }}>
                    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                        <View style={{ paddingHorizontal: 24, marginBottom: 16 }}>
                            <AlertBox isError={true} show={updateError} message={i18n.t('requestUpdateError')} />
                        </View>
                        <View style={styles.screen}>
                            <View style={styles.form}>
                                <View style={styles.formGroup}>
                                    <TextInput
                                        onChangeText={handleFirstNameChange}
                                        value={linkRequest.FirstName}
                                        {...textFieldPropFactory(i18n.t('firstName'))}
                                        error={errors.emptyFirstName}
                                    />
                                </View>
                                <View style={styles.formGroup}>
                                    <TextInput
                                        onChangeText={handleLastNameChange}
                                        value={linkRequest.LastName}
                                        {...textFieldPropFactory(i18n.t('lastName'))}
                                        error={errors.emptyLastName}
                                    />
                                </View>
                                <View style={styles.formGroup}>
                                    <Picker
                                        onValueChange={handleGradeChange}
                                        inputLabel={i18n.t('grade')}
                                        value={`${linkRequest.Grade}`}
                                        items={gradeOptions}
                                        style={styles.picker}
                                    />
                                    <Picker
                                        inputLabel={i18n.t('school')}
                                        items={schoolOptions}
                                        style={styles.picker}
                                        onValueChange={handleSchoolIdChange}
                                        value={linkRequest.SchoolId}
                                        error={errors.emptySchool}
                                    />
                                </View>
                                <View style={styles.formGroup}>
                                    <TextInput
                                        onChangeText={handleStudentNumberChange}
                                        value={linkRequest.StudentNumber}
                                        {...textFieldPropFactory(i18n.t('studentNumber'))}
                                    />
                                </View>
                                <View style={styles.formGroup}>
                                    <Picker
                                        onValueChange={handleRelationshipToStudentChange}
                                        value={`${linkRequest.RelationshipToStudent}`}
                                        inputLabel={i18n.t('relationshipToStudent')}
                                        items={relationshipOptions}
                                        error={errors.emptyRelationship}
                                    />
                                </View>
                                <View style={styles.formGroup}>
                                    <Button
                                        label={linkRequestId ? i18n.t('save') : i18n.t('linkChildCta')}
                                        underlineColor={theme.colors.watermelon}
                                        leftIcon={!linkRequestId && <SvgPlus />}
                                        onPress={handleSubmit}
                                        labelStyle={styles.cta}
                                    />
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </Modal>
            <Button
                label={buttonLabel}
                underlineColor={buttonColor}
                leftIcon={!reverseIcon && buttonLeftIcon}
                rightIcon={reverseIcon && buttonLeftIcon}
                style={{ marginBottom: 24 }}
                onPress={handleCtaClick}
                labelStyle={buttonLabelStyle ? buttonLabelStyle : styles.cta}
            />
        </>
    );
};

export default LinkStudent;
