import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconDelete = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.125.417h-2.25L8 2.917h4l-.875-2.5ZM1.667 5.833h2.11v11.25H16.25V5.833h2.083v-2.5H1.667v2.5Zm7.604 8.313H7.125v-7.5H9.27v7.5Zm3.604 0h-2.146v-7.5h2.146v7.5Z'
            fill='#FF5F4D'
        />
    </Svg>
);
export default SvgIconDelete;
