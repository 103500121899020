import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        margin: 24,
        paddingBottom: 48,
        paddingLeft: 60,
        paddingRight: 32,
    },
    title: {
        ...typography.h2,
    },
    bodyContainer: {
        marginTop: 16,
    },
    body: {
        ...typography.body2,
    },
});
