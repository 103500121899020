import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgEmail = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.983.366h23.034v14.267H.983V.366ZM20.1 5.774 12.5 10.7 4.9 5.774V2.9l7.6 4.95 7.6-4.95v2.875Z'
            fill='#73CC49'
        />
    </Svg>
);
export default SvgEmail;
