import {
    ConfirmEventResponseProps,
    EventDetails,
    EventIdProp,
    EventPreviewIdProp,
} from '@Redux/services/EventOfferApi/types';
import { StudentConsentForm, StudentConsentFormsParams, StudentConsentFormsProp, StudentEvents } from '@Redux/types';
import { baseApi } from '../base.ts';

const studentApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getEventDetails: builder.query<EventDetails, EventIdProp>({
            query: (data) => ({
                url: `EventOfferApi/GetEventDetails/${data.eventResponseId}`,
                method: 'GET',
            }),
        }),
        confirmEventDetails: builder.mutation<void, ConfirmEventResponseProps>({
            query: (data) => ({
                url: 'EventOfferApi/ConfirmEvent',
                method: 'POST',
                data,
            }),
        }),
        getStudentConsentForms: builder.query<StudentConsentForm[], StudentConsentFormsProp>({
            query: (params) => ({
                url: 'EventOfferApi/GetConsentForms',
                method: 'GET',
                params,
            }),
        }),
        getStudentEvents: builder.query<StudentEvents[], StudentConsentFormsParams>({
            query: (data) => ({
                url: `EventOfferApi/GetStudentEvents?viewType=${data.viewType}`,
                method: 'GET',
            }),
        }),
        getEventDetailsPreview: builder.query<EventDetails, EventPreviewIdProp>({
            query: (data) => ({
                url: `EventOfferApi/GetEventDetailsPreview?id=${data.eventPreviewId}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetEventDetailsQuery,
    useConfirmEventDetailsMutation,
    useGetStudentConsentFormsQuery,
    useGetStudentEventsQuery,
    useGetEventDetailsPreviewQuery,
    useLazyGetEventDetailsPreviewQuery,
    useLazyGetEventDetailsQuery,
} = studentApi;
