import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        height: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    image: {
        paddingBottom: 16,
    },
    header: {
        ...typography.h2,
        paddingBottom: 16,
    },
    subHeader: {
        ...typography.body2,
    },
});

export default styles;
