import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    tabscreenLabel: {
        ...typography.buttonMedium,
    },
    sectionHeaderContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 80,
        borderBottomColor: defaultTheme.colors.textPrimary,
        borderBottomWidth: 2,
        marginBottom: 32,
    },
    sectionHeaderTitle: {
        ...typography.h2,
    },
    sectionRowContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    itemDesktop: {
        flexBasis: '33%',
    },
    itemMargin: {
        marginBottom: 24,
    },
    desktopModal: {
        minHeight: 400,
        minWidth: 400,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
    },
});

export default styles;
