import { View, Text } from 'react-native';
import { styles } from '../styles';
import {
    useEditNotificationPreferencesMutation,
    useEditStudentNotificationPreferencesMutation,
    useGetNotificationPreferencesQuery,
    useGetParentQuery,
    useGetStudentsQuery,
} from '@Redux/services/parent';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { NotificationPreferenceResponse } from '@Redux/types';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import ListItemEdit from './ListItemEdit';
import ContactPreferencesModal, {
    ContactPreferencesModalData,
    ContactPreferencesModalFieldOptions,
} from './ContactPreferencesModal';
import { useNavigation } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import i18n from '@I18n';
import { ScrollView } from 'react-native-gesture-handler';
import ErrorContext from '@Contexts/ErrorContext';

export type NotificationPreferencesData = {
    type: string;
    home: boolean;
    email: boolean;
    work: boolean;
    mobile: MobileValues;
};

export function getNotificationPreferencesDescription(preference: Partial<NotificationPreferencesData>) {
    if (preference.home && preference.work && preference.email && preference.mobile === 'Both') {
        return 'All contact channels';
    }
    if (!preference.home && !preference.work && !preference.email && preference.mobile === 'None') {
        return 'No contact channel';
    }
    const labels: string[] = [];
    if (preference.home) {
        labels.push('Home');
    }
    if (preference.work) {
        labels.push('Work');
    }
    if (preference.email) {
        labels.push('Email');
    }
    if (preference.mobile === 'Both') {
        labels.push('Mobile', 'Text');
    } else if (preference.mobile === 'Voice') {
        labels.push('Mobile');
    } else if (preference.mobile === 'SMS') {
        labels.push('Text');
    }

    return labels.join(' + ');
}

export type MobileValues = 'Both' | 'Voice' | 'SMS' | 'None';

export type ContactPreferenceItemProps = {
    firstName: string;
    studentId?: string;
    photo: string;
    value: ContactPreferencesModalData;
};

export function getPreferenceString(pref: string) {
    switch (pref) {
        case 'absences':
            return 'Absences';
        case 'urgentNotifications':
            return 'Urgent Notifications';
        case 'busCancellations':
            return 'Bus Cancellations';
        case 'schoolAndBoardNews':
            return 'School and Board News';
        case 'studentRegistrations':
            return 'Student Registrations';
        case 'eventNotifications':
            return 'Event Notifications';
        default:
            return '';
    }
}

export function getPreferenceType(pref: string) {
    switch (pref) {
        case 'Absences':
            return 'absences';
        case 'Urgent Notifications':
            return 'urgentNotifications';
        case 'Bus Cancellations':
            return 'busCancellations';
        case 'School and Board News':
            return 'schoolAndBoardNews';
        case 'Student Registrations':
            return 'studentRegistrations';
        case 'Event Notifications':
            return 'eventNotifications';
        default:
            return '';
    }
}

export function prepareContactPreferenceData(
    notificationPreferences: NotificationPreferenceResponse,
    value: ContactPreferencesModalData,
    prefType: string
) {
    const preferenceRows = notificationPreferences.NotificationPreferences.PreferenceRows;
    const prefs = preferenceRows
        .filter((i) => i.Type !== prefType)
        .map((i) => {
            return {
                Type: i.Type,
                HomePhone: i.HomePhone,
                MobilePhone: i.MobilePhone,
                WorkPhone: i.WorkPhone,
                Email: i.Email,
                MobilePhoneValue: getMobilePhoneValue(i.MobilePhone),
            };
        });
    return {
        PreferenceRows: [
            ...prefs,
            {
                Type: prefType,
                HomePhone: value.home,
                MobilePhone: value.mobile,
                WorkPhone: value.work,
                Email: value.email,
                MobilePhoneValue: getMobilePhoneValue(value.mobile),
            },
        ],
    };
}

function getMobilePhoneValue(value: MobileValues | string) {
    switch (value) {
        case 'Both':
            return '951030003';
        case 'Voice':
            return '951030001';
        case 'SMS':
            return '951030002';
        default:
            return '951030000';
    }
}

export function prepareStudentContactPreferenceData(
    notificationPreferences: NotificationPreferenceResponse,
    value: ContactPreferencesModalData,
    prefType: string,
    studentId: string
) {
    const student = notificationPreferences.Students.find((i) => i.StudentId === studentId);
    if (!student) {
        throw Error('Student contact preference not found');
    }
    const preferenceRows = student.NotificationPreferences.PreferenceRows;
    const prefs = preferenceRows
        .filter((i) => i.Type !== prefType)
        .map((i) => {
            return {
                Type: i.Type,
                HomePhone: i.HomePhone,
                MobilePhone: i.MobilePhone,
                WorkPhone: i.WorkPhone,
                Email: i.Email,
                MobilePhoneValue: getMobilePhoneValue(i.MobilePhone),
            };
        });
    return {
        StudentId: studentId,
        PreferenceRows: [
            ...prefs,
            {
                Type: prefType,
                HomePhone: value.home,
                MobilePhone: value.mobile,
                WorkPhone: value.work,
                Email: value.email,
                MobilePhoneValue: getMobilePhoneValue(value.mobile),
            },
        ],
    };
}

type ContactPreferencesDetailProp = NativeStackScreenProps<RootStackParamList, 'ContactPreferencesDetail'>;

type Data = {
    studentId?: string;
    firstName: string;
    photo: string;
    hasHomeNumber: boolean;
    hasMobileNumber: boolean;
    hasWorkNumber: boolean;
    hasEmail: boolean;
    prefs: NotificationPreferencesData[];
};

type Preferences = {
    parent: Data;
    students: Data[];
};

const defaultValue: ContactPreferencesModalData = {
    home: false,
    email: false,
    work: false,
    mobile: 'None',
};

function ContactPreferencesDetail({ route }: ContactPreferencesDetailProp) {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const navigation = useNavigation();
    const { data: notificationPreferences, refetch } = useGetNotificationPreferencesQuery();
    const { data: students } = useGetStudentsQuery();
    const { data: parent } = useGetParentQuery();
    const [editNotificationPreferences] = useEditNotificationPreferencesMutation();
    const [editStudentNotificationPreferences] = useEditStudentNotificationPreferencesMutation();

    const [preferences, setPreferences] = useState<Preferences>();
    const [showModal, setShowModal] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [parentPrefs, setParentPrefs] = useState<NotificationPreferencesData>();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (!notificationPreferences || !students || !parent) {
            return;
        }

        const parentPrefData = notificationPreferences.NotificationPreferences.PreferenceRows.map((i) => {
            return {
                type: getPreferenceType(i.Type),
                home: i.HomePhone || false,
                email: i.Email || false,
                work: i.WorkPhone || false,
                mobile: (i.MobilePhone || 'None') as MobileValues,
            };
        });
        const studentsPrefData = notificationPreferences.Students.map((i) => {
            const student = students.find((j) => j.StudentId === i.StudentId);
            const prefs = i.NotificationPreferences.PreferenceRows.map((k) => {
                return {
                    type: getPreferenceType(k.Type),
                    home: k.HomePhone || false,
                    email: k.Email || false,
                    work: k.WorkPhone || false,
                    mobile: (k.MobilePhone || 'None') as MobileValues,
                };
            });
            return {
                studentId: student?.StudentId || '',
                firstName: student?.FirstName || '',
                photo: student?.PhotoBase64 || '',
                hasHomeNumber: Boolean(i.NotificationPreferences.HomePhone),
                hasMobileNumber: Boolean(i.NotificationPreferences.MobilePhone),
                hasWorkNumber: Boolean(i.NotificationPreferences.WorkPhone),
                hasEmail: i.NotificationPreferences.PrimaryEmailSet || i.NotificationPreferences.AlternativeEmailSet,
                prefs: prefs,
            };
        });

        const data = {
            parent: {
                firstName: parent.FirstName,
                photo: parent.Photo,
                hasHomeNumber: Boolean(notificationPreferences.NotificationPreferences.HomePhone),
                hasMobileNumber: Boolean(notificationPreferences.NotificationPreferences.MobilePhone),
                hasWorkNumber: Boolean(notificationPreferences.NotificationPreferences.WorkPhone),
                hasEmail:
                    notificationPreferences.NotificationPreferences.PrimaryEmailSet ||
                    notificationPreferences.NotificationPreferences.AlternativeEmailSet,
                prefs: parentPrefData,
            },
            students: studentsPrefData,
        };
        setPreferences(data);
        setParentPrefs(data.parent.prefs.find((i) => i.type === route.params.preference));
    }, [notificationPreferences, students, parent]);

    useLayoutEffect(() => {
        if (['studentRegistrations', 'eventNotifications'].includes(route.params.preference)) {
            return;
        }
        navigation.setOptions({
            headerRight: () => (
                <IconButton
                    icon='dots-vertical'
                    onPress={() => {
                        setShowModal(true);
                    }}
                />
            ),
        });
    }, []);

    function getDisabledOptions() {
        const disabledOpts: ContactPreferencesModalFieldOptions[] = [];
        if (['absences', 'urgentNotifications', 'busCancellations'].includes(route.params.preference)) {
            const hasHomeNumber = notificationPreferences?.NotificationPreferences.HomePhone;
            const hasMobileNumber = notificationPreferences?.NotificationPreferences.MobilePhone;
            const hasWorkNumber = notificationPreferences?.NotificationPreferences.WorkPhone;
            if (!hasHomeNumber) {
                disabledOpts.push('Home');
            }
            if (!hasMobileNumber) {
                disabledOpts.push('Mobile');
            }
            if (!hasWorkNumber) {
                disabledOpts.push('Work');
            }
        } else {
            disabledOpts.push('Home', 'Mobile', 'Work');
        }
        const hasEmail =
            notificationPreferences?.NotificationPreferences.PrimaryEmailSet ||
            notificationPreferences?.NotificationPreferences.AlternativeEmailSet;
        if (!hasEmail) {
            disabledOpts.push('Email');
        }
        return disabledOpts;
    }

    async function handleSubmit(newValue: ContactPreferencesModalData) {
        if (!notificationPreferences) {
            return;
        }
        const newPrefs = prepareContactPreferenceData(
            notificationPreferences,
            newValue,
            getPreferenceString(route.params.preference)
        );
        try {
            await editNotificationPreferences(newPrefs).unwrap();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackEditNotificationPreferenceErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        }
        refetch();
        setShowModal(false);
    }

    async function handleSave(newValue: ContactPreferencesModalData, onFinishCallback: () => void, studentId: string) {
        if (!notificationPreferences) {
            onFinishCallback();
            return;
        }
        if (studentId) {
            const pref = prepareStudentContactPreferenceData(
                notificationPreferences,
                newValue,
                getPreferenceString(route.params.preference),
                studentId
            );
            try {
                await editStudentNotificationPreferences(pref).unwrap();
                refetch();
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackEditNotificationPreferenceErrorMessage');;
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            } finally {
                onFinishCallback();
            }
        } else {
            const pref = prepareContactPreferenceData(
                notificationPreferences,
                newValue,
                getPreferenceString(route.params.preference)
            );
            try {
                await editNotificationPreferences(pref).unwrap();
                refetch();
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackEditNotificationPreferenceErrorMessage');;
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            } finally {
                onFinishCallback();
            }
        }
    }

    return (
        <>
            <ContactPreferencesModal
                show={showModal}
                onDismiss={() => setShowModal(false)}
                initialValue={parentPrefs || defaultValue}
                onSubmit={handleSubmit}
                disabled={getDisabledOptions()}
                required={route.params.preference === 'urgentNotifications' || route.params.preference === 'absences'}
                prefType={route.params.preference}
            />
            <ScrollView>
                <View style={styles.screen}>
                    <Text>{i18n.t('contactPreferencesDescriptionChildListMobile')}</Text>
                    {preferences &&
                        (['studentRegistrations', 'eventNotifications'].includes(route.params.preference) ? (
                            <View>
                                {parentPrefs && (
                                    <ListItemEdit
                                        photo={preferences.parent.photo}
                                        text={preferences.parent.firstName}
                                        subtext={getNotificationPreferencesDescription(parentPrefs)}
                                        disabled={disabled}
                                        setDisabled={setDisabled}
                                        initialValue={parentPrefs}
                                        disabledValue={getDisabledOptions()}
                                        onSave={(val, cb) => handleSave(val, cb, '')}
                                    />
                                )}
                            </View>
                        ) : (
                            preferences.students.map((student, key) => {
                                const pref = student.prefs.find((i) => i.type === route.params.preference);
                                if (!pref) {
                                    return <></>;
                                }
                                return (
                                    <View key={key}>
                                        <ListItemEdit
                                            photo={student.photo}
                                            text={student.firstName}
                                            subtext={getNotificationPreferencesDescription(pref)}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                            required={
                                                route.params.preference === 'urgentNotifications' ||
                                                route.params.preference === 'absences'
                                            }
                                            initialValue={pref}
                                            disabledValue={getDisabledOptions()}
                                            onSave={(val, cb) => handleSave(val, cb, student.studentId || '')}
                                        />
                                    </View>
                                );
                            })
                        ))}
                </View>
            </ScrollView>
        </>
    );
}

export default ContactPreferencesDetail;
