import { View, Text, ViewStyle } from 'react-native';
import { typography } from '@Hooks/useTheme';
import styles from './styles';

interface IProps {
    style: ViewStyle;
    label: string;
    value?: string;
}

const ContactWithLabel = (props: IProps) => (
    <View style={props.style}>
        <View>
            <View style={styles.labelContainer}>
                <Text style={typography.body3}>{props.label}</Text>
            </View>
            <View>
                <Text style={typography.h5}>{props.value || ''}</Text>
            </View>
        </View>
    </View>
);

export default ContactWithLabel;
