import React, { ReactNode, useCallback, useEffect } from 'react';
import { View, Text } from 'react-native';
import i18n from '@I18n';

import { IconCalMonth, IconSchool } from '@Icon';
import SwitchInput from '@Components/SwitchInput';
import { useGetStudentsQuery } from '@Redux/services/parent';

import { OfferStatusFilter, EventTypesFilter, StudentsFilter } from '@Redux/services/CalendarApi';
import { OfferStatusFilterList } from './components';

interface FiltersProps {
    eventTypesFilter: EventTypesFilter;
    offerStatusFilter: OfferStatusFilter;
    setEventTypesFilterValue: (v: EventTypesFilter) => void;
    setOfferStatusFilterValue: (v: OfferStatusFilter) => void;
    setStudentsFilterValue: (v: StudentsFilter) => void;
    studentsFilter: StudentsFilter;
    lateButton?: ReactNode;
    absentButton?: ReactNode;
}

const areAllFiltersOff = (nextValue: OfferStatusFilter | EventTypesFilter | StudentsFilter) =>
    Object.values(nextValue).reduce((memo, v) => memo && Boolean(!v), true);
const areAnyFiltersOn = (nextValue: OfferStatusFilter | EventTypesFilter | StudentsFilter) =>
    Object.values(nextValue).reduce((memo, v) => memo || Boolean(v), false);

import { styles } from './styles';
import Avatar from '@Components/Avatar';
function Filters({
    eventTypesFilter,
    offerStatusFilter,
    setEventTypesFilterValue,
    setOfferStatusFilterValue,
    setStudentsFilterValue,
    studentsFilter,
    lateButton,
    absentButton,
}: FiltersProps) {
    const { data: students } = useGetStudentsQuery();

    useEffect(() => {
        setStudentsFilterValue(studentsFilter);
        setEventTypesFilterValue(eventTypesFilter);
    }, [studentsFilter, eventTypesFilter]);

    const handleEventFilterValueChange = useCallback(
        (value: string) => {
            return (checked: boolean) => {
                setEventTypesFilterValue({ ...eventTypesFilter, [value]: checked });

                if (value === 'offers') {
                    setOfferStatusFilterValue({
                        accepted: checked,
                        expired: checked,
                        acceptedpast: checked,
                        invited: checked,
                    });
                }
            };
        },
        [eventTypesFilter, setEventTypesFilterValue]
    );

    const handleStudentFilterValueChange = useCallback(
        (value: string) => {
            return (checked: boolean) => {
                setStudentsFilterValue({ ...studentsFilter, [value]: checked });
            };
        },
        [studentsFilter, setStudentsFilterValue]
    );

    const handleOfferStatusFilterValueChange = useCallback(
        (value: string) => {
            return (checked: boolean) => {
                const nextValue = { ...offerStatusFilter, [value]: checked };
                const allFiltersOff = areAllFiltersOff(nextValue);
                const anyFiltersOn = areAnyFiltersOn(nextValue);
                setOfferStatusFilterValue({ ...offerStatusFilter, [value]: checked });

                if (allFiltersOff) {
                    setEventTypesFilterValue({ ...eventTypesFilter, offers: false });
                }
                if (anyFiltersOn) {
                    setEventTypesFilterValue({ ...eventTypesFilter, offers: true });
                }
            };
        },
        [setOfferStatusFilterValue, setEventTypesFilterValue, eventTypesFilter, offerStatusFilter]
    );

    return (
        <View style={styles.filterWrapper}>
            {lateButton ? <View style={styles.filterTitleWrapper}>{lateButton}</View> : null}
            {absentButton ? <View style={styles.filterTitleWrapper}>{absentButton}</View> : null}
            <View style={styles.filterTitleWrapper}>
                <Text style={styles.filterTitle}>Event</Text>
                <IconCalMonth height={32} />
            </View>
            <SwitchInput
                checked={eventTypesFilter.schoolboard}
                isCheckbox
                label={i18n.t('calendarFilterEventTypeSchoolboard')}
                onChange={handleEventFilterValueChange('schoolboard')}
                viewStyle={styles.checkbox}
            />
            <SwitchInput
                checked={eventTypesFilter.schoolyear}
                isCheckbox
                label={i18n.t('calendarFilterEventTypeSchoolyear')}
                onChange={handleEventFilterValueChange('schoolyear')}
                viewStyle={styles.checkbox}
            />
            <SwitchInput
                checked={eventTypesFilter.offers}
                isCheckbox
                label={i18n.t('calendarFilterEventTypeOffers')}
                onChange={handleEventFilterValueChange('offers')}
                viewStyle={styles.checkbox}
            />
            <OfferStatusFilterList
                offerStatusFilter={offerStatusFilter}
                onOfferStatusFilterValueChange={handleOfferStatusFilterValueChange}
            />
            <SwitchInput
                checked={eventTypesFilter.other}
                isCheckbox
                label={i18n.t('calendarFilterEventTypeOther')}
                onChange={handleEventFilterValueChange('other')}
                viewStyle={styles.checkbox}
            />
            <View style={[styles.filterTitleWrapper, styles.topBorder]}>
                <Text style={styles.filterTitle}>Student</Text>
                <IconSchool height={32} />
            </View>
            {students?.map((student) => (
                <SwitchInput
                    checked={studentsFilter[student.FullName]}
                    isCheckbox
                    key={student.FullName}
                    label={
                        <View style={{ flexDirection: 'row' }}>
                            <Avatar size={26} label={student.FirstName} picture={student.PhotoBase64} />
                            <Text style={[{ marginLeft: 8 }, styles.label]}>{student.FullName}</Text>
                        </View>
                    }
                    onChange={handleStudentFilterValueChange(student.FullName)}
                    viewStyle={styles.checkbox}
                />
            ))}
        </View>
    );
}

export default Filters;
