import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgWarning = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='#fff' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.068 3.02 16 30.615 31.932 3.02H.068Zm17.265 11.683h-2.666v-6.3h2.666v6.3Zm-.282 3.45c-.3.3-.651.45-1.051.45-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05s.15-.744.45-1.033c.3-.289.65-.433 1.05-.433s.75.144 1.05.433c.3.29.45.633.45 1.034 0 .4-.15.75-.45 1.049Z'
        />
    </Svg>
);
export default SvgWarning;
