import {
    LoginRequest,
    LoginResponse,
    SignUpRequest,
    RegisterRequest,
    RequestPasswordResetRequest,
    ResetPasswordRequest,
    SocialLogin,
    RegisterWithInviteRequest,
} from '../types';
import { baseApi } from './base.ts';
import { URLSearchParams } from 'react-native-url-polyfill';

const accountApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        loginWithPassword: builder.mutation<LoginResponse, LoginRequest>({
            query: (data) => ({
                url: 'AccountApi/LoginWithPassword',
                method: 'POST',
                data: new URLSearchParams(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }),
        }),
        socialLogin: builder.mutation<LoginResponse, SocialLogin>({
            query: (data) => ({
                url: 'AccountApi/SocialLogin',
                method: 'POST',
                params: new URLSearchParams(data),
            }),
        }),
        signUp: builder.mutation<void, SignUpRequest>({
            query: (data) => ({
                url: 'AccountApi/SignUp',
                method: 'POST',
                data: new URLSearchParams(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }),
        }),
        register: builder.mutation<void, RegisterRequest>({
            query: (data) => ({
                url: 'AccountApi/Register',
                method: 'POST',
                data: new URLSearchParams(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }),
        }),
        registerWithInvite: builder.mutation<void, RegisterWithInviteRequest>({
            query: (data) => ({
                url: 'AccountApi/RegisterWithInvite',
                method: 'POST',
                data: new URLSearchParams(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }),
        }),
        requestPasswordReset: builder.mutation<void, RequestPasswordResetRequest>({
            query: (data) => ({
                url: 'AccountApi/RequestPasswordReset',
                method: 'POST',
                data: new URLSearchParams(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }),
        }),
        resetPassword: builder.mutation<void, ResetPasswordRequest>({
            query: (data) => ({
                url: 'AccountApi/ResetPassword',
                method: 'POST',
                data: new URLSearchParams(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }),
        }),
    }),
});

export const {
    useLoginWithPasswordMutation,
    useSocialLoginMutation,
    useSignUpMutation,
    useRegisterMutation,
    useRegisterWithInviteMutation,
    useRequestPasswordResetMutation,
    useResetPasswordMutation,
} = accountApi;
