import { TriangleExclamation } from '@Icon/index';
import { FC } from 'react';
import { Text, View, Pressable, Linking } from 'react-native';
import { styles } from './styles';
import i18n from '@I18n';

interface IProps {
    show: boolean;
}
const InactiveAlert: FC<IProps> = ({ show }) => {
    if (show) return null;

    return (
        <View style={styles.errorLabelContainer}>
            <TriangleExclamation style={styles.icon} />
            <Text style={styles.alertMessage}>
                {i18n.t('eWalletHelp')}
                <Pressable style={styles.helpLink} onPress={() => Linking.openURL('mailto:askucit@ucdsb.on.ca')}>
                    <Text style={styles.alertMessage}>askucit@ucdsb.on.ca</Text>
                </Pressable>
            </Text>
        </View>
    );
};

export default InactiveAlert;
