import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';
const SvgIconOfferStatusExpired = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <G clipPath='url(#icon-offer-status-expired_svg__a)'>
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.355-.33h-3.21V3.7H2.2v19.643h19.643V3.7h-2.988V-.33h-3.21V3.7h-7.29V-.33ZM20.637 8.9H3.363v10.7h17.276V8.9ZM8.15 16.575l1.675 1.675L12 16.075l2.175 2.175 1.675-1.675-2.175-2.175 2.175-2.175-1.675-1.675L12 12.725 9.825 10.55 8.15 12.225l2.175 2.175-2.175 2.175Z'
                fill='#373639'
                fillOpacity={0.53}
            />
        </G>
        <Defs>
            <ClipPath id='icon-offer-status-expired_svg__a'>
                <Path fill='#fff' d='M0 0h24v24H0z' />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgIconOfferStatusExpired;
