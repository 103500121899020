import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconOfferStatusInvited = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.483 4.867h23.034v14.266H.483V4.867ZM19.6 10.275 12 15.2l-7.6-4.925V7.399l7.6 4.95 7.6-4.95v2.876Z'
            fill='#EEAB00'
        />
    </Svg>
);
export default SvgIconOfferStatusInvited;
