import { Text, View } from 'react-native';
import { StudentRegistration } from '@Redux/types';
import Avatar from '@Components/Avatar';
import styles from './styles';
import SvgSuccess from '@Icon/Success';
import SvgInfo from '@Icon/Info';
import i18n from '@I18n';
import Button from '@Components/Button';
import useTheme from '@Hooks/useTheme';
import { LinkChild, RegisterChild } from '@Screens/Student/components';
import { RegistrationStatuses, LinkStatuses } from '@App/constants';

interface IProps {
    studentRegistrations?: StudentRegistration[];
    refetchRegistrations: () => void;
    onDeletePress: (registrationId: string, submissionType: number) => void;
}
const Registrations: React.FC<IProps> = ({ onDeletePress, studentRegistrations, refetchRegistrations }) => {
    const theme = useTheme();
    if (!studentRegistrations) {
        return null;
    }
    const getStatusLabel = (status: number, submissionType: number) => {
        if (submissionType === 2) {
            switch (status) {
                case RegistrationStatuses.Submitted:
                    return i18n.t('registrationStatusSubmitted');
                case RegistrationStatuses.DocumentsRequired:
                    return i18n.t('registrationStatusInProgress');
                case RegistrationStatuses.Approved:
                    return i18n.t('registrationStatusApprovedArchived');
                case RegistrationStatuses.Archived:
                    return i18n.t('registrationStatusApprovedArchived');
                case RegistrationStatuses.Rejected:
                    return i18n.t('registrationStatusRejected');
            }
        } else {
            switch (status) {
                case LinkStatuses.Submitted:
                    return i18n.t('requestStatusSubmitted');
                case LinkStatuses.Approved:
                    return i18n.t('requestStatusApproved');
                case LinkStatuses.Rejected:
                    return i18n.t('requestStatusRejected');
            }
        }
    };

    return (
        <View>
            {studentRegistrations.map((registration, index) => {
                return (
                    <View style={styles.registrationContainer} key={`registration-${index}`}>
                        <Avatar style={styles.avatar} size={48} label={registration.StudentFirstName} />
                        <View>
                            <Text style={styles.name}>{registration.StudentFirstName}</Text>
                            <View style={styles.status}>
                                <View style={styles.statusIcon}>
                                    {registration.StatusDescription === 'Submitted' ? <SvgSuccess /> : <SvgInfo />}
                                </View>

                                <Text style={styles.body2}>
                                    {getStatusLabel(registration.Status, registration.SubmissionType)}
                                </Text>
                            </View>
                            <Text style={styles.body2}>{registration.SchoolName}</Text>
                            {((RegistrationStatuses.Submitted === registration.Status &&
                                registration.SubmissionType === 2) ||
                                (LinkStatuses.Submitted === registration.Status &&
                                    registration.SubmissionType === 1)) && (
                                <View style={styles.actionsContainer}>
                                    <View style={styles.editButton}>
                                        {registration.SubmissionType === 2 ? (
                                            <RegisterChild
                                                buttonLabel={i18n.t('edit')}
                                                registrationId={registration.Id}
                                                buttonColor={theme.colors.persimmon}
                                                onDismiss={() => {
                                                    refetchRegistrations();
                                                }}
                                            />
                                        ) : (
                                            <LinkChild
                                                buttonLabel={i18n.t('edit')}
                                                linkRequestId={registration.Id}
                                                buttonColor={theme.colors.persimmon}
                                            />
                                        )}
                                    </View>
                                    {(registration.Status === RegistrationStatuses.Submitted ||
                                        registration.Status === LinkStatuses.Submitted) && (
                                        <Button
                                            label={i18n.t('delete')}
                                            underlineColor={theme.colors.raspberry}
                                            labelStyle={styles.deleteButton}
                                            onPress={() => onDeletePress(registration.Id, registration.SubmissionType)}
                                        />
                                    )}
                                </View>
                            )}
                        </View>
                    </View>
                );
            })}
        </View>
    );
};

export default Registrations;
