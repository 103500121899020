import { Pressable, Text, View } from 'react-native';
import styles from './styles';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Close } from '@Icon/index';

interface IProps {
    message: string;
    visible: boolean;
    setVisible: (x: boolean) => void;
}

const ErrorAlert = ({ message, visible, setVisible }: IProps) => {
    const inset = useSafeAreaInsets();

    const handleClose = () => {
        setVisible(false);
    };

    return visible ? (
        <View
            style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                opacity: 1,
                paddingBottom: inset.bottom,
                left: 0,
                right: 0,
                margin: 'auto',
                maxWidth: 1280,
            }}
        >
            <View style={styles.innerContainer}>
                <View style={styles.messageContainer}>
                    <Text style={styles.message}>{message}</Text>
                </View>
                <Pressable onPress={handleClose}>
                    <Close fill={'white'} width={15} />
                </Pressable>
            </View>
        </View>
    ) : (
        <></>
    );
};

export default ErrorAlert;
