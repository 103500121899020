import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    reminderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 200,
        position: 'absolute',
        bottom: -10,
    },
    reminderContainerNonReverse: {
        left: 28,
    },
    reminderContainerFirst: {
        left: 7,
    },
    reminderContainerInverted: {
        position: 'relative',
        bottom: 0,
        left: 0,
        marginLeft: 12,
        marginBottom: -10,
        paddingTop: 20,
    },
    reminderText: {
        ...typography.body3,
    },
    reminderIcon: {
        paddingHorizontal: 4,
    },
    reverse: {
        flexDirection: 'row-reverse',
        right: 25,
    },
    inverted: {
        transform: [{ scaleY: -1 }],
    },
});
