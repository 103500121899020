import DeviceContext from '@Contexts/DeviceContext';
import { useStudentListEmpty } from '@Hooks/useStudentListEmpty';
import { LoadingIndicator } from '@Screens/Calendar/components';
import Empty from '@Screens/Home/Empty';
import React, { useContext } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Header from '../Header';
import styles from '../styles';

function NewUser() {
    const { isDesktop } = useContext(DeviceContext);
    const inset = useSafeAreaInsets();
    const { isLoadingRegistrations, isLoadingStudents } = useStudentListEmpty();

    return (
        <View style={[styles.container, { paddingTop: inset.top }]}>
            {isLoadingRegistrations && isLoadingStudents ? (
                <LoadingIndicator />
            ) : (
                <>
                    {!isDesktop && <Header />}
                    <Empty />
                </>
            )}
        </View>
    );
}

export default NewUser;
