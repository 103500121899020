import { FC } from 'react';
import styles from '../styles';
import { View, Text } from 'react-native';
import { TriangleExclamation } from '@Icon/index';

interface IProps {
    message: string;
}

const Alert: FC<IProps> = ({ message }) => {
    return (
        <View style={styles.sectionMargin}>
            <View style={[styles.iconContainer, styles.alert]}>
                <TriangleExclamation style={styles.iconInfo} />
                <Text style={styles.body}>{message}</Text>
            </View>
        </View>
    );
};

export default Alert;
