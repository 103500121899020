import { RootNavigationProp } from '@App/rootNavigation';
import DeviceContext from '@Contexts/DeviceContext';
import { StudentListEmpty } from '@Components/StudentListEmpty';
import Registrations from './components/Registrations';
import SvgBrush from '@Icon/Brush';
import { View, Text, ScrollView, Linking } from 'react-native';
import { LinkChild, RegisterChild } from '../components';
import styles from './styles';
import i18n from '@I18n';
import useTheme, { typography } from '@Hooks/useTheme';
import Accordion from '@Components/Accordion';
import Avatar from '@Components/Avatar';
import SvgInfo from '@Icon/Info';
import Button from '@Components/Button';
import SvgIconDelete from '@Icon/IconDelete';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import withLayout from '@Screens/common';
import { ActivityIndicator } from 'react-native-paper';
import ListLink from '@Components/ListLink';
import SvgIconCall from '@Icon/IconCall';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useGetRegistrationsAndLinkingsQuery, useGetStudentsQuery } from '@Redux/services/parent';
import { AttendanceSummary, ChildInfo, StudentRegistration } from '@Redux/types';
import { useGetAttendenceSummaryMutation } from '@Redux/services/absence';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import SvgArrowRight from '@Icon/ArrowRight';
import Attendance from './components/Attendence';
import { useRemoveStudentLinkRequestMutation } from '@Redux/services/StudentLinkApi';
import { useRemoveStudentRegistrationMutation } from '@Redux/services/StudentRegistrationApi';
import SvgPlus from '@Icon/Plus';
import useNotify from '@Hooks/useNotify';
import { LinkStatuses, RegistrationStatuses } from '@App/constants';
import ErrorContext from '@Contexts/ErrorContext';

interface ActionButtonProps {
    onDismiss: () => void;
}

function ActionButtonComponent({ onDismiss }: ActionButtonProps) {
    const { absentButton, lateButton, absenceForm } = useNotify({
        reverseIcon: true,
        labelStyle: typography.buttonMedium,
    });
    const theme = useTheme();
    const { isDesktop } = useContext(DeviceContext);
    return (
        <View style={styles.actions}>
            <View style={{ marginRight: 24 }}>
                <LinkChild
                    buttonLabel={i18n.t('linkChildCta')}
                    buttonLeftIcon={<SvgPlus />}
                    reverseIcon={isDesktop}
                    buttonColor={theme.colors.watermelon}
                />
                <RegisterChild
                    buttonLabel={i18n.t('registerNewStudent')}
                    buttonColor={theme.colors.grape}
                    reverseIcon={isDesktop}
                    buttonLeftIcon={<SvgBrush fill={theme.colors.grape} />}
                    onDismiss={onDismiss}
                />
            </View>
            {isDesktop && (
                <View>
                    <View style={{ marginBottom: 24 }}>{lateButton}</View>
                    {absentButton}
                    {absenceForm}
                </View>
            )}
        </View>
    );
}

interface IProps {
    students: ChildInfo[];
}

const DesktopStudentList: React.FC<IProps> = ({ students }) => {
    const theme = useTheme();
    const { setMessage, setVisible } = useContext(ErrorContext);
    const [attendanceSummaries, setAttendanceSummaries] = useState<AttendanceSummary[]>();
    const [getAttendenceSummary] = useGetAttendenceSummaryMutation();
    const navigation = useNavigation<RootNavigationProp>();
    useEffect(() => {
        getStudentAttendenceSummary();
    }, []);

    const getStudentAttendenceSummary = async () => {
        try {
            const response = await getAttendenceSummary().unwrap();
            setAttendanceSummaries(response);
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackGetErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        }
    };

    const filterAttendence = useCallback(
        (studentId: string) => {
            return attendanceSummaries?.filter((attendanceSummary) => attendanceSummary.StudentId === studentId);
        },
        [attendanceSummaries, setAttendanceSummaries]
    );

    return (
        <ScrollView style={{ maxHeight: '100%' }} showsVerticalScrollIndicator={false}>
            {students.map((student, index) => {
                return (
                    <View style={styles.desktopContainer} key={`student-${index}`}>
                        <View style={{ justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
                            <View style={styles.avatarContainer}>
                                <Avatar style={styles.avatar} label={student.FirstName} picture={student.PhotoBase64} />
                                <View style={{ width: '90%' }}>
                                    <Text style={styles.name}>{student.FirstName}</Text>
                                    <View
                                        style={{
                                            maxWidth: '95%',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Text style={styles.school}>{student.SchoolInfo.Name}</Text>
                                        <Button
                                            label={i18n.t('studentDetailsSeeMore')}
                                            rightIcon={<SvgArrowRight fill={theme.colors.watermelon} />}
                                            underlineColor={theme.colors.watermelon}
                                            onPress={() =>
                                                navigation.navigate('StudentProfile', {
                                                    studentId: student.StudentId,
                                                })
                                            }
                                        />
                                    </View>
                                    <Text style={styles.school}>{student.SchoolInfo.Telephone}</Text>
                                    <Attendance attendenceSummaries={filterAttendence(student.StudentId)} />
                                </View>
                            </View>
                        </View>
                    </View>
                );
            })}
        </ScrollView>
    );
};

interface RegistrationListItemsProps {
    studentRegistrations?: StudentRegistration[];
    refetchRegistrations: () => void;
    onDeletePress: (registrationId: string, submissionType: number) => void;
    deleteRegistrationId?: string;
    isDeleting: boolean;
}

function RegistrationListItems({
    studentRegistrations,
    refetchRegistrations,
    onDeletePress,
    deleteRegistrationId,
    isDeleting,
}: RegistrationListItemsProps) {
    const theme = useTheme();

    const handleDismiss = () => {
        refetchRegistrations();
    };

    const getStatusLabel = (status: number, submissionType: number) => {
        if (submissionType === 2) {
            switch (status) {
                case RegistrationStatuses.Submitted:
                    return i18n.t('registrationStatusSubmitted');
                case RegistrationStatuses.DocumentsRequired:
                    return i18n.t('registrationStatusInProgress');
                case RegistrationStatuses.Approved:
                    return i18n.t('registrationStatusApprovedArchived');
                case RegistrationStatuses.Archived:
                    return i18n.t('registrationStatusApprovedArchived');
                case RegistrationStatuses.Rejected:
                    return i18n.t('registrationStatusRejected');
            }
        } else {
            switch (status) {
                case LinkStatuses.Submitted:
                    return i18n.t('requestStatusSubmitted');
                case LinkStatuses.Approved:
                    return i18n.t('requestStatusApproved');
                case LinkStatuses.Rejected:
                    return i18n.t('requestStatusRejected');
            }
        }
    };

    const showIcon = (registration: StudentRegistration) =>
        (RegistrationStatuses.Submitted === registration.Status && registration.SubmissionType === 2) ||
        (LinkStatuses.Submitted === registration.Status && registration.SubmissionType === 1) ||
        registration.Status === RegistrationStatuses.Submitted ||
        registration.Status === LinkStatuses.Submitted;

    return (
        <>
            {studentRegistrations && studentRegistrations.length > 0 && (
                <Text style={[styles.subhead]}>{i18n.t('newStudentRegistrations')}</Text>
            )}
            {studentRegistrations?.map((registration, i) => {
                return (
                    <Accordion
                        key={i}
                        icon={<Avatar label={registration.StudentFirstName} size={46} />}
                        header={registration.StudentFirstName}
                        body={
                            <View style={styles.infoContaner}>
                                <SvgInfo width={18} fill={theme.colors.blueberry} style={styles.infoIcon} />
                                <Text style={styles.infoLabel}>
                                    {getStatusLabel(registration.Status, registration.SubmissionType)}
                                </Text>
                            </View>
                        }
                        showIcon={showIcon(registration)}
                    >
                        {((RegistrationStatuses.Submitted === registration.Status &&
                            registration.SubmissionType === 2) ||
                            (LinkStatuses.Submitted === registration.Status && registration.SubmissionType === 1)) && (
                            <View style={styles.buttonContainer}>
                                <View style={{ marginRight: 24 }}>
                                    {registration.SubmissionType === 2 ? (
                                        <RegisterChild
                                            registrationId={registration.Id}
                                            buttonLabel={i18n.t('edit')}
                                            buttonLeftIcon={<SvgBrush fill={theme.colors.grape} />}
                                            buttonColor={theme.colors.grape}
                                            onDismiss={handleDismiss}
                                        />
                                    ) : (
                                        <LinkChild
                                            buttonLabel={i18n.t('edit')}
                                            linkRequestId={registration.Id}
                                            buttonLeftIcon={<SvgBrush fill={theme.colors.grape} />}
                                            buttonColor={theme.colors.persimmon}
                                        />
                                    )}
                                </View>
                                {(registration.Status === RegistrationStatuses.Submitted ||
                                    registration.Status === LinkStatuses.Submitted) && (
                                    <Button
                                        labelStyle={styles.buttonLabel}
                                        underlineColor={theme.colors.raspberry}
                                        style={{ marginRight: 24 }}
                                        leftIcon={
                                            isDeleting ? (
                                                deleteRegistrationId == registration.Id && (
                                                    <ActivityIndicator size='small' />
                                                )
                                            ) : (
                                                <SvgIconDelete />
                                            )
                                        }
                                        label={
                                            isDeleting && deleteRegistrationId == registration.Id
                                                ? i18n.t('deleting')
                                                : i18n.t('delete')
                                        }
                                        onPress={() => onDeletePress(registration.Id, registration.SubmissionType)}
                                    />
                                )}
                            </View>
                        )}
                    </Accordion>
                );
            })}
        </>
    );
}

interface StudentListProps {
    students?: ChildInfo[];
    studentRegistrations?: StudentRegistration[];
    refetchRegistrations: () => void;
    mobileRender: boolean;
    onDeletePress: (registrationId: string, submissionType: number) => void;
    deleteRegistrationId?: string;
    isDeleting: boolean;
}

function StudentListComponent({
    students,
    studentRegistrations,
    refetchRegistrations,
    mobileRender,
    onDeletePress,
    deleteRegistrationId,
    isDeleting,
}: StudentListProps) {
    const theme = useTheme();
    const inset = useSafeAreaInsets();

    const handleDismiss = () => {
        refetchRegistrations();
    };

    return withLayout(
        <View style={[styles.container, { paddingTop: inset.top }]}>
            <ScrollView style={styles.containerInside}>
                {!students && <ActivityIndicator />}
                {students?.map((student, i) => {
                    return (
                        <View key={i}>
                            <ListLink
                                icon={<Avatar label={student.FirstName} size={96} picture={student.PhotoBase64} />}
                                destination={'StudentProfile'}
                                args={{ studentId: student.StudentId }}
                                text={student.FullName}
                                subtext={student.SchoolInfo.Name}
                                variant='large'
                            >
                                <View style={styles.callSchool}>
                                    <Button
                                        label={i18n.t('callSchool')}
                                        onPress={() => Linking.openURL(`tel:${student.SchoolInfo.Telephone}`)}
                                        underlineColor={theme.colors.watermelon}
                                        leftIcon={<SvgIconCall fill={theme.colors.watermelon} />}
                                    />
                                </View>
                            </ListLink>
                        </View>
                    );
                })}
                {mobileRender && (
                    <>
                        {!studentRegistrations && <ActivityIndicator />}
                        <RegistrationListItems
                            onDeletePress={onDeletePress}
                            isDeleting={isDeleting}
                            deleteRegistrationId={deleteRegistrationId}
                            studentRegistrations={studentRegistrations}
                            refetchRegistrations={refetchRegistrations}
                        />
                        <ActionButtonComponent onDismiss={handleDismiss} />
                    </>
                )}
            </ScrollView>
        </View>
    );
}

function StudentList() {
    const { isDesktop } = useContext(DeviceContext);
    const { setMessage, setVisible } = useContext(ErrorContext);
    const isFocused = useIsFocused();
    const { data: students, refetch: getStudents } = useGetStudentsQuery();
    const { data: studentRegistrations, refetch: getStudentRegistrationsAndLinkings } =
        useGetRegistrationsAndLinkingsQuery();
    const { absenceForm, notifyButtonModal, notifyButton } = useNotify();

    useEffect(() => {
        if (!isFocused) return;
        getStudentRegistrationsAndLinkings();
        getStudents();
    }, [isFocused]);

    const [deletingRegistrationId, setDeletingRegistrationId] = useState<string>();
    const [isDeleting, setIsDeleting] = useState(false);

    const [removeStudentLinkRequest] = useRemoveStudentLinkRequestMutation();
    const [removeStudentRegistrationMutation] = useRemoveStudentRegistrationMutation();
    const handleDismiss = () => {
        getStudentRegistrationsAndLinkings();
    };

    const handleDeletePress = async (registrationId: string, submissionType: number) => {
        if (registrationId === deletingRegistrationId) {
            return;
        }
        setDeletingRegistrationId(registrationId);
        setIsDeleting(true);
        if (submissionType === 1) {
            try {
                await removeStudentLinkRequest({ id: registrationId }).unwrap();
                getStudentRegistrationsAndLinkings();
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackDeleteStudentLinkErrorMessage');
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            } finally {
                setIsDeleting(false);
                setDeletingRegistrationId(undefined);
            }
        } else {
            try {
                await removeStudentRegistrationMutation({ registrationId }).unwrap();
                getStudentRegistrationsAndLinkings();
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackDeleteStudentRegistrationErrorMessage');
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            } finally {
                setIsDeleting(false);
                setDeletingRegistrationId(undefined);
            }
        }
    };

    return isDesktop ? (
        withLayout(
            <View style={{ height: '100%', maxHeight: '100%', flexDirection: 'row', paddingLeft: 60 }}>
                <View style={{ flexDirection: 'row', width: '100%' }}>
                    {students?.length === 0 && studentRegistrations?.length === 0 ? (
                        <StudentListEmpty />
                    ) : (
                        <>
                            <View style={{ flexBasis: '55%', paddingRight: 16 }}>
                                <DesktopStudentList students={students ? students : []} />
                            </View>
                            <View style={{ borderLeftWidth: 1 }}>
                                <ScrollView
                                    style={{ maxHeight: '80%', overflow: 'scroll' }}
                                    showsVerticalScrollIndicator={false}
                                >
                                    <View style={{ paddingLeft: 16 }}>
                                        <Text
                                            style={[styles.subhead, { marginTop: 0 }]}
                                            accessibilityLabel={i18n.t('newStudentRegistrations')}
                                        >
                                            {i18n.t('newStudentRegistrations')}
                                        </Text>
                                        <Registrations
                                            studentRegistrations={studentRegistrations}
                                            refetchRegistrations={getStudentRegistrationsAndLinkings}
                                            onDeletePress={handleDeletePress}
                                        />
                                    </View>
                                </ScrollView>
                                <View style={styles.actionContainer}>
                                    <ActionButtonComponent onDismiss={handleDismiss} />
                                </View>
                            </View>
                        </>
                    )}
                </View>
            </View>
        )
    ) : (
        <>
            <StudentListComponent
                students={students}
                studentRegistrations={studentRegistrations}
                refetchRegistrations={getStudentRegistrationsAndLinkings}
                mobileRender={true}
                onDeletePress={handleDeletePress}
                deleteRegistrationId={deletingRegistrationId}
                isDeleting={isDeleting}
            />
            {notifyButton}
            {notifyButtonModal}
            {absenceForm}
        </>
    );
}

export default StudentList;
