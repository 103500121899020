import React, { useContext, useState } from 'react';
import Correct from '@Icon/Correct';
import LeftArrow from '@Icon/LeftArrow';
import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';

import { View, Text, Pressable } from 'react-native';
import { Divider } from 'react-native-paper';
import useTheme from '@Hooks/useTheme';
import i18n from '@I18n';
import styles from './styles';
import { format, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import DeviceContext from '@Contexts/DeviceContext';
import Button from '@Components/Button';
import { Checkmark } from '@Icon/index';
import { defaultTheme } from '@Hooks/useTheme';
import { Picker } from '@react-native-picker/picker';

interface IProps {
    header: string;
    firstChoicelabel: string;
    value: Date;
    initialSelection: number;
    setInitialSelection: (selection: number) => void;
    onDismiss: () => void;
    onDateChange: (value: Date | string) => void;
}

function calculate24Hour(hour: string, period: string) {
    let h;
    if (hour === '12') {
        h = period === 'am' ? 0 : 12;
    } else {
        h = Number(hour);
        if (period === 'pm') {
            h += 12;
        }
    }
    return h;
}

function getInitialHour(date: Date) {
    const hour = getHours(date);
    if (hour === 0) {
        return '12';
    } else if (hour > 12) {
        return (hour - 12).toString();
    } else {
        return hour.toString();
    }
}

function getInitialMinute(date: Date) {
    const minute = getMinutes(date);
    if (![0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].includes(minute)) {
        return '0';
    } else {
        return minute.toString();
    }
}

function getInitialPeriod(date: Date) {
    const hour = getHours(date);
    if (hour < 12) {
        return 'am';
    } else {
        return 'pm';
    }
}

const Choices: React.FC<IProps> = ({
    value,
    header,
    onDateChange,
    firstChoicelabel,
    onDismiss,
    initialSelection,
    setInitialSelection,
}) => {
    const [selection, setSelection] = useState<number>(initialSelection);
    const [date, setDate] = useState<Date>(value);
    const [dateSelected, setDateSelected] = useState(Boolean(initialSelection));
    const { isDesktop } = useContext(DeviceContext);
    const theme = useTheme();
    const [hourValue, setHourValue] = useState(getInitialHour(value));
    const [minuteValue, setMinuteValue] = useState(getInitialMinute(value));
    const [periodValue, setPeriodValue] = useState(getInitialPeriod(value));

    const handleHourValueChange = (itemValue: string) => {
        setHourValue(itemValue);
        const newDate = setHours(date, calculate24Hour(itemValue, periodValue));
        setDate(newDate);
        setDateSelected(true);
    };

    const handleMinuteValueChange = (itemValue: string) => {
        setMinuteValue(itemValue);
        const newDate = setMinutes(date, Number(itemValue));
        setDate(newDate);
        setDateSelected(true);
    };

    const handlePeriodValueChange = (itemValue: string) => {
        setPeriodValue(itemValue);
        const newDate = setHours(date, calculate24Hour(hourValue, itemValue));
        setDate(newDate);
        setDateSelected(true);
    };

    const pressSelectSpecificTime = () => {
        setSelection(1);
        setDateSelected(false);
    };

    const handleDateConfirmationPress = () => {
        if (dateSelected) {
            setInitialSelection(1);
            onDateChange(date);
        } else {
            setInitialSelection(0);
            onDateChange(firstChoicelabel);
        }
        onDismiss();
    };

    return (
        <Modal
            style={!isDesktop ? { height: '100%' } : styles.modalDesktop}
            header={
                <ModalHeader
                    header={header}
                    leftIcon={<LeftArrow />}
                    // rightIcon={<Checkmark fill={defaultTheme.colors.watermelon} />}
                    // onRightIconPress={handleDateConfirmationPress}
                    onDismiss={onDismiss}
                />
            }
            visible={true}
        >
            <View style={{ backgroundColor: 'white', height: '100%' }}>
                <View style={styles.choiceContainer}>
                    <Pressable
                        onPress={() => {
                            setSelection(0);
                            setDateSelected(false);
                            setDate(value);
                        }}
                        style={styles.choice}
                        android_ripple={{ color: useTheme().colors.disabled }}
                    >
                        <Text style={styles.choiceLabel}>{firstChoicelabel}</Text>
                        {selection === 0 && <Correct width={17} height={12} />}
                    </Pressable>
                    <Divider style={{ borderBottomColor: 'rgba(55, 54, 57, 0.5299)', borderWidth: 1 }} />
                    <Pressable
                        onPress={pressSelectSpecificTime}
                        style={styles.choice}
                        android_ripple={{ color: useTheme().colors.disabled }}
                    >
                        <Text style={styles.choiceLabel}>{i18n.t('specificTime')}</Text>
                        {selection === 1 && <Correct width={17} height={12} />}
                    </Pressable>
                    {selection === 1 && (
                        <>
                            <Text style={styles.specificTimeLabel}>{format(date, 'hh:mm a')}</Text>
                            <View style={styles.pickerContainer}>
                                <Picker
                                    style={styles.picker}
                                    itemStyle={styles.choiceLabel}
                                    selectedValue={hourValue}
                                    onValueChange={handleHourValueChange}
                                >
                                    <Picker.Item label='1' value='1' />
                                    <Picker.Item label='2' value='2' />
                                    <Picker.Item label='3' value='3' />
                                    <Picker.Item label='4' value='4' />
                                    <Picker.Item label='5' value='5' />
                                    <Picker.Item label='6' value='6' />
                                    <Picker.Item label='7' value='7' />
                                    <Picker.Item label='8' value='8' />
                                    <Picker.Item label='9' value='9' />
                                    <Picker.Item label='10' value='10' />
                                    <Picker.Item label='11' value='11' />
                                    <Picker.Item label='12' value='12' />
                                </Picker>
                                <Picker
                                    style={styles.picker}
                                    itemStyle={styles.choiceLabel}
                                    selectedValue={minuteValue}
                                    onValueChange={handleMinuteValueChange}
                                >
                                    <Picker.Item label='00' value='0' />
                                    <Picker.Item label='05' value='5' />
                                    <Picker.Item label='10' value='10' />
                                    <Picker.Item label='15' value='15' />
                                    <Picker.Item label='20' value='20' />
                                    <Picker.Item label='25' value='25' />
                                    <Picker.Item label='30' value='30' />
                                    <Picker.Item label='35' value='35' />
                                    <Picker.Item label='40' value='40' />
                                    <Picker.Item label='45' value='45' />
                                    <Picker.Item label='50' value='50' />
                                    <Picker.Item label='55' value='55' />
                                </Picker>
                                <Picker
                                    style={styles.picker}
                                    itemStyle={styles.choiceLabel}
                                    selectedValue={periodValue}
                                    onValueChange={handlePeriodValueChange}
                                >
                                    <Picker.Item label='AM' value='am' />
                                    <Picker.Item label='PM' value='pm' />
                                </Picker>
                            </View>
                        </>
                    )}
                    <View style={styles.confirmButtonContainer}>
                        <Button
                            leftIcon={<Checkmark fill={defaultTheme.colors.watermelon} />}
                            label={i18n.t('confirm')}
                            underlineColor={theme.colors.watermelon}
                            onPress={handleDateConfirmationPress}
                        />
                    </View>
                </View>
            </View>
        </Modal>
    );
};

export default Choices;
