import React from 'react';
import { Text, View, ViewStyle, StyleProp, Pressable } from 'react-native';
import styles from './styles';
import Checkbox from '@Components/SwitchInput/components/Checkbox';
import Avatar from '@Components/Avatar';

// TODO:  image uri and name
interface IProps {
    checked: boolean;
    name: string;
    picture?: string;
    studentId: string;
    style?: StyleProp<ViewStyle>;
    disabled?: boolean;
    onChecked?: (checked: boolean, studentId: string) => void;
}

const CheckBoxProfile: React.FC<IProps> = ({ studentId, picture, disabled, checked, name, style, onChecked }) => {
    const handleCheckboxPress = (checked: boolean) => {
        onChecked && onChecked(checked, studentId);
    };

    return (
        <Pressable style={style} onPress={() => !disabled && handleCheckboxPress(!checked)}>
            <View style={styles.conatiner}>
                <View style={styles.checkBox}>
                    <Checkbox disabled={disabled} checked={checked} onChange={handleCheckboxPress} />
                </View>
                <View>
                    <Avatar labelStyle={[styles.avatarText]} size={30} label={name} picture={picture} />
                </View>
                <View style={styles.nameContainer}>
                    <Text style={styles.name}>{name}</Text>
                </View>
            </View>
        </Pressable>
    );
};

export default CheckBoxProfile;
