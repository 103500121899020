import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconLogout = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.603.922H10V3.79H2.386v12.475H10v2.89H.603V.923ZM9.7 4.57 19.108 10l-9.407 5.431V4.57Z'
            fill='#373639'
        />
    </Svg>
);
export default SvgIconLogout;
