import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 22,
    },
    amountContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 32,
        minHeight: 60,
    },
    description: {
        ...typography.h5,
        marginTop: 16,
        marginBottom: 8,
    },
    subDescription: {
        ...typography.body2,
        marginBottom: 32,
    },
    modal: {
        height: 500,
        marginTop: 'auto',
    },
    modalDesktop: {
        margin: 'auto',
        width: 500,
        minHeight: 440,
        paddingBottom: 20,
        borderRadius: 8,
    },
    child: {
        flexBasis: '25%',
        padding: 4,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    buttonBarText: {
        ...typography.buttonLarge,
    },
    buttonBar: {
        height: 20,
        width: '100%',
        backgroundColor: defaultTheme.colors.persimmon,
    },
    buttonEmpty: {
        width: '100%',
        height: 12,
        borderBottomColor: defaultTheme.colors.textPrimary,
        borderBottomWidth: 1,
    },
    controlContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    feeTextContainer: {
        paddingVertical: 16,
    },
    feeText: {
        ...typography.body3,
    },
    newBalanceTextContainer: {
        marginVertical: 4,
    },
    newBalanceText: {
        ...typography.body3,
        textAlign: 'right',
    },
    errorLabelContainer: {},
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.error,
    },
    inputContainer: {
        paddingBottom: 20,
    },
    modalWebview: {
        height: '100%',
    },
});

export default styles;
