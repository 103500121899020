import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    errorLabelContainer: {
        borderColor: defaultTheme.colors.persimmon,
        flexDirection: 'row',
        borderWidth: 1,
        padding: 16,
        marginBottom: 16,
    },
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.error,
    },
    icon: {
        marginRight: 16,
    },
    alertMessage: {
        ...typography.body,
        flexShrink: 1,
    },
    helpLink: {
        borderBottomWidth: 1,
        borderBottomColor: defaultTheme.colors.backdrop,
    },
    message: {
        flexDirection: 'column',
    },
});
