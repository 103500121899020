import { ComponentProps, useMemo, useState } from 'react';
import { default as NativePicker } from 'react-native-picker-select';
import { Platform, Text, TextStyle, View, ViewStyle } from 'react-native';
import { styles } from './styles';
import { IconButton } from 'react-native-paper';

export type PickerProps = ComponentProps<typeof NativePicker> & {
    inputLabel?: string;
    style?: ViewStyle;
    placeHolderStyle?: TextStyle;
    error?: boolean;
};

function Picker(props: PickerProps) {
    const [open, setOpen] = useState(false);
    const inputWebStyle = useMemo(
        () => Object.assign({}, props.error && styles.inputWebError, open && styles.inputWebErrorOpen),
        [props.error, open]
    );

    const viewContainerStyle = useMemo(() => props.error && styles.viewContainerError, [props.error]);

    const isMobile = useMemo(() => Platform.OS !== 'web', []);

    return (
        <View style={styles.container}>
            <NativePicker
                {...props}
                useNativeAndroidPickerStyle={false}
                Icon={() => isMobile && <IconButton icon='chevron-down' />}
                style={{
                    viewContainer: {
                        ...styles.inputContainer,
                        ...props.style,
                        ...viewContainerStyle,
                        ...{ outlineColor: 'none' },
                    },
                    iconContainer: isMobile
                        ? {
                              top: 5,
                              right: -8,
                          }
                        : undefined,
                    inputWeb: { ...styles.input, ...inputWebStyle },
                    inputIOS: { ...styles.input, marginLeft: 2, ...inputWebStyle },
                    inputAndroid: { ...styles.input, marginLeft: 4, ...inputWebStyle },
                    inputAndroidContainer: { ...styles.inputContainer, ...props.style, ...viewContainerStyle },
                    placeholder: props.error ? { color: 'red', ...props.placeHolderStyle } : props.placeHolderStyle,
                }}
                pickerProps={{
                    onFocus: () => setOpen(true),
                    onBlur: () => setOpen(false),
                }}
            />
            <View style={styles.labelContainer} accessibilityLabel={props.inputLabel}>
                <Text style={[styles.label, props.error && styles.labelError]}>{props.inputLabel}</Text>
            </View>
        </View>
    );
}

export default Picker;
