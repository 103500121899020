import Dashboard from '@Screens/Dashboard';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Profile from '@Screens/Profile/index';
import Balance from '@Screens/Profile/Balance';
import ContactInformation from '@Screens/Profile/ContactInformation';
import ContactPreferences from '@Screens/Profile/ContactPreferences';
import SecurityPreferences from '@Screens/Profile/SecurityPreferences';
import i18n from '@I18n/index';
import ContactPreferencesDetail from '@Screens/Profile/ContactPreferencesDetail';
import { RootStackParamList } from '@App/rootNavigation';
import { IconButton } from 'react-native-paper';
import DeviceContext from '@Contexts/DeviceContext';
import { useContext } from 'react';
import { withIntro } from '../Intro';
import NewUser from '@Screens/Dashboard/NewUser';
import { useStudentListEmpty } from '@Hooks/useStudentListEmpty';
import Terms from '@Screens/Auth/Terms';
import Privacy from '@Screens/Auth/Privacy';

// type HomeStackParamList = {
//     Dashboard: undefined;
//     Profile: undefined;
//   };

function Home() {
    // const HomeStack = createNativeStackNavigator<HomeStackParamList>();
    const HomeStack = createNativeStackNavigator<RootStackParamList>();
    const { isDesktop } = useContext(DeviceContext);

    const { hasStudents } = useStudentListEmpty();

    return (
        <HomeStack.Navigator initialRouteName={'Dashboard'}>
            <HomeStack.Screen
                name='SecurityPreferences'
                component={SecurityPreferences}
                options={{
                    title: i18n.t('signInAndSecurity'),
                    headerShown: !isDesktop,
                }}
            />
            <HomeStack.Screen name='Profile' component={Profile} options={{ headerShown: !isDesktop }} />
            <HomeStack.Screen
                name='ContactInformation'
                component={ContactInformation}
                options={{ headerShown: !isDesktop }}
            />
            <HomeStack.Screen
                name='Balance'
                component={Balance}
                options={{
                    title: i18n.t('accountBalance'),
                    headerRight: () => (
                        <IconButton icon='dots-vertical' onPress={() => console.log('Page not loaded')} />
                    ),
                    headerShown: !isDesktop,
                }}
            />
            <HomeStack.Screen name='terms' component={Terms} options={{ headerShown: !isDesktop, headerTitle: '' }} />
            <HomeStack.Screen
                name='privacy'
                component={Privacy}
                options={{ headerShown: !isDesktop, headerTitle: '' }}
            />
            <HomeStack.Screen
                name='ContactPreferences'
                component={ContactPreferences}
                options={{
                    title: i18n.t('contactPrefs'),
                    headerShown: !isDesktop,
                }}
            />

            <HomeStack.Screen
                name='Dashboard'
                component={hasStudents ? Dashboard : NewUser}
                options={{
                    headerShown: false,
                }}
            />

            {hasStudents && (
                <HomeStack.Screen
                    name='ContactPreferencesDetail'
                    component={ContactPreferencesDetail}
                    options={({ route }) => ({
                        title: i18n.t(route.params?.preference) || i18n.t('contactPrefs'),
                        headerShown: !isDesktop,
                    })}
                />
            )}
        </HomeStack.Navigator>
    );
}

export default withIntro(Home);
