import { useMemo } from 'react';
import { Pressable, ViewStyle } from 'react-native';
import { format } from 'date-fns';
import { DatePickerModal } from 'react-native-paper-dates';
import useToggle from '@Hooks/useToggle';
import TextInput from '@Components/TextInput';
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar';
import { isDate } from 'lodash';
import 'intl';
import 'intl/locale-data/jsonp/en';

interface IProps {
    disabled?: boolean;
    label: string;
    maximumDate?: Date;
    minimumDate?: Date;
    disabledDates?: Date[];
    onDateChange: (date: Date) => void;
    style?: ViewStyle;
    value?: Date | string;
}
const DatePicker: React.FC<IProps> = ({
    value,
    minimumDate,
    maximumDate,
    disabledDates,
    label,
    disabled,
    onDateChange,
    ...rest
}) => {
    const { show, toggle } = useToggle(false);

    const handleDateChange = (params: { date: CalendarDate }) => {
        toggle();
        isDate(params.date) && onDateChange(params.date);
    };

    const date = useMemo(() => {
        if (value) {
            if (value.toString().includes('Z')) {
                return new Date(value.toString().slice(0, value.toString().length - 1));
            } else {
                return new Date(value.toString());
            }
        } else {
            return new Date();
        }
    }, [value]);

    return (
        <>
            <Pressable onPress={() => toggle()}>
                <TextInput
                    disabled={disabled}
                    mode='outlined'
                    autoFocus={false}
                    showSoftInputOnFocus={false}
                    label={label}
                    value={format(date, 'E LLL dd y')}
                    onPressOut={() => toggle()}
                    {...rest}
                />
            </Pressable>
            {show && (
                <DatePickerModal
                    visible={show}
                    onDismiss={toggle}
                    date={date}
                    onConfirm={handleDateChange}
                    label='Pick A Date'
                    locale='en'
                    mode='single'
                    validRange={{ startDate: minimumDate, endDate: maximumDate, disabledDates: disabledDates }}
                />
            )}
        </>
    );
};

export default DatePicker;
