import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    choiceContainer: {
        paddingHorizontal: 20,
    },
    choice: {
        height: 48,
        flexDirection: 'row',
        paddingRight: 4,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    choiceLabel: {
        ...typography.body,
    },
    modalDesktop: {
        minHeight: 400,
        minWidth: 500,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
    },
    specificTimeLabel: {
        ...typography.body,
        marginBottom: 16,
    },
    confirmButtonContainer: {
        alignItems: 'flex-end',
        width: '100%',
    },
    pickerContainer: {
        flexDirection: 'row',
        marginTop: 24,
        marginBottom: 12,
    },
    picker: {
        flexGrow: 1,
        marginRight: 12,
    },
});

export default styles;
