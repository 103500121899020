import i18n from '@I18n';
import withLayout from '@Screens/common';
import { Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { styles } from './styles';
import terms from './terms.json';
import HtmlView from '@Components/HtmlView/HtmlView';

function Terms() {
    return (
        <ScrollView keyboardShouldPersistTaps='handled'>
            {withLayout(
                <View style={styles.container}>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('terms')}>
                            {i18n.t('terms')}
                        </Text>
                    </View>
                    <HtmlView htmlString={terms.content} />
                </View>
            )}
        </ScrollView>
    );
}

export default Terms;
