import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import i18n from '@I18n';

import Picker from '@Components/Picker';
import TextInput from '@Components/TextInput';
import { Accessor, lookupToOptions, phoneMask } from '@Utilities';
import { useGetRelationshipsQuery, useGetLanguagesQuery, BasicLookup } from '@Redux/services/LookupApi';

import { styles } from '../../../../styles';
import { ManagePickerProps, ManageFieldPropFactory, TextFieldPropFactory, ManageTextProps } from '../../../../types';

interface IProps {
    accessor: Accessor;
    manageFieldPropFactory: ManageFieldPropFactory;
    textFieldPropFactory: TextFieldPropFactory;
}

const ContactInfo: FC<IProps> = ({ accessor, manageFieldPropFactory, textFieldPropFactory }) => {
    const { data: languages } = useGetLanguagesQuery();
    const languageOptions = useMemo(() => lookupToOptions<BasicLookup>(languages), [languages]);

    const { data: relationships } = useGetRelationshipsQuery();
    const relationshipOptions = useMemo(() => lookupToOptions<BasicLookup>(relationships), [relationships]);

    return (
        <View>
            <Picker
                {...manageFieldPropFactory<ManagePickerProps>(
                    [...accessor, 'RelationshipToStudent'],
                    'onValueChange',
                    'value'
                )}
                inputLabel={i18n.t('relationshipToStudent')}
                items={relationshipOptions}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterContactPhoneNumberPrimary'))}
                {...manageFieldPropFactory<ManageTextProps>(
                    [...accessor, 'PrimaryPhoneNumber'],
                    'onChangeText',
                    'value'
                )}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterContactPhoneNumberMobile'))}
                {...manageFieldPropFactory<ManageTextProps>(
                    [...accessor, 'MobilePhoneNumber'],
                    'onChangeText',
                    'value'
                )}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterContactPhoneNumberWork'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'WorkPhoneNumber'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterContactPhoneNumberWorkExtension'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'WorkExtension'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('firstName'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'FirstName'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('lastName'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'Surname'], 'onChangeText', 'value')}
            />

            <Picker
                inputLabel={i18n.t('studentRegisterContactLanguage')}
                {...manageFieldPropFactory<ManagePickerProps>([...accessor, 'Language'], 'onValueChange', 'value')}
                items={languageOptions}
                style={styles.picker}
            />
        </View>
    );
};

export const ContactInfoValidator = (accessor: Accessor) => {
    return [
        { accessorPath: [...accessor, 'RelationshipToStudent'], validator: Boolean },
        {
            accessorPath: [...accessor, 'PrimaryPhoneNumber'],
            validator: (phone: string) => {
                if (phone?.match(/\d{3}-\d{3}-\d{4}/)) {
                    return true;
                }
                return false;
            },
        },
        { accessorPath: [...accessor, 'FirstName'], validator: Boolean },
        { accessorPath: [...accessor, 'Surname'], validator: Boolean },
        { accessorPath: [...accessor, 'Language'], validator: Boolean },
        {
            accessorPath: [...accessor, 'WorkPhoneNumber'],
            validator: (phone: string) => {
                if (!phone || phone.match(/\d{3}-\d{3}-\d{4}/)) {
                    return true;
                }
                return false;
            },
        },
        {
            accessorPath: [...accessor, 'MobilePhoneNumber'],
            validator: (phone: string) => {
                if (!phone || phone.match(/\d{3}-\d{3}-\d{4}/)) {
                    return true;
                }
                return false;
            },
        },
    ];
};

export const ContactInfoMasks = (accessor: Accessor) => {
    return [
        { accessorPath: [...accessor, 'WorkPhoneNumber'], mask: phoneMask },
        { accessorPath: [...accessor, 'MobilePhoneNumber'], mask: phoneMask },
        { accessorPath: [...accessor, 'PrimaryPhoneNumber'], mask: phoneMask },
    ];
};

export default ContactInfo;
