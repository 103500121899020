import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconChevronRight = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m7 15.054 3 1.85 8-4.933-8-4.931-3 1.85 5 3.081-5 3.083Z'
            fill='#373639'
        />
    </Svg>
);
export default SvgIconChevronRight;
