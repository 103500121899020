import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

const styles = StyleSheet.create({
    pressed: {
        opacity: 0.75,
    },
    listItem: {
        marginVertical: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    topContainer: {
        justifyContent: 'flex-start',
    },
    bottomContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    label: {
        ...typography.h5,
        marginBottom: 4,
    },
    subtext: {
        ...typography.body2,
        marginBottom: 4,
    },
    iconContainer: {
        width: 50,
        alignSelf: 'flex-start',
    },
    icon: {
        width: 32,
        height: 32,
    },
    chevron: {
        width: 20,
    },
});

export default styles;
