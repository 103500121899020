import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconSchoolBoard = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.467 29.534V7.8H7.8V2.467h16.4v10.666h5.334v16.4h-12.2v-6.866h-2.667v6.866h-12.2Zm9.409-21.977 1.45-1.055 1.452 1.055-.554 1.706H12.43l-.554-1.706Zm5.333 0 1.451-1.055 1.452 1.055-.555 1.706h-1.794l-.554-1.706Zm0 5.333 1.451-1.054 1.452 1.054-.555 1.706h-1.794l-.554-1.706Zm1.451 4.279-1.45 1.055.553 1.706h1.794l.555-1.706-1.452-1.055ZM7.994 11.836 6.542 12.89l.555 1.706H8.89l.554-1.706-1.451-1.054Zm3.882 1.054 1.45-1.054 1.452 1.054-.554 1.706H12.43l-.554-1.706ZM7.994 17.17l-1.452 1.055.555 1.706H8.89l.554-1.706-1.451-1.055Zm14.548 1.055 1.452-1.055 1.451 1.055-.554 1.706h-1.794l-.555-1.706Zm-9.215-1.055-1.451 1.055.554 1.706h1.794l.554-1.706-1.451-1.055Zm-6.785 6.388 1.452-1.055 1.451 1.055-.554 1.706H7.097l-.555-1.706Zm17.452-1.055-1.452 1.055.555 1.706h1.794l.554-1.706-1.451-1.055Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgIconSchoolBoard;
