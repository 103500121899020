import { StyleSheet } from 'react-native';
import { defaultTheme, fonts, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    row1: {
        ...typography.buttonSmall,
        color: defaultTheme.colors.textPrimary,
        fontFamily: fonts.MontserratBold,
        fontSize: 12,
        minWidth: 60,
    },
    row2: {
        ...typography.buttonSmall,
        color: defaultTheme.colors.textPrimary,
        fontSize: 12,
        minWidth: 60,
    },
    timeLabelContainer: {
        borderTopColor: 'black',
        paddingTop: 4,
        borderTopWidth: 2,
        marginRight: 25,
    },
    timeLabelOfferStatusExpired: {
        color: defaultTheme.colors.disabled,
    },
    timeLabelContainerOfferStatusInvited: {
        borderTopColor: defaultTheme.colors.persimmon,
    },
    timeLabelContainerOfferStatusExpired: {
        borderTopColor: defaultTheme.colors.disabled,
    },
});
