import React from 'react';
import { Avatar as PaperAvatar } from 'react-native-paper';
import { ComponentProps } from 'react';
import { getInitials, stringToColor } from '@Utilities/index';

export type AvatarProps = ComponentProps<typeof PaperAvatar.Text> & {
    picture?: string;
};

const Avatar = ({ label, picture, style, ...rest }: AvatarProps) => {
    if (picture) {
        return <PaperAvatar.Image {...rest} style={style} source={{ uri: `data:image/png;base64,${picture}` }} />;
    }

    return (
        <PaperAvatar.Text
            style={[{ backgroundColor: stringToColor(label) }, style]}
            labelStyle={{ fontWeight: '800' }}
            {...rest}
            label={getInitials(label)}
        />
    );
};

export default Avatar;
