import useTheme from '@Hooks/useTheme';
import SvgPlus from '@Icon/Plus';
import SvgBuilding6 from '@Svg/Building6';
import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import RegisterChild from '@Screens/Student/components/RegisterChild';
import LinkStudent from '@Screens/Student/components/LinkChild';
import { styles } from './styles';
import i18n from '@I18n';
import { useGetParentQuery } from '@Redux/services/parent';
import { getGreetingByTimeofDay } from '@Utilities';
import { Brush } from '@Icon/index';
import DeviceContext from '@Contexts/DeviceContext';

export const Empty = () => {
    const theme = useTheme();
    const { isDesktop } = useContext(DeviceContext);
    const { data: parent } = useGetParentQuery();

    return (
        <View style={styles.container}>
            <View style={styles.messageContianer}>
                <View style={{ maxWidth: '100%' }}>
                    <Text style={styles.header}>
                        {getGreetingByTimeofDay()} {parent?.FirstName}
                    </Text>
                    <Text style={styles.header}>{i18n.t('getStarted')}</Text>
                    <Text style={styles.body}>{i18n.t('addRegisterStudent')}</Text>
                    <View style={[styles.buttonMargin, { maxWidth: '100%' }]}>
                        <LinkStudent
                            buttonColor={theme.colors.watermelon}
                            buttonLabelStyle={styles.buttonLabel}
                            buttonLabel={i18n.t('emptyLinkChildCta')}
                            buttonLeftIcon={<SvgPlus />}
                        />
                    </View>
                    <View style={{ maxWidth: '100%' }}>
                        <RegisterChild
                            buttonLabelStyle={styles.buttonLabel}
                            buttonLabel={i18n.t('emptyRegisterChildCta')}
                            buttonColor={theme.colors.grape}
                            buttonLeftIcon={<Brush fill={theme.colors.grape} />}
                        />
                    </View>
                </View>
                {isDesktop && (
                    <View>
                        <SvgBuilding6 />
                    </View>
                )}
            </View>
        </View>
    );
};

export default Empty;
