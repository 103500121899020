import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        opacity: 0,
        zIndex: 500,
    },
    innerContainer: {
        padding: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        backgroundColor: defaultTheme.colors.lycheeDark,
    },
    messageContainer: {
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    message: {
        ...typography.body2,
        color: '#FFFFFF',
    },
});

export default styles;