import { useState } from 'react';
import { View, Text, Pressable } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import i18n from '@I18n';
import { styles } from './styles';
import { ArrowRight } from '@Icon';
import TextInput from '@Components/TextInput';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import { useSignUpMutation } from '@Redux/services/account';
import { withLayoutSm } from '@Screens/common';

type SignUpScreenProp = NativeStackScreenProps<RootStackParamList, 'signup'>;

function SignUpScreen({ navigation }: SignUpScreenProp) {
    const [email, setEmail] = useState('');
    const [signUp] = useSignUpMutation();
    const [errorMessage, setErrorMessage] = useState('');

    async function handleSignUp() {
        if (!email || !email.match(/^\S+@\S+\.\S+$/)) {
            setErrorMessage(i18n.t('invalidEmail'));
            return;
        }
        try {
            await signUp({ email }).unwrap();
            setErrorMessage('');
            navigation.navigate('confirm', { email });
        } catch (e) {
            setErrorMessage(e?.data?.Message || e?.data || 'Unknown error');
        }
    }

    return (
        <ScrollView keyboardShouldPersistTaps='handled'>
            {withLayoutSm(
                <View style={styles.container}>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('signUp1')}>
                            {i18n.t('signUp1')}
                        </Text>
                    </View>
                    <View style={styles.fieldContainer}>
                        <TextInput
                            label={i18n.t('email')}
                            accessibilityLabel={i18n.t('email')}
                            keyboardType='email-address'
                            mode='outlined'
                            value={email}
                            onChangeText={(val) => setEmail(val)}
                            error={Boolean(errorMessage)}
                        />
                        {Boolean(errorMessage) && (
                            <View style={styles.errorLabelContainer}>
                                <Text style={styles.errorLabel}>{errorMessage}</Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.controlContainer}>
                        <Pressable testID='sign-up-button' onPress={handleSignUp}>
                            <View style={styles.buttonContainer}>
                                <View style={styles.signUpTextContainer}>
                                    <Text style={styles.signUp} accessibilityLabel={i18n.t('signUp2')}>
                                        {i18n.t('signUp2')}
                                    </Text>
                                </View>
                                <ArrowRight style={styles.arrowRight} />
                            </View>
                        </Pressable>
                    </View>
                </View>
            )}
        </ScrollView>
    );
}

export default SignUpScreen;
