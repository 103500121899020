import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    cta: {
        ...typography.buttonMedium,
    },
    form: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    formGroup: {
        marginBottom: 20,
    },
    picker: {
        marginBottom: 12,
    },
    screen: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    textInput: {
        marginBottom: 8,
    },
    desktopModal: {
        height: '90%',
        minWidth: 600,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
        overflow: 'hidden',
    },
});
