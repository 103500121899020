import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgPerson = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.823 1.9h5.313l2.657 4.602-2.657 4.601H9.823L7.167 6.502 9.823 1.9ZM4.438 13.507h16.124v9.987H4.438v-9.987Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgPerson;
