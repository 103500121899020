import React, { useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { IconButton } from 'react-native-paper';
import styles from './styles';
interface IProps {
    icon: React.ReactNode;
    header: string;
    body?: React.ReactNode;
    onPress?: () => void;
    children: React.ReactNode;
    showIcon?: boolean;
}

const Accordion: React.FC<IProps> = ({ icon, header, body, showIcon, onPress, children }) => {
    const [expanded, setExpanded] = useState(false);

    const handlePress = () => {
        setExpanded(!expanded);
        onPress && onPress();
    };

    return (
        <>
            <Pressable onPress={handlePress}>
                <View style={[styles.container, !expanded && styles.containerExpanded]}>
                    <View style={{ flexDirection: 'row', width: '90%' }}>
                        <View style={styles.icon}>{icon}</View>
                        <View>
                            <Text style={styles.header}>{header}</Text>
                            {body && body}
                        </View>
                    </View>
                    {showIcon && <IconButton icon={expanded ? 'chevron-up' : 'chevron-down'} onPress={handlePress} />}
                </View>
            </Pressable>
            {expanded && showIcon && (
                <View style={styles.actionContainer}>
                    <View style={styles.iconPlaceHolder}></View>
                    {children}
                </View>
            )}
        </>
    );
};

export default Accordion;
