import i18n from '@I18n';

export const HELP_URL = 'https://askuc.ucdsb.ca/MyFamilyRoom/';

export const ABSENT_REASONS: Record<string, string> = {
    '1': 'Slept In',
    '2': 'Family',
    '19': 'Flu',
    B: 'Bus/Car Related',
    I: 'Ill/Injury',
    K: 'Funeral/Wake',
    M: 'Medical Dental',
    N: 'Parent Approved/Note',
    P: 'Personal Appointment',
    R: 'Religious Reasons',
    V: 'Vacation/Out of Town',
    W: 'Bad Weather',
    L: 'Late',
    T: 'Truant',
    S: 'SchoolActivity',
    E: 'Sports',
};

export const socialProviders = {
    google: {
        clientId:
            process.env.GOOGLE_CLIENT_ID || '169172966390-f6c8sv67ee34q3e2b6kfff7uepc7c7os.apps.googleusercontent.com',
        tokenUrl: 'https://www.googleapis.com/oauth2/v1/tokeninfo',
    },
    facebook: {
        clientId: process.env.FACEBOOK_CLIENT_ID || '1434150713485753',
        tokenUrl: 'https://graph.facebook.com/me',
    },
    outlook: {
        clientId: process.env.OUTLOOK_CLIENT_ID || '00000000401256F9',
        tokenUrl: 'https://apis.live.net/v5.0/me',
    },
    apple: {
        clientId: process.env.APPLE_CLIENT_ID || 'ca.ucdsb.myfamilyroom',
        tokenUrl: 'https://appleid.apple.com/auth/token',
    },
};

export const provinceList = [
    { value: '951030002', label: i18n.t('provinceBC'), shortCode: 'BC' },
    { value: '951030000', label: i18n.t('provinceON'), shortCode: 'ON' },
    { value: '951030001', label: i18n.t('provinceAB'), shortCode: 'AB' },
    { value: '951030003', label: i18n.t('provinceMB'), shortCode: 'MB' },
    { value: '951030011', label: i18n.t('provinceSK'), shortCode: 'SK' },
    { value: '951030012', label: i18n.t('provinceYT'), shortCode: 'YT' },
    { value: '951030008', label: i18n.t('provinceNU'), shortCode: 'NU' },
    { value: '951030006', label: i18n.t('provinceNT'), shortCode: 'NT' },
    { value: '951030010', label: i18n.t('provinceQC'), shortCode: 'QC' },
    { value: '951030007', label: i18n.t('provinceNS'), shortCode: 'NS' },
    { value: '951030009', label: i18n.t('provincePE'), shortCode: 'PE' },
    { value: '951030005', label: i18n.t('provinceNL'), shortCode: 'NL' },
];

export const RegistrationStatuses = {
    Submitted: 951030001,
    Rejected: 951030002,
    DocumentsRequired: 951030003,
    Approved: 951030004,
    Deleted: 951030005,
    Expired: 951030006,
    Archived: 951030007,
};

export const LinkStatuses = {
    Submitted: 951030000,
    Approved: 951030001,
    Rejected: 951030002,
    Deleted: 951030004,
};

export const AccountLockedHelpURL = 'https://askuc.ucdsb.ca/MyFamilyRoom/ViewKnownError.aspx?knownErrorId=986';
