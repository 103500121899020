import { Pressable, Text, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import styles from './styles';

// @TODO consider better method to expose this sub-component
import Switch from '@Components/SwitchInput/components/Switch';

export interface ListButtonProps {
    icon: React.FC<SvgProps>;
    text: string;
    subtext?: string;
    checked: boolean;
    disabled?: boolean;
    variant?: 'small' | 'medium' | 'large';
    onChange?: (checked: boolean) => void;
}

function ListButton({ icon: Icon, text, subtext, checked, disabled, variant, onChange }: ListButtonProps) {
    function itemPressHandler() {
        !disabled && onChange && onChange(!checked);
    }

    const iconContainerStyle = [styles.iconContainer, variant === 'large' && styles.iconContainerLarge];

    const iconStyle = [styles.icon, variant === 'large' && styles.iconLarge, variant === 'small' && styles.iconSmall];

    const textLabelStyle = [styles.label, variant === 'small' && styles.labelSmall];

    return (
        <Pressable onPress={itemPressHandler} style={({ pressed }) => pressed && styles.pressed}>
            <View style={styles.listItem}>
                <View style={iconContainerStyle}>
                    <Icon style={iconStyle} />
                </View>
                <View style={styles.labelContainer}>
                    <Text style={textLabelStyle}>{text}</Text>
                    <Text style={styles.subtext}>{subtext}</Text>
                </View>
                <Switch disabled={disabled} checked={checked} onChange={onChange} />
            </View>
        </Pressable>
    );
}

export default ListButton;
