import React, { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import Modal from '@Components/Modal';
import styles from './styles';
import i18n from '@I18n';
import { ActivityIndicator } from 'react-native-paper';
import { defaultTheme } from '@Hooks/useTheme';
import Button from '@Components/Button';
import ArrowRight from '@Icon/ArrowRight';
import TextInput from '@Components/TextInput';
import Picker from '@Components/Picker';
import ModalHeader from '@Components/ModalHeader';
import DeviceContext from '@Contexts/DeviceContext';
import { useRequestCashoutMutation } from '@Redux/services/CashoutApi';
import { useGetParentQuery } from '@Redux/services/parent';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import AlertBox from '@Components/AlertBox';

interface IProps {
    onDismiss: () => void;
    show: boolean;
    balance: number;
    onSubmit: () => void;
}

const preferenceList = [
    { value: 'paypal', label: 'PayPal' },
    { value: 'cheque', label: 'Cheque' },
];

function getPrefType(preference: string) {
    switch (preference) {
        case 'cheque':
            return 3;
        default:
            return 1;
    }
}
const RefundModal: React.FC<IProps> = ({ show, balance, onDismiss, onSubmit }) => {
    const [requestCashout] = useRequestCashoutMutation();
    const { data: parent } = useGetParentQuery();
    const [preference, setPreference] = useState('paypal');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [availability, setAvailability] = useState('');
    const [email, setEmail] = useState('');
    const [mailingAddress, setMailingAddress] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { isDesktop } = useContext(DeviceContext);

    function initialize() {
        setPreference('paypal');
        setName(parent?.FirstName || '');
        setPhone(parent?.MobilePhone || '');
        setAvailability('');
        setEmail('');
        setMailingAddress('');
    }

    useEffect(() => {
        initialize();
    }, []);

    function prepareCashoutData() {
        const prefType = getPrefType(preference);
        return {
            Type: prefType,
            PhoneNumber: phone,
            BestTimeToReach: availability,
            PayPalEmail: email,
            MailingAddress: mailingAddress,
        };
    }

    async function handleSubmit() {
        if (preference === 'paypal' && (!email || !email.match(/^\S+@\S+\.\S+$/))) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false);
        }
        setIsSubmitting(true);
        const data = prepareCashoutData();
        try {
            await requestCashout(data).unwrap();
            onSubmit();
        } catch (e) {
            setErrorMessage(e?.data?.ExceptionMessage || 'Error, please try again later');
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <Modal
            style={isDesktop ? styles.modalDesktop : styles.modal}
            visible={show}
            header={<ModalHeader onDismiss={onDismiss} header={i18n.t('requestCashout')} />}
        >
            <KeyboardAwareScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={styles.alert}>
                    <AlertBox
                        show={balance === 0}
                        isError={true}
                        message={i18n.t("cashoutError")}
                    />
                </View>
                {balance > 0 && <Text style={styles.subDescription}>{i18n.t('eWalletClose')}</Text>}
                <View>
                    <Picker
                        inputLabel={i18n.t('preference')}
                        value={preference}
                        onValueChange={(val) => setPreference(val)}
                        items={preferenceList}
                        placeholder={{}}
                    />
                    <TextInput
                        label={i18n.t('name')}
                        value={name}
                        mode='outlined'
                        accessibilityLabel={i18n.t('name')}
                        autoCorrect={false}
                        autoCapitalize='words'
                        onChangeText={(val) => setName(val)}
                    />
                    <TextInput
                        label={i18n.t('phone')}
                        value={phone}
                        mode='outlined'
                        accessibilityLabel={i18n.t('phone')}
                        keyboardType='phone-pad'
                        onChangeText={(val) => setPhone(val)}
                    />
                    <TextInput
                        label={i18n.t('availability')}
                        value={availability}
                        mode='outlined'
                        accessibilityLabel={i18n.t('availability')}
                        autoCorrect={false}
                        onChangeText={(val) => setAvailability(val)}
                    />
                    {preference === 'paypal' && (
                        <TextInput
                            label={`PayPal ${i18n.t('email')}`}
                            value={email}
                            mode='outlined'
                            keyboardType='email-address'
                            accessibilityLabel={`PayPal ${i18n.t('email')}`}
                            autoCorrect={false}
                            autoCapitalize='none'
                            onChangeText={(val) => setEmail(val)}
                            error={emailError}
                        />
                    )}
                    {preference === 'cheque' && (
                        <TextInput
                            label={i18n.t('mailingAddress')}
                            value={mailingAddress}
                            mode='outlined'
                            accessibilityLabel={i18n.t('mailingAddress')}
                            autoCorrect={false}
                            autoCapitalize='none'
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={(val) => setMailingAddress(val)}
                        />
                    )}
                    {Boolean(errorMessage) && (
                        <Text style={styles.errorLabel}>{errorMessage}</Text>
                    )}
                </View>
                {balance > 0 && (
                    <View style={styles.controlContainer}>
                        <View>
                            <Button
                                onPress={handleSubmit}
                                leftIcon={isSubmitting ? <ActivityIndicator /> : <ArrowRight />}
                                label={i18n.t('cashout')}
                                underlineColor={defaultTheme.colors.watermelon}
                            />
                        </View>
                    </View>
                )}
            </KeyboardAwareScrollView>
        </Modal>
    );
};

export default RefundModal;
