import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    content: {
        flexDirection: 'row',
    },
    textContainer: {
        marginLeft: 16,
    },
    warning: {
        ...typography.body2,
    },
    button: {
        marginRight: 32,
    },
});
