import { RootStackParamList } from '@App/rootNavigation';
import { useEffect, useState } from 'react';
import Button from '@Components/Button';
import Tabs from '@Components/Tabs';
import DeviceContext from '@Contexts/DeviceContext';
import useTheme, { typography } from '@Hooks/useTheme';
import i18n from '@I18n';
import SvgAlarm from '@Icon/Alarm';
import SvgNeutralFace from '@Icon/NeutralFace';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useGetStudentsQuery } from '@Redux/services/parent';
import { useGetStudentDetailsQuery } from '@Redux/services/student';
import withLayout from '@Screens/common';
import { compact } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import AbsencesList from '../Absence';
import Attendance from '../Attendence';
import ConsentForms from '../ConsentForms';
import Medical from '../Medical';
import Personal from '../Personal';
import School from '../School';
import Transportation from '../Transportation';
import styles from './styles';
import AbsenceForm from '@Screens/Dashboard/Absence/Planned';
import HeaderTitle from './HeaderTitle';

type StudentProfileScreenProp = NativeStackScreenProps<RootStackParamList, 'StudentProfile'>;

function SectionHeader({ label, actions }: { label: string; actions?: React.ReactNode }) {
    return (
        <View style={styles.sectionHeaderContainer}>
            <Text style={styles.sectionHeaderTitle}>{label}</Text>
            <View>{actions}</View>
        </View>
    );
}

const StudentProfileScreen: React.FC<StudentProfileScreenProp> = ({ route, navigation }) => {
    const studentId = route.params?.studentId;
    const { data: students } = useGetStudentsQuery();
    const { data: studentDetails } = useGetStudentDetailsQuery({ studentId });
    const { isDesktop } = useContext(DeviceContext);
    const [showAbsenceForm, setShowAbsenceForm] = useState(false);
    const [isReportingLate, setIsReportingLate] = useState(false);

    function handleLate() {
        setIsReportingLate(true);
        setShowAbsenceForm(true);
    }
    function handleAbsence() {
        setIsReportingLate(false);
        setShowAbsenceForm(true);
    }

    const theme = useTheme();
    const tabs = useMemo(
        () => [
            {
                label: i18n.t('absence'),
                component: <AbsencesList studentId={studentId} />,
            },
            {
                label: i18n.t('attendanceRecord'),
                component: <Attendance studentId={studentId} />,
            },
            {
                label: i18n.t('school'),
                component: <School studentId={studentId} />,
            },
            {
                label: i18n.t('consentForms'),
                component: <ConsentForms studentId={studentId} />,
            },
            {
                label: i18n.t('bus'),
                component: <Transportation studentId={studentId} style={styles.itemMargin} />,
            },
            {
                label: i18n.t('personal'),
                component: <Personal studentId={studentId} />,
            },
            {
                label: i18n.t('medical'),
                component: <Medical studentId={studentId} style={styles.itemMargin} />,
            },
        ],
        [studentId]
    );

    useEffect(() => {
        if (!students) {
            return;
        }
        const student = students.find((i) => i.StudentId === studentId);
        if (!student) {
            return;
        }
        navigation.setOptions({
            headerTitle: () => <HeaderTitle firstName={student.FirstName} photo={student.PhotoBase64} />,
        });
    }, [students]);

    return isDesktop ? (
        withLayout(
            <View style={{ height: '100%', flexDirection: 'row' }}>
                <ScrollView style={{ flexBasis: '70%', paddingHorizontal: 16, paddingBottom: 32 }}>
                    <Text style={typography.b2}>Preferred name</Text>
                    <Text style={[typography.h0, { marginBottom: 32 }]}>
                        {compact([
                            studentDetails?.LegalFirst,
                            studentDetails?.MiddleName,
                            studentDetails?.LegalLast,
                        ]).join(' ')}
                    </Text>
                    <SectionHeader
                        label={i18n.t('absences')}
                        actions={
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Button
                                    label={i18n.t('reportLateButton')}
                                    leftIcon={<SvgAlarm />}
                                    underlineColor={theme.colors.persimmon}
                                    style={{ marginRight: 24 }}
                                    onPress={handleLate}
                                />
                                <Button
                                    label={i18n.t('addAbsencedButton')}
                                    leftIcon={<SvgNeutralFace />}
                                    underlineColor={theme.colors.lychee}
                                    onPress={handleAbsence}
                                />
                            </View>
                        }
                    />

                    <AbsencesList studentId={studentId} />

                    <SectionHeader label={i18n.t('attendanceRecord')} />
                    <Attendance studentId={studentId} />

                    <SectionHeader label={i18n.t('consentForms')} />
                    <View>
                        <ConsentForms studentId={studentId} />
                    </View>
                    <SectionHeader label={i18n.t('transportation')} />
                    <View style={styles.sectionRowContainer}>
                        <Transportation studentId={studentId} style={[styles.itemMargin, styles.itemDesktop]} />
                    </View>
                    <SectionHeader label={i18n.t('personal')} />
                    <View>
                        <Personal studentId={studentId} />
                    </View>
                    <SectionHeader label={i18n.t('medical')} />
                    <View style={styles.sectionRowContainer}>
                        <Medical studentId={studentId} style={[styles.itemMargin, styles.itemDesktop]} />
                    </View>
                </ScrollView>
                <View style={{ flexBasis: '30%', paddingHorizontal: 16 }}>
                    <School studentId={studentId} />
                </View>
                {showAbsenceForm && (
                    <AbsenceForm
                        style={[styles.desktopModal, { width: 400 }]}
                        isReportingLate={isReportingLate}
                        onDismiss={() => setShowAbsenceForm(false)}
                        show={showAbsenceForm}
                    />
                )}
            </View>
        )
    ) : (
        <Tabs enableScroll={true} containerStyle={{ padding: 24 }} rootStyle={{ paddingHorizontal: 24 }} tabs={tabs} />
    );
};

export default StudentProfileScreen;
