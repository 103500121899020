import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { View } from 'react-native';
import { cancelable } from 'cancelable-promise';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import uuid from 'react-native-uuid';
import { polygon } from '@turf/helpers';

import i18n from '@I18n';
import { Coordinate } from '@Redux/types';
import { getGoogleMapsPlaceByAutocomplete, getGoogleMapsPlaceDetail } from '@Utilities';
import HtmlView from '@Components/HtmlView/HtmlView';
import { LocalAddress, SchoolLocation, useGetSchoolsLocationsQuery } from '@Redux/services/StudentRegistrationApi';
import {
    useGetCitiesQuery,
    useGetProvincesQuery,
    useGetCountriesQuery,
    Province,
    BasicLookup,
} from '@Redux/services/LookupApi';

import Picker from '@Components/Picker';
import SwitchInput from '@Components/SwitchInput';

import { createLookupMap } from '@Utilities';
import { StepProps, ManagePickerProps, ManageSwitchInputProps } from '../../types';
import { formatAddress } from '../../util';
import { styles } from '../../styles';

// TODO: Duplicate of Auth/register. Refactor
// consider storing lat/lng or placeId
function generateSessionId() {
    return uuid.v4().toString();
}

function gradeFilter(school: SchoolLocation, grade: number) {
    return !!school.GeographicalBoundingBoxes.find((boundingBoxDetail) =>
        boundingBoxDetail.Grade.includes(Number(grade))
    );
}

function immersionFilter(school: SchoolLocation) {
    return !!school.GeographicalBoundingBoxes.find((boundingBoxDetail) => boundingBoxDetail.FrenchImmersion);
}

function coordFilter(school: SchoolLocation, coord: [number, number]) {
    return !!school.GeographicalBoundingBoxes.find((boundingBoxDetail) =>
        booleanPointInPolygon(coord, coordinateMapper(boundingBoxDetail.SchoolCoordinatesList))
    );
}

interface PickerProps {
    value: string;
    label: string;
}

function coordinateMapper(data: Coordinate[]) {
    return polygon([data.map((i) => [i.lng, i.lat])]);
}

const SchoolInfo: FC<StepProps> = ({ manageFieldPropFactory, studentRegistration }) => {
    const { data: schoolsLocations } = useGetSchoolsLocationsQuery();

    const { data: cities } = useGetCitiesQuery();
    const cityMap = useMemo(() => createLookupMap<BasicLookup>(cities), [cities]);

    const { data: provinces } = useGetProvincesQuery();
    const provinceMap = useMemo(() => createLookupMap<Province>(provinces), [provinces]);

    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);

    const [filteredSchoolOptions, setFilteredSchoolOptions] = useState<PickerProps[]>([]);
    const [coord, setCoord] = useState<[number, number] | null>(null);

    const handleError = useCallback((error: string) => {
        // TODO: Show error
        console.log('Error getting lat/lng: ', error);
    }, []);

    useEffect(() => {
        if (!schoolsLocations?.length) {
            return;
        }

        if (!cityMap) {
            return;
        }

        if (!countryMap) {
            return;
        }

        if (!provinceMap) {
            return;
        }

        let address: LocalAddress;
        if (studentRegistration.TransportationInformation?.IsPickupNeeded) {
            address = studentRegistration.TransportationInformation.PickUpAddress as LocalAddress;
        } else if (studentRegistration.TransportationInformation?.IsDropOffNeeded) {
            address = studentRegistration.TransportationInformation?.DropOffAddress as LocalAddress;
        } else {
            address = studentRegistration.AddressInformation?.ChildAddress as LocalAddress;
        }

        const addressString = formatAddress({
            ...address,
            City: cityMap[address.City]?.Value,
            Country: countryMap[address.Country]?.Value,
            Province: provinceMap[address.Province]?.Abbreviation,
            intl: true,
            RRNumber: undefined,
        });
        const sessionToken = generateSessionId();
        const promise = cancelable(
            getGoogleMapsPlaceByAutocomplete(addressString, sessionToken)
                .then(({ data: { predictions: placeSuggestions } = {} }) =>
                    getGoogleMapsPlaceDetail(placeSuggestions?.[0]?.place_id as string, sessionToken)
                )
                .then(({ data: { result: { geometry } = { geometry: null } } = {} }) => {
                    if (!geometry) {
                        setCoord(null);
                        return;
                    }

                    setCoord([geometry.location.lng, geometry.location.lat]);
                })
                .catch((error) => {
                    handleError(error);
                })
        );

        return promise.cancel;
    }, [setCoord, schoolsLocations, cityMap, countryMap, provinceMap]);

    useEffect(() => {
        if (!schoolsLocations) {
            return;
        }

        let filteredSchools = [];
        const grade = studentRegistration.ChildInformation?.Grade as number;

        const immersion = studentRegistration?.SchoolInformation?.ImmersionSchool as boolean;
        const showAllSchools = studentRegistration?.SchoolInformation?.ShowAllSchools as boolean;

        if (showAllSchools && immersion) {
            filteredSchools = schoolsLocations.filter(
                (school) => gradeFilter(school, grade) && immersionFilter(school)
            );
        } else if (showAllSchools) {
            filteredSchools = schoolsLocations.filter((school) => gradeFilter(school, grade));
        } else if (coord && immersion) {
            filteredSchools = schoolsLocations.filter(
                (school) => gradeFilter(school, grade) && immersionFilter(school) && coordFilter(school, coord)
            );
        } else if (coord) {
            filteredSchools = schoolsLocations.filter(
                (school) => gradeFilter(school, grade) && coordFilter(school, coord)
            );
        } else {
            filteredSchools = schoolsLocations.filter((school) => gradeFilter(school, grade));
        }

        const nextFilteredSchoolOptions = filteredSchools.map(({ Id, SchoolName }) => ({
            value: Id,
            label: SchoolName,
        }));
        setFilteredSchoolOptions(nextFilteredSchoolOptions);
    }, [coord, schoolsLocations, studentRegistration]);

    return (
        <View>
            <SwitchInput
                {...manageFieldPropFactory<ManageSwitchInputProps>(
                    ['SchoolInformation', 'ImmersionSchool'],
                    'onChange',
                    'checked'
                )}
                label={i18n.t('frenchImmersionSchool')}
                reverse
                viewStyle={styles.checkbox}
            />
            <SwitchInput
                {...manageFieldPropFactory<ManageSwitchInputProps>(
                    ['SchoolInformation', 'ShowAllSchools'],
                    'onChange',
                    'checked'
                )}
                label={i18n.t('studentRegisterSeeAllSchools')}
                reverse
                viewStyle={styles.checkbox}
            />
            <Picker
                inputLabel={i18n.t('studentRegisterSchoolInformation')}
                {...manageFieldPropFactory<ManagePickerProps>(['SchoolInformation', 'Id'], 'onValueChange', 'value')}
                items={filteredSchoolOptions}
                style={styles.picker}
            />
            {studentRegistration?.SchoolInformation?.ShowAllSchools && (
                <View>
                    <HtmlView
                        htmlString={`
                            <p>Selecting a school outside of your child's home school area may initiate the <a target='_blank' href='http://www.boarddocs.com/can/ucdsb/Board.nsf/goto?open&id=9MXK6A4F7B2C'>cross-boundary</a> process and/or prevent <a target='_blank' href='https://www.steo.ca/about-us/policies/'>school bus transportation</a> to and from the pick-up or drop-off address.</p>
                        `}
                        style={{ paddingLeft: 12 }}
                    />
                </View>
            )}
        </View>
    );
};

export const SchoolInfoValidator = () => {
    return [{ accessorPath: ['SchoolInformation', 'Id'], validator: Boolean }];
};

export default SchoolInfo;
