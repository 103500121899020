import i18n from '@I18n';
import SvgNeutralFace from '@Icon/NeutralFace';
import { useGetAllStudentActivitiesQuery } from '@Redux/services/notification';
import { Notification } from '@Redux/types';
import React, { useState, useEffect, FC } from 'react';
import { View } from 'react-native';
import Header from '../Header';
import AbsenceItem from './components/AbsenceItem';
import { styles } from './styles';
import { isToday } from 'date-fns';

interface IProps {
    onLoad: (isEmpty: boolean) => void;
}

const UnreportedAbsence: FC<IProps> = ({ onLoad }) => {
    const [unreportedAbsences, setUnreportedAbsences] = useState<Notification[]>();
    const { data: notifications, refetch } = useGetAllStudentActivitiesQuery();
    useEffect(() => {
        const newUnreportedAbsences = notifications?.Notifications.filter(
            (notification) =>
                notification.Classification === 0 &&
                notification.IsActionable &&
                !notification.IsReviewed &&
                notification.AbsenceDate &&
                isToday(new Date(notification.AbsenceDate))
        );
        setUnreportedAbsences(newUnreportedAbsences);
        onLoad(newUnreportedAbsences?.length === 0);
    }, [notifications]);

    return (
        <>
            {unreportedAbsences && unreportedAbsences?.length > 0 && (
                <View style={styles.container}>
                    <Header header={i18n.t('unreportedAbsence')} icon={<SvgNeutralFace />} />
                    {unreportedAbsences?.map((unreportedAbsence) => (
                        <AbsenceItem notification={unreportedAbsence} onResponse={() => refetch()} />
                    ))}
                </View>
            )}
        </>
    );
};

export default UnreportedAbsence;
