import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        paddingVertical: 16,
        borderBottomWidth: 2,
        borderBottomColor: '#373639',
    },
    subHeader: {
        ...typography.body3,
        borderTopWidth: 2,
        borderStyle: 'solid',
        borderTopColor: '#373639',
    },
});
