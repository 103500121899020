import React from 'react';
import { View, TextStyle, Text } from 'react-native';
import { DailySeriesEvent } from '@Redux/services/CalendarApi';
import { IconChevronRight } from '@Icon';

interface EventTitleProps {
    event: DailySeriesEvent;
}

import { styles } from './styles';
function EventTitle({ event }: EventTitleProps) {
    const eventTitleStyles: TextStyle[] = [styles.eventTitle];
    if (event.offerStatus === 'expired') {
        eventTitleStyles.push(styles.eventTitleExpired);
    }
    return (
        <View style={styles.eventTitleContainer}>
            <Text style={eventTitleStyles}>{event.title}</Text>
            {event.offerStatus === 'invited' && <IconChevronRight />}
        </View>
    );
}
export default EventTitle;
