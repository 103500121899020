import React from 'react';
import { Text, TextStyle, View, ViewStyle } from 'react-native';
import { styles } from './styles';
import i18n from '@I18n';
import { format, parseISO, formatDuration } from 'date-fns';
import { DailySeriesEvent, OfferStatus } from '@Redux/services/CalendarApi';

interface TimeLabelProps {
    event: DailySeriesEvent;
    isAllDay: boolean;
}

export type FormatDistanceToken =
    | 'lessThanXSeconds'
    | 'xSeconds'
    | 'halfAMinute'
    | 'lessThanXMinutes'
    | 'xMinutes'
    | 'aboutXHours'
    | 'xHours'
    | 'xDays'
    | 'aboutXWeeks'
    | 'xWeeks'
    | 'aboutXMonths'
    | 'xMonths'
    | 'aboutXYears'
    | 'xYears'
    | 'overXYears'
    | 'almostXYears';

export interface FormatDistanceFnOptions {
    addSuffix?: boolean;
    comparison?: -1 | 0 | 1;
}

export type FormatDistanceFn = (token: FormatDistanceToken, count: number, options?: FormatDistanceFnOptions) => string;

const formatDistance: FormatDistanceFn = (token, count, options) => {
    const result = i18n.t(`calendarDuration_${token}`, { count });

    if (options?.addSuffix) {
        if (options.comparison && options.comparison > 0) {
            return i18n.t('calendarDistanceIn', { result });
        } else {
            return i18n.t('calendarDistanceAgo', { result });
        }
    }

    return result;
};

//const validNoonstrings = ['midnight', 'noon'];
const formatTime = (date: Date) => {
    //const noonstring = format(date, 'b');
    //if (includes(validNoonstrings, noonstring)) {
    //    return i18n.t(`calendar12_${noonstring}`);
    //}

    return format(date, 'h:mm');
};

const durationOptions = { locale: { formatDistance } };

const offerStatusViewStyleMap: Record<OfferStatus, ViewStyle | null> = {
    accepted: null,
    expired: styles.timeLabelContainerOfferStatusExpired,
    invited: styles.timeLabelContainerOfferStatusInvited,
};

const offerStatusTextStyleMap: Record<OfferStatus, TextStyle | null> = {
    accepted: null,
    expired: styles.timeLabelOfferStatusExpired,
    invited: null,
};

function TimeLabel({ event, isAllDay }: TimeLabelProps) {
    const viewStyle: ViewStyle[] = [styles.timeLabelContainer];

    if (event.offerStatus && offerStatusViewStyleMap[event.offerStatus]) {
        viewStyle.push(offerStatusViewStyleMap[event.offerStatus] as ViewStyle);
    }

    const row1Style: TextStyle[] = [styles.row1];
    const row2Style: TextStyle[] = [styles.row2];

    if (event.offerStatus && offerStatusTextStyleMap[event.offerStatus]) {
        row1Style.push(offerStatusTextStyleMap[event.offerStatus] as TextStyle);
        row2Style.push(offerStatusTextStyleMap[event.offerStatus] as TextStyle);
    }

    if (isAllDay) {
        return (
            <View style={viewStyle}>
                <Text style={row1Style}>{i18n.t('allDay')}</Text>
            </View>
        );
    }

    let row1 = formatTime(parseISO(event.start));
    let row2 = formatDuration(event.duration, durationOptions);

    if (event.isFirstInDailySeries) {
        row1 = formatTime(parseISO(event.start));
        row2 = formatDuration(event.duration, durationOptions);
    } else if (event.isLastInDailySeries) {
        row1 = formatTime(parseISO(event.end));
        row2 = formatDuration(event.duration, durationOptions);
    }

    return (
        <View style={viewStyle}>
            <Text style={row1Style}>{row1}</Text>
            <Text style={row2Style}>{row2}</Text>
        </View>
    );
}

export default TimeLabel;
