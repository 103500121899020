import { View, Text } from 'react-native';
import i18n from '@I18n';
import ListLink, { ListLinkProps } from '@Components/ListLink';
import { IconMoney, IconLockLocked, IconCall, IconArticle, IconDelete, IconLogout, IconVideo, Help } from '@Icon';
import { styles } from './styles';
import { useGetParentQuery } from '@Redux/services/parent';
import ListItem, { ListItemProps } from '@Components/ListItem';
import { store } from '@Redux';
import { logout, setShowAppIntro } from '@Redux/auth';
import Avatar from '@Components/Avatar';
import { ScrollView } from 'react-native-gesture-handler';
import Balance from './Balance';
// import { SocialLinkComponent } from './SecurityPreferences';
import { ContactInformationComponent, EditAvatarComponent } from './ContactInformation';
import { ContactPreferencesDesktop } from './ContactPreferencesDetail/ContactPreferencesDesktop';
import withLayout from '@Screens/common';
import { useContext, useState } from 'react';
import DeviceContext from '@Contexts/DeviceContext';
import { defaultTheme } from '@Hooks/useTheme';
import { Parent } from '@Redux/types';
import EditProfileModal from './ContactInformation/components/EditProfileModal';
import { HELP_URL } from '@App/constants';

function handleLogout() {
    store.dispatch(logout());
}

const items: ListItemProps[] = [
    {
        icon: Help,
        destination: HELP_URL,
        text: i18n.t('help'),
        external: true,
    },
    {
        icon: IconVideo,
        text: i18n.t('quickTour'),
        onPress: () => store.dispatch(setShowAppIntro({ showAppIntro: true })),
        external: false,
    },
    {
        icon: IconArticle,
        destination: 'https://ucdsb.uservoice.com/forums/335937',
        text: i18n.t('suggestions'),
        external: true,
    },
    {
        icon: IconDelete,
        destination: `${HELP_URL}ViewKnownError.aspx?knownErrorId=1306`,
        text: i18n.t('deleteAccount'),
        external: true,
    },
    {
        icon: IconLogout,
        onPress: handleLogout,
        text: i18n.t('signOut'),
        external: false,
    },
];

type ProfileProps = {
    parent?: Parent;
};

function ProfileNavigation({ parent }: ProfileProps) {
    const parentName = parent?.FirstName + ' ' + parent?.LastName;
    const balance = parent?.Payment?.CurrentBalance?.toString() || '';

    const linkItems: ListLinkProps[] = [
        {
            icon: <IconMoney />,
            destination: 'Balance',
            text: balance,
            subtext: parent?.Payment.IsPaymentActive ? i18n.t('accountBalance') : i18n.t('eWalletInactive'),
        },
        {
            icon: <IconLockLocked />,
            destination: 'SecurityPreferences',
            text: i18n.t('signInAndSecurity'),
        },
        {
            icon: <IconCall fill={defaultTheme.colors.raspberry} />,
            destination: 'ContactPreferences',
            text: i18n.t('contactPrefs'),
        },
    ];
    return (
        <View style={styles.screen}>
            <ListLink
                icon={<Avatar label={parentName ? parentName : '-'} picture={parent?.Photo} size={100} />}
                destination='ContactInformation'
                text={parentName}
                subtext='View profile'
                variant='large'
            />

            {linkItems.map((link, key) => (
                <View key={key}>
                    <ListLink {...link} />
                </View>
            ))}

            {items.map((item, key) => (
                <View key={key}>
                    <ListItem {...item} />
                </View>
            ))}
        </View>
    );
}

function DesktopProfile() {
    return (
        <View style={styles.screen}>
            <EditAvatarComponent />
            {items.map((item, key) => (
                <View key={key}>
                    <ListItem {...item} />
                </View>
            ))}
        </View>
    );
}

// function SocialHeaderComponent() {
//     return (
//         <View style={styles.sectionHeaderContainer}>
//             <Text style={styles.sectionHeaderTitle}>{i18n.t('signInAndSecurity')}</Text>
//         </View>
//     );
// }

function Profile() {
    const { data: parent, refetch } = useGetParentQuery();
    const parentName = parent?.FirstName + ' ' + parent?.LastName;
    const { isDesktop } = useContext(DeviceContext);
    const [showModal, setShowModal] = useState(false);

    const handleDismissPress = () => {
        setShowModal(false);
        refetch();
    };

    return isDesktop ? (
        withLayout(
            <>
                {showModal && <EditProfileModal show={showModal} onDismiss={handleDismissPress} parent={parent} />}
                <View
                    style={{
                        maxWidth: 1280,
                        width: '100%',
                        alignSelf: 'center',
                        maxHeight: '100%',
                        flexDirection: 'row',
                        paddingLeft: 60,
                        paddingRight: 32,
                    }}
                >
                    <View style={{ flexBasis: '30%', paddingHorizontal: 16 }}>
                        <DesktopProfile />
                    </View>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={{ flexBasis: '70%', paddingHorizontal: 16, height: '100%', paddingBottom: 32 }}
                    >
                        <View>
                            <View style={{ marginBottom: 24 }}>
                                <Text style={styles.header}>{parentName}</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <ContactInformationComponent parent={parent} onEdit={() => setShowModal(true)} />
                            </View>
                        </View>
                        <View>
                            <Balance />
                        </View>
                        {/* <View>
                            <SocialHeaderComponent />
                            <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 20 }}>
                                <SocialLinkComponent wrapperStyle={styles.socialWrapperStyle} />
                            </View>
                        </View> */}
                        <View>
                            <ContactPreferencesDesktop />
                        </View>
                    </ScrollView>
                </View>
            </>
        )
    ) : (
        <ScrollView>
            <ProfileNavigation parent={parent} />
        </ScrollView>
    );
}

export default Profile;
