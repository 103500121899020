import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        padding: 24,
    },
    header: {
        ...typography.h3,
        textAlign: 'center',
        paddingBottom: 16,
    },
    button: {
        alignItems: 'flex-end',
    },
    description: {
        ...typography.body2,
        marginBottom: 16,
    },
    modalDesktop: {
        minHeight: 400,
        minWidth: 500,
        margin: 'auto',
        paddingBottom: 20,
        borderRadius: 8,
    },
});

export default styles;
