import React, { useCallback, useContext, useRef, useState } from 'react';
import {
    Dimensions,
    Modal,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Pressable,
    PressableProps,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import Animated, { Easing, useAnimatedStyle, withSequence, withTiming } from 'react-native-reanimated';
import { Video, ResizeMode, AVPlaybackSource } from 'expo-av';
import useTheme, { defaultTheme, typography } from '@Hooks/useTheme';
import { ArrowRightIntro, Close } from '@Icon';
import DeviceContext from '@Contexts/DeviceContext';
import DesktopModal from '@Components/Modal';
import { MfrLogo2 } from '@Svg';
import { useSelector } from 'react-redux';
import { RootState, store } from '@Redux';
import { setShowAppIntro } from '@Redux/auth';

type IntroScreenProps = {
    onDismiss: () => void;
};

type IntroPageData = {
    videoSource: AVPlaybackSource;
    videoSourceDesktop: AVPlaybackSource;
    text: string;
    backgroundColor: string;
    nextButtonText?: string;
};

type IntroPageProps = {
    data: IntroPageData;
    active: boolean;
    index: number;
};

type PagerProps = {
    page: number;
};

type PagerDotProps = {
    active?: boolean;
};

type NextButtonStylePreset = 'normal' | 'highlight';

type NextButtonProps = {
    onPress: () => void;
    text: string;
    stylePreset: NextButtonStylePreset;
};

type IntroButtonProps = {
    onDismiss: () => void;
    onNext: () => void;
    nextButtonText: string;
    nextButtonStylePreset: NextButtonStylePreset;
};

type VideoSectionDesktopProps = {
    videoSource: AVPlaybackSource;
};

type CaptionSectionDesktopProps = {
    captions: string[];
    activeIndex: number;
    onCaptionPress: (index: number) => void;
};

const introWelcomeData = {
    title: 'My\nFamily\nRoom',
    subtitle: 'The place to connect and communicate with your schools.',
    buttonText: 'Take a quick tour',
};

const introPageData = [
    {
        videoSource: require('@Assets/intro/MobileNotify3.mp4'),
        videoSourceDesktop: require('@Assets/intro/DesktopNotify3.mp4'),
        text: 'Report a late arrival or absence',
        backgroundColor: '#ddedea',
    },
    {
        videoSource: require('@Assets/intro/MobileEvents3.mp4'),
        videoSourceDesktop: require('@Assets/intro/DesktopEvents3.mp4'),
        text: 'Approve and pay for an event',
        backgroundColor: '#fce1e4',
    },
    {
        videoSource: require('@Assets/intro/MobileUpdates3.mp4'),
        videoSourceDesktop: require('@Assets/intro/DesktopUpdates3.mp4'),
        text: 'Get live updates',
        backgroundColor: '#fcf4dd',
    },
    {
        videoSource: require('@Assets/intro/MobileCalendar3.mp4'),
        videoSourceDesktop: require('@Assets/intro/DesktopCalendar3.mp4'),
        text: "See what's going on in class",
        backgroundColor: '#daeaf6',
    },
    {
        videoSource: require('@Assets/intro/MobileAbsence3.mp4'),
        videoSourceDesktop: require('@Assets/intro/DesktopAbsence3.mp4'),
        text: 'Address an unreported absence',
        nextButtonText: 'Get started',
        backgroundColor: '#e8dff5',
    },
];

// Intermediate color during background transition, only for desktop
// The first element is unused
const transitionColor = ['#ffffff', '#eeedea', '#fcffe2', '#fcf4f6', '#dfe2f6'];

const NextButton = ({ onPress, text, stylePreset }: NextButtonProps) => {
    const theme = useTheme();
    const { isDesktop } = useContext(DeviceContext);
    return (
        <TouchableOpacity onPress={onPress}>
            <View style={styles.welcomeNextButton}>
                <View style={stylePreset === 'highlight' && styles.welcomeNextButtonUnderline}>
                    <Text style={[styles.nextButtonText, isDesktop && styles.nextButtonTextDesktop]}>{text}</Text>
                </View>
                <View style={{ paddingLeft: 6, paddingEnd: 12 }}>
                    <ArrowRightIntro
                        width={isDesktop ? 20 : 24}
                        height={isDesktop ? 20 : 24}
                        fill={stylePreset === 'highlight' ? theme.colors.watermelon : theme.colors.textPrimary}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );
};

const IntroButtonSection = ({ onDismiss, onNext, nextButtonText, nextButtonStylePreset }: IntroButtonProps) => {
    const animatedBottomPadding = useAnimatedStyle(
        () => ({
            paddingBottom: withTiming(nextButtonStylePreset === 'highlight' ? 100 : 0),
        }),
        [nextButtonStylePreset]
    );
    return (
        <Animated.View style={[styles.introButtonSection, animatedBottomPadding]}>
            <TouchableOpacity onPress={onDismiss}>
                <View style={styles.skipButton}>
                    <Text style={styles.skipButtonText}>Skip</Text>
                </View>
            </TouchableOpacity>
            <NextButton onPress={onNext} text={nextButtonText} stylePreset={nextButtonStylePreset} />
        </Animated.View>
    );
};

const WelcomePage = () => {
    return (
        <View style={windowSize()}>
            <View style={styles.welcomeTop}>
                <View style={{ transform: [{ scale: 2 }], marginBottom: 84, marginLeft: 60, alignSelf: 'flex-start' }}>
                    <MfrLogo2 />
                </View>
                <Text style={styles.subtitle}>{introWelcomeData.subtitle}</Text>
            </View>
        </View>
    );
};

const pagerAnimationDuration = 500;

const PagerDot = ({ active = false }: PagerDotProps) => {
    const animationTiming = { duration: pagerAnimationDuration, easing: Easing.out(Easing.quad) };
    const animationTimingOpacity = { duration: pagerAnimationDuration, easing: Easing.out(Easing.exp) };
    const animatedActiveStyle = useAnimatedStyle(
        () => ({
            backgroundColor: withTiming(active ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)', animationTimingOpacity),
            borderRadius: withTiming(active ? 12 : 0, animationTiming),
            borderTopWidth: withTiming(active ? 3 : 0, animationTiming),
            borderTopColor: withTiming(active ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)', animationTimingOpacity),
            borderBottomWidth: withTiming(active ? 3 : 0, animationTiming),
            borderBottomColor: withTiming(active ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)', animationTimingOpacity),
        }),
        [active]
    );
    return (
        <View style={{ padding: 0, margin: 12 }}>
            <View style={styles.pagerDotHexElementContainer}>
                <Animated.View
                    style={[styles.pagerDotHexElement, { transform: [{ rotateZ: '90deg' }] }, animatedActiveStyle]}
                />
            </View>
            <View style={styles.pagerDotHexElementContainer}>
                <Animated.View
                    style={[styles.pagerDotHexElement, { transform: [{ rotateZ: '30deg' }] }, animatedActiveStyle]}
                />
            </View>
            <View style={styles.pagerDotHexElementContainer}>
                <Animated.View
                    style={[styles.pagerDotHexElement, { transform: [{ rotateZ: '-30deg' }] }, animatedActiveStyle]}
                />
            </View>
        </View>
    );
};

const Pager = ({ page }: PagerProps) => {
    const animatedOpacity = useAnimatedStyle(
        () => ({
            opacity: withTiming(page === 0 ? 0 : 1, { duration: pagerAnimationDuration, easing: Easing.exp }),
        }),
        [page]
    );
    return (
        <Animated.View style={[styles.pagerContainer, animatedOpacity]}>
            <View style={{ flexDirection: 'row' }}>
                <PagerDot active={page === 1} />
                <PagerDot active={page === 2} />
                <PagerDot active={page === 3} />
                <PagerDot active={page === 4} />
                <PagerDot active={page === 5} />
            </View>
        </Animated.View>
    );
};

const IntroPage = ({ data, active = false, index }: IntroPageProps) => {
    const { videoSource, text, backgroundColor } = data;
    const videoPlayer = useCallback(
        (player: Video) => {
            if (player !== null) {
                if (active) {
                    player.playAsync();
                } else {
                    player.pauseAsync();
                }
            }
        },
        [active]
    );

    return (
        <View style={[windowSize(), { backgroundColor }]}>
            <View style={{ flexGrow: 1 }}>
                <Video
                    ref={videoPlayer}
                    source={videoSource}
                    isLooping
                    isMuted
                    resizeMode={ResizeMode.COVER}
                    useNativeControls={false}
                    style={{ flex: 1 }}
                />
            </View>
            <View style={styles.introPageCaption}>
                <Text style={styles.introPageCaptionText}>
                    {index + 1}. {text}
                </Text>
            </View>
        </View>
    );
};

const Intro = ({ onDismiss }: IntroScreenProps) => {
    const scrollView = useRef<ScrollView>(null);
    // page 0 is the welcome page
    const [page, setPage] = useState(0);
    const nextButtonText = page === 0 ? introWelcomeData.buttonText : introPageData[page - 1].nextButtonText ?? 'Next';
    const nextButtonStylePreset = page === 0 ? 'highlight' : 'normal';
    const scrollHandler = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        setPage(Math.round(e.nativeEvent.contentOffset.x / Dimensions.get('window').width));
    };
    const nextPageHandler =
        page === introPageData.length
            ? onDismiss
            : () => {
                  scrollView.current?.scrollTo({ x: (page + 1) * Dimensions.get('window').width });
              };

    return (
        <>
            <ScrollView
                ref={scrollView}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                bounces={false}
                onScroll={scrollHandler}
                scrollEventThrottle={100}
            >
                <WelcomePage />
                {introPageData.map((data, index) => (
                    <IntroPage data={data} key={index} index={index} active={page === index + 1} />
                ))}
            </ScrollView>
            <IntroButtonSection
                onDismiss={onDismiss}
                onNext={nextPageHandler}
                nextButtonText={nextButtonText}
                nextButtonStylePreset={nextButtonStylePreset}
            />
            <Pager page={page} />
        </>
    );
};

const VideoSectionDesktop = ({ videoSource }: VideoSectionDesktopProps) => {
    const videoPlayer = useCallback(
        (player: Video) => {
            if (player !== null) {
                player.playAsync();
            }
        },
        [videoSource]
    );

    return (
        <View style={styles.videoContainerDesktop}>
            <Video
                ref={videoPlayer}
                source={videoSource}
                isLooping
                isMuted
                resizeMode={ResizeMode.COVER}
                useNativeControls={false}
                style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
            />
        </View>
    );
};

type PressableWithHoverProps = PressableProps & {
    onHoverIn: () => void;
    onHoverOut: () => void;
};

type PressableWithHover = React.ForwardRefExoticComponent<PressableWithHoverProps & React.RefAttributes<View>>;

const CaptionButtonDesktop = ({ children, onPress }: PressableProps) => {
    const PressableWithHover = Pressable as PressableWithHover;
    const [hover, setHover] = useState(false);
    const animatedOpacity = useAnimatedStyle(
        () => ({
            opacity: withTiming(hover ? 1 : 0, {
                duration: 500,
                easing: Easing.inOut(Easing.exp),
            }),
        }),
        [hover]
    );

    return (
        <PressableWithHover onPress={onPress} onHoverIn={() => setHover(true)} onHoverOut={() => setHover(false)}>
            {children}
            <Animated.View style={[styles.captionCursorDesktop, { top: 8, bottom: 8 }, animatedOpacity]} />
        </PressableWithHover>
    );
};

const CaptionSectionDesktop = ({ captions, activeIndex, onCaptionPress }: CaptionSectionDesktopProps) => {
    const textPaddingVertical = 8;
    const textLineHeight = 18;

    const animatedCursorPosition = useAnimatedStyle(
        () => ({
            top: withTiming(textPaddingVertical + activeIndex * (2 * textPaddingVertical + textLineHeight), {
                duration: 250,
                easing: Easing.out(Easing.quad),
            }),
        }),
        [activeIndex]
    );

    return (
        <View>
            {captions.map((text, index) => (
                <CaptionButtonDesktop key={index} onPress={() => onCaptionPress(index)}>
                    <Text
                        style={{
                            fontFamily: activeIndex === index ? 'Montserrat_700Bold' : 'Montserrat_300Light',
                            paddingVertical: textPaddingVertical,
                            lineHeight: textLineHeight,
                            fontWeight: activeIndex === index ? 'bold' : 'normal',
                        }}
                    >
                        {text}
                    </Text>
                </CaptionButtonDesktop>
            ))}
            <Animated.View style={[styles.captionCursorDesktop, animatedCursorPosition]} />
        </View>
    );
};

export const IntroDesktopModal = ({ onDismiss, visible }: IntroScreenProps & { visible: boolean }) => {
    // page in desktop is zero indexed since there is no welcome page
    const [page, setPage] = useState(0);
    const pageData = introPageData[page];
    const backgroundTransitionColor = transitionColor[page];

    // Without intermediate color, the transition looks weird
    // HSV transition with renaimated only shows white background, not sure why
    const animatedBackgroundColor = useAnimatedStyle(
        () => ({
            backgroundColor: withSequence(
                withTiming(backgroundTransitionColor, { duration: 150, easing: Easing.in(Easing.exp) }),
                withTiming(pageData.backgroundColor, { duration: 150, easing: Easing.out(Easing.linear) })
            ),
        }),
        [pageData, backgroundTransitionColor]
    );

    return (
        <DesktopModal
            visible={visible}
            header={
                <TouchableOpacity onPress={onDismiss}>
                    <Close style={{ zIndex: -1, position: 'absolute', right: -32 }} fill='white' />
                </TouchableOpacity>
            }
            style={styles.modalDesktop}
            animationType='fade'
        >
            <Animated.View style={[styles.introContainerDesktop, animatedBackgroundColor]}>
                <View style={styles.introTextSectionDesktop}>
                    <MfrLogo2 />
                    <View>
                        <Text style={[typography.introSubtitle, { fontSize: 12, lineHeight: 18 }]}>
                            {introWelcomeData.subtitle}
                        </Text>
                    </View>
                    <View>
                        <Text style={[typography.buttonLarge, { fontSize: 12 }]}>{introWelcomeData.buttonText}.</Text>
                    </View>

                    <CaptionSectionDesktop
                        captions={introPageData.map((p) => p.text)}
                        activeIndex={page}
                        onCaptionPress={(n) => setPage(n)}
                    />

                    <NextButton
                        onPress={() => {
                            page < introPageData.length - 1 ? setPage(page + 1) : onDismiss();
                        }}
                        text={pageData.nextButtonText ?? 'Next'}
                        stylePreset='normal'
                    />
                </View>
                <View style={styles.introVideoSectionDesktop}>
                    <VideoSectionDesktop videoSource={pageData.videoSourceDesktop} />
                </View>
            </Animated.View>
        </DesktopModal>
    );
};

export function withIntro<P extends { children?: React.ReactNode }>(Component: React.ComponentType<P>) {
    return (props: P) => {
        const showAppIntro = useSelector((state: RootState) => state.auth.showAppIntro) || false;
        const { isDesktop } = useContext(DeviceContext);

        function closeAppIntro() {
            store.dispatch(setShowAppIntro({ showAppIntro: false }));
        }

        return (
            <View style={{ height: '100%' }}>
                {isDesktop ? (
                    <IntroDesktopModal visible={showAppIntro} onDismiss={closeAppIntro} />
                ) : (
                    <Modal animationType='fade' visible={showAppIntro} onRequestClose={closeAppIntro}>
                        <Intro onDismiss={closeAppIntro} />
                    </Modal>
                )}
                <Component {...props} />
            </View>
        );
    };
}

const introButtonSectionHeight = 120;

const windowSize = () => ({
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
});

const videoSize = {
    width: 650,
    height: 650,
};

const styles = StyleSheet.create({
    welcomeTop: {
        flexGrow: 1,
        justifyContent: 'center',
        paddingStart: 24,
        marginBottom: introButtonSectionHeight,
    },
    introButtonSection: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: introButtonSectionHeight,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    title: {
        color: 'black',
        fontSize: 64,
        fontWeight: '800',
        lineHeight: 52,
    },
    subtitle: {
        ...typography.introSubtitle,
    },
    bigDot: {
        color: 'orange',
        fontSize: 108,
        lineHeight: 0,
    },
    welcomeNextButton: {
        flexDirection: 'row',
    },
    welcomeNextButtonUnderline: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    nextButtonText: {
        ...typography.buttonLarge,
    },
    introPageCaption: {
        paddingBottom: 180,
        alignItems: 'center',
    },
    introPageCaptionText: {
        ...typography.h6,
    },
    skipButton: {
        paddingLeft: 24,
    },
    skipButtonText: {
        ...typography.buttonSmall,
    },
    pagerContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 130,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    pagerDotHexElementContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        transform: [{ scale: 0.8 }],
    },
    pagerDotHexElement: {
        paddingHorizontal: 5,
        paddingVertical: 4,
        borderLeftColor: defaultTheme.colors.textPrimary,
        borderLeftWidth: 2,
        borderRightColor: defaultTheme.colors.textPrimary,
        borderRightWidth: 2,
        width: 0,
        height: 0,
    },

    modalDesktop: {
        width: 950,
        height: videoSize.height,
        margin: 'auto',
    },
    introContainerDesktop: {
        flexDirection: 'row',
        width: '100%',
        height: '100%',
    },
    introTextSectionDesktop: {
        flexShrink: 1,
        padding: 48,
        paddingEnd: 16,
        justifyContent: 'space-between',
    },
    introVideoSectionDesktop: {
        width: videoSize.width,
        height: '100%',
    },
    nextButtonTextDesktop: {
        fontSize: 14,
        lineHeight: 20,
    },
    videoContainerDesktop: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
    captionCursorDesktop: {
        backgroundColor: defaultTheme.colors.text,
        position: 'absolute',
        left: -8,
        paddingRight: 3,
        paddingVertical: 9,
    },
});
