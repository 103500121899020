import i18n from '@I18n';
import { Tims } from '@Svg';
import { View, Text } from 'react-native';
import styles from './styles';

const Empty = () => {
    return (
        <View style={styles.container}>
            <Tims style={styles.image} />
            <Text style={styles.header}>{i18n.t('emptyActionItems')}</Text>
            <Text style={styles.subHeader}>{i18n.t('emptyActionItems1')}</Text>
        </View>
    );
};

export default Empty;
