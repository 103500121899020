import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconEventTypeFieldtrip = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.832 1.396H3.17v17.662h.735l1.073 3.679h2.82l1.074-3.68h6.258l1.073 3.68h2.821l1.073-3.68h.735V1.397ZM5.588 7.046h12.826v3.547H5.588V7.045Zm2.273 6.622h1.53l.766 1.325-.765 1.326h-1.53l-.766-1.325.765-1.326Zm6.75 0h1.53l.765 1.325-.765 1.326h-1.53l-.766-1.325.765-1.326Z'
            fill='#D77A7F'
        />
    </Svg>
);
export default SvgIconEventTypeFieldtrip;
