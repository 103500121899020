import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgSchoolLocation = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.734.566H4.207L.444 7.085l3.763 6.519h7.527l3.764-6.519L11.734.566ZM8.003 8.711a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25Zm3.165 6.01L8 20.209 4.832 14.72h6.336Z'
            fill='#73CC49'
        />
    </Svg>
);
export default SvgSchoolLocation;
