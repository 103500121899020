import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBrush = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            clipRule='evenodd'
            d='m13.56.9 4.54 4.54-7.328 7.328-4.54-4.54L13.561.899ZM9.566 15.194l-7.87 2.109 2.11-7.87 5.76 5.761Z'
        />
    </Svg>
);
export default SvgBrush;
