import React from 'react';
import { View, Text } from 'react-native';

import { endOfWeek, eachWeekOfInterval, eachDayOfInterval, format } from 'date-fns';
import { first } from 'lodash';

import { DateEntry } from './components';

import { CalendarComponentProps } from '../types';
import { dateWSO } from '../types';

import { styles } from './styles';

function CalendarMonth({ ...rest }: CalendarComponentProps) {
    const weeks = eachWeekOfInterval(rest.currentInterval, dateWSO).map((firstDayOfWeek) =>
        eachDayOfInterval({ start: firstDayOfWeek, end: endOfWeek(firstDayOfWeek, { weekStartsOn: 6 }) })
    );

    return (
        <View style={styles.calendarWrapper}>
            <View style={styles.weekdayHeaderWrapper}>
                {first(weeks)?.map((date) => (
                    <View style={styles.weekdayHeader}>
                        <View style={styles.weekdayHeaderInner}>
                            <Text style={styles.weekdayHeaderLabel} numberOfLines={1}>
                                {format(date, 'EEEE')}
                            </Text>
                        </View>
                    </View>
                ))}
            </View>
            {weeks.map((week) => (
                <View style={styles.weekWrapper}>
                    {week.map((day) => (
                        <View style={styles.dayWrapper}>
                            <DateEntry onEventAccepted={rest.loadEvents} item={day} {...rest} />
                        </View>
                    ))}
                </View>
            ))}
        </View>
    );
}

export default CalendarMonth;
