import IconList from '@Components/IconList';
import { useGetStudentMedicalQuery } from '@Redux/services/student';
import { StyleProp, Text, View, ViewStyle, ScrollView } from 'react-native';
import i18n from '@I18n/index';
import { ActivityIndicator } from 'react-native-paper';
import SvgIconWarning from '@Icon/IconWarning';
import SvgMedicalBag from '@Icon/MedicalBag';
import SvgFlower from '@Icon/Flower';
import BulletPoint from '@Components/BulletPoint';
import { typography } from '@Hooks/useTheme';
import React from 'react';

interface IProps {
    studentId: string;
    style?: StyleProp<ViewStyle>;
}

const Medical: React.FC<IProps> = ({ studentId, style: wrapperStyle }) => {
    const { data: studentMedical } = useGetStudentMedicalQuery({ studentId });

    if (!studentMedical) {
        return <ActivityIndicator />;
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <View style={wrapperStyle}>
                <IconList icon={<SvgIconWarning />} header={i18n.t('lifeThreatening')}>
                    {studentMedical.LifeThreateningMedicalAlerts ? (
                        <BulletPoint label={studentMedical.LifeThreateningMedicalAlerts} />
                    ) : (
                        <Text style={typography.body}>-</Text>
                    )}
                </IconList>
            </View>

            <View style={wrapperStyle}>
                <IconList icon={<SvgMedicalBag />} header={i18n.t('nonlifeThreatening')}>
                    {studentMedical.NonLifeThreateningMedicalDetails ? (
                        <BulletPoint label={studentMedical.NonLifeThreateningMedicalDetails} />
                    ) : (
                        <Text style={typography.body}>-</Text>
                    )}
                </IconList>
            </View>

            <View style={wrapperStyle}>
                <IconList icon={<SvgFlower />} header={i18n.t('allergies')}>
                    {studentMedical.Allergies ? (
                        <BulletPoint label={studentMedical.Allergies} />
                    ) : (
                        <Text style={typography.body}>-</Text>
                    )}
                </IconList>
            </View>
        </ScrollView>
    );
};

export default Medical;
