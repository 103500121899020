import i18n from '@I18n';
import { LoadingIndicator } from '@Screens/Calendar/components';
import useCalendar from '@Screens/Calendar/useCalendar';
import { formatISO, getMonth, startOfToday } from 'date-fns';
import { FC, useMemo, useEffect } from 'react';
import { View } from 'react-native';
import Header from '../Header';
import DateEntry from './components/DateEntry';
import { styles } from './styles';
import { DailySeriesEventDateMap, dateRep } from '@Redux/services/CalendarApi';
import { IconOfferStatusInvited } from '@Icon/index';
import { first, isEmpty } from 'lodash';

interface IProps {
    onLoad: (isEmpty: boolean) => void;
}

const offerStatusFilters = { accepted: false, expired: false, invited: true };
const eventTypeFilters = {
    offers: true,
    other: false,
    schoolboard: false,
    schoolyear: false,
    noevents: true,
    fieldtrip: true,
};

const Events: FC<IProps> = ({ onLoad }) => {
    const { loadEvents, captureHeight, dailySeriesEventMap, eachDayOfDailySeriesEventMap, initialFetchComplete } =
        useCalendar({
            endOfPeriod: (date: Date) => {
                let year = date.getFullYear();
                if (date.getMonth() + 1 >= 9) {
                    year += 1;
                }
                return new Date(`${year}-10-31`);
            },
            getPeriod: getMonth,
            startOfPeriod: startOfToday,
            overrideEventTypesFilter: eventTypeFilters,
            overrideOfferStatusFilter: offerStatusFilters,
        });

    const { eventsMap, eachDaySeriesMap } = useMemo(() => {
        const newDailySeriesEventMap: DailySeriesEventDateMap = {};
        const newEachDayOfDailySeriesEventMap: Date[] = [];
        const eventIdMap: Record<string, boolean> = {};
        // A day must have a minimum of 1 event
        // - 1 event of type noevents
        // - 1 or more events not of type noevents
        eachDayOfDailySeriesEventMap.forEach((date) => {
            const dateString = formatISO(date, dateRep);
            const dailyEvents = dailySeriesEventMap?.[dateString];
            const firstEvent = first(dailyEvents);
            if (dailyEvents.length === 0 || (dailyEvents.length === 1 && firstEvent?.type === 'noevents')) {
                return;
            }
            const newEvents = dailyEvents.filter((i) => !eventIdMap[i.id]);
            if (newEvents.length === 0) {
                return;
            }
            newEvents.forEach((event) => {
                eventIdMap[event.id] = true;
            });
            newEachDayOfDailySeriesEventMap.push(date);
            newDailySeriesEventMap[dateString] = newEvents;
        });
        return { eventsMap: newDailySeriesEventMap, eachDaySeriesMap: newEachDayOfDailySeriesEventMap };
    }, [eachDayOfDailySeriesEventMap, dailySeriesEventMap]);

    const isEmptyEvents: boolean = useMemo(
        () =>
            (dailySeriesEventMap &&
                Object.values(dailySeriesEventMap)
                    .flat()
                    .every((event) => event.type === 'noevents')) ||
            isEmpty(dailySeriesEventMap),
        [dailySeriesEventMap]
    );

    useEffect(() => {
        onLoad(isEmptyEvents);
    }, [isEmptyEvents]);

    return (
        <View style={styles.calendarWrapper}>
            {!initialFetchComplete ? (
                <LoadingIndicator />
            ) : isEmptyEvents ? null : (
                <>
                    <Header header={i18n.t('calendarFilterEventTypeOffers')} icon={<IconOfferStatusInvited />} />
                    {eventsMap &&
                        eachDaySeriesMap?.map((event) => {
                            return (
                                <DateEntry
                                    key={`calendar-flatlist-${formatISO(event, dateRep)}`}
                                    item={event}
                                    dailySeriesEventMap={eventsMap}
                                    reportHeight={captureHeight}
                                    onEventAccepted={loadEvents}
                                />
                            );
                        })}
                </>
            )}
        </View>
    );
};

export default Events;
