import React, { useCallback } from 'react';
import { Pressable, Text, View, Linking } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useNavigation } from '@react-navigation/native';

import styles from './styles';

export interface ListItemProps {
    icon: React.FC<SvgProps>;
    text: string;
    destination?: string;
    onPress?: () => void;
    external: boolean;
}

function ListItem({ icon: Icon, text, onPress, destination, external = false }: ListItemProps) {
    const navigation = useNavigation();
    const defaultOnPress = useCallback(() => {
        if (!destination) {
            return;
        }
        if (external) {
            Linking.openURL(destination);
        } else {
            navigation.navigate(destination);
        }
    }, [navigation, destination]);

    return (
        <Pressable onPress={onPress || defaultOnPress} style={({ pressed }) => pressed && styles.pressed}>
            <View style={styles.listItem}>
                <View style={styles.icon}>
                    <Icon />
                </View>
                <View style={styles.labelContainer}>
                    <Text style={styles.label}>{text}</Text>
                </View>
            </View>
        </Pressable>
    );
}

export default ListItem;
