import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    costItem: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingBottom: 12,
        alignItems: 'center',
    },
    costItemLabel: {
        ...typography.body2,
    },
    inputs: {
        marginBottom: 8,
    },
    switchContainer: {
        minHeight: 40,
    },
    switchPressable: {
        flexBasis: '50%',
    },
    switchContainerDisabled: {
        borderColor: defaultTheme.colors.disabled,
        borderWidth: 1,
        marginBottom: 16,
        minHeight: 56,
        padding: 10,
    },
    error: {
        ...typography.body2,
        color: defaultTheme.colors.error,
        marginBottom: 8,
    },
    divider: {
        marginTop: 8,
        marginBottom: 24,
    },
    checkboxContainer: {
        flexWrap: 'wrap',
        marginVertical: 8,
        flexDirection: 'row',
    },
    checkbox: {
        flexDirection: 'row',
        alignItems: 'center',
        flexBasis: '50%',
        marginBottom: 8,
    },
    pickerDefaultLabel: {
        color: 'rgb(55, 54, 57)',
    },
});

export default styles;
