import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    headerGreetingContainer: {
        maxWidth: '65%',
    },
    headerBorderMobile: {
        borderBottomWidth: 2,
        borderBottomColor: 'black',
    },
    headerGreeting: {
        ...typography.h3,
        width: '100%',
    },
    headerGreetingDesktop: {
        ...typography.h0,
    },
    headerProfile: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    headerContainer: {
        paddingVertical: 16,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
    },
    avatarContainer: { justifyContent: 'flex-end', width: '100%', maxWidth: '25%' },
    absenceContainer: {},
    absenceText: { ...typography.buttonLarge },
});

export default styles;
