import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconCall = (props: SvgProps) => (
    <Svg width={24} height={24} fill='#73CC49' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.56 2.541 14.38 6.36l-2.758 5.727L4.833 5.3l5.727-2.758Zm15.085 15.085 3.819 3.819-2.758 5.727-6.788-6.788 5.727-2.758Zm-.212 9.688L4.691 6.572.92 10.343l20.742 20.742 3.771-3.771Z'
        />
    </Svg>
);
export default SvgIconCall;
