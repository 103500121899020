import React, { ReactNode, useContext, useEffect } from 'react';
import { Text, View, Pressable } from 'react-native';
import { getGreetingByTimeofDay } from '@Utilities';
import styles from './styles';
import { useSelector } from 'react-redux';
import { RootState, store } from '@Redux';
import Avatar from '@Components/Avatar';
import { setParent } from '@Redux/parent';
import { useLazyGetParentQuery } from '@Redux/services/parent';
import { useNavigation } from '@react-navigation/native';
import { updateAccountPreference } from '@Redux/auth';
import { RootNavigationProp } from '@App/rootNavigation';
import DeviceContext from '@Contexts/DeviceContext';
import ErrorContext from '@Contexts/ErrorContext';
import i18n from '@I18n';

type DashboardHeaderProps = {
    lateButton?: ReactNode;
    absentButton?: ReactNode;
};

const Header = ({ lateButton, absentButton }: DashboardHeaderProps) => {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const parent = useSelector((state: RootState) => state.parent);
    const userId = useSelector((state: RootState) => state.auth.userId);
    const [getParent] = useLazyGetParentQuery();
    const { isDesktop } = useContext(DeviceContext);

    useEffect(() => {
        getParentProfile();
    }, []);

    const getParentProfile = async () => {
        try {
            const response = await getParent().unwrap();
            if (!response) {
                // TODO: Improve flow. Most of the time this happens only on session expired
                return;
            }
            store.dispatch(
                setParent({ firstName: response.FirstName, lastName: response.LastName, avatar: response.Photo })
            );
            store.dispatch(
                updateAccountPreference({
                    userId: userId,
                    firstName: response.FirstName,
                    profilePictureUri: response.Photo,
                })
            );
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackGetErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        }
    };

    const navigation = useNavigation<RootNavigationProp>();
    function profilePressHandler() {
        navigation.navigate('Profile');
    }

    return (
        <View style={[styles.headerContainer, !isDesktop && styles.headerBorderMobile]}>
            <View style={styles.headerGreetingContainer}>
                <Text style={[styles.headerGreeting, isDesktop && styles.headerGreetingDesktop]}>
                    {getGreetingByTimeofDay()} {parent.firstName}
                </Text>
            </View>
            {isDesktop ? (
                <View style={{ flexDirection: 'row' }}>
                    <View>{lateButton}</View>
                    <View style={{ marginLeft: 16 }}>{absentButton}</View>
                </View>
            ) : (
                <View style={styles.avatarContainer}>
                    <View style={styles.headerProfile}>
                        <View style={{ alignSelf: 'center' }}>
                            <Pressable onPress={profilePressHandler}>
                                <Avatar
                                    label={parent.firstName ? parent.firstName : '-'}
                                    size={40}
                                    picture={parent.avatar}
                                />
                            </Pressable>
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
};

export default Header;
