import type { PartialDeep } from 'type-fest';
import { baseApi } from '../base.ts';
import type {
    SchoolLocation,
    CreateStudentRegistrationBody,
    LocalAddress,
    RegistrationIdParam,
    SchoolLocations,
    StudentRegistrationContact,
} from './types';

const StudentRegistrationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSchoolsLocations: builder.query<SchoolLocations, void>({
            query: () => ({
                url: 'StudentRegistrationApi/GetSchoolsLocations',
                method: 'GET',
            }),
        }),
        createStudentRegistration: builder.mutation<void, PartialDeep<CreateStudentRegistrationBody>>({
            query: (data) => ({
                url: 'StudentRegistrationApi/CreateStudentRegistration',
                method: 'POST',
                data,
            }),
        }),
        getStudentRegistration: builder.query<CreateStudentRegistrationBody, RegistrationIdParam>({
            query: (data) => ({
                url: `StudentRegistrationApi/GetStudentRegistration/${data.registrationId}`,
                method: 'GET',
            }),
        }),
        removeStudentRegistration: builder.mutation<void, RegistrationIdParam>({
            query: (data) => ({
                url: `StudentRegistrationApi/RemoveStudentRegistration/${data.registrationId}`,
                method: 'POST',
            }),
        }),
        updateStudentRegistration: builder.mutation<void, PartialDeep<CreateStudentRegistrationBody>>({
            query: (data) => ({
                url: 'StudentRegistrationApi/UpdateStudentRegistration',
                method: 'POST',
                data,
            }),
        }),
    }),
});

export { LocalAddress, CreateStudentRegistrationBody, StudentRegistrationContact, SchoolLocations, SchoolLocation };

export const {
    useCreateStudentRegistrationMutation,
    useGetSchoolsLocationsQuery,
    useGetStudentRegistrationQuery,
    useRemoveStudentRegistrationMutation,
    useUpdateStudentRegistrationMutation,
} = StudentRegistrationApi;
