import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    label: {
        ...typography.h6,
    },
    avatar: {
        marginRight: 8,
    },
    container: {
        width: '85%',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default styles;
