import {
    ChildInfo,
    EditNotificationPreferenceRequest,
    EditProfileData,
    EditProfilePicture,
    EditStudentNotificationPreferenceRequest,
    NotificationPreferenceResponse,
    Parent,
    SocialLogin,
    StudentRegistration,
} from '../types';
import { baseApi } from './base.ts';

const parentApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getParent: builder.query<Parent, void>({
            query: () => ({
                url: 'ParentApi/GetProfile',
                method: 'GET',
            }),
        }),
        getSocialLogin: builder.query<SocialLogin[], void>({
            query: () => ({
                url: 'ParentApi/GetSocialLogin',
                method: 'GET',
            }),
        }),
        addSocialLogin: builder.mutation<void, SocialLogin>({
            query: (data) => ({
                url: 'ParentApi/AddSocialLogin',
                method: 'POST',
                data,
            }),
        }),
        removeSocialLogin: builder.mutation<void, SocialLogin>({
            query: (data) => ({
                url: 'ParentApi/RemoveSocialLogin',
                method: 'POST',
                data,
            }),
        }),
        getStudents: builder.query<ChildInfo[], void>({
            query: () => ({
                url: 'ParentApi/GetStudents',
                method: 'GET',
            }),
        }),
        getNotificationPreferences: builder.query<NotificationPreferenceResponse, void>({
            query: () => ({
                url: 'ParentApi/GetNotificationPreferences',
                method: 'GET',
            }),
        }),
        editNotificationPreferences: builder.mutation<void, EditNotificationPreferenceRequest>({
            query: (data) => ({
                url: 'ParentApi/EditNotificationPreferences',
                method: 'POST',
                data,
            }),
        }),
        editStudentNotificationPreferences: builder.mutation<void, EditStudentNotificationPreferenceRequest>({
            query: (data) => ({
                url: 'ParentApi/EditStudentNotificationPreferences',
                method: 'POST',
                data,
            }),
        }),
        getRegistrationsAndLinkings: builder.query<StudentRegistration[], void>({
            query: () => ({
                url: 'ParentApi/GetRegistrationsAndLinkings',
                method: 'GET',
            }),
        }),
        editProfile: builder.mutation<void, EditProfileData>({
            query: (data) => ({
                url: 'ParentApi/EditProfile',
                method: 'POST',
                data,
            }),
        }),
        editProfilePicture: builder.mutation<void, EditProfilePicture>({
            query: (data) => ({
                url: 'ParentApi/EditProfilePicture',
                method: 'POST',
                data,
            }),
        }),
    }),
});

export const {
    useAddSocialLoginMutation,
    useEditNotificationPreferencesMutation,
    useEditStudentNotificationPreferencesMutation,
    useGetNotificationPreferencesQuery,
    useGetParentQuery,
    useGetSocialLoginQuery,
    useGetStudentsQuery,
    useLazyGetParentQuery,
    useRemoveSocialLoginMutation,
    useGetRegistrationsAndLinkingsQuery,
    useEditProfileMutation,
    useEditProfilePictureMutation,
} = parentApi;
