import React, { ComponentProps } from 'react';
import { Text, Pressable } from 'react-native';
import { styles } from './styles';

import Checkbox from '../Checkbox';
import { StyleProp } from 'react-native';
import { ViewStyle } from 'react-native';

interface CheckboxWithLabelProps extends ComponentProps<typeof Checkbox> {
    label: string;
    styleOverride?: StyleProp<ViewStyle>;
}

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({ checked, label, disabled, onChange, styleOverride }) => (
    <Pressable
        style={styleOverride ? styleOverride : styles.checkboxBase}
        onPress={() => !disabled && onChange(!checked)}
    >
        <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
        <Text style={styles.checkboxLabel}>{label}</Text>
    </Pressable>
);

export default CheckboxWithLabel;
