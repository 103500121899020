import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    container: {
        flexGrow: 1,
        margin: 24,
        paddingBottom: 48,
    },
    buildingContainer: {
        padding: 16,
        alignItems: 'center',
    },
    buildingContainerExtraSmall: {
        display: 'none',
    },
    buildingContainerSmall: {
        paddingTop: 0,
    },
    greetings: {
        ...typography.h2,
        lineHeight: 35,
    },
    greetings2: {
        ...typography.body,
        fontWeight: 'bold',
    },
    fieldContainer: {
        paddingVertical: 16,
    },
    controlContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 36,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconWrapper: {
        borderRadius: 0,
        marginRight: 24,
        width: 50,
    },
    forgotTextContainer: {
        borderBottomColor: defaultTheme.colors.raspberry,
        borderBottomWidth: 2,
    },
    forgot: {
        ...typography.buttonSmall,
        lineHeight: 20,
    },
    signInTextContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    signIn: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    arrowRight: {
        fontSize: 24,
        marginLeft: 8,
    },
    signUpContainer: {
        alignItems: 'baseline',
        marginVertical: 20,
    },
    signUpContainerSmall: {
        flexDirection: 'row',
        marginBottom: 12,
    },
    signUpTitle: {
        ...typography.h6,
        marginBottom: 16,
        marginRight: 16,
    },
    signUpTextContainer: {
        borderBottomColor: defaultTheme.colors.grape,
        borderBottomWidth: 3,
    },
    signUp: {
        ...typography.buttonMedium,
        lineHeight: 22,
    },
    signUpIcon: {
        fontSize: 24,
        marginLeft: 8,
    },
    reminderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 200,
        position: 'absolute',
        bottom: -10,
        left: 21,
    },
    reminderContainerInverted: {
        position: undefined,
        bottom: undefined,
        left: undefined,
        marginLeft: 12,
        marginBottom: 4,
    },
    reminderText: {
        ...typography.body3,
    },
    reminderIcon: {
        paddingHorizontal: 4,
    },
    reverse: {
        flexDirection: 'row-reverse',
        left: undefined,
        right: 21,
    },
    inverted: {
        transform: [{ scaleY: -1 }],
    },
    section: {
        marginBottom: 24,
    },
    listItem: {
        marginVertical: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    labelContainer: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    label: {
        ...typography.body2,
    },
    icon: {
        width: 24,
        marginRight: 8,
    },
    errorLabelContainer: {
        marginHorizontal: 8,
    },
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.error,
    },
    socialLoginContainerSmall: {
        marginTop: 16,
    },
});
