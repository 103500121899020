import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-evenly',
    },
    desktopContainer: {
        width: 1024,
        margin: 'auto',
    },
    content: {
        flex: 9,
        paddingTop: 50,
    },
    footer: {
        flex: 1,
        borderColor: '#000000',
        borderWidth: 1,
    },
    tabBarLabel: {
        ...typography.h4,
        marginTop: 3,
        textAlign: 'center',
        fontWeight: '700',
    },
    drawerLabel: {
        ...typography.h4,
        marginLeft: 6,
        marginTop: 3,
        textAlign: 'left',
        fontWeight: '700',
        color: defaultTheme.colors.textPrimary,
    },
    drawerLabel2: {
        ...typography.h6,
    },
    copyright: {
        ...typography.body2,
    },
    underlined: {
        borderBottomColor: defaultTheme.colors.blueberry,
        borderBottomWidth: 2,
    },
    iconPlaceHolder: {
        width: 30,
        height: 30,
    },
});
