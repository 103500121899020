import { baseApi } from '../base.ts';
import { BasicLookup, Province } from './types';

const LookupApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAboriginalSelfIds: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetAboriginalSelfIds',
                method: 'GET',
            }),
        }),
        getCities: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetCities',
                method: 'GET',
            }),
        }),
        getCountries: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetCountries',
                method: 'GET',
            }),
        }),
        getGenders: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetGenders',
                method: 'GET',
            }),
        }),
        getGrades: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetGradeOptions',
                method: 'GET',
            }),
        }),
        getLanguageTypes: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetLanguageTypes',
                method: 'GET',
            }),
        }),
        getLanguages: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetLanguages',
                method: 'GET',
            }),
        }),
        getPreferredGenders: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetPreferredGenders',
                method: 'GET',
            }),
        }),
        getProvinces: builder.query<Province[], void>({
            query: () => ({
                url: 'LookupApi/GetProvinces',
                method: 'GET',
            }),
        }),
        getRelationships: builder.query<BasicLookup[], void>({
            query: () => ({
                url: 'LookupApi/GetRelationships',
                method: 'GET',
            }),
        }),
    }),
});

export { BasicLookup, Province };
export const {
    useGetAboriginalSelfIdsQuery,
    useGetCitiesQuery,
    useGetCountriesQuery,
    useGetGendersQuery,
    useGetGradesQuery,
    useGetLanguageTypesQuery,
    useGetLanguagesQuery,
    useGetPreferredGendersQuery,
    useGetProvincesQuery,
    useGetRelationshipsQuery,
} = LookupApi;
