import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import i18n from '@I18n';
import Picker from '@Components/Picker';
import TextInput from '@Components/TextInput';
import type { PartialDeep } from 'type-fest';

import { BasicLookup, useGetCountriesQuery, useGetProvincesQuery } from '@Redux/services/LookupApi';
import { Accessor, createLookupMap, getMutableForAccessor, lookupToOptions, postalMask } from '@Utilities';
import { CreateStudentRegistrationBody } from '@Redux/services/StudentRegistrationApi';

import { ManagePickerProps, ManageFieldPropFactory, TextFieldPropFactory, ManageTextProps } from '../../types';
import { styles } from '../../styles';

interface IProps {
    accessor: Accessor;
    manageFieldPropFactory: ManageFieldPropFactory;
    textFieldPropFactory: TextFieldPropFactory;
    studentRegistration: PartialDeep<CreateStudentRegistrationBody>;
}

const MailingAddress: FC<IProps> = ({
    accessor,
    manageFieldPropFactory,
    textFieldPropFactory,
    studentRegistration,
}) => {
    const { data: provinces } = useGetProvincesQuery();
    const provinceOptions = useMemo(() => lookupToOptions<BasicLookup>(provinces), [provinces]);

    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);
    const countryOptions = useMemo(() => lookupToOptions(countries), [countries]);

    const address = getMutableForAccessor(studentRegistration, [...accessor, 'Country']);
    const selectedCountry = countryMap?.[address?.Country as number];

    return (
        <View>
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterStreetName'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'StreetName'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterApartmentNumber'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'ApartmentNumber'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterRRNumber'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'RRNumber'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterPOBox'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'POBox'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterPostalCode'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'PostalCode'], 'onChangeText', 'value')}
            />
            <Picker
                {...manageFieldPropFactory<ManagePickerProps>([...accessor, 'Country'], 'onValueChange', 'value')}
                inputLabel={i18n.t('studentRegisterCountry')}
                items={countryOptions}
            />
            {selectedCountry?.Value === 'Canada' && (
                <Picker
                    {...manageFieldPropFactory<ManagePickerProps>([...accessor, 'Province'], 'onValueChange', 'value')}
                    inputLabel={i18n.t('studentRegisterProvince')}
                    items={provinceOptions}
                    style={styles.picker}
                />
            )}
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterCity'))}
                {...manageFieldPropFactory<ManageTextProps>([...accessor, 'CityFreeForm'], 'onChangeText', 'value')}
            />
        </View>
    );
};

export const MailingAddressValidator = (
    accessor: Accessor,
    studentRegistration: PartialDeep<CreateStudentRegistrationBody>,
    conditional = false
) => {
    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);

    if (!conditional) {
        return [];
    }

    return [
        {
            accessorPath: [...accessor, 'PostalCode'],
            validator: (postalCode: string) => {
                const finalMutable = getMutableForAccessor(studentRegistration, [...accessor, 'Country']);
                const country = countryMap[finalMutable.Country as number];
                if (country?.Value === 'Canada') {
                    return Boolean(postalCode);
                }
                return true;
            },
        },
        { accessorPath: [...accessor, 'Country'], validator: Boolean },
        {
            accessorPath: [...accessor, 'Province'],
            validator: (province: string) => {
                const finalMutable = getMutableForAccessor(studentRegistration, [...accessor, 'Country']);
                const country = countryMap[finalMutable.Country as number];
                if (country?.Value === 'Canada') {
                    return Boolean(province);
                }
                return true;
            },
        },
        { accessorPath: [...accessor, 'CityFreeForm'], validator: Boolean },
    ];
};

export const MailingAddressMasks = (
    accessor: Accessor,
    studentRegistration: PartialDeep<CreateStudentRegistrationBody>
) => {
    const { data: countries } = useGetCountriesQuery();
    const countryMap = useMemo(() => createLookupMap<BasicLookup>(countries), [countries]);

    return [
        {
            accessorPath: [...accessor, 'PostalCode'],
            mask: (value: string) => {
                const finalMutable = getMutableForAccessor(studentRegistration, [...accessor, 'Country']);
                const country = countryMap[finalMutable.Country as number];

                if (country?.Value === 'Canada') {
                    return postalMask(value as string);
                }
                return value;
            },
        },
    ];
};

export default MailingAddress;
