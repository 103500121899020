import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import i18n from '@I18n';
import Picker from '@Components/Picker';
import TextInput from '@Components/TextInput';
import type { PartialDeep } from 'type-fest';

import { CreateStudentRegistrationBody } from '@Redux/services/StudentRegistrationApi';
import { useGetCitiesQuery, BasicLookup } from '@Redux/services/LookupApi';
import { Accessor, lookupToOptions, postalMask } from '@Utilities';

import { ManageFieldPropFactory, TextFieldPropFactory } from '../../types';

interface IProps {
    accessor: Accessor;
    manageFieldPropFactory: ManageFieldPropFactory;
    textFieldPropFactory: TextFieldPropFactory;
    studentRegistration: PartialDeep<CreateStudentRegistrationBody>;
}

const LocalAddress: FC<IProps> = ({ accessor, manageFieldPropFactory, textFieldPropFactory }) => {
    const { data: cities } = useGetCitiesQuery();
    const cityOptions = useMemo(() => lookupToOptions<BasicLookup>(cities), [cities]);
    return (
        <View>
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterStreetName'))}
                {...manageFieldPropFactory([...accessor, 'StreetName'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterApartmentNumber'))}
                {...manageFieldPropFactory([...accessor, 'ApartmentNumber'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterRRNumber'))}
                {...manageFieldPropFactory([...accessor, 'RRNumber'], 'onChangeText', 'value')}
            />
            {/* <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterPOBox'))}
                {...manageFieldPropFactory([...accessor, 'POBox'], 'onChangeText', 'value')}
            /> */}
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterPostalCode'))}
                {...manageFieldPropFactory([...accessor, 'PostalCode'], 'onChangeText', 'value')}
            />
            <Picker
                inputLabel={i18n.t('studentRegisterCity')}
                {...manageFieldPropFactory([...accessor, 'City'], 'onValueChange', 'value')}
                items={cityOptions}
            />
        </View>
    );
};

export const LocalAddressValidator = (
    accessor: Accessor,
    studentRegistration: PartialDeep<CreateStudentRegistrationBody>,
    conditional = false
) => {
    if (!conditional) {
        return [];
    }

    return [
        { accessorPath: [...accessor, 'StreetName'], validator: Boolean },
        { accessorPath: [...accessor, 'PostalCode'], validator: Boolean },
        { accessorPath: [...accessor, 'City'], validator: Boolean },
    ];
};

export const LocalAddressMasks = (accessor: Accessor) => {
    return [{ accessorPath: [...accessor, 'PostalCode'], mask: postalMask }];
};

export default LocalAddress;
