import React, { ReactNode, useState, useCallback } from 'react';
import { Pressable, ViewProps, View } from 'react-native';
import { EventDetailsModal } from '@Components/EventDetails';

import { CalendarEvent, EventTypes } from '@Redux/services/CalendarApi';

import BasicEventModal from '@Screens/Calendar/components/BasicEventModal';

interface EventPressableProps extends ViewProps {
    event: CalendarEvent;
    onEventAccepted?: () => Promise<void>;
    children: ReactNode;
}

const pressableEvents = ['fieldtrip', 'offers'];
function isEventPayable(type: EventTypes) {
    return pressableEvents.includes(type);
}

function EventPressable({ event, children, onEventAccepted, ...rest }: EventPressableProps) {
    const [showSelectedEventDetails, setSelectedEventId] = useState<boolean>(false);

    const handleShowEventDetailsPress = useCallback(() => {
        setSelectedEventId(true);
    }, [event]);

    const dismissEventDetail = useCallback(() => {
        setSelectedEventId(false);
    }, [setSelectedEventId]);

    return (
        <>
            <Pressable {...rest} onPress={handleShowEventDetailsPress}>
                {children}
            </Pressable>
            {isEventPayable(event.type) && showSelectedEventDetails ? (
                <EventDetailsModal
                    event={event}
                    eventResponseId={event.id}
                    onDismiss={dismissEventDetail}
                    show={showSelectedEventDetails}
                    onEventAccepted={onEventAccepted}
                />
            ) : (
                <BasicEventModal event={event} onDismiss={dismissEventDetail} show={showSelectedEventDetails} />
            )}
        </>
    );
}

export default EventPressable;
