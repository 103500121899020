import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgPlus = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.75.833h-3.5V7.25H.833v3.5H7.25v6.417h3.5V10.75h6.417v-3.5H10.75V.833Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgPlus;
