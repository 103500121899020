import { View, Text, Pressable } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import i18n from '@I18n';
import { styles } from './styles';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import TriangleExclamation from '@Icon/TriangleExclamation';
import { withLayoutSm } from '@Screens/common';

type ForgotConfirmationScreenProp = NativeStackScreenProps<RootStackParamList, 'forgotConfirmation'>;

function ForgotConfirmationScreen({ navigation }: ForgotConfirmationScreenProp) {
    function handlePress() {
        navigation.replace('signin');
    }

    return (
        <ScrollView keyboardShouldPersistTaps='handled'>
            {withLayoutSm(
                <View style={styles.container}>
                    <View style={styles.triangleContainer}>
                        <TriangleExclamation width={128} height={128} />
                    </View>
                    <View>
                        <Text style={styles.title} accessibilityLabel={i18n.t('forgotConfirmation1')}>
                            {i18n.t('forgotConfirmation1')}
                        </Text>
                    </View>
                    <View style={styles.bodyContainer}>
                        <Text style={styles.body} accessibilityLabel={i18n.t('forgotConfirmation2')}>
                            {i18n.t('forgotConfirmation2')}
                        </Text>
                    </View>
                    <View style={styles.bodyContainer}>
                        <Text style={styles.body} accessibilityLabel={i18n.t('forgotConfirmation3')}>
                            {i18n.t('forgotConfirmation3')}
                        </Text>
                    </View>
                    <View style={styles.controlContainer}>
                        <Pressable onPress={handlePress}>
                            <View style={styles.resendEmailTextContainer}>
                                <Text style={styles.resendEmail} accessibilityLabel={i18n.t('forgotConfirmation4')}>
                                    {i18n.t('forgotConfirmation4')}
                                </Text>
                            </View>
                        </Pressable>
                    </View>
                </View>
            )}
        </ScrollView>
    );
}

export default ForgotConfirmationScreen;
