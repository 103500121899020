import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    checkbox: {
        minHeight: 40,
        paddingLeft: 8,
    },
    eventStatusWrapper: {
        marginLeft: 15,
    },
    filterWrapper: {
        minWidth: 300,
        padding: 16,
        overflowY: 'scroll',
    },
    filterTitle: {
        ...typography.h4,
        lineHeight: 32,
        paddingRight: 8,
    },
    filterTitleWrapper: {
        flexDirection: 'row',
        paddingTop: 8,
        alignItems: 'center',
        marginBottom: 15,
    },
    topBorder: {
        borderTopWidth: 2,
        borderColor: defaultTheme.colors.textPrimary,
        marginTop: 32,
    },
    label: {
        ...typography.body2,
        color: defaultTheme.colors.textPrimary,
        marginLeft: 10,
    },
});
