import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        maxWidth: '100%',
    },
    label: {
        ...typography.body3,
        marginBottom: 4,
    },
    icon: {
        marginRight: 24,
    },
    header: {
        ...typography.h5,
        marginBottom: 8,
    },
    body: {
        ...typography.body,
        marginBottom: 8,
    },
    button: {
        ...typography.buttonMedium,
    },
});

export default styles;
