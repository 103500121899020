import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgPaypalLogoDisabled = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M19.12 6.833c-.02.127-.043.256-.069.389-.874 4.49-3.865 6.04-7.686 6.04H9.42a.945.945 0 0 0-.934.8L7.49 20.38l-.282 1.79a.498.498 0 0 0 .491.576h3.45a.83.83 0 0 0 .82-.7l.034-.175.65-4.123.042-.226a.83.83 0 0 1 .82-.701h.516c3.342 0 5.96-1.357 6.724-5.285.32-1.64.154-3.01-.691-3.974a3.3 3.3 0 0 0-.945-.728Z'
            fill='#373639'
            fillOpacity={0.53}
        />
        <Path
            d='M18.205 6.469a6.898 6.898 0 0 0-.85-.19c-.525-.084-1.1-.124-1.716-.124h-5.196a.825.825 0 0 0-.82.7l-1.105 7.003-.032.204c.073-.46.466-.8.934-.8h1.945c3.82 0 6.812-1.551 7.687-6.04.026-.133.048-.262.067-.389a4.66 4.66 0 0 0-.914-.364Z'
            fill='#373639'
            fillOpacity={0.53}
        />
        <Path
            d='M18.205 6.469a6.898 6.898 0 0 0-.85-.19c-.525-.084-1.1-.124-1.716-.124h-5.196a.825.825 0 0 0-.82.7l-1.105 7.003-.032.204c.073-.46.466-.8.934-.8h1.945c3.82 0 6.812-1.551 7.687-6.04.026-.133.048-.262.067-.389a4.66 4.66 0 0 0-.914-.364Z'
            fill='#373639'
            fillOpacity={0.53}
        />
        <Path
            d='M9.623 6.856a.826.826 0 0 1 .82-.7h5.197c.615 0 1.19.04 1.714.125a6.89 6.89 0 0 1 1.047.25c.258.085.498.186.72.302.26-1.659-.003-2.788-.9-3.811-.989-1.126-2.774-1.608-5.058-1.608h-6.63a.948.948 0 0 0-.937.8L2.835 19.72a.57.57 0 0 0 .562.659H7.49l1.028-6.52 1.105-7.003Z'
            fill='#373639'
            fillOpacity={0.53}
        />
    </Svg>
);
export default SvgPaypalLogoDisabled;
