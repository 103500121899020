import { BuildingGovernment, Home1, IconBus, LanguageFrench, Medical, SchoolLocation } from '@Icon/index';
import { FC } from 'react';
import { Pressable, Platform } from 'react-native';
import { View, Text, Linking } from 'react-native';
import { Menu, Portal } from 'react-native-paper';
import { styles } from './styles';
import { useMemo, useState } from 'react';
import useTheme from '@Hooks/useTheme';
import i18n from '@I18n';
import WarningDialog from '../Dialog';

interface IProps {
    show: boolean;
    onDismiss: () => void;
    coords: { x: number; y: number };
}
const HelpMenu: FC<IProps> = ({ show, onDismiss, coords }) => {
    const [showWarning, setShowWarning] = useState(false);
    const [openUrl, setOpenUrl] = useState<string>();

    const theme = useTheme();
    const menuItems = useMemo(
        () => [
            {
                icon: <Home1 />,
                label: i18n.t('studentRegisterHelp1'),
                url: 'https://www.dcp.edu.gov.on.ca/en/curriculum/kindergarten',
            },
            {
                icon: <LanguageFrench />,
                label: i18n.t('studentRegisterHelp2'),
                url: 'http://www.ucdsb.on.ca/programs___initiatives/school_programs_k-12/french_programming/',
            },
            {
                icon: <Medical />,
                label: i18n.t('studentRegisterHelp3'),
                url: 'https://www.ontario.ca/page/health-care-ontario',
            },
            {
                icon: <BuildingGovernment />,
                label: i18n.t('studentRegisterHelp4'),
                url: 'http://www.edu.gov.on.ca/eng/parents/faq-parents.html',
            },
            {
                icon: <IconBus fill={theme.colors.kiwi} />,
                label: i18n.t('studentRegisterHelp5'),
                url: 'https://www.steo.ca/about-us/policies/',
            },
            {
                icon: <SchoolLocation />,
                label: i18n.t('studentRegisterHelp6'),
                url: 'https://transportation.steo.ca/Eligibility.aspx?Page=School',
            },
        ],
        []
    );

    const handleOpenURL = (url: string) => {
        onDismiss();
        if (Platform.OS !== 'web') {
            setShowWarning(true);
            setOpenUrl(url);
        } else {
            Linking.openURL(url);
        }
    };

    const handleCloseWarningDialog = () => {
        setShowWarning(false);
        setOpenUrl(undefined);
    };

    return (
        <>
            <Menu visible={show} onDismiss={onDismiss} anchor={{ x: coords.x ?? 0, y: coords.y ?? 0 }}>
                {menuItems.map((item, i) => (
                    <Pressable
                        onPress={() => handleOpenURL(item.url)}
                        android_ripple={{ color: theme.colors.disabled }}
                        key={`help-menu-item${i}`}
                    >
                        <View style={styles.itemContainer}>
                            <View style={styles.icon}>{item.icon}</View>
                            <Text style={styles.title}>{item.label}</Text>
                        </View>
                    </Pressable>
                ))}
            </Menu>
            <Portal>
                {showWarning && openUrl && (
                    <WarningDialog
                        show={showWarning}
                        onDismiss={handleCloseWarningDialog}
                        message={i18n.t('studentRegisterHelpWarning')}
                        onLeavePress={() => {
                            Linking.openURL(openUrl);
                            handleCloseWarningDialog();
                        }}
                        onStayPress={handleCloseWarningDialog}
                    />
                )}
            </Portal>
        </>
    );
};

export default HelpMenu;
