import { defaultTheme } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    switchContainer: {
        borderWidth: 2,
        width: 23,
        height: 14,
        justifyContent: 'center',
        padding: 1,
    },
    switchCheckedContainer: {
        backgroundColor: 'black',
    },
    switchCheckedDisbledContainer: {
        backgroundColor: defaultTheme.colors.disabled,
        borderColor: defaultTheme.colors.disabled,
    },
    UnCheckedDisabledContainer: {
        backgroundColor: defaultTheme.colors.disabled,
        borderColor: defaultTheme.colors.disabled,
    },
    UnCheckedNotDisabledContainer: {
        backgroundColor: 'white',
        borderColor: 'black',
    },
    circle: {
        borderRadius: 50,
        width: 7,
        height: 7,
    },
    unCheckedCircle: {
        backgroundColor: 'black',
    },
    unCheckedDisabledCircle: {
        backgroundColor: 'black',
    },
    checkedCircle: {
        backgroundColor: 'white',
        alignSelf: 'flex-end',
    },
    checkedDisabledCircle: {
        backgroundColor: 'white',
        alignSelf: 'flex-end',
    },
});

export default styles;
