import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgHash = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.568 4.489h4.818v5.08h3.228v-5.08h4.818v5.08h5.08v4.817h-5.08v3.228h5.08v4.817h-5.08v5.08h-4.818v-5.08h-3.228v5.08H9.568v-5.08h-5.08v-4.817h5.08v-3.229h-5.08V9.569h5.08v-5.08Zm4.818 13.125h3.228v-3.229h-3.228v3.23Z'
            fill='#37363987'
        />
    </Svg>
);
export default SvgHash;
