import React, { useState, useEffect, useMemo, useContext } from 'react';

import Modal from '@Components/Modal';
import i18n from '@I18n/index';
import { ActivityIndicator } from 'react-native-paper';
import { Text, View, ScrollView, Linking, Platform, Pressable } from 'react-native';
import styles from './styles';
import useTheme, { defaultTheme } from '@Hooks/useTheme';
import Button from '@Components/Button';
import {
    useConfirmEventDetailsMutation,
    useLazyGetEventDetailsPreviewQuery,
    useLazyGetEventDetailsQuery,
} from '@Redux/services/EventOfferApi';
import ModalHeader from '@Components/ModalHeader';
import { Answer, EventDetails, EventItem } from '@Redux/services/EventOfferApi/types';
import { useGetPaymentConfigurationQuery, useGetRecentTransactionsQuery } from '@Redux/services/PaymentApi';
import { Attribute } from '@Redux/services/EventOfferApi/types';

import {
    chain,
    cloneDeep,
    forEach,
    isBoolean,
    isEmpty,
    isNumber,
    isString,
    isUndefined,
    keyBy,
    keys,
    map,
    range,
    set,
    some,
    values,
} from 'lodash';

import Avatar from '@Components/Avatar';
import IconList from '@Components/IconList';
import { useGetStudentDetailsQuery, useGetStudentSchoolQuery } from '@Redux/services/student';
import {
    IconStudent,
    Info,
    Folder,
    Scissor,
    IconCall,
    Success,
    Error,
    LeftArrow,
    Checkmark,
    Rule,
    Select,
    ContactSupport,
} from '@Icon';

import Header from './Header';
import CostItem from './CostItem';
import Question from './Question';
import Safety from './Safety';
import Disclaimer from './Disclaimer';
import AddBalanceModal from '@Screens/Profile/Balance/AddBalanceModal';
import { currencyFormat } from '@Utilities';
import { format, isPast } from 'date-fns';
import { CostGroup, LabelsList, OptionGroups } from './Interfaces';
import DeviceContext from '@Contexts/DeviceContext';
import { useGetParentQuery, useGetStudentsQuery } from '@Redux/services/parent';
import { CalendarEvent } from '@Redux/services/CalendarApi';
import CheckboxWithLabel from '@Components/SwitchInput/components/CheckboxWithLabel';
import Alert from './Alert';
import ErrorAlert from '@Components/ErrorAlert';

interface IProps {
    show: boolean;
    event?: CalendarEvent;
    eventResponseId?: string;
    eventPreviewId?: string;
    onDismiss: () => void;
    onEventAccepted?: () => Promise<void>;
}

const EventDetailsModal: React.FC<IProps> = ({
    show,
    event,
    eventResponseId,
    eventPreviewId,
    onDismiss,
    onEventAccepted,
}) => {
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [qas, setQAs] = useState<Answer[]>();
    const [costItems, setCostItems] = useState<OptionGroups[]>();
    const [loading, setLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const { data: paymentConfiguration, refetch: getPaymentConfiguration } = useGetPaymentConfigurationQuery(
        undefined,
        { skip: !eventResponseId }
    );
    const { refetch: getParent } = useGetParentQuery(undefined, { skip: !eventResponseId });
    const [paymentOptions, setPaymentOptions] = useState<number[]>();
    const [price, setPrice] = useState(0);
    const [amountRequired, setAmountRequired] = useState('');
    const [acceptDisclaimer, setAcceptDisclaimer] = useState(false);
    const [acceptDisclaimerError, setAcceptDisclaimerError] = useState(false);
    const { refetch: refetchProfile } = useGetParentQuery(undefined, { skip: !eventResponseId });
    const { data: students } = useGetStudentsQuery(undefined, { skip: !eventResponseId });
    const { refetch: refetchRecentTransactions } = useGetRecentTransactionsQuery(
        { page: 1, pageSize: 5 },
        { skip: !eventResponseId }
    );
    const [eventDetails, setEventDetails] = useState<EventDetails>();
    const [isLoading, setIsLoading] = useState(false);
    const [isPreview, setIsPreview] = useState(false);

    const studentsMap = keyBy(students, 'StudentId');

    const costItemIconHeader = useMemo(
        () => ({
            required: { icon: <Scissor style={styles.iconSpace} />, header: i18n.t('requiredHeader') },
            'Select One': { icon: <Rule style={styles.iconSpace} />, header: i18n.t('selectOneOrMore') },
            optional: { icon: <Select style={styles.iconSpace} />, header: i18n.t('optional') },
        }),
        []
    );

    const { data: studentDetails } = useGetStudentDetailsQuery(
        { studentId: eventDetails?.StudentId ?? '' },
        {
            skip: !eventDetails || !eventResponseId,
        }
    );
    const { data: schoolDetails } = useGetStudentSchoolQuery(
        { studentId: eventDetails?.StudentId ?? '' },
        {
            skip: !eventDetails || !eventResponseId,
        }
    );
    const [confirmEventDetails] = useConfirmEventDetailsMutation();
    const theme = useTheme();

    const statusCodes = useMemo(
        () => ({
            Reschedule: 'Reschedule',
            New: 'New',
            Accepted: 'Accepted',
            Ignore: 'Ignore',
            Past: 'Past',
        }),
        []
    );

    const [getEventDetails] = useLazyGetEventDetailsQuery();
    const [getEventDetailsPreview] = useLazyGetEventDetailsPreviewQuery();

    useEffect(() => {
        fetchData();
    }, [eventResponseId, eventPreviewId]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            if (eventResponseId) {
                setIsPreview(false);
                const response = await getEventDetails({ eventResponseId }).unwrap();
                setEventDetails(response);
            } else if (eventPreviewId) {
                setIsPreview(true);
                const response = await getEventDetailsPreview({ eventPreviewId }).unwrap();
                setEventDetails(response);
            }
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackGetErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (eventDetails) {
            const test = toQuestionObject(
                eventDetails.Questions,
                eventDetails.EventResponseAnswers ? eventDetails.EventResponseAnswers.Answers : []
            );
            setQAs(test);
            getCostItems();
        }
    }, [eventDetails]);

    useEffect(() => {
        calculateTotalPrice(costItems);
    }, [costItems, setCostItems]);

    function attributesToObject(item?: EventItem) {
        type LabelGroup = Attribute & {
            Group?: string;
            Selected?: string;
        };
        interface ItemAttributes {
            ucdsb_quantity?: number;
            maxQuantity?: number[];
            ucdsb_maxquantityallowed?: number;
            labelGroups?: LabelGroup[];
            groups?: LabelsList[];
            selectedGroups?: any;
            defaultSelectedGroups?: any[];
        }
        const newItem: Record<string, string | number | boolean> & ItemAttributes = {};
        const labelGroups: LabelGroup[] = [];
        if (!item) return {};

        if (Object.prototype.hasOwnProperty.call(item, 'Attributes')) {
            for (const attr of item.Attributes) {
                if (attr.Key === 'ucdsb_eventid') {
                    newItem[attr.Key] = !(isString(attr.Value) || isBoolean(attr.Value) || isNumber(attr.Value))
                        ? attr.Value.Id
                        : '';
                } else {
                    newItem[attr.Key] =
                        isString(attr.Value) || isBoolean(attr.Value) || isNumber(attr.Value)
                            ? attr.Value
                            : attr.Value.Value;
                }
                const label = attr.Key.match(/ucdsb_option(.*)label/);
                if (label) {
                    const newAttr = { ...attr, Group: '', Selected: '' };
                    newAttr.Group = 'ucdsb_option' + label[1] + 'group';
                    newAttr.Selected = 'ucdsb_isoption' + label[1] + 'selected';
                    labelGroups.push(newAttr);
                }
            }
            newItem['labelGroups'] = labelGroups;
        }
        return newItem;
    }

    function getResponseAnswerObj(id: string, responseAnswers?: EventItem[]) {
        const responseAnswer = responseAnswers?.find((ra) => {
            return ra.Attributes.some((attr) => {
                return attr.Key === 'ucdsb_eventquestionid' && typeof attr.Value !== 'string' && attr.Value.Id === id;
            });
        });

        return attributesToObject(responseAnswer);
    }

    function toQuestionObject(questions: EventItem[], responseAnswers: EventItem[]) {
        const newItem: Record<string, string | boolean | number> = {};
        const answers: Answer[] = [];
        responseAnswers = responseAnswers || [];

        if (!questions) return [];
        for (const item of questions) {
            const responseAnswer = getResponseAnswerObj(item.Id, responseAnswers);

            for (const attr of item.Attributes) {
                newItem[attr.Key] =
                    isString(attr.Value) || isBoolean(attr.Value) || isNumber(attr.Value)
                        ? attr.Value
                        : attr.Value.Value;
                if (
                    attr.Key === 'ucdsb_eventid' &&
                    Object.prototype.hasOwnProperty.call(attr.Value, 'Id') &&
                    !isString(attr.Value)
                ) {
                    newItem[attr.Key] = attr.Value.Id;
                }
            }

            const questionAnswer =
                responseAnswer.ucdsb_answer === true || responseAnswer.ucdsb_answer === false
                    ? responseAnswer.ucdsb_answer
                    : newItem.ucdsb_isrequired
                    ? undefined
                    : false;

            const answer = {
                ucdsb_text: newItem.ucdsb_text,
                answer: questionAnswer,
                isValid: true,
                isCommentValid: true,
                ucdsb_answer: isBoolean(questionAnswer) ? questionAnswer : undefined,
                ucdsb_comment: responseAnswer.ucdsb_comment || undefined,
                ucdsb_isrequired:
                    ((newItem.ucdsb_isrequired ||
                        newItem.ucdsb_iscommentrequired ||
                        newItem.ucdsb_iscommentrequired_no) &&
                        newItem.ucdsb_yesnotypeofquestion) ||
                    (newItem.ucdsb_isrequired && !newItem.ucdsb_yesnotypeofquestion), // if comment is required - then answer should be required as well UPCA0016-1825
                ucdsb_iscommentrequired: newItem.ucdsb_iscommentrequired,
                ucdsb_iscommentrequired_no: newItem.ucdsb_iscommentrequired_no,
                ucdsb_eventresponseid: eventDetails?.EventResponse?.Id,
                ucdsb_eventquestionid: newItem.ucdsb_eventquestionid,
                ucdsb_eventid: newItem.ucdsb_eventid,
                ucdsb_yesnotypeofquestion: newItem.ucdsb_yesnotypeofquestion,
            };
            answers.push(answer);
        }

        return answers;
    }

    const getCostItems = () => {
        const costItems = eventDetails?.CostItems;
        const costItemAnswers = eventDetails?.EventResponseAnswers?.CostItems;
        const required: any = [];
        const selectOne: any = [];
        const optional: any = [];
        forEach(costItems, function (costItem) {
            const requiredness = chain(costItem.FormattedValues)
                .filter(function (value) {
                    return value.Key === 'ucdsb_requirednesstype';
                })
                .map(function (item) {
                    return item.Value;
                })
                .head()
                .value();
            const newCostItemAnswer = chain(costItemAnswers)
                .find(function (value) {
                    return some(value.Attributes, function (attr) {
                        return (
                            attr.Key === 'ucdsb_costitemid' && !isString(attr.Value) && attr.Value.Id === costItem.Id
                        );
                    });
                })
                .value();
            const costItemAnswer = attributesToObject(newCostItemAnswer);

            switch (isString(requiredness) && requiredness) {
                case 'Required':
                    required.push(toCostItemObject(costItem, costItemAnswer, 1)); //required always has at least one selected
                    break;
                case 'Required Select One':
                    selectOne.push(toCostItemObject(costItem, costItemAnswer, 0));
                    break;
                case 'Optional':
                    optional.push(toCostItemObject(costItem, costItemAnswer, 0));
                    break;
            }
        });
        setCostItems([
            { name: 'required', groups: required },
            { name: 'Select One', groups: selectOne },
            { name: 'optional', groups: optional },
        ]);
    };

    function getMaxSubsidyAmount(eventResponse: EventItem) {
        if (!eventResponse) return 0;

        const maxSubsidyAttribute = chain(eventResponse.Attributes)
            .find(function (attr) {
                return attr.Key === 'ucdsb_maxsubsidyamount';
            })
            .value();

        if (maxSubsidyAttribute && isString(maxSubsidyAttribute.Value)) return parseInt(maxSubsidyAttribute.Value, 10);

        return 0;
    }
    const calculateTotalPrice = (optionGroups: any) => {
        let totalPrice = 0;
        chain(optionGroups)
            .map(function (item) {
                return item.groups;
            })
            .flatten()
            .forEach(function (item) {
                item.ucdsb_unitcost = item.ucdsb_unitcost ? item.ucdsb_unitcost : 0;
                totalPrice += item.ucdsb_quantity * item.ucdsb_unitcost;
            })
            .value();
        if (eventDetails?.EventResponse) {
            const maxSubsidyAmount = getMaxSubsidyAmount(eventDetails?.EventResponse);
            if (maxSubsidyAmount && maxSubsidyAmount > 0) {
                totalPrice -= maxSubsidyAmount;
                if (totalPrice < 0) totalPrice = 0;
            }
        }
        setPrice(totalPrice);
        paymentConfiguration &&
            setAmountRequired(parseFloat(`${totalPrice - paymentConfiguration.CurrentBalance}`).toFixed(2));
        return totalPrice;
    };

    function toCostItemObject(
        eventItem: EventItem,
        costItemAnswer: Record<string, string | boolean | any>,
        minQuantity: number
    ) {
        const costItem = attributesToObject(eventItem);
        const selectedGroups: any = {};
        const defaultSelectedGroups: any = {};

        let labelsList: LabelsList[] = [];
        const groupsObject: Record<string | number, any> = {};
        forEach(keys(costItem), function (item) {
            const label = item.match(/ucdsb_option(.*)label/);
            if (label) {
                const group = costItem['ucdsb_option' + label[1] + 'group'];
                const selectedLabel = 'ucdsb_isoption' + label[1] + 'selected';
                const optionLabel = 'ucdsb_option' + label[1] + 'label';
                if (group) {
                    if (!isBoolean(group) && !groupsObject[group]) groupsObject[group] = [];

                    const groupItem = {
                        name: group,
                        value: selectedLabel,
                        selected: costItem[optionLabel],
                        selectedLabel: selectedLabel,
                        label: !isBoolean(costItem[optionLabel]) ? '' + costItem[optionLabel] : '',
                    };

                    if (costItemAnswer && costItemAnswer[selectedLabel] && !isBoolean(group)) {
                        selectedGroups[group] = groupItem;
                    } else if (!isBoolean(group) && !selectedGroups[group]) {
                        selectedGroups[group] = groupItem;
                        defaultSelectedGroups[group] = groupItem;
                    }
                    if (!isBoolean(group)) {
                        groupsObject[group].push(groupItem);
                    }
                } else {
                    selectedGroups[selectedLabel] = { selectedLabel: costItemAnswer[selectedLabel] };
                    labelsList.push({
                        type: 'label',
                        name: isString(costItem[optionLabel]) ? '' + costItem[optionLabel] : '',
                        selected: costItemAnswer[selectedLabel],
                        selectedLabel: selectedLabel,
                        values: [],
                        number: +label[1],
                    });
                }
            }
        });

        const groupsList: LabelsList[] = map(values(groupsObject), function (group, i) {
            return {
                type: 'group',
                values: group,
                name: group[0].name,
                label: group.label,
                number: i + 1,
                selected: false,
                selectedLabel: group.selectedLabel,
            };
        });
        labelsList = labelsList.sort(function (a, b) {
            if (!isUndefined(a.number) && !isUndefined(b.number)) return a.number - b.number;
            else return 0;
        });
        costItem.groups = labelsList.concat(groupsList);
        costItem.selectedGroups = selectedGroups;
        costItem.defaultSelectedGroups = defaultSelectedGroups;
        if (costItem.ucdsb_maxquantityallowed) {
            costItem.ucdsb_maxquantityallowed =
                costItem.ucdsb_maxquantityallowed > 100 ? 100 : costItem.ucdsb_maxquantityallowed;
            costItem.maxQuantity = range(minQuantity, ++costItem.ucdsb_maxquantityallowed || 2);
        }

        costItem.ucdsb_quantity = minQuantity;
        if (!isEmpty(costItemAnswer)) {
            costItem.ucdsb_quantity = costItemAnswer.ucdsb_quantity;
            forEach(Object.keys(costItemAnswer), function (key) {
                const isMatch = key.match(/ucdsb_isoption(.*)selected/);
                if (isMatch && costItemAnswer[key] === true) {
                    groupsObject[key] = true;
                }
            });
        }

        return costItem;
    }

    const handleChange = (index: number, value: boolean | string) => {
        if (!qas) {
            return;
        }
        const newQAs = [...qas];
        const item = newQAs[index];
        if (
            (item.ucdsb_yesnotypeofquestion && item.ucdsb_iscommentrequired && !value) ||
            (item.ucdsb_yesnotypeofquestion && item.ucdsb_iscommentrequired_no && value)
        ) {
            item.ucdsb_comment = undefined;
        }
        item.ucdsb_answer = value;
        item.answer = value;
        setQAs(newQAs);
    };

    const handleCommentChange = (index: number, value: string) => {
        if (!qas) {
            return;
        }
        const newQAs = [...qas];
        newQAs[index].ucdsb_comment = value;
        setQAs(newQAs);
    };

    const handleAcceptClick = async () => {
        if (!eventDetails || loading) return;
        let hasError = false;
        if (eventDetails?.IsFieldTrip || price > 0) {
            if (!acceptDisclaimer) {
                setAcceptDisclaimerError(true);
                hasError = true;
            } else {
                setAcceptDisclaimerError(false);
            }
        }
        if (
            !eventDetails.StatusCode ||
            eventDetails.StatusCode === statusCodes.Accepted ||
            eventDetails.StatusCode === statusCodes.Ignore
        )
            return;

        const newCostItems = costItems ? cloneDeep(costItems) : [];

        const selectOneOptionItems = newCostItems.filter(function (item) {
            return item.name === 'Select One';
        });
        const selectOneItems = chain(selectOneOptionItems)
            .map(function (item) {
                return item.groups;
            })
            .flatten()
            .value();

        if (selectOneItems.length > 0) {
            let answer_error: string | undefined;
            if (!selectOneItems.some((item) => item.ucdsb_quantity && item.ucdsb_quantity > 0)) {
                answer_error = 'Please make one or more selection.';
            }
            selectOneOptionItems[0].group_error = answer_error;
            if (!hasError) {
                hasError = !isUndefined(answer_error);
            }
        }
        setCostItems(newCostItems);
        if (qas) {
            for (const item of qas) {
                if (item.ucdsb_yesnotypeofquestion) {
                    item.answer_error = undefined;
                    if (
                        (item.ucdsb_iscommentrequired &&
                            item.ucdsb_answer === true &&
                            (isEmpty(item.ucdsb_comment) || isUndefined(item.ucdsb_comment))) ||
                        (item.ucdsb_iscommentrequired_no &&
                            item.ucdsb_answer === false &&
                            (isEmpty(item.ucdsb_comment) || isUndefined(item.ucdsb_comment)))
                    ) {
                        // if comment is required if user answers "No" but is empty
                        item.answer_error = 'This is a required field';
                        hasError = true;
                    }

                    if (item.ucdsb_isrequired && isUndefined(item.ucdsb_answer)) {
                        // if Yes/No answer is required but neither option is selected
                        item.answer_error = 'This is a required field';
                        hasError = true;
                    }
                } else if (item.ucdsb_isrequired && isEmpty(item.ucdsb_comment)) {
                    // if Yes/No options should be hidden and only comment is required but is empty
                    item.answer_error = 'This is a required field';
                    hasError = true;
                } else {
                    item.answer_error = undefined;
                }
            }
            setQAs([...qas]);
        }

        if (hasError) {
            return;
        }

        const totalPrice = calculateTotalPrice(costItems);
        if (paymentConfiguration && paymentConfiguration.CurrentBalance - totalPrice < 0) {
            setPaymentOptions([totalPrice, totalPrice + 10, totalPrice + 20, totalPrice + 30]);
            setShowPayment(true);
            return;
        }

        if (paymentConfiguration && totalPrice > paymentConfiguration?.MaxEWalletBalance) {
            setPaymentOptions([totalPrice, totalPrice + 10, totalPrice + 20, totalPrice + 30]);
            setShowPayment(true);
            return;
        }

        if (!eventResponseId) {
            return;
        }

        setLoading(true);
        try {
            await confirmEventDetails({
                EventResponseId: eventResponseId,
                CostItems: getCostItemsResponses(),
                Answers: qas ? qas : [],
            }).unwrap();
            getEventDetails({ eventResponseId });
            getPaymentConfiguration();
            getParent();
            onEventAccepted && onEventAccepted();
            onDismiss();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackConfirmEventErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCostItemChange = (type: string, index: number, value: any, attribute: any, groupIndex: number) => {
        if (!costItems || costItems.length === 0) return;
        const newCostItems = [...costItems];
        const costItem = newCostItems.find((costItem) => costItem.name === type);
        if (costItem) {
            const itemIndex = newCostItems.indexOf(costItem);
            if (attribute in newCostItems[itemIndex].groups[groupIndex]) {
                set(newCostItems[itemIndex].groups[groupIndex], attribute, value);
                setCostItems(newCostItems);
            }
        }
    };

    function getCostItemsResponses() {
        return chain(costItems)
            .map(function (item) {
                return item.groups;
            })
            .flatten()
            .filter(function (item) {
                const quantity = item.ucdsb_quantity || 0;
                return quantity > 0;
            })
            .map(function (item) {
                const response: any = {
                    ucdsb_quantity: item.ucdsb_quantity,
                    ucdsb_costitemid: item.ucdsb_costitemid,
                    ucdsb_eventid: item.ucdsb_eventid,
                };

                forEach(values(item.selectedGroups), function (selectedGroup) {
                    if (selectedGroup.value) {
                        response[selectedGroup.selectedLabel] = true;
                    }
                });
                return response;
            })
            .value();
    }

    const handleAddPaymentModalDismiss = () => {
        setShowPayment(false);
        getPaymentConfiguration();
        refetchProfile();
        refetchRecentTransactions();
    };

    const hasCostItems = useMemo(() => costItems && costItems?.some((item) => item.groups.length > 0), [costItems]);

    const disabled = useMemo(
        () => !eventDetails ||
            [statusCodes.Accepted, statusCodes.Ignore, statusCodes.Past].includes(eventDetails.StatusCode) ||
            isPast(new Date(eventDetails.ActualStart)),
        [eventDetails]
    );

    const showAcceptButton = useMemo(
        () => eventDetails &&
            [statusCodes.New, statusCodes.Reschedule].includes(eventDetails.StatusCode) &&
            !isPast(new Date(eventDetails.ActualStart)),
        [eventDetails]
    );

    const handleCostItemCollapse = (
        type: string,
        costGroup: CostGroup,
        index: number,
        quantity: number,
        defaultSelectedGroups?: Record<string | number, any>
    ) => {
        if (type === 'required') return;
        const newCostItems = cloneDeep(costItems);
        const costItem = chain(newCostItems)
            .filter((item) => item.name === type)
            .flatten()
            .value();
        if (costItem.length > 0) {
            costGroup.selectedGroups = defaultSelectedGroups;
            costGroup.ucdsb_quantity = quantity;
            costItem[0].groups[index] = { ...costGroup };
            setCostItems(newCostItems);
        }
    };

    const schoolPhone = useMemo(
        () => eventDetails && studentsMap[eventDetails.StudentId]?.SchoolInfo.Telephone,
        [studentsMap, eventDetails]
    );

    const showInactiveAlert = useMemo(
        () => paymentConfiguration && !paymentConfiguration?.IsPaymentActive && price > 0,
        [paymentConfiguration, price]
    );

    const getPayButtonLabel = () => {
        if (eventDetails?.IsFieldTrip && price > 0) {
            return i18n.t('buttonPay', { amount: currencyFormat(price) });
        } else if (eventDetails?.IsFieldTrip && price === 0) {
            return i18n.t('send');
        }
        if (price === 0) {
            return i18n.t('accept');
        } else {
            return i18n.t('buttonPay', { amount: currencyFormat(price) });
        }
    };

    const { isDesktop } = useContext(DeviceContext);
    return (
        <>
            <Modal
                header={
                    <ModalHeader
                        header={i18n.t('calendarEventDetails')}
                        leftIcon={<LeftArrow />}
                        onDismiss={onDismiss}
                    />
                }
                visible={Platform.OS === 'ios' ? (show && !showPayment) : show}
                style={isDesktop ? { height: '80%', margin: 'auto', width: 500 } : { height: '100%' }}
            >
                <ScrollView>
                    <View style={styles.container}>
                        {(!eventDetails || isLoading) && <ActivityIndicator />}
                        {eventDetails && !isLoading && (
                            <>
                                {showInactiveAlert && (
                                    <Alert message={i18n.t('eWalletInactiveEventDetails', { schoolPhone })} />
                                )}
                                {paymentConfiguration && !paymentConfiguration?.IsPaymentActive && price === 0 && (
                                    <Alert message={i18n.t('eWalletInactiveEventDetails1')} />
                                )}
                                {eventDetails.Status === 'Draft' && (
                                    <View style={styles.sectionMargin}>
                                        <View style={[styles.iconContainer, styles.alert]}>
                                            <Info style={styles.iconInfo} />
                                            <Text style={styles.body}>
                                                {i18n.t('eventResponseDraftWarning')}
                                                <Pressable style={styles.helpLink} onPress={() => Linking.openURL('https://askuc.ucdsb.ca/MyFamilyRoom/ViewKnownError.aspx?knownErrorId=1456')}>
                                                    <Text>{i18n.t('eventResponseDraftWarning1')}</Text>
                                                </Pressable>
                                                {i18n.t('eventResponseDraftWarning2')}
                                            </Text>
                                        </View>
                                    </View>
                                )}
                                {!eventDetails.IsRefundable && hasCostItems && (
                                    <View style={styles.sectionMargin}>
                                        <View style={[styles.iconContainer, styles.alert]}>
                                            <Info style={styles.iconInfo} fill='rgb(114, 190, 251)'/>
                                            <Text style={styles.body}>{i18n.t('eventDetailsNonRefundable')}</Text>
                                        </View>
                                    </View>
                                )}

                                {eventDetails.StatusCode == statusCodes.Accepted && (
                                    <View style={styles.sectionMargin}>
                                        <View style={[styles.iconContainer, styles.alert]}>
                                            <Success style={styles.iconInfo} />
                                                <Text style={styles.body}>
                                                <Text style={styles.amountPaid}>{currencyFormat(eventDetails.AmountPaid)}</Text>
                                                    {i18n.t('eventPaidAlert', {
                                                        date: format(new Date(eventDetails.RespondedOn), 'MMM dd'),
                                                    })}
                                                </Text>
                                        </View>
                                    </View>
                                )}

                                {(eventDetails.StatusCode === statusCodes.Past ||
                                    isPast(new Date(eventDetails.ActualStart))) && (
                                    <View style={styles.sectionMargin}>
                                        <View style={[styles.iconContainer, styles.alert]}>
                                            <Error style={styles.iconInfo} />
                                            <Text style={styles.body}>{i18n.t('pastEvent')} </Text>
                                        </View>
                                    </View>
                                )}
                                <Header event={event} {...eventDetails} />
                                <View style={[styles.iconContainer, styles.sectionMargin]}>
                                    <IconList icon={<IconStudent fill={defaultTheme.colors.blueberry} />}>
                                        <Avatar
                                            size={30}
                                            style={{ marginBottom: 8 }}
                                            label={studentDetails?.LegalFirst ?? ''}
                                            picture={studentsMap[eventDetails?.StudentId]?.PhotoBase64}
                                        />
                                        <Text style={styles.body}>{studentDetails?.LegalFirst}</Text>
                                    </IconList>
                                </View>

                                {eventDetails.OfferDescription && (
                                    <View style={[styles.iconContainer, styles.sectionMargin]}>
                                        <IconList icon={<Folder />} header={'Description'}>
                                            <Text style={[styles.description, { paddingRight: 16 }]}>
                                                {eventDetails.OfferDescription}
                                            </Text>
                                        </IconList>
                                    </View>
                                )}

                                {(hasCostItems || (qas && qas?.length > 0)) &&
                                    costItems &&
                                    costItems.map(
                                        (costItem, i) =>
                                            costItem.groups.length > 0 && (
                                                <View
                                                    style={[
                                                        styles.iconContainer,
                                                        styles.sectionMargin,
                                                        { width: '95%' },
                                                    ]}
                                                >
                                                    <View>{costItemIconHeader[costItem.name].icon}</View>
                                                    <View style={{ width: '100%', paddingRight: 24 }}>
                                                        <Text style={styles.subheader}>
                                                            {costItemIconHeader[costItem.name].header}
                                                        </Text>

                                                        {costItem.group_error && (
                                                            <Text style={[styles.error, { marginBottom: 12 }]}>
                                                                {costItem.group_error}
                                                            </Text>
                                                        )}

                                                        <React.Fragment key={`cost-item-${costItem.name}-${i}`}>
                                                            {costItem.groups.map((costGroup, j) => (
                                                                <CostItem
                                                                    expanded={
                                                                        (disabled === true && disabled) ||
                                                                        costItem.name === 'required'
                                                                    }
                                                                    disabled={disabled}
                                                                    onChange={(value, attribute) =>
                                                                        handleCostItemChange(
                                                                            costItem.name,
                                                                            i,
                                                                            value,
                                                                            attribute,
                                                                            j
                                                                        )
                                                                    }
                                                                    key={`cost-item-group-${costItem.name}-${j}`}
                                                                    costGroup={costGroup}
                                                                    type={costItem.name}
                                                                    index={j}
                                                                    showDivider={costItem.groups.length - 1 !== j}
                                                                    onCollapse={handleCostItemCollapse}
                                                                />
                                                            ))}
                                                        </React.Fragment>
                                                    </View>
                                                </View>
                                            )
                                    )}
                                {qas && qas?.length > 0 && (
                                    <View style={[styles.iconContainer, styles.sectionMargin, { width: '95%' }]}>
                                        <View>
                                            <ContactSupport style={styles.iconSpace} />
                                        </View>
                                        <View style={{ width: '100%', paddingRight: 24 }}>
                                            <Text style={styles.subheader}>{i18n.t('questions')}</Text>
                                            {qas.map((qa, i) => (
                                                <React.Fragment
                                                    key={`question-answer-${qa.ucdsb_eventquestionid}-${i}`}
                                                >
                                                    <Question
                                                        expanded={disabled === true}
                                                        qa={qa}
                                                        disabled={disabled}
                                                        onSwitchChange={(checked: boolean) => handleChange(i, checked)}
                                                        onCommentChange={(value) => handleCommentChange(i, value)}
                                                        onInputChange={(value) => handleChange(i, value)}
                                                    />
                                                </React.Fragment>
                                            ))}
                                        </View>
                                    </View>
                                )}

                                <Safety {...eventDetails} />
                                <Disclaimer {...eventDetails} />
                                {(price > 0 || eventDetails.IsFieldTrip) && paymentConfiguration?.IsPaymentActive && !disabled && (
                                    <View style={styles.accept}>
                                        {(eventDetails.StatusCode === statusCodes.New ||
                                            eventDetails.StatusCode === statusCodes.Reschedule) && (
                                            <CheckboxWithLabel
                                                styleOverride={styles.checkbox}
                                                checked={acceptDisclaimer}
                                                onChange={(value) => setAcceptDisclaimer(value)}
                                                label={i18n.t('disclaimerAccept')}
                                                disabled={disabled}
                                            />
                                        )}
                                        {acceptDisclaimerError && (
                                            <Text style={styles.error}>{i18n.t('disclaimerAcceptError')}</Text>
                                        )}
                                    </View>
                                )}
                                <View style={styles.button}>
                                    {!isPreview &&
                                        !showInactiveAlert &&
                                        showAcceptButton && (
                                            <Button
                                                label={getPayButtonLabel()}
                                                underlineColor={theme.colors.watermelon}
                                                leftIcon={
                                                    loading ? (
                                                        <ActivityIndicator size={24} />
                                                    ) : (
                                                        <Checkmark fill={theme.colors.watermelon} />
                                                    )
                                                }
                                                onPress={handleAcceptClick}
                                            />
                                        )}
                                    {eventDetails.StatusCode === statusCodes.Accepted && (
                                        <Button
                                            label={i18n.t('buttonContactForRefund')}
                                            underlineColor={theme.colors.lychee}
                                            leftIcon={<IconCall fill={theme.colors.lychee} />}
                                            onPress={() => Linking.openURL(`tel:${schoolDetails?.Telephone}`)}
                                        />
                                    )}
                                    {showInactiveAlert && (
                                        <View style={styles.callSchoolContainer}>
                                            <Button
                                                label={i18n.t('callSchoolPhone', { schoolPhone })}
                                                leftIcon={<IconCall fill={theme.colors.lychee} />}
                                                underlineColor={theme.colors.lychee}
                                                onPress={() => {
                                                    Linking.openURL(`tel:${schoolPhone}`);
                                                }}
                                                style={styles.callSchoolButton}
                                            />
                                            <Text style={styles.callSchoolLabel}>
                                                {i18n.t('eWalletInactiveCallSchool')}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            </>
                        )}
                    </View>
                </ScrollView>
                <ErrorAlert message={message} visible={visible} setVisible={setVisible} />
            </Modal>
            {showPayment && (
                <AddBalanceModal
                    show={showPayment}
                    isLoadFunds={true}
                    amountToLoad={amountRequired}
                    onDismiss={handleAddPaymentModalDismiss}
                    options={paymentOptions}
                    onSubmit={handleAddPaymentModalDismiss}
                />
            )}
        </>
    );
};

export default EventDetailsModal;
