import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    checkboxBase: {
        flexBasis: '50%',
        padding: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkboxLabel: {
        ...typography.body2,
        marginLeft: 16,
        alignSelf: 'center',
    },
});
