import React, { ReactNode, useEffect, useRef } from 'react';
import { Modal as NativeModal, ViewStyle, SafeAreaView, Animated, View, StyleProp } from 'react-native';
import { Portal } from 'react-native-paper';

interface IProps {
    visible: boolean;
    header: ReactNode;
    style?: StyleProp<ViewStyle>;
    animationType?: 'slide' | 'none' | 'fade';
    safeArea?: boolean;
    children?: React.ReactNode;
}

const Modal: React.FC<IProps> = ({ visible, header, children, style, animationType, safeArea = true }) => {
    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        Animated.timing(fadeAnim, {
            toValue: 0.7,
            duration: 180,
            useNativeDriver: true,
        }).start();
    };

    const fadeOut = () => {
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 180,
            useNativeDriver: true,
        }).start();
    };

    useEffect(() => {
        if (visible) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [visible]);

    // Backdrop also slides in with the modal in android since the extra height didn't get rendered
    return (
        <NativeModal transparent={true} visible={visible} animationType={animationType || 'slide'}>
            <Portal.Host>
                <Animated.View
                    style={{
                        backgroundColor: 'black',
                        opacity: fadeAnim,
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        height: '200%',
                    }}
                />
                {safeArea ? (
                    <SafeAreaView style={[style, { backgroundColor: 'white', elevation: 8 }]}>
                        {header}
                        {children}
                    </SafeAreaView>
                ) : (
                    <View style={style}>
                        {header}
                        {children}
                    </View>
                )}
            </Portal.Host>
        </NativeModal>
    );
};

export default Modal;
