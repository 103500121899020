import React, { useContext } from 'react';
import { Text, View } from 'react-native';

import Button from '@Components/Button';
import DeviceContext from '@Contexts/DeviceContext';
import useTheme from '@Hooks/useTheme';

import styles from './styles';

interface IProps {
    body?: string;
    buttonIcon?: React.ReactNode;
    buttonLabel?: string;
    children?: React.ReactNode;
    header?: string;
    icon?: React.ReactNode;
    label?: string;
    onButtonPress?: () => void;
}

const IconList: React.FC<IProps> = ({
    label,
    icon,
    header,
    children,
    body,
    buttonIcon,
    buttonLabel,
    onButtonPress,
}) => {
    const { isDesktop } = useContext(DeviceContext);
    return (
        <View style={styles.container}>
            {icon && <View style={styles.icon}>{icon}</View>}
            <View style={[{ maxWidth: '90%' }, isDesktop && { flex: 1 }]}>
                {label && <Text style={styles.label}>{label}</Text>}
                {header && <Text style={styles.header}>{header}</Text>}
                {body && <Text style={styles.body}>{body}</Text>}
                <View>{children}</View>
                {buttonLabel && (
                    <Button
                        label={buttonLabel}
                        leftIcon={buttonIcon}
                        underlineColor={useTheme().colors.watermelon}
                        onPress={onButtonPress}
                        labelStyle={styles.button}
                    />
                )}
            </View>
        </View>
    );
};

export default IconList;
