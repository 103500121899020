import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBuilding6 = (props: SvgProps) => (
    <Svg width={484} height={270} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M454.121 39.48h-58.534l-29.259 51.534 29.259 51.535h58.534l29.267-51.535-29.267-51.534Z'
            fill='#73CC49'
        />
        <Path fill='#373639' d='M421 91h8v142h-8z' />
        <Path d='M31.344 77h63.738l24.787 72H6.557l24.787-72Z' fill='#73CC49' />
        <Path fill='#373639' d='M60 113h8v120h-8z' />
        <Path d='m238 152.764-74.667-46.032L238 60.707l74.667 46.025L238 152.764Z' fill='#72BEFB' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M152.009 128.404h-28v106.525h93.831v-48.036h39.95v48.036h94.201V128.404H152.009Zm9.582 47.058 20.317-14.764 20.319 14.764-7.76 23.884h-25.115l-7.761-23.884Zm132.317-14.764-20.317 14.764 7.761 23.884h25.115l7.76-23.884-20.319-14.764Z'
            fill='#EEAB00'
        />
        <Path d='m287 49.495-22.811 39.51h45.623L287 49.496Z' fill='#A28AC7' />
        <Path d='M390.292 139.48H356.61v55.274h33.682V139.48Z' fill='#5DA638' />
        <Path fill='#373639' d='M370 167h8v66h-8zM362 233h118v2H362zM0 233h118v2H0z' />
    </Svg>
);
export default SvgBuilding6;
