import { Pressable, Text, View } from 'react-native';
import { Brush } from '@Icon';
import styles from './styles';
import Avatar from '@Components/Avatar';
import { defaultTheme } from '@Hooks/useTheme';
import { ContactPreferencesModalData, ContactPreferencesModalFieldOptions } from '../ContactPreferencesModal';
import ContactPreferencesForm from '../ContactPreferencesForm';
import { useState } from 'react';

export interface ListItemEditProps {
    photo: string;
    text: string;
    subtext?: string;
    disabled: boolean;
    setDisabled: (val: boolean) => void;
    required?: boolean;
    initialValue: ContactPreferencesModalData;
    disabledValue: ContactPreferencesModalFieldOptions[];
    onSave: (newValue: ContactPreferencesModalData, onFinishCallback: () => void) => void;
}

function ListItemEdit({
    photo,
    text,
    subtext,
    disabled,
    setDisabled,
    required,
    initialValue,
    disabledValue,
    onSave,
}: ListItemEditProps) {
    const [showForm, setShowForm] = useState(false);

    function onFinish() {
        setDisabled(false);
        setShowForm(false);
    }

    function handleSave(newValue: ContactPreferencesModalData) {
        onSave(newValue, onFinish);
    }

    function handleEdit() {
        setShowForm(true);
        setDisabled(true);
    }

    return (
        <View style={styles.listItem}>
            <View style={styles.iconContainer}>
                <Avatar label={text ? text : '-'} size={40} picture={photo} />
            </View>
            <View style={{ flexDirection: 'column', flexGrow: 1, flexShrink: 1 }}>
                <View style={styles.topContainer}>
                    <Text style={styles.label}>{text}</Text>
                </View>
                {showForm ? (
                    <ContactPreferencesForm
                        initialValue={initialValue}
                        disabled={disabledValue}
                        required={required}
                        onSave={handleSave}
                        onCancel={onFinish}
                    />
                ) : (
                    <View style={styles.bottomContainer}>
                        <Text style={styles.subtext}>{subtext}</Text>
                        <Pressable
                            onPress={handleEdit}
                            style={({ pressed }) => pressed && styles.pressed}
                            disabled={disabled}
                        >
                            <View style={styles.chevron}>
                                <Brush fill={defaultTheme.colors.watermelon} />
                            </View>
                        </Pressable>
                    </View>
                )}
            </View>
        </View>
    );
}

export default ListItemEdit;
