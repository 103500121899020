import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgHelp = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.819 14.496c-.38.817-.579 2.07-.598 3.762h4.025c0-1.03.107-1.793.32-2.29.215-.495.769-1.132 1.663-1.91.895-.777 1.546-1.609 1.954-2.493.409-.885.613-1.91.613-3.077 0-1.731-.574-3.199-1.721-4.405-1.147-1.205-2.78-1.808-4.9-1.808-1.77 0-3.238.452-4.404 1.356-1.167.905-2.013 2.076-2.538 3.515l3.5 1.517c.272-.798.69-1.449 1.254-1.955.564-.505 1.293-.758 2.188-.758.855 0 1.531.248 2.027.744.496.496.744 1.123.744 1.881 0 .7-.18 1.342-.54 1.925-.36.583-.919 1.177-1.677 1.78-.894.66-1.531 1.4-1.91 2.216Zm-2.446 11.963 3.802-6.585 3.802 6.585h-7.604Z'
            fill='#D77A7F'
        />
    </Svg>
);
export default SvgHelp;
