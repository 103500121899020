import { View, Text, Pressable } from 'react-native';
import TransactionsList from './TransactionsList';
import { styles } from './styles';
import {
    RecentTransaction,
    useGetPaymentConfigurationQuery,
    useGetRecentTransactionsQuery,
} from '@Redux/services/PaymentApi';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import Plus from '@Icon/Plus';
import { currencyFormat } from '@Utilities';
import TaskAlt from '@Icon/TaskAlt';
import AddBalanceModal from './AddBalanceModal';
import RefundModal from './RefundModal';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator, IconButton } from 'react-native-paper';
import BalanceOptionsModal from './OptionsModal';
import DeviceContext from '@Contexts/DeviceContext';
import { TransactionProps } from './Transaction';
import i18n from '@I18n';
import Button from '@Components/Button';
import useTheme from '@Hooks/useTheme';
import { useGetParentQuery } from '@Redux/services/parent';
import InactiveAlert from './InactiveAlert';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';

type BalanceHeaderComponentProps = {
    balance: number;
    addBalance: () => void;
    onOptions?: () => void;
    isPaymentActive: boolean;
};

function BalanceHeaderComponent({ balance, isPaymentActive, addBalance, onOptions }: BalanceHeaderComponentProps) {
    const { isDesktop } = useContext(DeviceContext);
    const getLabel = () => {
        if (isDesktop) {
            if (isPaymentActive) {
                return i18n.t('accountBalance') + ': ';
            } else {
                return i18n.t('eWalletInactive') + '. Balance ';
            }
        }
        return '';
    };

    return (
        <View style={[styles.topContainer, isDesktop && styles.topContainerDesktop]}>
            <Text style={isDesktop ? styles.headerDesktop : styles.header}>
                {getLabel()}
                {currencyFormat(balance)}
            </Text>
            {isPaymentActive && (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Pressable onPress={addBalance}>
                        <View style={styles.addBalanceButtonContainer}>
                            <Plus style={styles.addBalanceButtonIcon} width={14} height={14} />
                            <View style={styles.addBalanceButton}>
                                <Text style={styles.addBalanceButtonText}>Add $</Text>
                            </View>
                        </View>
                    </Pressable>
                    {onOptions && <IconButton icon='dots-vertical' onPress={onOptions} style={{ marginLeft: 20 }} />}
                </View>
            )}
        </View>
    );
}

function BalanceAlertComponent() {
    return (
        <View style={styles.alert}>
            <View>
                <TaskAlt width={24} height={24} />
            </View>
            <View style={styles.alertTextContainer}>
                <Text style={styles.alertText}>Funds have been added to your account</Text>
            </View>
        </View>
    );
}

type BalanceComponentProps = {
    showAlert: boolean;
    transactions: TransactionProps[];
} & BalanceHeaderComponentProps;

function BalanceComponent({
    showAlert,
    transactions,
    isPaymentActive,
    balance,
    addBalance,
    onOptions,
}: BalanceComponentProps) {
    const { isDesktop } = useContext(DeviceContext);

    // TODO: Custom component for transactions list
    // Can't use flatlist for desktop transactions list with pagination
    return isDesktop ? (
        <>
            <BalanceHeaderComponent
                isPaymentActive={isPaymentActive}
                balance={balance}
                addBalance={addBalance}
                onOptions={onOptions}
            />
            <InactiveAlert show={isPaymentActive} />
            {showAlert && <BalanceAlertComponent />}
            <Text style={styles.recentTransaction}>
                {isPaymentActive ? i18n.t('recentTransactions') : i18n.t('pastTransactions')}
            </Text>
            <View style={{}}>
                <TransactionsList transactions={transactions} />
            </View>
        </>
    ) : (
        <View style={styles.screen}>
            <BalanceHeaderComponent isPaymentActive={isPaymentActive} balance={balance} addBalance={addBalance} />
            <InactiveAlert show={isPaymentActive} />
            {showAlert && <BalanceAlertComponent />}
            <Text style={styles.recentTransaction}>
                {isPaymentActive ? i18n.t('recentTransactions') : i18n.t('pastTransactions')}
            </Text>
            <TransactionsList transactions={transactions} />
        </View>
    );
}

type BalanceProp = NativeStackScreenProps<RootStackParamList, 'Balance'>;

function Balance({ route }: BalanceProp) {
    const [page, setPage] = useState(1);
    const { data, refetch: refetchPaymentConfiguration } = useGetPaymentConfigurationQuery();
    const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);
    const [transactions, setTransactions] = useState<RecentTransaction[]>([]);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const {
        data: recentTransactions,
        isLoading,
        refetch: refetchRecentTransactions,
    } = useGetRecentTransactionsQuery({ page: page | 2, pageSize: 5 }, { skip: page === 1 });
    const { refetch: refetchProfile } = useGetParentQuery();
    const [showAlert] = useState(false);
    const navigation = useNavigation();

    useEffect(() => {
        let newTransactions: RecentTransaction[] = transactions.length > 0 ? transactions : [];
        if (transactions?.length === 0 && data?.RecentTransactions) {
            newTransactions = newTransactions.concat(data?.RecentTransactions);
        }
        if (recentTransactions) {
            newTransactions = newTransactions.concat(recentTransactions);
        }
        setTransactions(newTransactions);
    }, [data, recentTransactions]);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () =>
                data?.IsPaymentActive && (
                    <IconButton
                        icon='dots-vertical'
                        onPress={() => {
                            setShowOptionsModal(true);
                        }}
                    />
                ),
            title: i18n.t(data?.IsPaymentActive ? 'accountBalance' : 'eWalletInactive'),
        });
    }, [data]);

    useEffect(() => {
        refetchPaymentConfiguration();
    }, [route]);

    const handleAddBalance = () => {
        setTransactions([]);
        setPage(1);
        refetchPaymentConfiguration();
        refetchProfile();
        setShowAddBalanceModal(false);
    };

    const handleRefund = () => {
        setShowOptionsModal(false);
        setShowRefundModal(true);
    };

    function handleSubmitRefund() {
        setTransactions([]);
        setPage(1);
        refetchPaymentConfiguration();
        refetchProfile();
        setShowRefundModal(false);
    }

    return (
        <>
            {data?.IsPaymentActive && (
                <BalanceOptionsModal
                    visible={showOptionsModal}
                    onCancel={() => setShowOptionsModal(false)}
                    onSubmit={handleRefund}
                />
            )}
            {data?.IsPaymentActive && (
                <RefundModal
                    balance={data?.CurrentBalance}
                    show={showRefundModal}
                    onDismiss={() => setShowRefundModal(false)}
                    onSubmit={handleSubmitRefund}
                />
            )}
            {data?.IsPaymentActive && showAddBalanceModal && (
                <AddBalanceModal
                    show={showAddBalanceModal}
                    isLoadFunds={false}
                    onDismiss={() => setShowAddBalanceModal(false)}
                    onSubmit={handleAddBalance}
                />
            )}
            <BalanceComponent
                showAlert={showAlert}
                transactions={transactions || []}
                balance={data?.CurrentBalance || 0}
                addBalance={() => setShowAddBalanceModal(true)}
                onOptions={() => setShowOptionsModal(true)}
                isPaymentActive={data ? data.IsPaymentActive : false}
            />
            {data?.TotalTransactions !== undefined && transactions.length <= data?.TotalTransactions && (
                <Button
                    style={{ alignItems: 'center' }}
                    label={i18n.t('loadMoreLabel')}
                    onPress={() => setPage(page + 1)}
                    leftIcon={isLoading && <ActivityIndicator />}
                    underlineColor={useTheme().colors.watermelon}
                />
            )}
        </>
    );
}

export default Balance;
