import React, { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';
import styles from './styles';
import i18n from '@I18n';
import { ActivityIndicator } from 'react-native-paper';
import { defaultTheme } from '@Hooks/useTheme';
import Button from '@Components/Button';
import Checkmark from '@Icon/Checkmark';
import DeviceContext from '@Contexts/DeviceContext';
import CheckboxWithLabel from '@Components/SwitchInput/components/CheckboxWithLabel';

interface IProps {
    onDismiss: () => void;
    show: boolean;
    initialValue: ContactPreferencesModalData;
    onSubmit: (newValue: ContactPreferencesModalData, prefType: string) => void;
    disabled: ContactPreferencesModalFieldOptions[];
    prefType: string;
    required: boolean;
}

export type ContactPreferencesModalFieldOptions = 'Home' | 'Work' | 'Mobile' | 'Email';
type MobileValues = 'Both' | 'Voice' | 'SMS' | 'None';

export type ContactPreferencesModalData = {
    home: boolean;
    work: boolean;
    mobile: MobileValues;
    email: boolean;
};

function getDescription(prefType: string) {
    switch (prefType) {
        case 'absence':
            return i18n.t('editNotificationAbsence');
        case 'busCancellations':
            return i18n.t('editNotificationBus');
        case 'urgentNotifications':
            return i18n.t('editNotificationUrgent');
        case 'schoolAndBoardNews':
            return i18n.t('editNotificationSchoolAndBoard');
        default:
            return '';
    }
}

const ContactPreferencesModal: React.FC<IProps> = ({
    show,
    onDismiss,
    required,
    initialValue,
    onSubmit,
    disabled,
    prefType,
}) => {
    const [isSubmitting] = useState(false);

    const [home, setHome] = useState(initialValue.home);
    const [work, setWork] = useState(initialValue.work);
    const [mobile, setMobile] = useState(['Both', 'Voice'].includes(initialValue.mobile));
    const [text, setText] = useState(['Both', 'SMS'].includes(initialValue.mobile));
    const [email, setEmail] = useState(initialValue.email);
    const [error, setError] = useState<string>();

    const { isDesktop } = useContext(DeviceContext);

    useEffect(() => {
        if (required && !home && !work && !email && !mobile && !text) {
            setError(i18n.t('urgentContactPreferenceError'));
        } else {
            setError(undefined);
        }
    }, [home, work, email, mobile, text]);

    useEffect(() => {
        setHome(initialValue.home);
        setWork(initialValue.work);
        setMobile(['Both', 'Voice'].includes(initialValue.mobile));
        setText(['Both', 'SMS'].includes(initialValue.mobile));
        setEmail(initialValue.email);
    }, [initialValue]);

    function getNewValue(): ContactPreferencesModalData {
        let mobileVal: MobileValues;
        if (mobile && text) {
            mobileVal = 'Both';
        } else if (mobile) {
            mobileVal = 'Voice';
        } else if (text) {
            mobileVal = 'SMS';
        } else {
            mobileVal = 'None';
        }
        return {
            home: home,
            email: email,
            mobile: mobileVal,
            work: work,
        };
    }

    function handleSubmit() {
        const newValue = getNewValue();
        if (
            required &&
            !Object.values(newValue).some(
                (value) => (typeof value === 'boolean' && value) || (typeof value === 'string' && value !== 'None')
            )
        ) {
            return;
        }
        if (prefType) {
            onSubmit(newValue, prefType);
        } else {
            onSubmit(newValue, '');
        }
    }

    return (
        <Modal
            style={isDesktop ? styles.modalDesktop : styles.modal}
            visible={show}
            header={<ModalHeader header={'Edit'} onDismiss={onDismiss} />}
        >
            <View style={styles.container}>
                <Text style={styles.description}>{getDescription(prefType)}</Text>
                {error && <Text style={styles.error}>{error}</Text>}
                <View style={styles.checkboxContainer}>
                    {!disabled.includes('Home') && (
                        <CheckboxWithLabel
                            checked={home}
                            onChange={(val) => setHome(val)}
                            label={i18n.t('home')}
                            disabled={disabled.includes('Home')}
                        />
                    )}
                    {!disabled.includes('Work') && (
                        <CheckboxWithLabel
                            checked={work}
                            onChange={(val) => setWork(val)}
                            disabled={disabled.includes('Work')}
                            label={i18n.t('work')}
                        />
                    )}
                    {!disabled.includes('Mobile') && (
                        <>
                            <CheckboxWithLabel
                                checked={mobile}
                                onChange={(val) => setMobile(val)}
                                disabled={disabled.includes('Mobile')}
                                label={i18n.t('mobile')}
                            />
                            <CheckboxWithLabel
                                checked={text}
                                onChange={(val) => setText(val)}
                                disabled={disabled.includes('Mobile')}
                                label={i18n.t('text')}
                            />
                        </>
                    )}
                    {!disabled.includes('Email') && (
                        <CheckboxWithLabel
                            checked={email}
                            onChange={(val) => setEmail(val)}
                            disabled={disabled.includes('Email')}
                            label={i18n.t('email')}
                        />
                    )}
                </View>
                <View style={styles.controlContainer}>
                    <Button
                        onPress={handleSubmit}
                        leftIcon={
                            isSubmitting ? <ActivityIndicator /> : <Checkmark fill={defaultTheme.colors.watermelon} />
                        }
                        label={'Save'}
                        underlineColor={defaultTheme.colors.watermelon}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default ContactPreferencesModal;
