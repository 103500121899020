import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
export const rowHeight = 21;

export const styles = StyleSheet.create({
    dayDate: {
        height: '100%',
        overflow: 'hidden',
    },
    dayEvents: {},
    dateTextWrapper: {
        alignSelf: 'flex-start',
        borderTopColor: defaultTheme.colors.textPrimary,
        borderTopWidth: 2,
    },
    dateTextWrapperActive: {
        alignSelf: 'stretch',
        borderTopColor: defaultTheme.colors.watermelon,
        borderTopWidth: 4,
    },
    dateTextWrapperDisabled: {
        alignSelf: 'flex-start',
        borderTopColor: defaultTheme.colors.inputOutline,
    },
    dateText: {
        ...typography.calendarDate,
        marginBottom: 2,
    },
    dateTextDisabled: {
        color: defaultTheme.colors.inputOutline,
    },
    eventText: {
        ...typography.calendarEvent,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    eventRow: {
        height: rowHeight,
    },
    moreText: {
        ...typography.calendarEvent,
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 2,
        fontWeight: 700,
        whiteSpace: 'nowrap',
        width: 50,
    },
    eventIcon: {
        marginRight: 4,
    },
});
