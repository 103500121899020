import { baseApi } from '../base.ts';
import { CashoutDetails } from './types';

const CashoutApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCashoutDetails: builder.query<CashoutDetails, void>({
            query: () => ({
                url: 'CashoutApi/GetCashoutDetails',
                method: 'GET',
            }),
        }),
        requestCashout: builder.mutation<void, Partial<CashoutDetails>>({
            query: (data) => ({
                url: 'CashoutApi/RequestCashout',
                method: 'POST',
                data,
            }),
        }),
    }),
});

export { CashoutDetails };
export const { useGetCashoutDetailsQuery, useRequestCashoutMutation } = CashoutApi;
