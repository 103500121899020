import { typography, defaultTheme } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    error: {
        paddingHorizontal: 16,
        ...typography.body,
        color: defaultTheme.colors.error,
    },
    text: {
        ...typography.body,
    },
});
