import React, { FC } from 'react';
import { Text, View } from 'react-native';
import i18n from '@I18n';

import { Building4 } from '@Svg';
import { IconWarning2 } from '@Icon';

import { styles } from './styles';
import { StepProps } from '../../types';

const Intro: FC<StepProps> = () => {
    return (
        <>
            <View style={styles.header}>
                <Building4 />
            </View>
            <View>
                <View style={styles.info}>
                    <IconWarning2 style={styles.infoIcon} />
                    <Text style={styles.infoText}>{i18n.t('studentRegisterInfo')}</Text>
                </View>
                <Text style={styles.introText}>
                    {'\n'}
                    {i18n.t('studentRegisterIntro')}
                </Text>
                <Text style={styles.claimText}>
                    {'\n'}
                    {i18n.t('studentRegisterClaim')}
                </Text>
            </View>
        </>
    );
};

export default Intro;
