import { View } from 'react-native';
import { styles } from './styles';
import { MfrLogo2 } from '@Svg';

function Header2() {
    return (
        <View style={styles.logoContainer}>
            <MfrLogo2 />
        </View>
    );
}

export default Header2;
