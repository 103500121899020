import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgAccordionDown = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 40 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M20 26.083 1.906 14.928 20 3.774l18.094 11.154L20 26.083Z'
            fill='#fff'
            stroke='#373639'
            strokeWidth={2}
        />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.244 11.972 20 14.903l4.756-2.931 3.244 2-8 4.932-8-4.932 3.244-2Z'
            fill='#373639'
        />
    </Svg>
);
export default SvgAccordionDown;
