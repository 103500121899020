import React from 'react';
import { Pressable, View } from 'react-native';
import styles from './styles';

interface IProps {
    checked: boolean;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
}

const Switch: React.FC<IProps> = ({ checked, disabled, onChange }) => (
    <Pressable onPress={() => !disabled && onChange && onChange(!checked)}>
        <View
            style={[
                styles.switchContainer,
                checked && !disabled && styles.switchCheckedContainer,
                checked && disabled && styles.switchCheckedDisbledContainer,
                !checked && disabled && styles.UnCheckedDisabledContainer,
                !checked && !disabled && styles.UnCheckedNotDisabledContainer,
            ]}
        >
            <View
                style={[
                    styles.circle,
                    checked && disabled && styles.checkedDisabledCircle,
                    checked && !disabled && styles.checkedCircle,
                    !checked && disabled && styles.unCheckedDisabledCircle,
                    !checked && !disabled && styles.unCheckedCircle,
                ]}
            ></View>
        </View>
    </Pressable>
);

export default Switch;
