import { useGetRegistrationsAndLinkingsQuery, useGetStudentsQuery } from '@Redux/services/parent';
import { useEffect, useState } from 'react';

export const useStudentListEmpty = () => {
    const [hasStudents, setHasStudents] = useState<boolean>();
    const { data: students, refetch: getStudents, isLoading: isLoadingStudents } = useGetStudentsQuery();
    const {
        data: registrations,
        refetch: getStudentRegistrations,
        isLoading: isLoadingRegistrations,
    } = useGetRegistrationsAndLinkingsQuery();

    useEffect(() => {
        if ((students && students?.length > 0) || (registrations && registrations?.length > 0)) {
            setHasStudents(true);
        } else {
            setHasStudents(false);
        }
    }, [students, getStudents, registrations, getStudentRegistrations]);

    useEffect(() => {
        getStudents();
        getStudentRegistrations();
    }, []);

    return {
        hasStudents,
        isLoadingStudents,
        isLoadingRegistrations,
    };
};
