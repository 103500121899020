import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

const styles = StyleSheet.create({
    pressed: {
        opacity: 0.75,
    },
    listItem: {
        marginVertical: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    labelContainer: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    label: {
        ...typography.smallListItem,
        marginBottom: 4,
    },
    icon: {
        width: 50,
    },
});

export default styles;
