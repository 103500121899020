import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    eventTitle: {
        ...typography.body2,
        lineHeight: 24,
        flex: 1,
        flexWrap: 'wrap',
    },
    eventTitleContainer: {
        alignItems: 'flex-end',
        flexDirection: 'row',
        flexGrow: 1,
        flexShrink: 1,
    },
    eventTitleExpired: {
        color: defaultTheme.colors.disabled,
        pointerEvents: 'none',
    },
    eventTitleOfferStatusInvitedAdornment: {
        backgroundColor: defaultTheme.colors.persimmon,
        borderRadius: 6,
        height: 12,
        margin: 6,
        width: 12,
    },
});
