import { Error, Success } from '@Icon/index';
import { FC } from 'react';
import { Text, View } from 'react-native';
import { styles } from './styles';

interface IProps {
    isError: boolean;
    show: boolean;
    message: string;
}
const AlertBox: FC<IProps> = ({ message, isError, show }) => (
    <>
        {show && (
            <View style={[styles.iconContainer, styles.alert, isError ? styles.error : styles.success]}>
                {isError ? <Error style={styles.iconInfo} /> : <Success style={styles.iconInfo} />}

                <Text style={styles.body}>{message}</Text>
            </View>
        )}
    </>
);

export default AlertBox;
