import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    messageContianer: {
        flexDirection: 'row',
        width: '80%',
        justifyContent: 'space-between',
    },
    header: {
        ...typography.h2,
        marginBottom: 32,
    },
    body: {
        ...typography.body,
        marginBottom: 36,
    },
    buttonMargin: {
        marginBottom: 18,
    },
    buttonLabel: {
        ...typography.buttonLarge,
    },
});
