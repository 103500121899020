import { SafeAreaView } from 'react-native-safe-area-context';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import { styles } from './styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@Redux';
import BiometricScreen from './Biometric';
import AppReturnScreen from './AppReturn';
import SignInWebScreen from './Web';
import { Platform } from 'react-native';

export type SignInScreenProp = NativeStackScreenProps<RootStackParamList, 'signin'>;

function SignInScreen({ navigation }: SignInScreenProp) {
    const email = useSelector((state: RootState) => state.auth.email);
    const userId = useSelector((state: RootState) => state.auth.lastUserId);
    const biometricEnabled = useSelector(
        (state: RootState) => (userId && state.auth?.storedAccountsPreference?.[userId]?.biometricEnabled) || false
    );
    const [activeScreen, setActiveScreen] = useState('');

    useEffect(() => {
        if (Platform.OS === 'web') {
            setActiveScreen('signin');
        } else {
            if (email && biometricEnabled) {
                setActiveScreen('biometric');
            } else if (email) {
                setActiveScreen('appreturn');
            } else {
                setActiveScreen('signin');
            }
        }
    }, []);

    function SignInComponent() {
        if (activeScreen === 'biometric') {
            return <BiometricScreen navigation={navigation} setActiveScreen={setActiveScreen} />;
        } else if (activeScreen === 'appreturn') {
            return <AppReturnScreen navigation={navigation} setActiveScreen={setActiveScreen} />;
        } else if (activeScreen === 'signin') {
            return <SignInWebScreen navigation={navigation} />;
        } else {
            return <></>;
        }
    }

    return (
        <SafeAreaView style={styles.base}>
            <KeyboardAwareScrollView contentContainerStyle={styles.container} keyboardShouldPersistTaps='handled'>
                <SignInComponent />
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
}

export default SignInScreen;
