import { baseApi } from '../base.ts';
import { CreateStudentLinkRequestBody, StudentLinkIdParam, StudentLinkRequestIdParam } from './types';

const StudentLinkApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createStudentLinkRequest: builder.mutation<void, CreateStudentLinkRequestBody>({
            query: (data) => ({
                url: 'StudentLinkApi/CreateStudentLinkRequest',
                method: 'POST',
                data,
            }),
        }),
        removeStudentLinkRequest: builder.mutation<void, StudentLinkIdParam>({
            query: (data) => ({
                url: `StudentLinkApi/RemoveStudentLinkRequest/${data.id}`,
                method: 'POST',
            }),
        }),
        getStudentLinkRequest: builder.query<CreateStudentLinkRequestBody, StudentLinkRequestIdParam>({
            query: (data) => ({
                url: `StudentLinkApi/GetStudentLinkRequest/${data.linkRequestId}`,
                method: 'GET',
            }),
        }),
        updateStudentLinkRequest: builder.mutation<void, CreateStudentLinkRequestBody>({
            query: (data) => ({
                url: `StudentLinkApi/UpdateStudentLinkRequest`,
                method: 'POST',
                data,
            }),
        }),
    }),
});

export { CreateStudentLinkRequestBody };
export const {
    useCreateStudentLinkRequestMutation,
    useRemoveStudentLinkRequestMutation,
    useGetStudentLinkRequestQuery,
    useUpdateStudentLinkRequestMutation,
} = StudentLinkApi;
