import React, { FC } from 'react';
import { View, Text } from 'react-native';
import i18n from '@I18n';
import SwitchInput from '@Components/SwitchInput';

import type { PartialDeep } from 'type-fest';
import { CreateStudentRegistrationBody } from '@Redux/services/StudentRegistrationApi';

import LocalAddress, { LocalAddressMasks, LocalAddressValidator } from '../LocalAddress';
import MailingAddress, { MailingAddressMasks, MailingAddressValidator } from '../MailingAddress';
import { StepProps, ManageSwitchInputProps } from '../../types';
import { styles } from '../../styles';
import { typography } from '@Hooks/useTheme';

const AddressInfo: FC<StepProps> = ({ manageFieldPropFactory, textFieldPropFactory, studentRegistration }) => {
    return (
        <View>
            <View style={styles.formGroup}>
                <SwitchInput
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['AddressInformation', 'IsChildAddressUnknown'],
                        'onChange',
                        'checked'
                    )}
                    reverse
                    label={i18n.t('studentRegisterNoAddress')}
                    viewStyle={styles.checkbox}
                />
                {!studentRegistration?.AddressInformation?.IsChildAddressUnknown && (
                    <LocalAddress
                        manageFieldPropFactory={manageFieldPropFactory}
                        textFieldPropFactory={textFieldPropFactory}
                        studentRegistration={studentRegistration}
                        accessor={['AddressInformation', 'ChildAddress']}
                    />
                )}
            </View>
            <Text style={[typography.body, { marginBottom: 12 }]}>{i18n.t('additionalAddresses')}</Text>
            <View style={styles.formGroup}>
                <SwitchInput
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['AddressInformation', 'IsParentAddressDifferent'],
                        'onChange',
                        'checked'
                    )}
                    label={i18n.t('studentRegisterParentAddress')}
                    reverse
                    viewStyle={styles.checkbox}
                />
                {studentRegistration?.AddressInformation?.IsParentAddressDifferent && (
                    <MailingAddress
                        manageFieldPropFactory={manageFieldPropFactory}
                        textFieldPropFactory={textFieldPropFactory}
                        studentRegistration={studentRegistration}
                        accessor={['AddressInformation', 'ParentAddress']}
                    />
                )}
            </View>
            <View style={styles.formGroup}>
                <SwitchInput
                    {...manageFieldPropFactory<ManageSwitchInputProps>(
                        ['AddressInformation', 'IsMailingAddressDifferent'],
                        'onChange',
                        'checked'
                    )}
                    label={i18n.t('studentRegisterMailingAddress')}
                    reverse
                    viewStyle={styles.checkbox}
                />
                {studentRegistration?.AddressInformation?.IsMailingAddressDifferent && (
                    <MailingAddress
                        manageFieldPropFactory={manageFieldPropFactory}
                        textFieldPropFactory={textFieldPropFactory}
                        studentRegistration={studentRegistration}
                        accessor={['AddressInformation', 'ChildMailingAddress']}
                    />
                )}
            </View>
        </View>
    );
};

export const AddressInfoValidator = (studentRegistration: PartialDeep<CreateStudentRegistrationBody>) => {
    const AddressInfoValidatorResult = [
        ...LocalAddressValidator(
            ['AddressInformation', 'ChildAddress'],
            studentRegistration,
            !studentRegistration?.AddressInformation?.IsChildAddressUnknown
        ),
        ...MailingAddressValidator(
            ['AddressInformation', 'ParentAddress'],
            studentRegistration,
            studentRegistration?.AddressInformation?.IsParentAddressDifferent
        ),
        ...MailingAddressValidator(
            ['AddressInformation', 'ChildMailingAddress'],
            studentRegistration,
            studentRegistration?.AddressInformation?.IsMailingAddressDifferent
        ),
    ];
    return AddressInfoValidatorResult;
};

export const AddressInfoMasks = (studentRegistration: PartialDeep<CreateStudentRegistrationBody>) => {
    const AddressInfoMasksResult = [
        ...LocalAddressMasks(['AddressInformation', 'ChildAddress']),
        ...MailingAddressMasks(['AddressInformation', 'ParentAddress'], studentRegistration),
        ...MailingAddressMasks(['AddressInformation', 'ChildMailingAddress'], studentRegistration),
    ];
    return AddressInfoMasksResult;
};

export default AddressInfo;
