import { combineReducers } from 'redux';

import auth from './auth';
import language from './language';
import parent from './parent';
import alert from './alert';
import { baseApi } from './services/base.ts';
import { AnyAction } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';

const appReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    auth,
    language,
    parent,
    alert,
});

type AppRootState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppRootState | undefined, action: AnyAction) => {
    if (action.type === 'auth/logout') {
        if (state?.auth) {
            AsyncStorage.removeItem('persist:main');
            state = { auth: state.auth } as AppRootState;
        }
    }
    return appReducer(state, action);
};

export default rootReducer;
