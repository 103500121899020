import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    switchContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    disabled: {},
    label: {
        ...typography.body2,
        color: defaultTheme.colors.textPrimary,
    },
    disabledLabel: {
        color: defaultTheme.colors.disabled,
    },
    labelContainer: {
        flexDirection: 'row',
        width: '85%',
    },
});

export default styles;
