import { FC, useEffect, useState } from 'react';
import { RootStackParamList } from '@App/rootNavigation';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { View } from 'react-native';
import { useSocialLoginMutation } from '@Redux/services/account';
import { store } from '@Redux';
import { loginWithSocial } from '@Redux/auth';
import { socialProviders } from '@App/constants';
import axios from 'axios';
import { ActivityIndicator, Text } from 'react-native-paper';
import { styles } from './styles';
import i18n from '@I18n';
import { decodeToken } from '@Utilities';

type StudentProfileScreenProp = NativeStackScreenProps<RootStackParamList, 'sociallogin'>;

const SocialLoginScreen: FC<StudentProfileScreenProp> = ({ route }) => {
    const [socialLogin] = useSocialLoginMutation();
    const [errorMessage, setErrorMessage] = useState<string>();

    async function socialLoginCallback(providerUserId: string, provider: string) {
        try {
            const response = await socialLogin({ provider, providerUserId }).unwrap();
            store.dispatch(
                loginWithSocial({ userId: response.UserId, email: response.UserName, socialProvider: provider })
            );
        } catch (e) {
            setErrorMessage(i18n.t('loginErrorGeneric'));
        }
    }

    useEffect(() => {
        if (route.params.provider === 'Apple') {
            handleAppleLogin();
        } else if (route.params.provider === 'Facebook') {
            handleFacebookLogin();
        } else if (route.params.provider === 'Google') {
            handleGoogleLogin();
        } else if (route.params.provider === 'Microsoft') {
            handleMicrosoftLogin();
        }
    }, [route]);

    const handleAppleLogin = () => {
        const payload = decodeToken(route.params.accessToken);
        const providerUserId = payload.sub;
        if (!providerUserId) {
            return;
        }
        authenticateAndRedirect(providerUserId, 'Apple');
    };

    function getProviderUserUri(baseUri: string, token: string) {
        return `${baseUri}?access_token=${token}`;
    }

    const handleFacebookLogin = async () => {
        try {
            const url = getProviderUserUri(socialProviders.facebook.tokenUrl, route.params.accessToken);
            const { data } = await axios.get(url);
            const providerUserId = data?.id;
            if (!providerUserId) {
                return;
            }
            authenticateAndRedirect(providerUserId, 'Facebook');
        } catch (e) {
            setErrorMessage(i18n.t('socialLoginInvalidToken'));
        }
    };

    const handleMicrosoftLogin = async () => {
        try {
            const res2 = await axios.get(`https://apis.live.net/v5.0/me?access_token=${route.params.accessToken}`);

            const providerUserId = res2.data?.id;
            if (providerUserId) {
                authenticateAndRedirect(providerUserId, 'Microsoft');
            }
        } catch (e) {
            setErrorMessage(i18n.t('socialLoginInvalidToken'));
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const url = getProviderUserUri(socialProviders.google.tokenUrl, route.params.accessToken);

            const { data } = await axios.get(url);

            const providerUserId = data?.user_id;
            if (providerUserId) {
                authenticateAndRedirect(providerUserId, 'Google');
            }
        } catch (e) {
            setErrorMessage(i18n.t('socialLoginInvalidToken'));
        }
    };

    const authenticateAndRedirect = async (providerUserId: string, provider: string) => {
        await socialLoginCallback(providerUserId, provider);
    };
    return (
        <View style={styles.container}>
            {errorMessage ? <Text style={styles.error}>{errorMessage}</Text> : <ActivityIndicator />}
        </View>
    );
};

export default SocialLoginScreen;
