import {
    clamp,
    eachDayOfInterval,
    endOfDay,
    formatISO,
    getDate,
    getMonth,
    getYear,
    intervalToDuration,
    isPast,
    isToday,
    parseISO,
    secondsInDay,
    startOfDay,
} from 'date-fns';
import { charsum } from '@Utilities';
import { first, last, find } from 'lodash';
export const dateRep = { representation: 'date' } as const;
import { toSeconds } from 'iso8601-duration';

import { baseApi } from '../base.ts';
import type {
    CalendarEvent,
    DailySeriesEvent,
    DailySeriesEventDateMap,
    EventTypes,
    EventAttachment,
    EventTypesFilter,
    ODataEvent,
    OfferStatus,
    OfferStatusFilter,
    RetrieveUsersEventsAsyncParams,
    StudentsFilter,
    UnknownEvent,
    UserEvent,
} from './types';

import { allStudentsLabel, isUserEvent, isODataEvent } from './types';

const calendarApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        retrieveEventAttachmentsAsync: builder.query<EventAttachment[], { eventId: string; email: string }>({
            query: ({ eventId, email }) => ({
                url: 'calendarapi/RetrieveDataAsync',
                method: 'POST',
                data: {
                    email,
                    query: `/events/${eventId}/Attachments`,
                },
            }),
            transformResponse: async (response: { value: EventAttachment[] }) => {
                return response.value;
            },
        }),
        retrieveUsersEventsAsync: builder.query<DailySeriesEventDateMap, RetrieveUsersEventsAsyncParams>({
            query: (data) => ({
                url: 'calendarapi/RetrieveUsersEventsAsync',
                method: 'POST',
                data,
            }),

            transformResponse: async (response: UnknownEvent[], _meta, args) => {
                const newEvents = response
                    ?.map(convertJSONToEvent)
                    .filter(studentsFilterFactory(args.studentsFilter))
                    .filter(eventTypesFilterFactory(args.eventTypesFilter))
                    .filter(offerStatusFilterFactory('offers', args.offerStatusFilter));

                const dateEventsMap: DailySeriesEventDateMap = {};
                newEvents?.forEach((event) => {
                    const start = parseISO(event.start);
                    const end = parseISO(event.end);
                    const eventInterval = { start, end };
                    const eachDayOfEventInterval = eachDayOfInterval(eventInterval);
                    const trailingEdgeInterval = { start: startOfDay(last(eachDayOfEventInterval) as Date), end };
                    const trailingEdgeIntervalSeconds = toSeconds(intervalToDuration(trailingEdgeInterval), end);
                    if (eachDayOfEventInterval.length > 1 && trailingEdgeIntervalSeconds === 0) {
                        eachDayOfEventInterval.pop();
                    }

                    eachDayOfEventInterval.map((date, idx) => {
                        const isoDateString: keyof DailySeriesEventDateMap = formatISO(date, dateRep);
                        const isFirst = idx === 0;
                        const isLast = idx === eachDayOfEventInterval.length - 1;
                        const dailyEvent: Partial<DailySeriesEvent> = { ...event };

                        const dailyInterval = {
                            start: clamp(startOfDay(date), eventInterval),
                            end: clamp(endOfDay(date), eventInterval),
                        };
                        const dailyIntervalSeconds = toSeconds(intervalToDuration(dailyInterval), date);
                        if (Math.abs(secondsInDay - dailyIntervalSeconds) <= 1) {
                            dailyEvent.allDay = true;
                        }

                        dailyEvent.isFirstInDailySeries = !(isFirst && isLast) && isFirst;
                        dailyEvent.isLastInDailySeries = !(isFirst && isLast) && isLast;
                        dateEventsMap[isoDateString] ??= [];
                        dateEventsMap[isoDateString].push(dailyEvent as DailySeriesEvent);
                    });
                });

                // Backfill empty days.
                const emptyDatesByMonthAndYear: Record<number, Record<number, number[]>> = {};
                //Igonre time, otherwise it will cause start date to pick previous day after UTC to GMT conversion.
                //ex: 2023-12-01T00:00:00Z would result in 2023-11-30 19:00:00 GMT, this causes issues while rendering prevoius or next month data.
                eachDayOfInterval({
                    start: parseISO(args.Start.split('T')[0]),
                    end: parseISO(args.End.split('T')[0]),
                }).map((date) => {
                    const year = getYear(date);
                    const month = getMonth(date);
                    const datesKey: keyof DailySeriesEventDateMap = formatISO(date, dateRep);

                    emptyDatesByMonthAndYear[year] ??= {};
                    emptyDatesByMonthAndYear[year][month] ??= [];

                    if (!dateEventsMap[datesKey]) {
                        emptyDatesByMonthAndYear[year][month].push(getDate(date));
                    }
                });

                Object.entries(emptyDatesByMonthAndYear).map(([year, months]) => {
                    Object.entries(months).map(([month, dates]) => {
                        const emptyGroups = getConsecutiveValuesGrouped(dates);
                        emptyGroups.forEach((range) => {
                            const startDate = first(range);
                            const endDate = last(range);
                            const isoDateStart: keyof DailySeriesEventDateMap = formatISO(
                                new Date(parseInt(year), parseInt(month), startDate),
                                dateRep
                            );
                            const isoDateEnd: keyof DailySeriesEventDateMap = formatISO(
                                new Date(parseInt(year), parseInt(month), endDate),
                                dateRep
                            );
                            dateEventsMap[isoDateStart] = [
                                {
                                    ...createCalendarEvent({
                                        start: isoDateStart,
                                        end: isoDateEnd,
                                        title: 'No events',
                                        type: 'noevents',
                                    }),
                                    isFirstInDailySeries: false,
                                    isLastInDailySeries: false,
                                },
                            ];
                        });
                    });
                });

                return dateEventsMap;
            },
        }),
    }),
});

function getConsecutiveValuesGrouped(array: number[]): number[][] {
    const result = [];
    let temp = [];
    let difference, i;

    for (i = 0; i < array.length; i += 1) {
        if (difference !== array[i] - i) {
            if (difference !== undefined) {
                result.push(temp);
                temp = [];
            }
            difference = array[i] - i;
        }
        temp.push(array[i]);
    }

    if (temp.length) {
        result.push(temp);
    }
    return result;
}

export const { useLazyRetrieveEventAttachmentsAsyncQuery, useLazyRetrieveUsersEventsAsyncQuery } = calendarApi;

export {
    CalendarEvent,
    DailySeriesEvent,
    DailySeriesEventDateMap,
    EventAttachment,
    EventTypes,
    EventTypesFilter,
    OfferStatus,
    OfferStatusFilter,
    RetrieveUsersEventsAsyncParams,
    StudentsFilter,
    allStudentsLabel,
    isUserEvent,
};

// This is terrible. Fix the backend.
const emptyUuid = '00000000-0000-0000-0000-000000000000';
const patchUserEventId = (id: string, start: string, end: string, title: string): string =>
    id === emptyUuid ? uniqueEventId(start, end, title) : id;

const uniqueEventId = (start: string, end: string, uniquenessToken: string) =>
    [parseISO(start).getTime(), charsum(uniquenessToken), parseISO(end).getTime()].join('-');

const eventTypesFilterFactory = (filter: EventTypesFilter) => (event: CalendarEvent) => filter[event.type];

const studentsFilterFactory = (filter: StudentsFilter) => (event: CalendarEvent) =>
    event.owner === allStudentsLabel || find(event.owner, (owner) => filter[owner]);

const offerStatusFilterFactory =
    (eventType: 'offers', offerStatusFilter: OfferStatusFilter) => (event: CalendarEvent) =>
        event.type !== eventType || !event.offerStatus || offerStatusFilter[event.offerStatus];

const createCalendarEvent = ({
    allDay = false,
    body = '',
    end = '',
    id = emptyUuid,
    isCancelled = false,
    isTentative = false,
    location = '',
    owner = allStudentsLabel,
    eventIds = [],
    recurrence = '',
    seriesMasterId = '',
    start = '',
    title = '',
    type = 'other',
    pastDue = false,
    offerStatus,
}: Partial<CalendarEvent>): CalendarEvent => ({
    allDay,
    body,
    duration: intervalToDuration({ start: parseISO(start), end: parseISO(end) }),
    end,
    id: patchUserEventId(id, start, end, title),
    isCancelled,
    isTentative,
    location,
    owner,
    eventIds,
    recurrence,
    seriesMasterId,
    start,
    title,
    type,
    pastDue,
    offerStatus,
});

const userEventToCalendarEvent = ({
    Body: { Content: body },
    End,
    EventType: type,
    Id: id,
    IsAllDay: allDay,
    Owner,
    Start,
    StatusCodeDescription,
    Subject: title,
    PastDue,
}: UserEvent): CalendarEvent => {
    // Ignore timezone portion for "all day" events.
    const start = allDay ? Start.split('T')[0] : Start;
    const end = allDay ? End.split('T')[0] : End;

    let offerStatus: OfferStatus | undefined;
    if (
        (['Draft', 'Pending'].includes(StatusCodeDescription) && PastDue) ||
        ['Closed', 'Cancelled', 'CancelledandClosed'].includes(StatusCodeDescription)
    ) {
        offerStatus = 'expired';
    } else if (['Draft', 'Pending'].includes(StatusCodeDescription) && !PastDue) {
        offerStatus = 'invited';
    } else if (['ConfirmedandClosed', 'Confirmed'].includes(StatusCodeDescription)) {
        if (isToday(parseISO(start)) || !isPast(parseISO(start))) {
            offerStatus = 'accepted';
        } else {
            offerStatus = 'acceptedpast';
        }
    }

    return createCalendarEvent({
        allDay,
        body,
        end,
        id: patchUserEventId(id, start, end, title),
        isCancelled: false,
        isTentative: false,
        owner: typeof Owner === 'string' ? [Owner] : [Owner.FullName],
        start,
        offerStatus,
        title,
        type,
        pastDue: PastDue,
    });
};

const schoolyearTitlePrefix = 'SY:';
const odataEventToCalendarEvent = ({
    end: { dateTime: end },
    id,
    isAllDay: allDay,
    Owner: owner,
    isCancelled,
    location: { displayName: location },
    recurrence,
    seriesMasterId,
    showAs,
    start: { dateTime: start },
    subject,
    bodyPreview,
}: ODataEvent): CalendarEvent => {
    let type = 'other';
    let title = subject;

    if (subject.startsWith(schoolyearTitlePrefix)) {
        title = subject.substring(schoolyearTitlePrefix.length);
        type = 'schoolyear';
    }

    return createCalendarEvent({
        allDay,
        end,
        id,
        isCancelled,
        isTentative: showAs === 'tentative',
        body: bodyPreview,
        location,
        owner: [owner],
        recurrence,
        seriesMasterId,
        start,
        title,
        type,
    });
};

function convertJSONToEvent(item: UserEvent | ODataEvent | unknown): CalendarEvent {
    if (isUserEvent(item)) {
        return userEventToCalendarEvent(item);
    }

    if (isODataEvent(item)) {
        return odataEventToCalendarEvent(item);
    }

    throw new Error('Unknown event information encountered!');
}
