import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconCalendar = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M24.042 47.443c12.924 0 23.401-10.477 23.401-23.401C47.443 11.117 36.966.64 24.042.64 11.117.64.64 11.117.64 24.042c0 12.924 10.477 23.401 23.402 23.401Z'
            fill='#5DA638'
        />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.14 7.56h-4.28v5.373h-3.927v26.191h26.192v-26.19H33.14V7.56h-4.28v5.373h-9.72V7.56Zm16.377 12.307H12.483v14.266h23.034V19.867Zm-6.204 3.468 2.177-1.581 2.177 1.581-.831 2.56h-2.691l-.832-2.56ZM16.5 21.857l-2.172 3.763h4.345L16.5 21.857Zm7.508 4.067c.77 0 1.394-.866 1.394-1.935s-.625-1.935-1.395-1.935-1.394.866-1.394 1.935.625 1.935 1.395 1.935Zm-6.454 2.236H15.43L14.367 30l1.062 1.841h2.125l1.063-1.84-1.063-1.841Zm3.78 1.83L24 31.636l2.667-1.644L24 28.347l-2.667 1.644Zm10.17 1.964a1.95 1.95 0 1 0 0-3.9 1.95 1.95 0 0 0 0 3.9Z'
            fill='#fff'
        />
    </Svg>
);
export default SvgIconCalendar;
