import React, { FC } from 'react';

import SummaryItem from '../SummaryItem';

interface SummaryItemBooleanProps {
    label: string;
    display?: boolean;
}

const SummaryItemBoolean: FC<SummaryItemBooleanProps> = ({ label, display }) => {
    return <SummaryItem value={label} display={display} />;
};

export default SummaryItemBoolean;
