import Button from '@Components/Button';
import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';
import Picker from '@Components/Picker';
import TextInput from '@Components/TextInput';
import DeviceContext from '@Contexts/DeviceContext';
import useTheme from '@Hooks/useTheme';
import i18n from '@I18n/index';
import { BasicLookup, useGetProvincesQuery } from '@Redux/services/LookupApi';
import { useEditProfileMutation } from '@Redux/services/parent';
import { Parent, EditProfileData } from '@Redux/types';
import { Accessor, lookupToOptions, phoneMask, postalMask } from '@Utilities';
import { set } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { styles } from '../../../styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import ErrorAlert from '@Components/ErrorAlert';

interface IProps {
    show: boolean;
    onDismiss: () => void;
    parent?: Parent;
}

type EditProfileDataForm = Omit<EditProfileData, 'ProvinceValue'> & {
    ProvinceValue: string;
};

const EditProfileModal: React.FC<IProps> = ({ show, parent, onDismiss }) => {
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [profile, setProfile] = useState<EditProfileDataForm>();
    const [isLoading, setIsLoading] = useState(false);
    const [postalCodeError, setPostalCodeError] = useState<string>();
    const { isDesktop } = useContext(DeviceContext);
    const theme = useTheme();
    const [homePhoneErrorMessage, setHomePhoneErrorMessage] = useState('');
    const [mobilePhoneErrorMessage, setMobilePhoneErrorMessage] = useState('');
    const [workPhoneErrorMessage, setWorkPhoneErrorMessage] = useState('');
    const [email2Error, setEmail2Error] = useState('');

    if (!parent) {
        return <></>;
    }

    const { data: provinces } = useGetProvincesQuery();

    useEffect(() => {
        if (!provinces) return;
        setProfile({
            Gwak: parent.Gwak,
            Name: parent.FirstName + ' ' + parent.LastName,
            Salutation: parent.Salutation,
            SalutationValue: parent.Salutation,
            FirstName: parent.FirstName,
            LastName: parent.LastName,
            Designation: parent.Designation,
            DesignationValue: parent.Designation,
            removeProfilePhoto: false,
            HomeAddress: parent.HomeAddress,
            ApartmentNumber: parent.ApartmentNumber,
            RuralRoute: parent.RuralRoute,
            PostalCode: parent.PostalCode,
            City: parent.City,
            Province: parent.Province?.toString(),
            ProvinceValue: provinces.find((i) => i.Abbreviation === parent.Province?.toString())?.Key?.toString() || '',
            HomePhone: parent.HomePhone,
            MobilePhone: parent.MobilePhone,
            WorkPhone: parent.WorkPhone,
            WorkPhoneExtension: parent.WorkPhoneExtension,
            Email: parent.Email,
            Email2: parent.Email2,
        });
    }, [parent, provinces]);

    const provincesOptions = useMemo(() => lookupToOptions<BasicLookup>(provinces), [provinces]);

    const [editProfile] = useEditProfileMutation();

    const validatePostalCode = (value: string) => {
        if (!/^[A-Z]\d[A-Z] \d[A-Z]\d$/.test(value)) {
            setPostalCodeError(i18n.t('postalCodeError'));
            return true;
        } else {
            setPostalCodeError(undefined);
            return false;
        }
    };

    const handleInputChange = (value: string, property: Accessor) => {
        if (!profile) return;
        if (property.includes('PostalCode')) {
            validatePostalCode(value);
        }
        const newProfile = { ...profile };
        set(newProfile, property, value);
        setProfile(newProfile);
    };

    const handleUpdatePress = async () => {
        if (!profile || isLoading || validatePostalCode(profile.PostalCode)) return;
        let formInvalid = false;
        if (profile.HomePhone && !profile.HomePhone.match(/\d{3}-\d{3}-\d{4}/)) {
            setHomePhoneErrorMessage(i18n.t('phoneInvalidFormat'));
            formInvalid = true;
        } else {
            setHomePhoneErrorMessage('');
        }
        if (profile.MobilePhone && !profile.MobilePhone.match(/\d{3}-\d{3}-\d{4}/)) {
            setMobilePhoneErrorMessage(i18n.t('phoneInvalidFormat'));
            formInvalid = true;
        } else {
            setMobilePhoneErrorMessage('');
        }
        if (profile.WorkPhone && !profile.WorkPhone.match(/\d{3}-\d{3}-\d{4}/)) {
            setWorkPhoneErrorMessage(i18n.t('phoneInvalidFormat'));
            formInvalid = true;
        } else {
            setWorkPhoneErrorMessage('');
        }

        if (profile.Email2) {
            const reg = /^\S+@\S+\.\S+$/;
            if (reg.test(profile.Email2) === false) {
                setEmail2Error(i18n.t('alternameEmailInvalidFormat'));
                formInvalid = true;
            } else {
                setEmail2Error('');
            }
        }
        if (formInvalid) return;

        const profileData = {
            ...profile,
            Province: provinces?.find((i) => i.Key.toString() === profile.ProvinceValue)?.Abbreviation || '',
            ProvinceValue: Number(profile.ProvinceValue),
        };
        setIsLoading(true);
        try {
            await editProfile(profileData).unwrap();
            onDismiss();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackEditProfileErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            style={isDesktop ? modalStyle.modal : { height: '100%' }}
            header={<ModalHeader header={i18n.t('editProfile')} onDismiss={onDismiss} />}
            visible={show}
        >
            <KeyboardAwareScrollView keyboardShouldPersistTaps='handled'>
                {!profile ? (
                    <ActivityIndicator />
                ) : (
                    <View style={{ padding: 24 }}>
                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('homeAddress')}
                            mode={'outlined'}
                            value={profile.HomeAddress}
                            onChangeText={(value) => handleInputChange(value, ['HomeAddress'])}
                        />
                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('apartmentNumber')}
                            value={profile.ApartmentNumber}
                            mode={'outlined'}
                            onChangeText={(value) => handleInputChange(value, ['ApartmentNumber'])}
                        />
                        <TextInput
                            label={i18n.t('ruralRoute')}
                            style={styles.buttonMargin}
                            value={profile.RuralRoute}
                            mode={'outlined'}
                            onChangeText={(value) => handleInputChange(value, ['RuralRoute'])}
                        />
                        <TextInput
                            style={styles.buttonMargin}
                            value={profile.City}
                            mode={'outlined'}
                            onChangeText={(value) => handleInputChange(value, ['City'])}
                            label={i18n.t('city')}
                        />
                        <View style={styles.buttonMargin}>
                            <TextInput
                                style={styles.buttonMargin}
                                label={i18n.t('postalCode')}
                                value={profile.PostalCode}
                                mode={'outlined'}
                                onChangeText={(value) => handleInputChange(postalMask(value), ['PostalCode'])}
                            />

                            {postalCodeError && (
                                <Text style={{ paddingBottom: 10, color: theme.colors.error }}>{postalCodeError}</Text>
                            )}
                        </View>

                        <Picker
                            style={styles.buttonMargin}
                            value={profile.ProvinceValue}
                            items={provincesOptions}
                            onValueChange={(value) => handleInputChange(value, ['ProvinceValue'])}
                            inputLabel={i18n.t('province')}
                        />

                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('phoneHome')}
                            mode={'outlined'}
                            value={profile.HomePhone}
                            onChangeText={(value) => handleInputChange(phoneMask(value), ['HomePhone'])}
                            error={Boolean(homePhoneErrorMessage)}
                        />
                        {Boolean(homePhoneErrorMessage) && (
                            <View style={styles.errorLabelContainer}>
                                <Text style={styles.errorLabel}>{homePhoneErrorMessage}</Text>
                            </View>
                        )}

                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('mobile')}
                            mode={'outlined'}
                            value={profile.MobilePhone}
                            onChangeText={(value) => handleInputChange(phoneMask(value), ['MobilePhone'])}
                            error={Boolean(mobilePhoneErrorMessage)}
                        />
                        {Boolean(mobilePhoneErrorMessage) && (
                            <View style={styles.errorLabelContainer}>
                                <Text style={styles.errorLabel}>{mobilePhoneErrorMessage}</Text>
                            </View>
                        )}

                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('phoneWork')}
                            mode={'outlined'}
                            value={profile.WorkPhone}
                            onChangeText={(value) => handleInputChange(phoneMask(value), ['WorkPhone'])}
                            error={Boolean(workPhoneErrorMessage)}
                        />
                        {Boolean(workPhoneErrorMessage) && (
                            <View style={styles.errorLabelContainer}>
                                <Text style={styles.errorLabel}>{workPhoneErrorMessage}</Text>
                            </View>
                        )}

                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('workPhoneExtension')}
                            mode={'outlined'}
                            value={profile.WorkPhoneExtension}
                            onChangeText={(value) => handleInputChange(value, ['WorkPhoneExtension'])}
                        />

                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('email')}
                            mode={'outlined'}
                            value={profile.Email}
                            disabled={true}
                        />
                        <TextInput
                            style={styles.buttonMargin}
                            label={i18n.t('alternateEmail')}
                            mode={'outlined'}
                            value={profile.Email2}
                            error={Boolean(email2Error)}
                            onChangeText={(value) => handleInputChange(value, ['Email2'])}
                        />
                        {Boolean(email2Error) && (
                            <View style={styles.errorLabelContainer}>
                                <Text style={styles.errorLabel}>{email2Error}</Text>
                            </View>
                        )}
                        <View style={{ marginVertical: 34, alignItems: 'flex-end' }}>
                            <Button
                                label={i18n.t('update')}
                                leftIcon={isLoading && <ActivityIndicator size={16} />}
                                underlineColor={theme.colors.watermelon}
                                onPress={handleUpdatePress}
                            />
                        </View>
                    </View>
                )}
            </KeyboardAwareScrollView>
            <ErrorAlert message={message} visible={visible} setVisible={setVisible} />
        </Modal>
    );
};

export default EditProfileModal;

const modalStyle = StyleSheet.create({
    modal: {
        maxHeight: '80%',
        margin: 'auto',
        paddingBottom: 20,
        width: 500,
    },
});
