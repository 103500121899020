import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgPolicy = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M12.004 17.167c.597 0 1.107-.213 1.53-.638.421-.425.633-.936.633-1.533 0-.598-.213-1.107-.638-1.53a2.098 2.098 0 0 0-1.534-.633c-.596 0-1.106.213-1.528.638a2.098 2.098 0 0 0-.634 1.533c0 .598.213 1.107.638 1.53.425.422.936.633 1.533.633ZM12 29.5c-3.417-.84-6.238-2.755-8.463-5.744C1.312 20.768.2 17.45.2 13.8V4.885L12 .5l11.8 4.385V13.8c0 1.548-.207 3.026-.62 4.431a18.286 18.286 0 0 1-1.713 4.002l-4.7-4.7c.2-.377.344-.783.433-1.216.089-.434.133-.873.133-1.317 0-1.467-.522-2.722-1.566-3.767-1.045-1.044-2.3-1.566-3.767-1.566s-2.722.522-3.767 1.566C7.19 12.278 6.667 13.533 6.667 15s.522 2.722 1.566 3.767c1.045 1.044 2.3 1.566 3.767 1.566.444 0 .872-.044 1.283-.133a4.594 4.594 0 0 0 1.184-.433l5.1 5.066a18.076 18.076 0 0 1-3.284 2.8C15.028 28.478 13.6 29.1 12 29.5Z'
            fill='#D77A7F'
        />
    </Svg>
);
export default SvgPolicy;
