import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import UnreportedAbsence from './components/UnreportedAbsence';
import Events from './components/Events';
import ConsentForms from './components/ConsentForms';
import Empty from './components/Empty';

function ActionItems() {
    const [isAbsencesEmpty, setIsAbsencesEmpty] = useState(false);
    const [isConsentFormsEmpty, setIsConsentFormsEmpty] = useState(false);
    const [isEventsEmpty, setIsEventsEmpty] = useState(false);

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            {isAbsencesEmpty && isConsentFormsEmpty && isEventsEmpty && <Empty />}
            <UnreportedAbsence onLoad={(isEmpty) => setIsAbsencesEmpty(isEmpty)} />
            <ConsentForms onLoad={(isEmpty) => setIsConsentFormsEmpty(isEmpty)} />
            <Events onLoad={(isEmpty) => setIsEventsEmpty(isEmpty)} />
        </ScrollView>
    );
}

export default ActionItems;
