import axios from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { logout } from '@Redux/auth';

export const BASEURL = process.env.BASEURL || window?.location?.origin || '';
const BASEAPIURL = `${BASEURL}/api/`;

const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
            headers?: AxiosRequestConfig['headers'];
        },
        unknown,
        unknown
    > =>
    async ({ url, data, method, params, headers }, api) => {
        try {
            const response = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
                withCredentials: true,
            });
            return { data: 'data' in response && response.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            // @TODO in future, refresh token instead of logout (current backend limitation).
            // @SEE https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
            if (err.response?.status === 401) {
                api.dispatch(logout());
            }
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                }
            }
        }
    };

const baseQuery = axiosBaseQuery({
    baseUrl: BASEAPIURL,
});

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQuery,
    endpoints: () => ({}),
});
