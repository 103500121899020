import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    dateTime: {
        marginBottom: 6,
    },
    calendarDateLineSeperator: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    dateWrapper: {
        paddingBottom: 18,
    },
    event: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 10,
    },
    eventDetail: {
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    eventIcon: {
        marginRight: 24,
    },
});

export default styles;
