import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgSchoolBoard = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.85 22.15V5.85h4v-4h12.3v8h4v12.3H13V17h-2v5.15H1.85ZM8.907 5.668l1.088-.791 1.089.79-.416 1.28H9.322l-.415-1.28Zm4 0 1.088-.791 1.089.79-.416 1.28h-1.346l-.415-1.28Zm0 4 1.088-.791 1.089.79-.416 1.28h-1.346l-.415-1.28Zm1.088 3.209-1.088.79.415 1.28h1.346l.416-1.28-1.089-.79Zm-8-4-1.088.79.415 1.28h1.346l.416-1.28-1.089-.79Zm2.912.79 1.088-.79 1.089.79-.416 1.28H9.322l-.415-1.28Zm-2.912 3.21-1.088.79.415 1.28h1.346l.416-1.28-1.089-.79Zm10.912.79 1.088-.79 1.089.79-.416 1.28h-1.346l-.415-1.28Zm-6.912-.79-1.088.79.415 1.28h1.346l.416-1.28-1.089-.79Zm-5.088 4.79 1.088-.79 1.089.79-.416 1.28H5.322l-.415-1.28Zm13.088-.79-1.088.79.415 1.28h1.346l.416-1.28-1.089-.79Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgSchoolBoard;
