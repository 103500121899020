import Modal from '@Components/Modal';
import i18n from '@I18n/index';
import React, { useContext, useState } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { Text, View } from 'react-native';
import styles from './styles';
import useTheme from '@Hooks/useTheme';
import SvgLeftArrow from '@Icon/LeftArrow';
import Button from '@Components/Button';
import SvgCheckmark from '@Icon/Checkmark';
import { useConfirmEventDetailsMutation, useGetEventDetailsQuery } from '@Redux/services/EventOfferApi';
import ModalHeader from '@Components/ModalHeader';
import HtmlView from '@Components/HtmlView/HtmlView';
import { ScrollView } from 'react-native-gesture-handler';
import DeviceContext from '@Contexts/DeviceContext';
import ErrorAlert from '@Components/ErrorAlert';

interface IProps {
    show: boolean;
    eventResponseId: string;
    onDismiss: () => void;
}

const EventDetailsModal: React.FC<IProps> = ({ show, eventResponseId, onDismiss }) => {
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const { data: eventDetails, isLoading } = useGetEventDetailsQuery({
        eventResponseId,
    });
    const theme = useTheme();
    const [confirmEventDetails] = useConfirmEventDetailsMutation();
    const { isDesktop } = useContext(DeviceContext);
    const [loading, setLoading] = useState(false);

    const handleAcceptClick = async () => {
        if (!eventDetails || loading) return;

        setLoading(true);
        try {
            await confirmEventDetails({
                EventResponseId: eventResponseId,
                CostItems: [],
                Answers: [],
            }).unwrap();
            onDismiss();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackConfirmEventErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            style={!isDesktop ? { height: '100%' } : styles.modalDesktop}
            header={<ModalHeader header={i18n.t('consentForm')} leftIcon={<SvgLeftArrow />} onDismiss={onDismiss} />}
            visible={show}
        >
            <ScrollView>
                <View style={styles.container}>
                    {(!eventDetails || isLoading) && <ActivityIndicator />}
                    {eventDetails && !isLoading && (
                        <>
                            <Text style={styles.header}>{eventDetails.EventName}</Text>
                            <Text style={styles.description}>{eventDetails.OfferDescription}</Text>
                            {eventDetails.ShowDisaclaimer && eventDetails.Disclaimer && (
                                <HtmlView
                                    htmlString={eventDetails.Disclaimer.replace('<EVENTNAME>', eventDetails.EventName)}
                                />
                            )}
                            <View style={styles.button}>
                                <Button
                                    label={i18n.t('accept')}
                                    underlineColor={theme.colors.watermelon}
                                    leftIcon={
                                        loading ? (
                                            <ActivityIndicator size={24} />
                                        ) : (
                                            <SvgCheckmark fill={theme.colors.watermelon} />
                                        )
                                    }
                                    onPress={handleAcceptClick}
                                />
                            </View>
                        </>
                    )}
                </View>
            </ScrollView>
            <ErrorAlert message={message} visible={visible} setVisible={setVisible} />
        </Modal>
    );
};

export default EventDetailsModal;
