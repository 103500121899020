import { View, Text } from 'react-native';
import i18n from '@I18n';
import { styles } from './styles';
import { Triangle } from '@Icon';

type SignInReminderProp = {
    inverted?: boolean;
    reversed?: boolean;
    first?: boolean;
};

function SignInReminder({ inverted, reversed, first }: SignInReminderProp) {
    return (
        <View
            style={[
                styles.reminderContainer,
                !reversed && styles.reminderContainerNonReverse,
                first && styles.reminderContainerFirst,
                inverted && styles.reminderContainerInverted,
                reversed && styles.reverse,
            ]}
        >
            <Triangle width={12} height={12} style={[styles.reminderIcon, inverted && styles.inverted]} />
            <Text style={styles.reminderText} accessibilityLabel={i18n.t('signInReminder')}>
                {i18n.t('signInReminder')}
            </Text>
        </View>
    );
}

export default SignInReminder;
