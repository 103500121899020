import React, { useContext, useState } from 'react';

import { endOfMonth, getMonth, startOfMonth } from 'date-fns';
import DesktopCalendar from './Desktop';
import MobileCalendar from './Mobile';

import type { CalendarViewTypes } from './types';

import useCalendar from './useCalendar';

import DeviceContext from '@Contexts/DeviceContext';
import StudentListEmpty from '@Components/StudentListEmpty';
import { useStudentListEmpty } from '@Hooks/useStudentListEmpty';
import { isUndefined } from 'lodash';

function Calendar() {
    const { isDesktop } = useContext(DeviceContext);
    const [selectedCalendarView, setSelectedCalendarView] = useState<CalendarViewTypes>('agenda');

    const { isLoadingRegistrations, isLoadingStudents, hasStudents } = useStudentListEmpty();

    const calendarProps = useCalendar({
        endOfPeriod: endOfMonth,
        getPeriod: getMonth,
        startOfPeriod: startOfMonth,
    });

    const calendar = isDesktop ? (
        <DesktopCalendar
            {...calendarProps}
            selectedCalendarView={selectedCalendarView}
            setSelectedCalendarView={setSelectedCalendarView}
            hasStudents={hasStudents}
        />
    ) : (
        <MobileCalendar
            {...calendarProps}
            selectedCalendarView={selectedCalendarView}
            setSelectedCalendarView={setSelectedCalendarView}
        />
    );

    return (
        <>
            {!isLoadingRegistrations && !isLoadingStudents && !isUndefined(hasStudents) && (
                <>{hasStudents ? <>{calendar}</> : <StudentListEmpty />}</>
            )}
        </>
    );
}

export default Calendar;
