import React from 'react';
import { View, Text, LayoutChangeEvent } from 'react-native';
import i18n from '@I18n';

import { CalendarEvent } from '@Redux/services/CalendarApi';
import { addDays, format, parseISO } from 'date-fns';

interface NoEventsProps {
    event: CalendarEvent;
    onLayout: (event: LayoutChangeEvent) => void;
}

import { styles } from './styles';
function NoEvents({ event, ...rest }: NoEventsProps) {
    const start = parseISO(event.start);
    const end = parseISO(event.end);
    const isOneDayOnly = addDays(start, 1) >= end;
    const dateFormat = 'EEE MMM d';
    return (
        <View style={styles.noEvents} {...rest}>
            <Text style={styles.dateLabelText}>
                {isOneDayOnly ? (
                    format(start, dateFormat)
                ) : (
                    <>
                        {format(start, dateFormat)} - {format(end, dateFormat)}
                    </>
                )}
            </Text>
            <View style={styles.noEventsIndicator}></View>
            <Text style={styles.noEventsLabel}>{i18n.t('noEvents')}</Text>
        </View>
    );
}

export default NoEvents;
