import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';
const SvgFacebookLogoDisabled = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <G clipPath='url(#facebookLogoDisabled_svg__a)'>
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M24.918 22.604c0 .727-.59 1.316-1.316 1.316h-6.075v-9.232h3.1l.463-3.598h-3.563V8.793c0-1.042.29-1.752 1.783-1.752h1.906V3.821c-.33-.043-1.46-.141-2.777-.141-2.747 0-4.627 1.676-4.627 4.756v2.653h-3.107v3.598h3.107v9.232H2.394c-.727 0-1.316-.59-1.316-1.316V1.396c0-.727.59-1.316 1.316-1.316h21.208c.727 0 1.316.589 1.316 1.316v21.208Z'
                fill='#373639'
                fillOpacity={0.53}
            />
        </G>
        <Defs>
            <ClipPath id='facebookLogoDisabled_svg__a'>
                <Path fill='#fff' transform='translate(.998)' d='M0 0h24v24H0z' />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgFacebookLogoDisabled;
