import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    root: {
        marginBottom: 32,
    },
    courseContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 20,
    },
    courseDescription: {
        ...typography.h4,
        flexShrink: 1,
    },
    iconContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    iconCount: {
        paddingHorizontal: 10,
        flexDirection: 'row',
    },
    count: {
        ...typography.body,
        marginLeft: 12,
    },
    accordion: {
        borderBottomColor: defaultTheme.colors.textPrimary,
        borderWidth: 1,
    },
    accordionIcon: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        right: 12,
        top: -12.5,
    },
});

export default styles;
