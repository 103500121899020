import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    loaderContainer: {
        display: 'flex',

        alignItems: 'center',
        minHeight: 85,
        justifyContent: 'center',
    },
});
