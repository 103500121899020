import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgNeutralFace = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.563.875h10.874l7.688 7.688v10.874l-7.688 7.688H8.563L.875 19.437V8.563L8.563.875Zm2.771 9.629a1.706 1.706 0 1 1-3.412 0 1.706 1.706 0 0 1 3.412 0Zm8.75 0a1.706 1.706 0 1 1-3.412 0 1.706 1.706 0 0 1 3.412 0ZM7 17.5h14v2.334H7V17.5Z'
            fill='#D77A7F'
        />
    </Svg>
);
export default SvgNeutralFace;
