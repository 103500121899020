import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    avatar: {
        marginTop: 3,
        marginBottom: 3,
    },
    avatarExpired: {
        backgroundColor: '#4b4a4d',
        opacity: 0.5,
        pointerEvents: 'none',
    },
    eventAvatarContainer: {
        flexDirection: 'row',
    },
});
