import { useState } from 'react';
import { View, Text, Pressable, Linking } from 'react-native';

import { TextInput as PaperTextInput } from 'react-native-paper';
import { RootState, store } from '@Redux';
import { loginWithEmail } from '@Redux/auth';
import { useLoginWithPasswordMutation } from '@Redux/services/account';
import i18n from '@I18n';
import { styles } from './styles';
import { ArrowRight } from '@Icon';
import { useSelector } from 'react-redux';
import { getGreetingByTimeofDay } from '@Utilities';
import TextInput from '@Components/TextInput';
import ShowPassword from '@Components/ShowPassword';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '@App/rootNavigation';
import Avatar from '@Components/Avatar';
import {
    AppleLoginButton,
    FacebookLoginButton,
    GoogleLoginButton,
    MicrosoftLoginButton,
} from '@Components/SocialLoginButtons';
import useTheme from '@Hooks/useTheme';
import { AccountLockedHelpURL } from '@App/constants';
import { useMediaQuery } from 'react-responsive';
import Header from '@Components/Header';

type AppReturnScreenProp = {
    navigation: NativeStackNavigationProp<RootStackParamList, 'signin', undefined>;
    setActiveScreen: (screen: string) => void;
};

function AppReturnScreen({ navigation, setActiveScreen }: AppReturnScreenProp) {
    const userId = useSelector((state: RootState) => state.auth.lastUserId);
    const email = useSelector((state: RootState) => state.auth?.email);
    const firstName =
        useSelector((state: RootState) => userId && state.auth?.storedAccountsPreference?.[userId]?.firstName) || '';
    const photo =
        useSelector(
            (state: RootState) => userId && state.auth?.storedAccountsPreference?.[userId]?.profilePictureUri
        ) || '';
    const [loginWithPassword] = useLoginWithPasswordMutation();
    const greetings = getGreetingByTimeofDay();
    const [passwordInput, setPassword] = useState('');
    const [hidePassword, setHidePassword] = useState(true);
    const lastLogin = useSelector((state: RootState) => state.auth.lastLogin);
    const socialProvider = useSelector((state: RootState) => state.auth.socialProvider);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [isAccountLocked, setIsAccountLocked] = useState(false);

    const theme = useTheme();

    const hideNavigation = useMediaQuery({
        maxHeight: 707,
    });

    const heightExtraSmall = useMediaQuery({
        maxHeight: 650,
    });

    function handleDefaultSignin() {
        setActiveScreen('signin');
    }

    function validateField() {
        if (!passwordInput) {
            setPasswordErrorMessage('Password is required');
            return false;
        } else {
            setPasswordErrorMessage('');
        }

        return true;
    }

    async function doLogin(password: string): Promise<void> {
        try {
            const response = await loginWithPassword({
                email,
                password,
            }).unwrap();
            store.dispatch(loginWithEmail({ userId: response.UserId, email: email, password: password }));
        } catch (e) {
            setIsAccountLocked(e.data.AccountIsLocked);
            if (e.data.AccountIsLocked) {
                // TODO: Custom error component for email link
                setLoginErrorMessage(i18n.t('loginErrorLocked'));
            } else {
                setLoginErrorMessage(i18n.t('loginErrorGeneric'));
            }
        }
    }

    function pressLogin() {
        const isValid = validateField();
        if (!isValid) {
            return;
        }
        doLogin(passwordInput);
    }

    function handleForgotPassword() {
        navigation.navigate('forgot');
    }

    function AppReturnSocial({ provider }) {
        switch (provider) {
            case 'Facebook':
                return <FacebookLoginButton errorCallback={() => setLoginErrorMessage(i18n.t('loginErrorGeneric'))} />;
            case 'Google':
                return <GoogleLoginButton errorCallback={() => setLoginErrorMessage(i18n.t('loginErrorGeneric'))} />;
            case 'Microsoft':
                return <MicrosoftLoginButton errorCallback={() => setLoginErrorMessage(i18n.t('loginErrorGeneric'))} />;
            case 'Apple':
                return <AppleLoginButton errorCallback={() => setLoginErrorMessage(i18n.t('loginErrorGeneric'))} />;
            default:
                return <></>;
        }
    }

    return (
        <>
            {!hideNavigation && <Header />}
            <View style={{ flex: 1, justifyContent: 'space-between' }}>
                <View>
                    <View>
                        <View
                            style={[styles.fingerprintContainer, heightExtraSmall && styles.fingerprintContainerSmall]}
                        >
                            <Avatar label={firstName} size={140} picture={photo} />
                        </View>
                    </View>
                    <Text
                        style={[styles.greetings, heightExtraSmall && styles.greetingsExtraSmall]}
                        accessibilityLabel={greetings}
                    >
                        {greetings}
                    </Text>
                    <Text
                        style={[styles.greetings2, heightExtraSmall && styles.greetings2ExtraSmall]}
                        accessibilityLabel={firstName || 'Parent'}
                        numberOfLines={1}
                    >
                        {firstName || 'Parent'}
                    </Text>
                    <View style={[styles.fieldContainer, heightExtraSmall && styles.fieldContainerExtraSmall]}>
                        {lastLogin === 'email' ? (
                            <>
                                <TextInput
                                    label={i18n.t('appreturnPassword')}
                                    accessibilityLabel={i18n.t('appreturnPassword')}
                                    testID='password-field'
                                    secureTextEntry={hidePassword}
                                    mode='outlined'
                                    right={
                                        <PaperTextInput.Icon
                                            name={() => (
                                                <ShowPassword
                                                    onPressIn={() => setHidePassword(false)}
                                                    onPressOut={() => setHidePassword(true)}
                                                />
                                            )}
                                            style={styles.iconWrapper}
                                        />
                                    }
                                    value={passwordInput}
                                    onChangeText={(val) => setPassword(val)}
                                    error={Boolean(loginErrorMessage || passwordErrorMessage)}
                                    onSubmitEditing={pressLogin}
                                />
                                {Boolean(passwordErrorMessage || loginErrorMessage) && (
                                    <View style={styles.errorLabelContainer}>
                                        <Text style={styles.errorLabel}>
                                            {passwordErrorMessage || loginErrorMessage}
                                        </Text>
                                        {isAccountLocked && (
                                            <Pressable
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: theme.colors.backdrop,
                                                }}
                                                onPress={() => Linking.openURL(AccountLockedHelpURL)}
                                            >
                                                <Text>{i18n.t('accountLockLinkLabel')}</Text>
                                            </Pressable>
                                        )}
                                    </View>
                                )}
                                <View style={[styles.controlContainer, styles.controlContainerAppReturn]}>
                                    <Pressable onPress={handleForgotPassword}>
                                        <View style={styles.forgotTextContainer}>
                                            <Text style={styles.forgot} accessibilityLabel={i18n.t('forgotPassword')}>
                                                {i18n.t('forgotPassword')}
                                            </Text>
                                        </View>
                                    </Pressable>
                                    <Pressable onPress={pressLogin}>
                                        <View style={styles.buttonContainer}>
                                            <View style={styles.signInTextContainer}>
                                                <Text style={styles.signIn} accessibilityLabel={i18n.t('signIn')}>
                                                    {i18n.t('signIn')}
                                                </Text>
                                            </View>
                                            <ArrowRight style={styles.arrowRight} />
                                        </View>
                                    </Pressable>
                                </View>
                            </>
                        ) : (
                            <View style={[styles.socialLoginContainer]}>
                                <AppReturnSocial provider={socialProvider} />
                            </View>
                        )}
                    </View>
                </View>
                <View style={[styles.controlContainer, styles.controlContainerBottom]}>
                    <Pressable onPress={handleDefaultSignin}>
                        <View style={styles.switchTextContainer}>
                            <Text style={styles.switch} accessibilityLabel={i18n.t('switchAccount')}>
                                {i18n.t('switchAccount')}
                            </Text>
                        </View>
                    </Pressable>
                </View>
            </View>
        </>
    );
}

export default AppReturnScreen;
