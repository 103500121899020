import { RootNavigationProp, RootStackParamList } from '@App/rootNavigation';
import DeviceContext from '@Contexts/DeviceContext';
import i18n from '@I18n';
import { useNavigation } from '@react-navigation/native';
import { store } from '@Redux';
import { loginWithSocial } from '@Redux/auth';
import { useSocialLoginMutation } from '@Redux/services/account';
import { decodeToken } from '@Utilities';
import { useContext, useEffect } from 'react';
import { Text, View } from 'react-native';
import { ValueOf } from 'type-fest';

export const CallbackScreen = () => {
    const navigation = useNavigation<RootNavigationProp>();
    const { isDesktop } = useContext(DeviceContext);
    const [socialLogin] = useSocialLoginMutation();

    async function socialLoginCallback(providerUserId: string, provider: string) {
        const response = await socialLogin({ provider, providerUserId }).unwrap();
        store.dispatch(
            loginWithSocial({ userId: response.UserId, email: response.UserName, socialProvider: provider })
        );
    }

    useEffect(() => {
        handleRedirect();
    }, []);

    const handleRedirect = async () => {
        const referrer = document.referrer;
        const hostname = referrer && referrer.trim() !== '' && new URL(referrer).hostname;
        const paramsUrl = (window.location.hash !== '' ? window.location.hash : window.location.search).slice(1);
        const searchParams = new URLSearchParams(paramsUrl);

        const auth_token = searchParams.get('authentication_token');
        const idToken = searchParams.get('id_token');
        const accessToken = searchParams.get('access_token');
        let isMicrosoft = false;
        let isApple = false;
        let isFacebook = false;
        if (idToken) {
            const decodedAuthToken = decodeToken(idToken);
            isApple = Object.values(decodedAuthToken).some((key: string) => key.includes('apple'));
        }
        const isGoogle = paramsUrl.indexOf('google') >= 0 ? true : false;
        if (auth_token) {
            const decodedAuthToken = decodeToken(auth_token);
            isMicrosoft = Object.keys(decodedAuthToken).some((key: string) => key.includes('microsoft'));
        }

        if (!isGoogle && !isApple && !isMicrosoft && accessToken) {
            isFacebook = true;
        }
        if (isApple || (hostname && 'appleid.apple.com' === hostname)) {
            const id_token = searchParams.get('id_token');
            if (!id_token) {
                return;
            }
            const payload = decodeToken(id_token);

            const providerUserId = payload.sub;
            if (!providerUserId) {
                return;
            }
            isDesktop && (await socialLoginCallback(providerUserId, 'Apple'));
            if (isDesktop) {
                window.opener.location = window.opener.location.origin;
                window.close();
            } else {
                handleOpenUrl(`myfamilyroom://sociallogin?accessToken=${id_token}&provider=Apple`, 'sociallogin', {
                    accessToken: id_token,
                    provider: 'Apple',
                });
            }
        } else if (accessToken && (isFacebook || (hostname && hostname.includes('facebook')))) {
            handleOpenUrl(`myfamilyroom://sociallogin?accessToken=${accessToken}&provider=Facebook`, 'sociallogin', {
                accessToken: accessToken,
                provider: 'Facebook',
            });
        } else if (accessToken && (isMicrosoft || (hostname && hostname === 'login.live.com'))) {
            handleOpenUrl(`myfamilyroom://sociallogin?accessToken=${accessToken}&provider=Microsoft`, 'sociallogin', {
                accessToken: accessToken,
                provider: 'Microsoft',
            });
        } else if (accessToken && (isGoogle || (hostname && hostname.includes('google')))) {
            handleOpenUrl(`myfamilyroom://sociallogin?accessToken=${accessToken}&provider=Google`, 'sociallogin', {
                accessToken: accessToken,
                provider: 'Google',
            });
        } else if (searchParams.get('isRegisterCallback') && searchParams.get('isRegisterCallback') === 'true') {
            let url = 'myfamilyroom://verify?';
            const email = searchParams.get('email');
            const gwak = searchParams.get('gwak');
            if (email && email !== null) {
                url += `email=${email}`;
                handleOpenUrl(url, 'password', {
                    email: email,
                });
            } else if (gwak && gwak !== null) {
                url += `gwak=${gwak}`;
                handleOpenUrl(url, 'password', {
                    gwak: gwak,
                });
            }
        } else if (
            searchParams.get('isResetPasswordCallback') &&
            searchParams.get('isResetPasswordCallback') === 'true' &&
            searchParams.get('resetToken')
        ) {
            const token = searchParams.get('resetToken');
            if (token && token !== null) {
                handleOpenUrl(`myfamilyroom://resetPassword?token=${token}`, 'resetPassword', {
                    token: token,
                });
            }
        }
    };

    const handleOpenUrl = async (
        url: string,
        screen: keyof RootStackParamList,
        params?: ValueOf<RootStackParamList>
    ) => {
        if (isDesktop) {
            navigation.navigate(screen, params);
        } else {
            window.location.replace(url);
            const start = new Date().valueOf();
            setTimeout(() => {
                // Prevents opening web if user backs to callback page
                if (new Date().valueOf() - start > 500) return;
                navigation.navigate(screen, params);
            }, 200);
        }
    };

    return (
        <View>
            <Text>{i18n.t('redirecting')}</Text>
        </View>
    );
};
