import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        margin: 24,
        paddingBottom: 48,
    },
    title: {
        ...typography.h3,
    },
    fieldContainer: {
        paddingVertical: 16,
        marginTop: 16,
    },
    addressContainer: {
        paddingVertical: 16,
    },
    controlContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 36,
        marginTop: 24,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    registerContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    registerText: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    arrowRight: {
        fontSize: 24,
        marginLeft: 8,
    },
    consentContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 36,
        marginTop: 32,
        paddingHorizontal: 8,
        minWidth: '100%',
    },
    autoCompleteDescriptionContainer: {
        backgroundColor: 'white',
        borderColor: defaultTheme.colors.inputOutline,
        borderWidth: 1,
        borderTopWidth: 0,
        paddingVertical: 2,
        paddingHorizontal: 4,
    },
    autoCompleteDescription: {
        ...typography.body2,
    },
    autoCompleteList: {
        minHeight: 160,
    },
    autoCompleteListAndroid: {
        minHeight: 250,
    },
    errorLabelContainer: {
        marginHorizontal: 8,
    },
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.error,
    },
});
