import * as React from 'react';
import Svg, { SvgProps, Mask, Path, G } from 'react-native-svg';
const SvgLanguageFrench = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Mask
            id='language-french_svg__a'
            style={{
                maskType: 'alpha',
            }}
            maskUnits='userSpaceOnUse'
            x={0}
            y={0}
            width={20}
            height={20}
        >
            <Path fill='#D9D9D9' d='M0 0h20v20H0z' />
        </Mask>
        <G mask='url(#language-french_svg__a)'>
            <Path
                d='M2.625 14.166V5.833h6.5v2.625H5.25v.938h3.042v2.416H5.25v2.354H2.625Zm7.75 0V5.833h5.042c.538 0 1 .192 1.383.575.383.384.575.845.575 1.383v1.813c0 .403-.15.74-.448 1.01-.299.271-.65.441-1.052.51l1.813 3.042h-3l-1.521-2.77H13v2.77h-2.625ZM13 9.396h1.875v-.938H13v.938Z'
                fill='#A28AC7'
            />
        </G>
    </Svg>
);
export default SvgLanguageFrench;
