/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

const ErrorContext = createContext({
    message: '',
    setMessage: (arg: string) => {},
    visible: false,
    setVisible: (arg: boolean) => {},
});

export const ErrorContextProvider = ErrorContext.Provider;
export default ErrorContext;
