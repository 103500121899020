import React, { useCallback, useMemo } from 'react';
import { LayoutChangeEvent, Text, View } from 'react-native';

import { first, groupBy } from 'lodash';
import { format, formatISO, isToday, isTomorrow, isYesterday } from 'date-fns';
import i18n from '@I18n';

import { CalendarEvent, DailySeriesEventDateMap, dateRep } from '@Redux/services/CalendarApi';

import { IconCalendarDateLineSeperator } from '@Icon';
import { EventTitle, NoEvents, TimeLabel } from '@Screens/Calendar/components/DateEntry/components';
import OwnerAvatar from '@Components/OwnerAvatar';
import EventPressable from '@Screens/Calendar/components/EventPressable';
import { styles } from './styles';
import EventIcon from '@Components/EventIcon';

interface DateEntryProps {
    dailySeriesEventMap: DailySeriesEventDateMap;
    item: Date;
    reportHeight: (date: Date, height: number) => void;
    onEventAccepted?: () => Promise<void>;
}

function DateEntry({ dailySeriesEventMap, item: date, onEventAccepted, reportHeight }: DateEntryProps) {
    const dateString = useMemo(() => formatISO(date, dateRep), [date]);
    const dailyEvents = dailySeriesEventMap?.[dateString];

    const eventsGroupedByAllDayStatus = useMemo(() => groupBy(dailyEvents, 'allDay'), [dailyEvents]);
    const handleLayout = useCallback(
        (object: LayoutChangeEvent) => {
            reportHeight(date, object.nativeEvent.layout.height);
        },
        [reportHeight]
    );

    if (first(dailyEvents)?.type === 'noevents') {
        return <NoEvents key={dateString} event={first(dailyEvents) as CalendarEvent} onLayout={handleLayout} />;
    }

    return (
        <View onLayout={handleLayout} style={styles.dateWrapper}>
            <>
                <View style={styles.date}>
                    {isToday(date) && <Text style={styles.dateLabel}>{i18n.t('isToday')}</Text>}
                    {isYesterday(date) && <Text style={styles.dateLabel}>{i18n.t('isYesterday')}</Text>}
                    {isTomorrow(date) && <Text style={styles.dateLabel}>{i18n.t('isTomorrow')}</Text>}
                    {(isToday(date) || isYesterday(date) || isTomorrow(date)) && (
                        <IconCalendarDateLineSeperator style={styles.calendarDateLineSeperator} width={11} height={7} />
                    )}
                    <Text style={styles.dateLabel}>{format(date, 'EEE MMM d')}</Text>
                </View>
                {Object.entries(eventsGroupedByAllDayStatus).map(([isAllDay, events]) => (
                    <View key={`is-all-day-${isAllDay}`}>
                        {events.map((event) => (
                            <View style={styles.event} key={`event-id-${event.id}`}>
                                <TimeLabel event={event} isAllDay={isAllDay === 'true'} />
                                <View style={styles.eventIcon}>
                                    <EventIcon event={event} />
                                </View>
                                <EventPressable
                                    event={event}
                                    onEventAccepted={onEventAccepted}
                                    style={styles.eventDetail}
                                >
                                    <EventTitle event={event} />
                                    <OwnerAvatar style={{ marginLeft: 12 }} event={event} size={26} />
                                </EventPressable>
                            </View>
                        ))}
                    </View>
                ))}
            </>
        </View>
    );
}

export default DateEntry;
