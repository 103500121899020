import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBubble = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.483.866h23.034v14.267H10.13L6 22.286l-4.13-7.153H.483V.866Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgBubble;
