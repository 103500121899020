import { View, Text, Pressable, Platform, Linking } from 'react-native';

import { store } from '@Redux';
import { loginWithSocial } from '@Redux/auth';
import { useSocialLoginMutation } from '@Redux/services/account';
import i18n from '@I18n';
import { styles } from './styles';
import { GoogleLogo, FacebookLogo, MicrosoftLogo, AppleLogo } from '@Icon';

import useSocialAuth from '@Hooks/useSocialAuth';
import { useMemo } from 'react';

interface SocialButtonProps {
    errorCallback?: () => void;
}

export function GoogleLoginButton({ errorCallback }: SocialButtonProps) {
    const [socialLogin] = useSocialLoginMutation();
    const { googleLogin, googleURL } = useSocialAuth();
    const isMobile = useMemo(() => Platform.OS !== 'web', [Platform.OS]);
    async function socialLoginCallback(providerUserId: string, provider: string) {
        const response = await socialLogin({ provider, providerUserId }).unwrap();
        store.dispatch(
            loginWithSocial({ userId: response.UserId, email: response.UserName, socialProvider: provider })
        );
    }

    async function handleGoogleLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(googleURL);
            } else {
                await googleLogin(socialLoginCallback);
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    return (
        <View style={styles.socialButtonContainer}>
            <Pressable onPress={handleGoogleLogin}>
                <View style={styles.buttonContainer}>
                    <View style={styles.socialIcon}>
                        <GoogleLogo width={32} height={32} />
                    </View>
                    <View style={styles.signInTextContainer}>
                        <Text style={styles.signIn} accessibilityLabel={i18n.t('signInSocialGoogle')}>
                            {i18n.t('signInSocialGoogle')}
                        </Text>
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export function FacebookLoginButton({ errorCallback }: SocialButtonProps) {
    const [socialLogin] = useSocialLoginMutation();
    const { facebookLogin, facebookURL } = useSocialAuth();
    const isMobile = useMemo(() => Platform.OS !== 'web', [Platform.OS]);
    async function socialLoginCallback(providerUserId: string, provider: string) {
        const response = await socialLogin({ provider, providerUserId }).unwrap();
        store.dispatch(
            loginWithSocial({ userId: response.UserId, email: response.UserName, socialProvider: provider })
        );
    }

    async function handleFacebookLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(facebookURL);
            } else {
                await facebookLogin(socialLoginCallback);
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    return (
        <View style={styles.socialButtonContainer}>
            <Pressable onPress={handleFacebookLogin}>
                <View style={styles.buttonContainer}>
                    <View style={styles.socialIcon}>
                        <FacebookLogo width={32} height={32} />
                    </View>
                    <View style={styles.signInTextContainer}>
                        <Text style={styles.signIn} accessibilityLabel={i18n.t('signInSocialFacebook')}>
                            {i18n.t('signInSocialFacebook')}
                        </Text>
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export function MicrosoftLoginButton({ errorCallback }: SocialButtonProps) {
    const [socialLogin] = useSocialLoginMutation();
    const { outlookLogin, microsoftURL } = useSocialAuth();
    const isMobile = useMemo(() => Platform.OS !== 'web', [Platform.OS]);
    async function socialLoginCallback(providerUserId: string, provider: string) {
        const response = await socialLogin({ provider, providerUserId }).unwrap();
        store.dispatch(
            loginWithSocial({ userId: response.UserId, email: response.UserName, socialProvider: provider })
        );
    }

    async function handleMicrosoftLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(microsoftURL);
            } else {
                await outlookLogin(socialLoginCallback);
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    return (
        <View style={styles.socialButtonContainer}>
            <Pressable onPress={handleMicrosoftLogin}>
                <View style={styles.buttonContainer}>
                    <View style={styles.socialIcon}>
                        <MicrosoftLogo width={32} height={32} />
                    </View>
                    <View style={styles.signInTextContainer}>
                        <Text style={styles.signIn} accessibilityLabel={i18n.t('signInSocialMicrosoft')}>
                            {i18n.t('signInSocialMicrosoft')}
                        </Text>
                    </View>
                </View>
            </Pressable>
        </View>
    );
}

export function AppleLoginButton({ errorCallback }: SocialButtonProps) {
    const { appleLogin, appleURL } = useSocialAuth();
    const isMobile = useMemo(() => Platform.OS !== 'web', [Platform.OS]);

    async function handleAppleLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(appleURL);
            } else {
                await appleLogin();
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    return (
        <View style={styles.socialButtonContainer}>
            <Pressable onPress={handleAppleLogin}>
                <View style={styles.buttonContainer}>
                    <View style={styles.socialIcon}>
                        <AppleLogo width={32} height={32} />
                    </View>
                    <View style={styles.signInTextContainer}>
                        <Text style={styles.signIn} accessibilityLabel={i18n.t('signInSocialApple')}>
                            {i18n.t('signInSocialApple')}
                        </Text>
                    </View>
                </View>
            </Pressable>
        </View>
    );
}
