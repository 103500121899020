import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBuilding2 = (props: SvgProps) => (
    <Svg width={99} height={100} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path d='M49.5 10.28 23.43 55.435h52.14L49.5 10.28Z' fill='#A28AC7' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.647 45.173h-12v45.654H40.86V70.24h17.122v20.587H98.353V45.173H12.647Zm8.57 15.467h8.5l4.251 7.362-4.25 7.363h-8.502l-4.25-7.363 4.25-7.362Zm56.5 0h-8.5l-4.25 7.362 4.25 7.363h8.5l4.251-7.363-4.25-7.362Z'
            fill='#73CC49'
        />
        <Path d='M64.425 14.25h12.15l4.725 13.5H59.7l4.725-13.5Z' fill='#D77A7F' />
    </Svg>
);
export default SvgBuilding2;
