import { StyleSheet } from 'react-native';
import { defaultTheme, fonts } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    calendarDateLineSeperator: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    date: {
        alignItems: 'center',
        borderTopColor: 'black',
        borderTopWidth: 2,
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 18,
        paddingTop: 6,
    },
    dateWrapper: {
        paddingBottom: 18,
    },
    dateLabel: {
        color: defaultTheme.colors.textPrimary,
        fontFamily: fonts.MontserratBold,
        fontSize: 12,
    },
    event: {
        display: 'flex',
        flexDirection: 'row',
    },
    eventDetail: {
        flexGrow: 1,
        flexShrink: 1,
    },
    eventIcon: {
        marginLeft: 4,
    },
});
