import React, { useCallback, useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { Dimensions, LayoutChangeEvent, Pressable, View, Text } from 'react-native';
import { format, formatISO } from 'date-fns';
import { throttle, times } from 'lodash';

import { CalendarViewTypes } from '../../../types';
import { EventPressable } from '../../../components';

import { rowHeight, styles } from './styles';

import { DailySeriesEventDateMap, dateRep } from '@Redux/services/CalendarApi';
import OwnerAvatar from '@Components/OwnerAvatar';

interface DateEntryProps {
    dailySeriesEventMap: DailySeriesEventDateMap;
    item: Date;
    currentIntervalMonth: Date;
    setSelectedCalendarView: Dispatch<SetStateAction<CalendarViewTypes>>;
    warpToDate?: (date: Date) => Promise<void>;
    onEventAccepted: () => Promise<void>;
}

function DateEntry({
    currentIntervalMonth,
    dailySeriesEventMap,
    item: date,
    setSelectedCalendarView,
    warpToDate,
    onEventAccepted,
}: DateEntryProps) {
    const dateString = useMemo(() => formatISO(date, dateRep), [date]);
    const events = dailySeriesEventMap?.[dateString];

    const dateInInterval = date.getMonth() === currentIntervalMonth.getMonth();
    const today = new Date();
    const dateActive =
        today.getDate() === date.getDate() &&
        today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear();

    const [renderIdx, setRenderIdx] = useState<number>(0);

    const [dayHeight, setDayHeight] = useState<number>(0);
    const handleDayViewLayout = useCallback((event: LayoutChangeEvent) => {
        const { height } = event.nativeEvent.layout;
        setDayHeight(height);
    }, []);

    const rowsToShow = useMemo(() => {
        if (!events?.length) {
            return 0;
        }

        const heightWithDateAffordance = dayHeight - rowHeight;

        const canFitRowCount = Math.floor(heightWithDateAffordance / rowHeight);
        if (canFitRowCount === 0) {
            return canFitRowCount;
        } else if (canFitRowCount >= events?.length) {
            return events?.length;
        } else {
            return canFitRowCount - 1;
        }
    }, [events, dayHeight]);

    useEffect(() => {
        // Stagger throttle timeout to prevent jank/jitter on resize.
        const dateNum = parseInt(format(date, 'dd'));
        const throttleTimeout = 125 + 16 * (dateNum % 5);

        const subscription = Dimensions.addEventListener(
            'change',
            throttle(() => {
                setRenderIdx((lastIdx) => lastIdx + 1);
            }, throttleTimeout)
        );
        return () => subscription?.remove();
    }, [setRenderIdx, date]);

    return (
        <View key={`date-${dateString}-${renderIdx}`} style={styles.dayDate} onLayout={handleDayViewLayout}>
            <View
                style={[
                    styles.dateTextWrapper,
                    dateActive && styles.dateTextWrapperActive,
                    !dateInInterval && styles.dateTextWrapperDisabled,
                ]}
            >
                <Text style={[styles.dateText, !dateInInterval && styles.dateTextDisabled]}>{format(date, 'dd')}</Text>
            </View>
            <View style={styles.dayEvents}>
                {times(rowsToShow, (index) => {
                    const event = events[index];

                    if (event.type === 'noevents') {
                        return null;
                    }

                    return (
                        <EventPressable onEventAccepted={onEventAccepted} event={event}>
                            <View key={`event-id-${event.id}`} style={[styles.eventRow, { flexDirection: 'row' }]}>
                                <View style={styles.eventIcon}>
                                    <OwnerAvatar event={event} size={16} />
                                </View>
                                <Text style={styles.eventText}>{event.title}</Text>
                            </View>
                        </EventPressable>
                    );
                })}
                {rowsToShow < events?.length && (
                    <Pressable
                        onPress={() => {
                            setSelectedCalendarView('agenda');
                            warpToDate && warpToDate(date);
                        }}
                    >
                        <View style={styles.eventRow}>
                            <Text style={styles.moreText}>{events.length - rowsToShow} More</Text>
                        </View>
                    </Pressable>
                )}
            </View>
        </View>
    );
}

export default DateEntry;
