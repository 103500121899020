import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgBuilding4 = (props: SvgProps) => (
    <Svg width={99} height={100} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path d='m49.5 55.613-32-19.728 32-19.725 32 19.725-32 19.728Z' fill='#73CC49' />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.647 45.174h-12v45.653H40.86V70.24h17.122v20.587h40.372V45.174H12.647Zm3.64 17.12h18.427v11.413H16.287V62.294Zm66.427 0H64.287v11.413h18.427V62.294Z'
            fill='#A28AC7'
        />
        <Path d='M76.921 10.68h-12.84v20.727h12.84V10.68Z' fill='#F99F9B' />
    </Svg>
);
export default SvgBuilding4;
