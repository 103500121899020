import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';
const SvgIconOfferStatusAccepted = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <G clipPath='url(#icon-offer-status-accepted_svg__a)'>
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.355-.33h-3.21V3.7H2.2v19.643h19.643V3.7h-2.988V-.33h-3.21V3.7h-7.29V-.33ZM20.637 8.9H3.363v10.7h17.276V8.9Zm-13.27 5.39 3.824 3.8 5.925-5.9-1.7-1.7-4.225 4.225-2.125-2.125-1.7 1.7Z'
                fill='#D77A7F'
            />
        </G>
        <Defs>
            <ClipPath id='icon-offer-status-accepted_svg__a'>
                <Path fill='#fff' d='M0 0h24v24H0z' />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgIconOfferStatusAccepted;
