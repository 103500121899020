import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgTims = (props: SvgProps) => (
    <Svg width={200} height={194} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M40.65 159.619c2.173 1.889 4.723 2.833 7.65 2.833h56.672c2.928 0 5.479-.944 7.651-2.833 2.172-1.889 3.4-4.298 3.684-7.226L127.641 49.11H25.632l11.335 103.283c.283 2.928 1.51 5.337 3.683 7.226Zm36.177-89.003c-18.174 0-32.906 10.612-32.906 23.702 0 13.091 14.732 23.703 32.906 23.703 18.173 0 32.905-10.612 32.905-23.703 0-13.09-14.732-23.702-32.905-23.702Z'
            fill='#FF5F4D'
        />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M31.771 28.643h25.503v.002H94.74l.001-.002h25.502l9.918 28.336h-22.669v.002H44.523v-.002H21.854l9.918-28.336Z'
            fill='#373639'
        />
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m121.447 130.777 27.414-19.922 27.417 19.922-10.471 32.226h-33.889l-10.471-32.226Zm54.405 32.93 10.39 3.568c1.819.63 3.638-.035 5.457-1.994s2.239-3.883 1.259-5.772l-8.92-18.366-8.186 22.564Zm-70 1.522c1.469 1.924 3.114 2.606 4.933 2.046l11.439-3.568-8.081-22.564-9.026 18.366c-.979 1.889-.734 3.796.735 5.72Z'
            fill='#EEAB00'
        />
    </Svg>
);
export default SvgTims;
