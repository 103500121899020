import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgRegisterHelp = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 14 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.819 12.496c-.38.817-.579 2.071-.598 3.763h4.025c0-1.03.107-1.794.32-2.29.215-.496.769-1.133 1.663-1.91.895-.778 1.546-1.61 1.954-2.494.409-.885.613-1.91.613-3.077 0-1.73-.574-3.199-1.72-4.404C10.927.878 9.294.275 7.175.275c-1.77 0-3.238.452-4.405 1.357C1.604 2.536.758 3.707.233 5.146l3.5 1.517c.273-.797.69-1.449 1.255-1.954.563-.506 1.293-.759 2.187-.759.856 0 1.531.248 2.027.744.496.496.744 1.123.744 1.881 0 .7-.18 1.342-.54 1.925-.36.584-.918 1.177-1.677 1.78-.894.66-1.531 1.4-1.91 2.216ZM3.373 24.46l3.802-6.585 3.802 6.585H3.373Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgRegisterHelp;
