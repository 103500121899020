import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgAndroidLogoWhite = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.903 7.064a.45.45 0 0 1-.44.543H6.508a.45.45 0 0 1-.44-.543A6.055 6.055 0 0 1 9.023 3.04l-.937-1.69a.237.237 0 0 1 .414-.229l.944 1.702a6.023 6.023 0 0 1 2.542-.559c.908 0 1.769.2 2.542.56l.943-1.703a.237.237 0 1 1 .415.23l-.937 1.69a6.055 6.055 0 0 1 2.954 4.022Zm-3.158-1.466a.51.51 0 1 0 0-1.018.51.51 0 0 0 0 1.018ZM9.736 5.09a.51.51 0 1 0-1.019 0 .51.51 0 0 0 1.02 0ZM2.755 9.586a1.325 1.325 0 1 1 2.65 0v5.382a1.325 1.325 0 0 1-2.65 0V9.586Zm3.664-1.323h11.16a.45.45 0 0 1 .45.45v9.358a.45.45 0 0 1-.45.45h-2.017v3.154a1.325 1.325 0 0 1-2.65 0V18.52h-1.853v3.154a1.325 1.325 0 0 1-2.65 0V18.52h-1.99a.45.45 0 0 1-.45-.45V8.713a.45.45 0 0 1 .45-.45Zm13.5-.002c-.733 0-1.326.593-1.326 1.325v5.382a1.325 1.325 0 1 0 2.65 0V9.586c0-.732-.593-1.325-1.325-1.325Z'
            fill='#fff'
        />
    </Svg>
);
export default SvgAndroidLogoWhite;
