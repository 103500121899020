import { defaultTheme } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
    },
    childContainer: {
        height: '100%',
    },
    highlight: {
        position: 'absolute',
        right: -4,
        borderRadius: 50,
        width: 8,
        height: 8,
        backgroundColor: defaultTheme.colors.lychee,
    },
});

export default styles;
