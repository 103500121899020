import React, { FC, useEffect } from 'react';
import { View } from 'react-native';
import i18n from '@I18n';
import type { PartialDeep } from 'type-fest';
import { compact } from 'lodash';

import SwitchInput from '@Components/SwitchInput';
import TextInput from '@Components/TextInput';
import { CreateStudentRegistrationBody } from '@Redux/services/StudentRegistrationApi';
import { phoneMask } from '@Utilities';

import { StepProps } from '../../types';
import { styles } from '../../styles';
import LocalAddress, { LocalAddressMasks, LocalAddressValidator } from '../LocalAddress';

const TransportationInfo: FC<StepProps> = ({
    manageFieldPropFactory,
    textFieldPropFactory,
    studentRegistration,
    setStudentRegistration,
}) => {
    useEffect(() => {
        const nextAddressInformation = studentRegistration?.AddressInformation?.ChildAddress;
        const pickUpAddress = studentRegistration?.TransportationInformation?.PickUpAddress || {};
        const dropOffAddress = studentRegistration?.TransportationInformation?.DropOffAddress || {};
        const contactName = studentRegistration?.EmergencyContactInformation?.Parent?.FirstName;
        const contactPhone = studentRegistration?.EmergencyContactInformation?.Parent?.PrimaryPhoneNumber;

        if ([...compact(Object.values(pickUpAddress)), ...compact(Object.values(dropOffAddress))].length !== 0) {
            return;
        }

        setStudentRegistration({
            ...studentRegistration,
            TransportationInformation: {
                ...studentRegistration.TransportationInformation,
                DropOffAddress: { ...nextAddressInformation },
                DropOffContactName: contactName,
                DropOffContactPhone: contactPhone,
                PickUpAddress: { ...nextAddressInformation },
                PickUpContactName: contactName,
                PickUpContactPhone: contactPhone,
            },
        });
    }, []);

    return (
        <View>
            <View style={styles.formGroup}>
                <SwitchInput
                    {...manageFieldPropFactory(['TransportationInformation', 'IsPickupNeeded'], 'onChange', 'checked')}
                    viewStyle={styles.checkbox}
                    label={i18n.t('studentRegisterPickUp')}
                    reverse
                />
                {studentRegistration?.TransportationInformation?.IsPickupNeeded && (
                    <>
                        <LocalAddress
                            manageFieldPropFactory={manageFieldPropFactory}
                            textFieldPropFactory={textFieldPropFactory}
                            studentRegistration={studentRegistration}
                            accessor={['TransportationInformation', 'PickUpAddress']}
                        />
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterContactName'))}
                            {...manageFieldPropFactory(
                                ['TransportationInformation', 'PickUpContactName'],
                                'onChangeText',
                                'value'
                            )}
                        />
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterContactPhone'))}
                            {...manageFieldPropFactory(
                                ['TransportationInformation', 'PickUpContactPhone'],
                                'onChangeText',
                                'value'
                            )}
                        />
                    </>
                )}
            </View>
            <View style={styles.formGroup}>
                <SwitchInput
                    {...manageFieldPropFactory(['TransportationInformation', 'IsDropOffNeeded'], 'onChange', 'checked')}
                    viewStyle={styles.checkbox}
                    reverse
                    label={i18n.t('studentRegisterDropOff')}
                />
                {studentRegistration?.TransportationInformation?.IsDropOffNeeded && (
                    <>
                        <LocalAddress
                            manageFieldPropFactory={manageFieldPropFactory}
                            textFieldPropFactory={textFieldPropFactory}
                            studentRegistration={studentRegistration}
                            accessor={['TransportationInformation', 'DropOffAddress']}
                        />
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterContactName'))}
                            {...manageFieldPropFactory(
                                ['TransportationInformation', 'DropOffContactName'],
                                'onChangeText',
                                'value'
                            )}
                        />
                        <TextInput
                            {...textFieldPropFactory(i18n.t('studentRegisterContactPhone'))}
                            {...manageFieldPropFactory(
                                ['TransportationInformation', 'DropOffContactPhone'],
                                'onChangeText',
                                'value'
                            )}
                        />
                    </>
                )}
            </View>
        </View>
    );
};

export const TransportationInfoValidator = (studentRegistration: PartialDeep<CreateStudentRegistrationBody>) => {
    const TransportationInfoValidatorresult = [
        ...LocalAddressValidator(
            ['TransportationInformation', 'PickUpAddress'],
            studentRegistration,
            studentRegistration?.TransportationInformation?.IsPickupNeeded
        ),
        {
            accessorPath: ['TransportationInformation', 'PickUpContactPhone'],
            validator: (phone: string) => {
                if (studentRegistration?.TransportationInformation?.IsPickupNeeded) {
                    if (phone.match(/\d{3}-\d{3}-\d{4}/)) {
                        return true;
                    }
                    return false;
                }

                return true;
            },
        },
        {
            accessorPath: ['TransportationInformation', 'PickUpContactName'],
            validator: (pickupContactName: string) => {
                if (studentRegistration?.TransportationInformation?.IsPickupNeeded) {
                    return Boolean(pickupContactName);
                }

                return true;
            },
        },
        ...LocalAddressValidator(
            ['TransportationInformation', 'DropOffAddress'],
            studentRegistration,
            studentRegistration?.TransportationInformation?.IsDropOffNeeded
        ),
        {
            accessorPath: ['TransportationInformation', 'DropOffContactPhone'],
            validator: (phone: string) => {
                if (studentRegistration?.TransportationInformation?.IsDropOffNeeded) {
                    if (phone.match(/\d{3}-\d{3}-\d{4}/)) {
                        return true;
                    }
                    return false;
                }

                return true;
            },
        },
        {
            accessorPath: ['TransportationInformation', 'DropOffContactName'],
            validator: (pickupContactName: string) => {
                if (studentRegistration?.TransportationInformation?.IsDropOffNeeded) {
                    return Boolean(pickupContactName);
                }

                return true;
            },
        },
    ];
    return TransportationInfoValidatorresult;
};

export const TransportationInfoMasks = () => {
    return [
        { accessorPath: ['TransportationInformation', 'DropOffContactPhone'], mask: phoneMask },
        { accessorPath: ['TransportationInformation', 'PickUpContactPhone'], mask: phoneMask },
        ...LocalAddressMasks(['TransportationInformation', 'DropOffAddress']),
        ...LocalAddressMasks(['TransportationInformation', 'PickUpAddress']),
    ];
};

export default TransportationInfo;
