import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import i18n from '@I18n';

import Picker from '@Components/Picker';
import TextInput from '@Components/TextInput';
import { useGetLanguageTypesQuery, BasicLookup } from '@Redux/services/LookupApi';
import { lookupToOptions } from '@Utilities';

import { StepProps } from '../../types';
import { styles } from '../../styles';

const AdmissionInfo: FC<StepProps> = ({ manageFieldPropFactory, textFieldPropFactory }) => {
    const { data: languageTypes } = useGetLanguageTypesQuery();
    const languageTypeOptions = useMemo(() => lookupToOptions<BasicLookup>(languageTypes), [languageTypes]);
    return (
        <View>
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterPrevSchoolName'))}
                {...manageFieldPropFactory(['AdmissionInformation', 'PreviousSchool'], 'onChangeText', 'value')}
            />
            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterPrevSchoolAddress'))}
                {...manageFieldPropFactory(['AdmissionInformation', 'Address'], 'onChangeText', 'value')}
            />

            <TextInput
                {...textFieldPropFactory(i18n.t('studentRegisterPrevSchoolDistrict'))}
                {...manageFieldPropFactory(['AdmissionInformation', 'PreviousDistrictName'], 'onChangeText', 'value')}
            />

            <Picker
                inputLabel={i18n.t('studentRegisterPrevSchoolLanguage')}
                {...manageFieldPropFactory(['AdmissionInformation', 'PreviousLanguageType'], 'onValueChange', 'value')}
                items={languageTypeOptions}
                style={styles.picker}
            />
        </View>
    );
};

export default AdmissionInfo;
