import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: 8,
        minHeight: 52,
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerContainer: {
        flexGrow: 1,
    },
    header: {
        width: '100%',
        textAlign: 'center',
        fontWeight: '800',
        ...typography.h6,
    },
});

export default styles;
