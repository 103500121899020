import { RootStackParamList } from '@App/rootNavigation';
import { EventDetailsModal } from '@Components/EventDetails';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC } from 'react';

type EventPreviewScreenProp = NativeStackScreenProps<RootStackParamList, 'Preview'>;
const EventPreview: FC<EventPreviewScreenProp> = ({ route }) => {
    return <EventDetailsModal show={true} eventPreviewId={route.params?.id} onDismiss={() => undefined} />;
};

export default EventPreview;
