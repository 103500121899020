import i18n from '@I18n';
import SvgHealthAndSafety from '@Icon/HealthAndSafety';
import { FC } from 'react';
import { Text, View } from 'react-native';
import styles from '../styles';

interface IProps {
    LifeThreateningHealthAlerts?: string;
    NonLifeThreateningMedicalDetails?: string;
}

const Safety: FC<IProps> = ({ LifeThreateningHealthAlerts, NonLifeThreateningMedicalDetails }) => {
    return (
        <>
            <View style={[styles.iconContainer, styles.sectionMargin]}>
                <View>
                    <SvgHealthAndSafety style={styles.iconSpace} />
                </View>
                <View style={{ width: '92%' }}>
                    <Text style={[styles.subheader]}>{i18n.t('safety')}</Text>
                    <Text style={styles.safetyNote}>{i18n.t('safetyNote')}</Text>
                    <View style={[styles.safetyMargin, styles.body]}>
                        <Text style={styles.body}>{i18n.t('lifeThreateningConditions')}</Text>
                        <Text style={styles.body}>
                            {LifeThreateningHealthAlerts
                                ? LifeThreateningHealthAlerts
                                : i18n.t('noLifeThreateningMedicalConditions')}
                        </Text>
                    </View>
                    <View style={styles.safetyMargin}>
                        <Text style={styles.body}>{i18n.t('nonLifeThreateningConditions')}</Text>
                        <Text style={styles.body}>
                            {NonLifeThreateningMedicalDetails
                                ? NonLifeThreateningMedicalDetails
                                : i18n.t('noNonLifeThreateningMedicalConditions')}
                        </Text>
                    </View>
                </View>
            </View>
        </>
    );
};

export default Safety;
