import { RefObject, Dispatch, SetStateAction } from 'react';
import { FlatList as FlatListType, LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

import {
    DailySeriesEventDateMap,
    EventTypesFilter,
    OfferStatusFilter,
    StudentsFilter,
} from '@Redux/services/CalendarApi';
export const dateWSO = { weekStartsOn: 1 } as const;
export type PeriodHeightMap = Record<string, number>;

export interface UseCalendarProps {
    endOfPeriod: (date: Date, options: typeof dateWSO) => Date;
    getPeriod: (date: Date) => number;
    startOfPeriod: (date: Date, options: typeof dateWSO) => Date;
    overrideEventTypesFilter?: EventTypesFilter;
    overrideOfferStatusFilter?: OfferStatusFilter;
}

export interface CalendarProps {
    captureHeight: (date: Date, height: number) => void;
    currentInterval: Interval;
    currentIntervalMonth: Date;
    currentYear: number;
    dailySeriesEventMap: DailySeriesEventDateMap;
    dateHeightMap: PeriodHeightMap;
    eachDayOfDailySeriesEventMap: Date[];
    eventTypesFilter: EventTypesFilter;
    exposedInterval: Interval;
    flatListRef: RefObject<FlatListType<unknown>>;
    handleDecrement: () => void;
    handleEndReached: () => Promise<void>;
    handleIncrement: () => void;
    handleScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
    handleStartReached: () => Promise<void>;
    handleViewLayout: (event: LayoutChangeEvent) => void;
    initialFetchComplete: boolean; // @TODO is this really required
    isLoading: boolean;
    offerStatusFilter: OfferStatusFilter;
    promisedSetSkipScrollListener: Promise<void>;
    setEventTypesFilterValue: (eventTypesFilter: EventTypesFilter) => void;
    setOfferStatusFilterValue: (offerStatusFilter: OfferStatusFilter) => void;
    setStudentsFilterValue: (studentsFilter: StudentsFilter) => void;
    studentsFilter: StudentsFilter;
    warpToDate: (date: Date) => Promise<void>;
    warpToNextPeriod: () => Promise<void>;
    warpToPrevPeriod: () => Promise<void>;
    loadEvents: () => Promise<void>;
}

export type CalendarViewTypes = 'agenda' | 'month';

export interface CalendarViewSelectorProps {
    selectedCalendarView: CalendarViewTypes;
    setSelectedCalendarView: Dispatch<SetStateAction<CalendarViewTypes>>;
}

export interface CalendarEventDetailProps {
    showEventDetail: (eventId: string) => void;
    dismissEventDetail: () => void;
}

export type CalendarComponentProps = CalendarProps & CalendarViewSelectorProps & CalendarEventDetailProps;
