import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    subjectDescriptionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    cellValue: {
        ...typography.h6,
    },
    bullet: {
        marginRight: 12,
    },
    body: {
        ...typography.body,
    },
    header: {
        ...typography.buttonMedium,
        color: defaultTheme.colors.textPrimary,
    },
});

export default styles;
