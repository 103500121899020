import { StyleSheet } from 'react-native';
import { defaultTheme, fonts, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    noEvents: {
        alignItems: 'center',
        borderTopColor: 'black',
        borderTopWidth: 2,
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 21,
        paddingTop: 7,
    },
    noEventsIndicator: {
        backgroundColor: defaultTheme.colors.textPrimary,
        borderRadius: 4,
        opacity: 0.36,
        marginLeft: 8,
        marginRight: 8,
        height: 7.8,
        width: 7.8,
    },
    noEventsLabel: {
        fontFamily: fonts.MontserratMedium,
        fontSize: 12,
        lineHeight: 14,
    },
    dateLabelText: {
        ...typography.buttonSmall,
        color: defaultTheme.colors.textPrimary,
        fontFamily: fonts.MontserratBold,
        fontSize: 12,
    },
});
