import React, { FC } from 'react';
import { View, Text } from 'react-native';

import Emerald from '@Icon/Emerald';
import Hexagon from '@Icon/Hexagon';
import Blueberry from '@Icon/Blueberry';
import { styles } from './styles';

interface RegisterChildApplicationHeaderProps {
    activeTitleIdx: number;
    titles: string[];
}

const RegisterChildApplicationHeader: FC<RegisterChildApplicationHeaderProps> = ({ activeTitleIdx, titles }) => {
    const activeTitle = titles[activeTitleIdx];

    if (activeTitleIdx === 0) {
        return (
            <View style={styles.wrap}>
                <Text style={styles.header}>{activeTitle}</Text>
            </View>
        );
    }

    return (
        <View style={styles.wrap}>
            <Text style={styles.header}>{activeTitle}</Text>
            <View style={styles.progress}>
                {titles.map((_step, idx) => {
                    if (idx === 0) {
                        return null;
                    }

                    if (idx < activeTitleIdx) {
                        return <Emerald key={`header-medalion-${idx}`} style={styles.progressIndicator} width={11} />;
                    }

                    if (idx > activeTitleIdx) {
                        return <Hexagon key={`header-medalion-${idx}`} style={styles.progressIndicator} width={11} />;
                    }

                    return <Blueberry key={`header-medalion-${idx}`} style={styles.progressIndicator} width={11} />;
                })}
            </View>
        </View>
    );
};

export default RegisterChildApplicationHeader;
