import React from 'react';
import { DailySeriesEvent } from '@Redux/services/CalendarApi';

interface EventIconProps {
    event: DailySeriesEvent;
}

import {
    IconArticle1,
    IconEventTypeFieldtrip,
    IconEventTypeOffers,
    IconEventTypeOther,
    IconEventTypeSchoolboard,
    IconOfferStatusAccepted,
    IconOfferStatusExpired,
    IconOfferStatusInvited,
} from '@Icon';

const offerStatusIconMap = {
    expired: IconOfferStatusExpired,
    invited: IconOfferStatusInvited,
    accepted: IconOfferStatusAccepted,
};

const eventTypeIconMap = {
    fieldtrip: IconEventTypeFieldtrip,
    offers: IconEventTypeOffers,
    other: IconEventTypeOther,
    schoolboard: IconArticle1,
    schoolyear: IconEventTypeSchoolboard,
    noevents: null,
};

function EventIcon({ event }: EventIconProps) {
    const OfferStatusIcon = event.offerStatus && offerStatusIconMap[event.offerStatus];
    const EventIcon = eventTypeIconMap[event.type];

    if (OfferStatusIcon) {
        return <OfferStatusIcon />;
    }

    if (EventIcon) {
        return <EventIcon />;
    }

    return null;
}

export default EventIcon;
