import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        margin: 24,
        paddingBottom: 48,
    },
    title: {
        ...typography.h3,
    },
    fieldContainer: {
        paddingVertical: 16,
        marginVertical: 16,
    },
    iconWrapper: {
        borderRadius: 0,
        marginRight: 24,
        width: 50,
    },
    descriptionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 32,
    },
    descriptionIcon: {
        width: 32,
        height: 32,
        marginRight: 24,
    },
    descriptionText: {
        ...typography.body2,
        flex: 1,
    },
    controlContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 36,
        marginTop: 72,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    confirmPasswordContainer: {
        borderBottomColor: defaultTheme.colors.watermelon,
        borderBottomWidth: 4,
    },
    confirmPassword: {
        ...typography.buttonLarge,
        lineHeight: 28,
    },
    arrowRight: {
        fontSize: 24,
        marginLeft: 8,
    },
    error: {
        ...typography.body2,
        color: defaultTheme.colors.error,
        marginVertical: 8,
    },
    success: {
        ...typography.body2,
        color: defaultTheme.colors.watermelon,
        marginVertical: 8,
    },
});
