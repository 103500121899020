import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    base: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        maxWidth: '100%',
        paddingVertical: 16,
    },
    icon: {
        marginRight: 24,
    },
    header: {
        ...typography.h5,
        marginBottom: 8,
    },
    body2: {
        ...typography.body2,
        marginBottom: 4,
    },
});

export default styles;
