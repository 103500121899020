import SvgComments from '@Icon/Comments';
import { useGetStudentConsentFormsQuery } from '@Redux/services/EventOfferApi';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import ListLink from '@Components/ListLink';

import EventDetailsModal from './components/EventDetails';
import { SafeAreaView, Text, ScrollView } from 'react-native';
import ListItem from '@Components/ListItem';
import SvgSuccess from '@Icon/Success';
import i18n from '@I18n/index';
import { typography } from '@Hooks/useTheme';
import { getSchoolEndDate, getSchoolStartDate } from '@Utilities';

interface IProps {
    studentId: string;
}

const ConsentForms: React.FC<IProps> = ({ studentId }) => {
    const [showConsentForm, setShowConsentForm] = useState(false);
    const [selectedEventResponseId, setSelectedEventResponseId] = useState<string>();
    const { data: consentForms, refetch } = useGetStudentConsentFormsQuery({
        studentId,
        Start: getSchoolStartDate(),
        End: getSchoolEndDate(),
    });

    useEffect(() => {
        refetch();
    }, []);

    const handlePressListLink = async (eventResponseId: string) => {
        setShowConsentForm(true);
        setSelectedEventResponseId(eventResponseId);
    };

    const handleDismissPress = () => {
        setShowConsentForm(false);
        refetch();
    };

    if (!consentForms) {
        return (
            <SafeAreaView>
                <ActivityIndicator />
            </SafeAreaView>
        );
    }

    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false}>
                {consentForms.length === 0 && (
                    <Text style={[typography.body, { textAlign: 'center' }]}>{i18n.t('noConsentForms')}</Text>
                )}
                {consentForms.map((consentForm, i) => {
                    if (consentForm.StatusCode !== 1) return null;
                    return (
                        <ListLink
                            key={`consent-form-${i}`}
                            icon={<SvgComments />}
                            text={consentForm.EventName}
                            subtext={format(new Date(consentForm.ActualStart), 'E MMM dd')}
                            onPress={() => handlePressListLink(consentForm.EventResponseId)}
                        />
                    );
                })}
                {consentForms.map((consentForm, i) => {
                    if (consentForm.StatusCode === 1) return null;
                    return (
                        <ListItem
                            key={`consent-form-responded-${i}`}
                            icon={SvgSuccess}
                            text={consentForm.EventName}
                            onPress={() => handlePressListLink(consentForm.EventResponseId)}
                            external={false}
                        />
                    );
                })}
            </ScrollView>
            {showConsentForm && selectedEventResponseId && (
                <EventDetailsModal
                    show={showConsentForm}
                    eventResponseId={selectedEventResponseId}
                    onDismiss={handleDismissPress}
                />
            )}
        </>
    );
};

export default ConsentForms;
