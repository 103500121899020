import { StyleSheet } from 'react-native';
import { typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 24,
    },
    header: {
        ...typography.dashboardHeader,
        marginRight: 16,
    },
});
