import { compact } from 'lodash';

interface FormatAddressProps {
    ApartmentNumber?: string | null;
    City?: string;
    Country?: string;
    POBox?: string | null;
    PostalCode?: string;
    Province?: string;
    RRNumber?: string | null;
    StreetName?: string;
    intl?: boolean;
}
export function formatAddress({
    ApartmentNumber,
    City,
    Country = 'Canada',
    POBox,
    PostalCode,
    Province = 'ON',
    RRNumber,
    StreetName,
    intl = false,
}: FormatAddressProps) {
    const lines = [
        compact([ApartmentNumber, StreetName]).join('-'),
        compact([RRNumber, POBox]).join(' '),
        compact([City, Country === 'Canada' ? Province : '', PostalCode]).join(' '),
        (intl || Country !== 'Canada') && Country,
    ];
    return compact(lines).join('\n');
}
