import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 22,
    },
    checkboxContainer: {
        flexDirecton: 'row',
        flexWrap: 'wrap',
        marginBottom: 16,
    },
    child: {
        flexBasis: '50%',
    },
    bottomMargin: {
        marginBottom: 16,
    },
    buttonContainer: {
        padding: 10,
        marginTop: 16,
        alignItems: 'flex-end',
    },
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.lychee,
        marginTop: 8,
    },
    modal: {
        height: '100%',
    },
    saveButton: {
        marginBottom: 24,
    },
    switchContainer: {
        borderColor: defaultTheme.colors.inputOutline,
        borderWidth: 1,
        marginBottom: 16,
        minHeight: 56,
        padding: 10,
    },
});

export default styles;
