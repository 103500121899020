import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginBottom: 8,
    },
    icon: {
        marginRight: 12,
    },
    label: {
        ...typography.body,
    },
});

export default styles;
