import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    screen: {
        padding: 24,
    },
    addressInfoLines: {
        marginBottom: 32,
    },
    profileImageContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 40,
    },
    header: {
        ...typography.h0,
    },
    socialWrapperStyle: {
        alignSelf: 'flex-start',
        marginRight: 'auto',
    },
    buttonMargin: {
        marginBottom: 16,
    },
    button: {
        ...typography.buttonSmall,
    },
    sectionHeaderContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 0,
        minHeight: 80,
        borderBottomColor: defaultTheme.colors.textPrimary,
        borderBottomWidth: 2,
        marginBottom: 16,
    },
    sectionHeaderTitle: {
        ...typography.h2,
    },
    errorLabelContainer: {
        marginHorizontal: 8,
    },
    errorLabel: {
        ...typography.body2,
        color: defaultTheme.colors.error,
    },
});
