import Button from '@Components/Button';
import { defaultTheme } from '@Hooks/useTheme';
import i18n from '@I18n';
import { Close, Done } from '@Icon/index';
import { isUndefined } from 'lodash';
import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { styles } from './styles';

interface IProps {
    choice?: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
}

interface StyleProp {
    underlineColor?: string;
    iconColor: string;
}

export const Question: FC<IProps> = ({ choice, disabled, onChange }) => {
    const yesButtonStyle = useMemo(() => {
        const style: StyleProp = {
            underlineColor: undefined,
            iconColor: '#373639C7',
        };
        if (!isUndefined(choice)) {
            if (choice) {
                style.underlineColor = defaultTheme.colors.watermelon;
                style.iconColor = defaultTheme.colors.watermelon;
            }
        }
        return style;
    }, [choice]);

    const noButtonStyle = useMemo(() => {
        const style: StyleProp = {
            underlineColor: undefined,
            iconColor: '#373639C7',
        };
        if (!isUndefined(choice)) {
            if (!choice) {
                style.underlineColor = defaultTheme.colors.lychee;
                style.iconColor = defaultTheme.colors.lychee;
            }
        }
        return style;
    }, [choice]);

    return (
        <View style={styles.container}>
            <Button
                label={i18n.t('yes')}
                leftIcon={<Done fill={yesButtonStyle.iconColor} />}
                underlineColor={yesButtonStyle.underlineColor}
                labelStyle={styles.buttonLabel}
                onPress={() => !disabled && onChange(true)}
            />
            <Button
                label={i18n.t('no')}
                style={styles.noButtonMargin}
                leftIcon={<Close width={16} fill={noButtonStyle.iconColor} />}
                underlineColor={noButtonStyle.underlineColor}
                labelStyle={styles.buttonLabel}
                onPress={() => !disabled && onChange(false)}
            />
        </View>
    );
};
