import i18n from '@I18n';
import IconCall from '@Icon/IconCall';
import Button from '@Components/Button';
import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';
import useTheme from '@Hooks/useTheme';
import React, { useContext } from 'react';
import { View, Text, Linking } from 'react-native';
import styles from './styles';
import DeviceContext from '@Contexts/DeviceContext';

interface IProps {
    visible: boolean;
    subject: string;
    schoolName: string | null;
    schoolPhone: string | null;
    onDismiss: () => void;
}

const ContactModal: React.FC<IProps> = ({ visible, subject, schoolName, schoolPhone, onDismiss }) => {
    const theme = useTheme();
    const { isDesktop } = useContext(DeviceContext);

    return (
        <Modal
            style={isDesktop ? [styles.desktopModal] : styles.contactModal}
            visible={visible}
            header={<ModalHeader header={i18n.t('contact')} onDismiss={onDismiss} />}
        >
            <View style={styles.contactModalContainer}>
                <Text style={styles.contactModalSubheader}>{i18n.t('contactSchool', { schoolName: schoolName })}</Text>
                <Text style={styles.contactSubject}>{subject}</Text>
                <View style={styles.contactActionContainer}>
                    <Button label={i18n.t('cancel')} onPress={onDismiss} underlineColor={theme.colors.raspberry} />
                    <Button
                        label={i18n.t('callSchoolPhone', { schoolPhone: schoolPhone })}
                        onPress={() => Linking.openURL(`tel:${schoolPhone}`)}
                        underlineColor={theme.colors.watermelon}
                        leftIcon={<IconCall fill={theme.colors.watermelon} />}
                    />
                </View>
            </View>
        </Modal>
    );
};

export default ContactModal;
