import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconVideo = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.444.778h12.472v2.264L18.75 1v12l-5.834-2.042v2.292H.444V.778Z'
            fill='#73CC49'
        />
    </Svg>
);
export default SvgIconVideo;
