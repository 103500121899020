import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    logoContainer: {
        marginVertical: 8,
    },
    logoContainerDesktop: {
        marginVertical: 29,
        paddingHorizontal: 14,
        maxWidth: 1280,
        width: '100%',
        alignSelf: 'center',
    },
});
