import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgProfile = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m1.111 5.301 5.213 3.214-2.013 3.487 2.833 4.908h5.668l2.834-4.908-2.002-3.467L18.889 5.3 10-.178l-8.889 5.48ZM.02 18.485h19.962v12.364H.02V18.485Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgProfile;
