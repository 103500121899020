import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    conatiner: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 12,
    },
    checkBox: {
        marginRight: 16,
    },
    nameContainer: {
        marginLeft: 8,
    },
    avatarText: {
        fontWeight: '700',
        fontSize: 14,
    },
    name: {
        fontWeight: '600',
        ...typography.body2,
    },
});

export default styles;
