import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgMedicalBag = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.4.667h7.2l2.8 4H8.6l2.8-4Zm18.267 6H.333v24h29.334v-24ZM17 24h-4v-3.333H9.667v-4H13v-3.333h4v3.333h3.334v4H17V24Z'
            fill='#EEAB00'
        />
    </Svg>
);
export default SvgMedicalBag;
