import Avatar from '@Components/Avatar';
import i18n from '@I18n';
import SvgComments from '@Icon/Comments';
import { IconChevronRight } from '@Icon/index';
import { useGetStudentEventsQuery } from '@Redux/services/EventOfferApi';
import { useGetStudentsQuery } from '@Redux/services/parent';
import { ChildInfo, StudentConsentForm } from '@Redux/types';
import EventDetailsModal from '@Screens/Student/ConsentForms/components/EventDetails';
import { isUndefined, sortBy } from 'lodash';
import { useState, useEffect, FC } from 'react';
import { Text, Pressable, View } from 'react-native';
import Header from '../Header';
import styles from './styles';

interface IProps {
    onLoad: (isEmpty: boolean) => void;
}

const ConsentForms: FC<IProps> = ({ onLoad }) => {
    const [consentForms, setConsentFroms] = useState<StudentConsentForm[]>();
    const { data: studentEvents, refetch } = useGetStudentEventsQuery({ viewType: 'New' });
    const { data: students } = useGetStudentsQuery();
    const [studentMap, setStudentMap] = useState<Record<string, ChildInfo>>({});
    const [showConsentForm, setShowConsentForm] = useState(false);
    const [selectedEventResponseId, setSelectedEventResponseId] = useState<string>();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (!students) return;
        const newStudentMap: Record<string, ChildInfo> = {};
        for (const student of students) {
            newStudentMap[student.StudentId] = student;
        }
        setStudentMap(newStudentMap);
    }, [students]);

    const handlePressListLink = async (eventResponseId: string) => {
        setShowConsentForm(true);
        setSelectedEventResponseId(eventResponseId);
    };

    const handleDismissPress = () => {
        setShowConsentForm(false);
        refetch();
    };

    useEffect(() => {
        if (!studentEvents) return;
        let newConsentForms = studentEvents[0]?.Events?.filter(
            (event) => event.IsOngoingConsent && event.StatusCode === 'New'
        );
        newConsentForms = sortBy(newConsentForms, ['FirstName', 'ActualStart', 'Event']);
        setConsentFroms(newConsentForms);
        onLoad(isUndefined(newConsentForms) || newConsentForms?.length === 0);
    }, [studentEvents]);

    return (
        <>
            {consentForms && consentForms?.length > 0 && (
                <View style={styles.container}>
                    <Header header={i18n.t('consentForms')} icon={<SvgComments />} />
                    {consentForms?.map((consentForm, i) => {
                        return (
                            <Pressable
                                key={`$consent-forms-${i}`}
                                onPress={() => handlePressListLink(consentForm.EventResponseId)}
                                style={({ pressed }) => pressed && styles.pressed}
                            >
                                <View style={styles.listItem}>
                                    <Avatar
                                        size={33}
                                        style={styles.avatar}
                                        label={studentMap[consentForm.StudentId]?.FirstName}
                                        picture={studentMap[consentForm.StudentId]?.PhotoBase64}
                                    />
                                    <View style={styles.labelContainer}>
                                        <Text style={styles.label}>{consentForm.EventName}</Text>
                                    </View>
                                    <View style={styles.chevron}>
                                        <IconChevronRight />
                                    </View>
                                </View>
                            </Pressable>
                        );
                    })}
                    {showConsentForm && selectedEventResponseId && (
                        <EventDetailsModal
                            show={showConsentForm}
                            eventResponseId={selectedEventResponseId}
                            onDismiss={handleDismissPress}
                        />
                    )}
                </View>
            )}
        </>
    );
};

export default ConsentForms;
