import SvgDiamond from '@Icon/Diamond';
import { CourseAbsence } from '@Redux/types';
import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

interface IProps {
    absences: CourseAbsence[];
    label: string;
}
const AttendanceItem: React.FC<IProps> = ({ absences, label }) =>
    absences.length === 0 ? (
        <></>
    ) : (
        <View>
            <View style={styles.expandedViewItem}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{label}</Text>
                </View>
                <View>
                    {absences?.map((absence) => (
                        <View style={styles.absenceInformation}>
                            <Text style={styles.margin}>{absence.AbsenceDateString?.split(',')[0]}</Text>
                            <SvgDiamond width={12} style={styles.margin} />
                            <Text style={styles.margin}>{absence.AbsentDescription}</Text>
                        </View>
                    ))}
                </View>
            </View>
        </View>
    );

export default AttendanceItem;
