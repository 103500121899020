import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    iconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    alert: {
        borderWidth: 1,
        padding: 12,
    },
    success: {
        borderColor: defaultTheme.colors.watermelon,
    },
    error: {
        borderColor: defaultTheme.colors.lychee,
    },
    iconInfo: {
        marginRight: 16,
        width: 30,
        height: 30,
    },
    body: {
        ...typography.body,
    },
});
