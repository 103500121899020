import Success from '@Icon/Success';
import SvgClose from '@Icon/Close';
import { Pressable, Text, View } from 'react-native';
import styles from './styles';
import SvgError from '@Icon/Error';
import { useContext } from 'react';
import DeviceContext from '@Contexts/DeviceContext';
import Modal from '@Components/Modal';
interface IProps {
    visible: boolean;
    isError?: boolean;
    message?: string;
    onClose: () => void;
}

const Alert = ({ message, visible, onClose, isError = false }: IProps) => {
    const { isDesktop } = useContext(DeviceContext);
    return (
        <Modal header={<></>} style={isDesktop ? styles.modalDesktop : styles.modelMobile} visible={visible}>
            <View style={styles.modalContainer}>
                {isError ? <SvgError /> : <Success />}
                <View style={styles.messageContainer}>
                    <Text style={styles.message}>{message}</Text>
                </View>
                <Pressable onPress={onClose}>
                    <SvgClose fill={'black'} width={15} />
                </Pressable>
            </View>
        </Modal>
    );
};

export default Alert;
