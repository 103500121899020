import useToggle from '@Hooks/useToggle';
import { RegisterHelp } from '@Icon/index';
import { useRef, useState, useEffect } from 'react';
import { View, Pressable } from 'react-native';
import HelpMenu from './components/Menu';

const Help = () => {
    const ref = useRef<View>(null);
    const { show, toggle } = useToggle(false);
    const [coords, setCoords] = useState({ x: 0, y: 0 });

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        ref.current?.measureInWindow((x: number, y: number) => {
            x && y && setCoords({ x: x, y: y + 30 });
        });
    };
    const handleHelpPress = () => {
        init();
        toggle();
    };

    return (
        <>
            <Pressable onPress={handleHelpPress}>
                <View collapsable={false} ref={ref} style={{ opacity: 1 }}>
                    <RegisterHelp />
                </View>
            </Pressable>
            <HelpMenu show={show} onDismiss={() => toggle()} coords={coords} />
        </>
    );
};

export default Help;
