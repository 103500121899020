import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgFlower = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m15.922 0-3.724 2.706.98 3.015-2.121-2.356L6.85 5.237l.482 4.578 3.1.66-3.1.658-.482 4.578 4.206 1.872 2.121-2.356-.98 3.016 3.724 2.706 3.724-2.706-.98-3.016 2.122 2.356 4.205-1.872-.481-4.578-3.102-.659 3.102-.659.481-4.578-4.205-1.872-2.122 2.356.98-3.015L15.922 0Zm2.937 12.17V8.778l-2.937-1.695-2.937 1.695v3.392l2.937 1.695 2.937-1.695Zm7.615 16.78 2.485-10.475-10.474 2.486L16 31.433 13.515 20.96 3.04 18.475 5.526 28.95 16 31.434l10.474-2.485Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgFlower;
