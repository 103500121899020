import React, { FC } from 'react';
import { Dimensions, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import useTheme from '@Hooks/useTheme';
import { styles } from './styles';

const LoadingIndicator: FC = () => {
    const theme = useTheme();
    const height = Dimensions.get('window').height / 7;
    return (
        <View style={[styles.loaderContainer, { height }]}>
            <ActivityIndicator animating={true} color={theme.colors.lychee} />
        </View>
    );
};
export default LoadingIndicator;
