import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgRule = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 30 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M.9 21.2V17h12.467v4.2H.9ZM.9 9V4.8h12.467V9H.9Zm17.3 16.5-2.933-2.934 3.466-3.466-3.466-3.467L18.2 12.7l3.467 3.466 3.466-3.466 2.934 2.933-3.467 3.466 3.467 3.467-2.934 2.934-3.466-3.467-3.467 3.466Zm3.333-13.534-5.8-5.8 2.934-2.933L21.5 6.066l5.133-5.2 2.967 3-8.067 8.1Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgRule;
