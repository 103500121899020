import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgDoubleCheck = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 27 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.93 4.333 26.52.96l-3.373 12.59-3.909-3.908-7.534 7.534-5.6-5.6 1.4-1.4 4.2 4.2 6.134-6.134-3.909-3.909ZM.506 11.574l1.4-1.4 5.621 5.622-1.4 1.4-5.621-5.622Zm12.622-4.179-2.8 2.8 1.4 1.4 2.8-2.8-1.4-1.4Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgDoubleCheck;
