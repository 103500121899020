import { useCallback, useState } from 'react';
import { GestureResponderEvent, Dimensions } from 'react-native';

const magicRatio = 2 / 9;

type OnSwipeType = () => void;

/**
 * @param {number} edge as 0 or 1; provides flag for inversing logic along axis (top:0;left:0;bottom:1;right:1)
 * @param {number} axis as 0 or 1; provides flag for inversing cardinal axis (x:0;y:1)
 * @param {function} onSwipe callback after swipe has occured
 * @param {number} swipeDistanceRatio as number between 0 and 1; represents ratio of cardinal axis travel required to trigger swipe
 * @param {number} edgeDistanceRatio as number between 0 and 1; represents ratio of cardinal axis as edge boundary of where swipe must begin
 */
function useEdgeSwipeFactory(
    edge: 0 | 1,
    axis: 0 | 1,
    onSwipe: OnSwipeType,
    swipeDistanceRatio: number,
    edgeDistanceRatio: number
) {
    const edgeThreshold = edge ? edge - edgeDistanceRatio : edgeDistanceRatio;
    const axisIdx1 = axis ? 1 : 0;
    const axisIdx2 = axis ? 0 : 1;

    const [touchStart, setTouchStart] = useState([1, 1]);
    const handleTouchEnd = useCallback(
        (e: GestureResponderEvent) => {
            // Only respond to touches that start on the far left.
            if (edge ^ (touchStart[axisIdx1] > edgeThreshold ? 1 : 0)) {
                return;
            }

            const windowWidth = Dimensions.get('window').width;
            const windowHeight = Dimensions.get('window').height;

            const pageX = e.nativeEvent?.changedTouches[0]?.pageX || e.nativeEvent.pageX;
            const pageY = e.nativeEvent?.changedTouches[0]?.pageY || e.nativeEvent.pageY;

            const touchEnd = [pageX / windowWidth, pageY / windowHeight];

            // Check that the swipe was a reasonable distance.
            const deltaAxis1 = Math.abs(touchEnd[axisIdx1] - touchStart[axisIdx1]);
            if (deltaAxis1 < swipeDistanceRatio) {
                return;
            }

            // Ensure that distance travelled in X direction is much greater than Y.
            const deltaAxis2 = Math.abs(touchStart[axisIdx2] - touchEnd[axisIdx2]);
            if (deltaAxis1 - deltaAxis2 < magicRatio) {
                return;
            }

            onSwipe();
        },
        [touchStart]
    );

    const handleTouchStart = useCallback((e: GestureResponderEvent) => {
        const windowWidth = Dimensions.get('window').width;
        const windowHeight = Dimensions.get('window').height;
        const pageX = e.nativeEvent?.changedTouches[0]?.pageX || e.nativeEvent.pageX;
        const pageY = e.nativeEvent?.changedTouches[0]?.pageY || e.nativeEvent.pageY;
        setTouchStart([pageX / windowWidth, pageY / windowHeight]);
    }, []);
    return {
        handleTouchEnd,
        handleTouchStart,
    };
}

export function useTopEdgeSwipe(onSwipe: OnSwipeType, swipeDistanceRatio = magicRatio, edgeDistanceRatio = magicRatio) {
    return useEdgeSwipeFactory(0, 1, onSwipe, swipeDistanceRatio, edgeDistanceRatio);
}

export function useBottomEdgeSwipe(
    onSwipe: OnSwipeType,
    swipeDistanceRatio = magicRatio,
    edgeDistanceRatio = magicRatio
) {
    return useEdgeSwipeFactory(1, 1, onSwipe, swipeDistanceRatio, edgeDistanceRatio);
}

export function useRightEdgeSwipe(
    onSwipe: OnSwipeType,
    swipeDistanceRatio = magicRatio,
    edgeDistanceRatio = magicRatio
) {
    return useEdgeSwipeFactory(1, 0, onSwipe, swipeDistanceRatio, edgeDistanceRatio);
}

export function useLeftEdgeSwipe(
    onSwipe: OnSwipeType,
    swipeDistanceRatio = magicRatio,
    edgeDistanceRatio = magicRatio
) {
    return useEdgeSwipeFactory(0, 0, onSwipe, swipeDistanceRatio, edgeDistanceRatio);
}
