import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    messageContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flex: 1,
    },
    message: {
        paddingHorizontal: 8,
        ...typography.body2,
    },
    modalDesktop: {
        minHeight: 50,
        maxWidth: 500,
        margin: 'auto',
        borderRadius: 8,
        justifyContent: 'center',
    },
    modelMobile: {
        paddingVertical: 16,
        minHeight: 50,
        justifyContent: 'center',
        top: '40%',
    },
    modalContainer: {
        padding: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
});

export default styles;
