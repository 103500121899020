import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';

import useNotify from '@Hooks/useNotify';
import { defaultTheme } from '@Hooks/useTheme';

import CalendarAgenda from '../Agenda';
import CalendarMonth from '../Month';
import withLayout from '@Screens/common';
import { CalendarComponentProps } from '../types';
import { Filters } from '../components';
import { Header } from './components';

const calendarViewMap = {
    agenda: CalendarAgenda,
    month: CalendarMonth,
};

function DesktopCalendar({ ...props }: CalendarComponentProps & { hasStudents?: boolean }) {
    const navigation = useNavigation();
    const RenderComponent = calendarViewMap[props.selectedCalendarView];
    const { absenceForm, lateButton, absentButton } = useNotify();
    useEffect(() => {
        props.hasStudents &&
            navigation.setOptions({
                headerTitle: () => <Header {...props} />,
                headerShadowVisible: true,
                headerStyle: {
                    height: 80,
                    shadowOpacity: 0,
                    borderBottomWidth: 1,
                    borderBottomColor: defaultTheme.colors.inputOutline,
                },
                headerTitleContainerStyle: { flex: 1, alignItems: 'center', maxWidth: '100%' },
                headerRightContainerStyle: { flexGrow: 0 },
                headerRight: () => <></>,
            });
    }, [props]);

    return withLayout(
        <>
            <View style={{ flexDirection: 'row', height: '100%' }}>
                <Filters lateButton={lateButton} absentButton={absentButton} {...props} />
                <RenderComponent {...props} />
            </View>
            {absenceForm}
        </>
    );
}

export default DesktopCalendar;
