import { typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 16,
        height: '100%',
    },
    header: {
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: 18,
        paddingTop: 8,
        paddingBottom: 24,
    },
    filterContainer: {
        flexDirection: 'row',
        paddingVertical: 8,
        paddingBottom: 16,
    },
    unreadButton: {
        marginLeft: 16,
    },
    buttonDefaultStyle: {
        ...typography.body,
    },
    eventsContainer: {
        borderRightColor: 'rgba(55, 54, 57, 0.5299)',
        borderRightWidth: 1,
        paddingRight: 8,
        height: '100%',
    },
    eventsContainerHeader: {
        ...typography.h6,
        borderBottomWidth: 2,
        borderBottomColor: '#373639',
        paddingVertical: 16,
    },
});

export default styles;
