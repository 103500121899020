import React from 'react';
import { View } from 'react-native';
import i18n from '@I18n';

import SwitchInput from '@Components/SwitchInput';
import { styles } from '../../styles';

import { OfferStatus, OfferStatusFilter } from '@Redux/services/CalendarApi';

interface OfferStatusFilterListProps {
    onOfferStatusFilterValueChange: (status: OfferStatus) => (checked: boolean) => void;
    offerStatusFilter: OfferStatusFilter;
}

const filterNames: OfferStatus[] = ['invited', 'accepted', 'acceptedpast', 'expired'];

const ucFirst = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

function OfferStatusFilterList({
    onOfferStatusFilterValueChange: handleOfferStatusFilterValueChange,
    offerStatusFilter,
}: OfferStatusFilterListProps) {
    return (
        <View style={styles.eventStatusWrapper}>
            {filterNames.map((filterName) => (
                <SwitchInput
                    checked={offerStatusFilter[filterName]}
                    isCheckbox
                    key={`filter-name-${filterName}`}
                    label={i18n.t(`calendarFilterOfferStatus${ucFirst(filterName)}`)}
                    onChange={handleOfferStatusFilterValueChange(filterName)}
                    viewStyle={styles.checkbox}
                />
            ))}
        </View>
    );
}

export default OfferStatusFilterList;
