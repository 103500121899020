import { Notification as NotificationType } from '@Redux/types';
import { store } from '@Redux';
import React, { useContext, useState } from 'react';
import { Text, View } from 'react-native';
import Button from '@Components/Button';
import Checkmark from '@Icon/Checkmark';
import Close from '@Icon/Close';
import useTheme, { defaultTheme } from '@Hooks/useTheme';
import i18n from '@I18n';
import useToggle from '@Hooks/useToggle';
import UnPlannedAbsenceForm from '@Screens/Dashboard/Absence/UnPlanned';
import { alert } from '@Redux/alert';
import ContactModal from '@Screens/Dashboard/Absence/Contact';
import { useRespondToAbsenceAsNotExcusedMutation } from '@Redux/services/notification';
import styles from './styles';
import { useGetStudentsQuery } from '@Redux/services/parent';
import Avatar from '@Components/Avatar';
import { keyBy } from 'lodash';
import ErrorContext from '@Contexts/ErrorContext';

interface IProps {
    notification: NotificationType;
    onResponse: () => void;
}

const AbsenceItem: React.FC<IProps> = ({ notification, onResponse }) => {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const { show, toggle } = useToggle(false);
    const [showContact, setShowContact] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [respondToAbsenceAsNotExcused] = useRespondToAbsenceAsNotExcusedMutation();
    const theme = useTheme();
    const { data: students } = useGetStudentsQuery();

    const studentsMap = keyBy(students, 'StudentId');

    const handleAcknowledge = () => {
        toggle();
        store.dispatch(alert({ showAlert: true, message: i18n.t('successAcknowledgement') }));
    };

    const handleRespondNoPress = async () => {
        if (notification.ActivityId && !isLoading) {
            setIsLoading(true);
            try {
                await respondToAbsenceAsNotExcused({ activityId: notification.ActivityId }).unwrap();
                setShowContact(true);
                onResponse();
            } catch (e) {
                const defaultErrorMessage = i18n.t('fallbackNotExcusedAbsenceErrorMessage');
                const errMessage = e?.data?.Message || defaultErrorMessage;
                setMessage(errMessage);
                setVisible(true);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const toggleShowContactModal = () => {
        setShowContact(!showContact);
    };

    return (
        <>
            <View style={styles.container}>
                <Avatar
                    label={notification.EmployeeId ? studentsMap[notification.EmployeeId].FirstName : '-'}
                    picture={notification.EmployeeId && studentsMap[notification.EmployeeId].PhotoBase64}
                    style={styles.avatar}
                    size={33}
                />
                <View style={styles.subjectContainer}>
                    <Text style={styles.subjectBold}>{notification.Subject}</Text>
                    <View>
                        {!notification.IsReviewed && notification.IsActionable && notification.Classification == 0 && (
                            <>
                                <Text style={[styles.responseText, styles.subject]}>{i18n.t('confirmAbsence')}</Text>
                                <View style={styles.actionContainer}>
                                    <Button
                                        label={i18n.t('no')}
                                        underlineColor={theme.colors.lychee}
                                        style={styles.buttonNo}
                                        onPress={handleRespondNoPress}
                                        leftIcon={<Close width={18} height={18} fill={defaultTheme.colors.lychee} />}
                                    />
                                    <Button
                                        underlineColor={theme.colors.watermelon}
                                        label={i18n.t('yes')}
                                        onPress={() => !isLoading && toggle()}
                                        leftIcon={<Checkmark fill={defaultTheme.colors.watermelon} />}
                                    />
                                </View>
                            </>
                        )}
                    </View>
                </View>
            </View>
            {show && (
                <UnPlannedAbsenceForm
                    onAcknowledge={handleAcknowledge}
                    onDismiss={toggle}
                    activityId={notification.ActivityId}
                    show={show}
                />
            )}
            <ContactModal
                visible={showContact}
                subject={notification.Subject}
                schoolPhone={notification.SchoolPhone}
                schoolName={notification.SchoolName}
                onDismiss={toggleShowContactModal}
            />
        </>
    );
};

export default AbsenceItem;
