import { ComponentProps } from 'react';
import { Text, TextInput, View, ViewStyle } from 'react-native';
import { styles } from './styles';
import { default as AutoCompleteInput } from 'react-native-autocomplete-input';
import { defaultTheme } from '@Hooks/useTheme';

export type AutoCompleteProps = ComponentProps<typeof AutoCompleteInput> & {
    inputLabel?: string;
    style?: ViewStyle;
};

function AutoComplete(props: AutoCompleteProps) {
    return (
        <View style={styles.container}>
            <View style={styles.autoCompleteContainer}>
                <AutoCompleteInput
                    {...props}
                    inputContainerStyle={styles.inputContainer}
                    renderTextInput={(props) => (
                        <TextInput
                            {...props}
                            style={styles.input}
                            placeholderTextColor={defaultTheme.colors.textPrimary}
                        />
                    )}
                />
                <View style={styles.labelContainer} accessibilityLabel={props.inputLabel}>
                    <Text style={styles.label}>{props.inputLabel}</Text>
                </View>
            </View>
        </View>
    );
}

export default AutoComplete;
