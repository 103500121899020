import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgTriangleExclamation = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 129 128' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M.773 114.175 64.501 3.795l63.728 110.38H.773Zm15.207-9.331 48.524-84.045 48.524 84.045H15.98Z'
            fill='#EEAB00'
        />
        <Path d='M59.167 80h10.666V54.8H59.167V80Z' fill='#F99F9B' />
        <Path d='M66.985 84h-4.97L58.5 87.515v4.97L62.015 96h4.97l3.515-3.515v-4.97L66.985 84Z' fill='#FF5F4D' />
    </Svg>
);
export default SvgTriangleExclamation;
