import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        marginTop: 16,
        marginBottom: 12,
    },
    dateTime: {
        marginBottom: 6,
    },
    subjectBold: {
        fontWeight: '700',
        ...typography.body2,
    },
    subject: {
        fontWeight: '500',
        ...typography.body2,
    },
    unreadIcon: {
        borderRadius: 50,
        width: 12,
        height: 12,
        backgroundColor: defaultTheme.colors.persimmon,
    },
    actionContainer: {
        marginTop: 8,
        flexDirection: 'row',
    },
    buttonNo: {
        marginRight: 32,
    },
    dateContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    responseText: {
        marginTop: 10,
    },
});

export default styles;
