import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgAlarm = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='m25.428.896-3.531 3.532 5.7 5.7 3.53-3.532-5.7-5.7ZM10.104 4.428 6.572.897l-5.7 5.7 3.532 3.53 5.7-5.7Zm5.917 25.288c6.597 0 11.945-5.348 11.945-11.945S22.618 5.827 16.02 5.827 4.077 11.174 4.077 17.77 9.424 29.716 16.02 29.716Zm3.48-19.217-3.803 6.585h7.604L19.5 10.5Z'
            fill='#EEAB00'
        />
    </Svg>
);
export default SvgAlarm;
