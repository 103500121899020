import { Text, View } from 'react-native';
import React, { useContext } from 'react';
import i18n from '@I18n/index';
import styles from './styles';
import { StudentDetails } from '@Redux/types';
import DeviceContext from '@Contexts/DeviceContext';

interface IProps {
    studentDetails: StudentDetails;
}

const StudentInfo: React.FC<IProps> = ({ studentDetails }) => {
    const { isDesktop } = useContext(DeviceContext);
    return isDesktop ? (
        <>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                    <Text style={styles.label}>{i18n.t('gender')}</Text>
                    <Text style={styles.body}>{studentDetails.Gender}</Text>
                </View>
                <View>
                    <Text style={styles.label}>{i18n.t('birthDate')}</Text>
                    <Text style={styles.body}>{studentDetails.DateOfBirth}</Text>
                </View>
                <View>
                    <Text style={styles.label}>{i18n.t('id')}</Text>
                    <Text style={styles.body}>{studentDetails.StudentNumber}</Text>
                </View>
                <View>
                    <Text style={styles.label}>{i18n.t('oen')}</Text>
                    <Text style={styles.body}>{studentDetails.Oen}</Text>
                </View>
            </View>
        </>
    ) : (
        <>
            <View>
                <Text style={styles.label}>{i18n.t('preferredName')}</Text>
                <Text style={styles.body}>{studentDetails.PreferredFull}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                    <Text style={styles.label}>{i18n.t('gender')}</Text>
                    <Text style={styles.body}>{studentDetails.Gender}</Text>
                </View>
                <View>
                    <Text style={styles.label}>{i18n.t('birthDate')}</Text>
                    <Text style={styles.body}>{studentDetails.DateOfBirth}</Text>
                </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                    <Text style={styles.label}>{i18n.t('id')}</Text>
                    <Text style={styles.body}>{studentDetails.StudentNumber}</Text>
                </View>
                <View>
                    <Text style={styles.label}>{i18n.t('oen')}</Text>
                    <Text style={styles.body}>{studentDetails.Oen}</Text>
                </View>
            </View>
        </>
    );
};

export default StudentInfo;
