import { StyleSheet } from 'react-native';
import { defaultTheme, fonts, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    row1: {
        ...typography.buttonSmall,
        color: defaultTheme.colors.textPrimary,
        fontFamily: fonts.MontserratBold,
        fontSize: 12,
        minWidth: 60,
    },
    date: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 4,
        paddingTop: 6,
    },
    timeLabelContainer: {
        borderTopColor: 'black',
        borderTopWidth: 2,
        marginRight: 8,
        minWidth: 80,
    },
    timeLabelOfferStatusExpired: {
        color: defaultTheme.colors.disabled,
    },
    timeLabelContainerOfferStatusInvited: {
        borderTopColor: defaultTheme.colors.persimmon,
    },
    timeLabelContainerOfferStatusExpired: {
        borderTopColor: defaultTheme.colors.disabled,
    },
    calendarDateLineSeperator: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    dateLabel: {
        color: defaultTheme.colors.textPrimary,
        fontFamily: fonts.MontserratBold,
        fontSize: 12,
    },
});
