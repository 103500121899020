import React, { useCallback, useEffect, useState, useContext } from 'react';
import { View } from 'react-native';
import CheckBoxProfile from '@Screens/Dashboard/components/CheckBoxProfile';
import Modal from '@Components/Modal';
import ModalHeader from '@Components/ModalHeader';
import styles from './styles';
import i18n from '@I18n';
import { useGetAcknowledgeAbsenceFormMutation } from '@Redux/services/absence';
import { useAcknowledgeAbsenceMutation, useGetAllStudentActivitiesQuery } from '@Redux/services/notification';
import { AcknowledgeAbsenceForm } from '@Redux/types';
import TextInput from '@Components/TextInput';
import { ActivityIndicator, TextInput as PaperTextInput } from 'react-native-paper';
import Comments from '@Icon/Comments';
import Plus from '@Icon/Plus';
import Button from '@Components/Button';
import useTheme from '@Hooks/useTheme';
import SwitchInput from '@Components/SwitchInput';
import SvgSchedule from '@Icon/Schedule';
import DeviceContext from '@Contexts/DeviceContext';
import { useGetStudentsQuery } from '@Redux/services/parent';
import { keyBy } from 'lodash';
import { ScrollView } from 'react-native-gesture-handler';
import Picker from '@Components/Picker';
import ErrorAlert from '@Components/ErrorAlert';

interface IProps {
    activityId?: string | null;
    onDismiss: () => void;
    onAcknowledge: () => void;
    show: boolean;
}

const UnPlannedAbsenceForm: React.FC<IProps> = ({ show, onAcknowledge, onDismiss, activityId }) => {
    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [absenceData, setAbsenceData] = useState<AcknowledgeAbsenceForm>();
    const [loading, setLoading] = useState(false);
    const { isDesktop } = useContext(DeviceContext);
    const [getAcknowledgeAbsenceForm] = useGetAcknowledgeAbsenceFormMutation();
    const [acknowledgeAbsence] = useAcknowledgeAbsenceMutation();
    const { refetch } = useGetAllStudentActivitiesQuery();
    const { data: students } = useGetStudentsQuery();
    const theme = useTheme();

    const studentsMap = keyBy(students, 'StudentId');

    useEffect(() => {
        getAcknowledgeAbsenceData();
    }, [activityId]);

    const getAcknowledgeAbsenceData = useCallback(async () => {
        if (!activityId) {
            return;
        }
        try {
            const response = await getAcknowledgeAbsenceForm({ activityId }).unwrap();
            setAbsenceData({ ...response, StudentActivityId: activityId });
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackGetErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        }
    }, [activityId]);

    const handleAbsenceReasonChange = (value: string) => {
        if (absenceData) {
            const newAbsenceData = { ...absenceData };
            newAbsenceData.ReasonId = value;
            setAbsenceData(newAbsenceData);
        }
    };

    const handleCommentChange = (value: string) => {
        if (absenceData) {
            const newAbsenceData = { ...absenceData };
            newAbsenceData.Note = value;
            setAbsenceData(newAbsenceData);
        }
    };

    const handleAddAbsencePress = async () => {
        if (loading || !activityId || !absenceData) {
            return;
        }
        setLoading(true);
        try {
            await acknowledgeAbsence(absenceData).unwrap();
            refetch();
            onAcknowledge();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackAddAbsenceErrorMessage');
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        } finally {
            setLoading(false);
        }
    };

    const handleAllDayChange = (checked: boolean) => {
        if (absenceData) {
            setAbsenceData({ ...absenceData, ApplicableForEntireDay: checked });
        }
    };

    return (
        <Modal
            style={isDesktop ? [styles.desktopModal, { width: 400, height: '80%' }] : { height: '100%' }}
            visible={show}
            header={<ModalHeader header={i18n.t('absent')} onDismiss={onDismiss} />}
        >
            <ScrollView>
                {!absenceData ? (
                    <ActivityIndicator animating={true} color={theme.colors.lychee} />
                ) : (
                    <>
                        <View style={styles.container}>
                            <View style={[{ flexDirection: 'row' }, styles.checkboxContainer]}>
                                {absenceData.ChildrenInfo.map((child) => {
                                    return (
                                        <CheckBoxProfile
                                            disabled={!activityId}
                                            studentId={child.StudentId}
                                            key={child.StudentId}
                                            style={styles.child}
                                            checked={true}
                                            name={child.FirstName}
                                            picture={studentsMap[child.StudentId]?.PhotoBase64}
                                        />
                                    );
                                })}
                            </View>
                            <SwitchInput
                                checked={absenceData.ApplicableForEntireDay}
                                onChange={handleAllDayChange}
                                icon={<SvgSchedule />}
                                label={i18n.t('allDay')}
                                reverse
                                viewStyle={styles.switchContainer}
                            />

                            <View style={styles.bottomMargin}>
                                <TextInput
                                    mode='outlined'
                                    value={absenceData.AbsenceDate}
                                    disabled={true}
                                    label={i18n.t('date')}
                                />
                            </View>

                            <View style={styles.bottomMargin}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ flexBasis: '45%' }}>
                                        <TextInput
                                            disabled
                                            label={i18n.t('startTime')}
                                            mode='outlined'
                                            value={absenceData.StartTime}
                                        />
                                    </View>

                                    <View style={{ flexBasis: '45%' }}>
                                        <TextInput
                                            disabled
                                            label={i18n.t('endTime')}
                                            mode='outlined'
                                            value={absenceData.EndTime}
                                        />
                                    </View>
                                </View>
                            </View>
                            <View style={styles.bottomMargin}>
                                <Picker
                                    items={absenceData.AbsenceReasons.map((item) => ({
                                        label: item.Name,
                                        value: item.Id,
                                    }))}
                                    onValueChange={handleAbsenceReasonChange}
                                    value={absenceData.ReasonId}
                                    inputLabel={i18n.t('reason')}
                                />
                            </View>
                            <View style={styles.bottomMargin}>
                                <TextInput
                                    mode={'outlined'}
                                    label={i18n.t('comments')}
                                    multiline={true}
                                    value={absenceData.Note}
                                    onChangeText={handleCommentChange}
                                    left={<PaperTextInput.Icon name={() => <Comments />} />}
                                />
                            </View>
                            <View style={styles.buttonContainer}>
                                <Button
                                    onPress={handleAddAbsencePress}
                                    leftIcon={loading ? <ActivityIndicator /> : <Plus />}
                                    label={i18n.t('submit')}
                                />
                            </View>
                        </View>
                    </>
                )}
            </ScrollView>
            <ErrorAlert message={message} visible={visible} setVisible={setVisible} />
        </Modal>
    );
};

export default UnPlannedAbsenceForm;
