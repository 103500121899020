import SvgBrush from '@Icon/Brush';
import React from 'react';
import { View, Text } from 'react-native';
import { IconButton } from 'react-native-paper';
import styles from './styles';

interface IProps {
    label: string;
    onEditClick: () => void;
}

const SummaryHeader: React.FC<IProps> = ({ label, onEditClick }) => (
    <View style={styles.container}>
        <Text style={styles.summaryTitle}>{label}</Text>
        <IconButton icon={() => <SvgBrush width={20} />} onPress={onEditClick} />
    </View>
);

export default SummaryHeader;
