import { RefObject, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { FlatList as FlatListType, LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import useStatePromise from '@Hooks/useStatePromise';

import { wait } from '@Utilities';

import {
    Interval,
    addDays,
    compareAsc,
    eachDayOfInterval,
    formatISO,
    getMonth,
    getTime,
    getYear,
    parseISO,
    subDays,
    isSameDay,
    startOfDay,
    endOfDay,
} from 'date-fns';

import type { UseCalendarProps, CalendarProps, PeriodHeightMap } from './types';

import { dateWSO } from './types';

import { useGetStudentsQuery } from '@Redux/services/parent';
import { first, findIndex } from 'lodash';
import {
    DailySeriesEventDateMap,
    EventTypesFilter,
    OfferStatusFilter,
    StudentsFilter,
    dateRep,
    useLazyRetrieveUsersEventsAsyncQuery,
} from '@Redux/services/CalendarApi';
import { ChildInfo } from '@Redux/types';

function concatIntervals(...intervals: Interval[]) {
    const starts = intervals.map((interval) => getTime(interval.start));
    const ends = intervals.map((interval) => getTime(interval.end));

    return {
        start: new Date(Math.min(...starts)),
        end: new Date(Math.max(...ends)),
    };
}

const scrollRefToDate = (
    ref: RefObject<FlatListType<unknown>>,
    dailySeriesEventMap: DailySeriesEventDateMap,
    toDate: Date
) => {
    if (!ref) {
        return;
    }

    const indexToScroll = findIndex(getEachDayOfDailySeriesEventMap(dailySeriesEventMap), (date) =>
        isSameDay(date, toDate)
    );

    if (indexToScroll < 0) {
        return;
    }

    ref.current?.scrollToIndex({ index: indexToScroll, viewPosition: 0 });
};

function createStudentsFilter(students: ChildInfo[] = [], studentsFilter: StudentsFilter = {}) {
    const nextStudentsFilter: StudentsFilter = { ...studentsFilter };
    students?.map((student) => {
        nextStudentsFilter[student.FullName] ??= true;
    });
    return nextStudentsFilter;
}

function getEachDayOfDailySeriesEventMap(dailySeriesEventMap: DailySeriesEventDateMap): Date[] {
    return (
        dailySeriesEventMap &&
        Object.keys(dailySeriesEventMap)
            .map((date) => parseISO(date))
            .sort(compareAsc)
    );
}

export function useCalendar({
    endOfPeriod,
    getPeriod,
    startOfPeriod,
    overrideEventTypesFilter,
    overrideOfferStatusFilter,
}: UseCalendarProps): CalendarProps {
    const flatListRef = useRef<FlatListType<unknown>>(null);
    const [eventTypesFilter, setEventTypesFilterValue] = useState<EventTypesFilter>(
        overrideEventTypesFilter
            ? overrideEventTypesFilter
            : {
                  offers: true,
                  other: true,
                  schoolboard: true,
                  schoolyear: true,
                  noevents: true,
                  fieldtrip: true,
              }
    );

    const [offerStatusFilter, setOfferStatusFilterValue] = useState<OfferStatusFilter>(
        overrideOfferStatusFilter
            ? overrideOfferStatusFilter
            : {
                  accepted: true,
                  invited: false,
                  acceptedpast: true,
                  expired: false,
              }
    );

    const [viewportHeight, setViewportHeight] = useState<number>(0);

    const initialDate = useMemo(() => new Date(), []);
    const initialPeriod = startOfPeriod(initialDate, dateWSO);
    const currentYear = useMemo(() => getYear(new Date()), []);

    const [currentInterval, promisedSetCurrentInterval, setCurrentInterval] = useStatePromise({
        start: startOfPeriod(initialPeriod, dateWSO),
        end: endOfPeriod(initialPeriod, dateWSO),
    });

    const [exposedInterval, promisedSetExposedInterval, setExposedInterval] = useStatePromise(currentInterval);

    const [initialFetchComplete, promisedSetInitialFetchComplete, setInitialFetchComplete] = useStatePromise(false);
    const [isLoading, promisedSetIsLoading, setIsLoading] = useStatePromise(true);

    const [getData] = useLazyRetrieveUsersEventsAsyncQuery();
    const [dailySeriesEventMap, promisedSetDateEventsMap, setDateEventsMap] = useStatePromise<DailySeriesEventDateMap>(
        {} as DailySeriesEventDateMap
    );

    const [skipScrollListener, promisedSetSkipScrollListener, setSkipScrollListener] = useStatePromise(true);

    const { data: students, isLoading: studentsAreLoading } = useGetStudentsQuery();
    const [studentsInitialized, setStudentInitialized] = useState(false);
    const [studentsFilter, setStudentsFilterValue] = useState<StudentsFilter>(createStudentsFilter(students));

    useEffect(() => {
        if (studentsAreLoading) return;
        setStudentsFilterValue(createStudentsFilter(students, studentsFilter));
        setStudentInitialized(true);
    }, [students, studentsAreLoading]);

    const loadEvents = async function () {
        setInitialFetchComplete(false);
        setDateHeightMap({});
        setExposedInterval(currentInterval);

        setIsLoading(true);
        if (studentsAreLoading) {
            return;
        }

        const { data: initialData } = await getData({
            End: `${formatISO(endOfDay(currentInterval.end)).substring(0, 19)}Z`,
            Start: `${formatISO(startOfDay(currentInterval.start)).substring(0, 19)}Z`,
            eventTypesFilter,
            offerStatusFilter,
            studentsFilter,
        });

        await setDateEventsMap(initialData as DailySeriesEventDateMap);
        setInitialFetchComplete(true);
        setIsLoading(false);
    };

    useEffect(() => {
        if (!studentsInitialized) return;
        loadEvents();
    }, [studentsInitialized, studentsFilter, eventTypesFilter, offerStatusFilter]);

    const loadInterval = useCallback(
        async (interval: Interval) => {
            const { data: newData } = await getData({
                End: `${formatISO(endOfDay(interval.end)).substring(0, 19)}Z`,
                Start: `${formatISO(startOfDay(interval.start)).substring(0, 19)}Z`,
                eventTypesFilter,
                // offerStatusFieldtripFilter,
                offerStatusFilter,
                studentsFilter,
            });

            const nextDailySeriesEventMap = await promisedSetDateEventsMap((oldData) => ({ ...oldData, ...newData }));
            return nextDailySeriesEventMap;
        },
        [studentsFilter, eventTypesFilter, offerStatusFilter]
    );

    const currentIntervalMonth = useMemo(() => {
        const intervalDays = eachDayOfInterval(currentInterval);
        const monthCount = intervalDays.reduce((memo, date) => {
            const month = getMonth(date);
            const year = getYear(date);
            const key = `${year}-${month}`;
            memo[key] ??= 0;
            ++memo[key];
            return memo;
        }, {} as Record<string, number>);
        const maxCount = Math.max(...Object.values(monthCount));
        const selectedYearMonth = (
            first(
                Object.entries(monthCount)
                    .filter(([, count]) => count === maxCount)
                    .map(([key]) => key)
                    .sort()
            ) as string
        )
            .split('-')
            .map((string) => parseInt(string, 10)) as [number, number];
        const selectedDate = new Date(...selectedYearMonth);
        return selectedDate;
    }, [currentInterval]);

    const handleStartReached = useCallback(async () => {
        if (isLoading) {
            return;
        }

        const newInterval = {
            start: startOfPeriod(subDays(exposedInterval.start, 1), dateWSO),
            end: subDays(exposedInterval.start, 1),
        };

        await Promise.all([promisedSetIsLoading(true), promisedSetSkipScrollListener(true)]);
        await loadInterval(newInterval);

        const newExposedInterval = concatIntervals(newInterval, exposedInterval);
        setExposedInterval(newExposedInterval);
        setSkipScrollListener(false);
        setIsLoading(false);
    }, [
        exposedInterval,
        studentsFilter,
        eventTypesFilter,
        isLoading,
        promisedSetSkipScrollListener,
        promisedSetIsLoading,
    ]);

    const handleEndReached = useCallback(async () => {
        if (isLoading) {
            return;
        }

        const newInterval = {
            start: addDays(exposedInterval.end, 1),
            end: endOfPeriod(addDays(exposedInterval.end, 1), dateWSO),
        };

        await Promise.all([promisedSetIsLoading(true), promisedSetSkipScrollListener(true)]);
        await loadInterval(newInterval);

        const newExposedInterval = concatIntervals(newInterval, exposedInterval);
        setExposedInterval(newExposedInterval);
        setSkipScrollListener(false);
        setIsLoading(false);
    }, [
        exposedInterval,
        studentsFilter,
        eventTypesFilter,
        isLoading,
        promisedSetSkipScrollListener,
        promisedSetIsLoading,
    ]);

    const [dateHeightMap, setDateHeightMap] = useState<PeriodHeightMap>({});

    const captureHeight = useCallback(
        (date: Date, height: number) => {
            setDateHeightMap((prevState) => ({ ...prevState, [formatISO(date, dateRep)]: height }));
        },
        [setDateHeightMap]
    );

    const handleViewLayout = useCallback((event: LayoutChangeEvent) => {
        const { height } = event.nativeEvent.layout;
        setViewportHeight(height);
    }, []);

    const eachDayOfDailySeriesEventMap = useMemo(
        () => getEachDayOfDailySeriesEventMap(dailySeriesEventMap),
        [dailySeriesEventMap]
    );

    const handleScroll = useCallback(
        ({
            nativeEvent: {
                contentOffset: { y: scrollPosition },
            },
        }: NativeSyntheticEvent<NativeScrollEvent>) => {
            if (skipScrollListener) {
                return;
            }
            const [visibleDates] = eachDayOfDailySeriesEventMap.reduce(
                (memo, date) => {
                    const [datesOnScreen, checkedHeight] = memo;

                    if (checkedHeight > scrollPosition + viewportHeight) {
                        // Visible region passed, skip.
                        return memo;
                    }

                    if (checkedHeight >= scrollPosition) {
                        datesOnScreen.push(date);
                    }

                    const dateHeight = dateHeightMap[formatISO(date, dateRep)];
                    if (typeof dateHeight !== 'number') {
                        // Race onLayout or something went wrong, skip.
                        return memo;
                    }

                    memo[1] = checkedHeight + dateHeight;
                    return memo;
                },
                [[], 0] as [Date[], number]
            );

            if (visibleDates.length === 0) {
                return;
            }

            const weekHeight: Record<number, number> = {};
            visibleDates.forEach((date) => {
                const week = getPeriod(date);
                weekHeight[week] ??= 0;
                weekHeight[week] += dateHeightMap[formatISO(date, dateRep)] || 0;
            });
            const maxCount = Math.max(...Object.values(weekHeight));
            const selectedPeriod = parseInt(
                first(Object.entries(weekHeight).find(([, count]) => count === maxCount)) as string,
                10
            );
            const selectedDate = visibleDates.find((date) => getPeriod(date) === selectedPeriod) as Date;
            setCurrentInterval({
                start: startOfPeriod(selectedDate, dateWSO),
                end: endOfPeriod(selectedDate, dateWSO),
            });
        },
        [skipScrollListener, eachDayOfDailySeriesEventMap, viewportHeight, dateHeightMap]
    );

    const handleIncrement = useCallback(async () => {
        if (isLoading) {
            return;
        }

        const newInterval = {
            start: addDays(currentInterval.end, 1),
            end: endOfPeriod(addDays(currentInterval.end, 1), dateWSO),
        };

        await Promise.all([promisedSetIsLoading(true), promisedSetSkipScrollListener(true)]);
        const nextDailySeriesEventMap = await loadInterval(newInterval);

        const newExposedInterval = concatIntervals(newInterval, exposedInterval);
        await Promise.all([promisedSetExposedInterval(newExposedInterval), promisedSetCurrentInterval(newInterval)]);
        scrollRefToDate(flatListRef, nextDailySeriesEventMap, newInterval.start);
        await wait(432);

        setSkipScrollListener(false);
        setIsLoading(false);
    }, [isLoading, currentInterval, exposedInterval, loadInterval]);

    const handleDecrement = useCallback(async () => {
        if (isLoading) {
            return;
        }

        const newInterval = {
            start: startOfPeriod(subDays(currentInterval.start, 1), dateWSO),
            end: subDays(currentInterval.start, 1),
        };

        await Promise.all([promisedSetIsLoading(true), promisedSetSkipScrollListener(true)]);
        const nextDailySeriesEventMap = await loadInterval(newInterval);

        const newExposedInterval = concatIntervals(newInterval, exposedInterval);
        Promise.all([promisedSetExposedInterval(newExposedInterval), promisedSetCurrentInterval(newInterval)]);
        scrollRefToDate(flatListRef, nextDailySeriesEventMap, newInterval.start);
        await wait(432);

        setSkipScrollListener(false);
        setIsLoading(false);
    }, [isLoading, currentInterval, exposedInterval, loadInterval]);

    const warpToDate = useCallback(
        async (date: Date) => {
            const newInterval = {
                start: startOfPeriod(date, dateWSO),
                end: endOfPeriod(date, dateWSO),
            };

            await Promise.all([
                promisedSetInitialFetchComplete(false),
                promisedSetIsLoading(true),
                promisedSetSkipScrollListener(true),
            ]);

            const { data: nextDailySeriesEventMap } = await getData({
                End: `${formatISO(endOfDay(newInterval.end)).substring(0, 19)}Z`,
                Start: `${formatISO(startOfDay(newInterval.start)).substring(0, 19)}Z`,
                eventTypesFilter,
                offerStatusFilter,
                studentsFilter,
            });

            await Promise.all([
                promisedSetDateEventsMap(nextDailySeriesEventMap as DailySeriesEventDateMap),
                promisedSetExposedInterval(newInterval),
                promisedSetCurrentInterval(newInterval),
                promisedSetInitialFetchComplete(true),
                wait(432),
            ]);

            scrollRefToDate(flatListRef, nextDailySeriesEventMap, date);
            await wait(432), setSkipScrollListener(false);
            setIsLoading(false);
        },
        [isLoading, currentInterval, exposedInterval, loadInterval]
    );

    const warpToPrevPeriod = () => warpToDate(subDays(currentInterval.start, 1));
    const warpToNextPeriod = () => warpToDate(addDays(currentInterval.end, 1));

    return {
        captureHeight,
        currentInterval,
        currentIntervalMonth,
        currentYear,
        dailySeriesEventMap,
        dateHeightMap,
        eachDayOfDailySeriesEventMap,
        eventTypesFilter,
        exposedInterval,
        flatListRef,
        handleDecrement,
        handleEndReached,
        handleIncrement,
        handleScroll,
        handleStartReached,
        handleViewLayout,
        initialFetchComplete,
        isLoading,
        offerStatusFilter,
        promisedSetSkipScrollListener,
        setEventTypesFilterValue,
        setOfferStatusFilterValue,
        setStudentsFilterValue,
        studentsFilter,
        warpToDate,
        warpToNextPeriod,
        warpToPrevPeriod,
        loadEvents,
    };
}

export default useCalendar;
