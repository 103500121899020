import { defaultTheme } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',
        display: 'flex',
        paddingHorizontal: 24,
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        borderTopColor: defaultTheme.colors.textPrimary,
        backgroundColor: 'white',
        borderTopWidth: 1,
        height: 70,
        bottom: 0,
        width: '100%',
    },
    first: {
        flexDirection: 'row-reverse',
    },
});
