import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgGuardian = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.863.196h12.248v12.248H9.863V.196Zm3.048 15.374H.663v12.247h12.248V15.57Zm18.44 0H19.103v12.247h12.248V15.57Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgGuardian;
