import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgGoogleLogoDisabled = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.853 6.55c-1.25-1.19-2.83-1.8-4.6-1.8-3.13 0-5.78 2.11-6.73 4.96-.24.72-.38 1.49-.38 2.29s.13 1.57.38 2.29c.95 2.85 3.6 4.96 6.73 4.96 1.62 0 2.99-.44 4.07-1.16 1.26-.85 2.11-2.1 2.4-3.58h-6.47V10h11.3c.12.73.19 1.48.19 2.27 0 3.65-1.3 6.73-3.56 8.82-1.98 1.83-4.69 2.91-7.93 2.91-4.69 0-8.74-2.7-10.71-6.62a11.86 11.86 0 0 1 0-10.76C4.513 2.7 8.563 0 13.253 0c3.24 0 5.95 1.19 8.02 3.13l-3.42 3.42Z'
            fill='#373639'
            fillOpacity={0.53}
        />
    </Svg>
);
export default SvgGoogleLogoDisabled;
