import i18n from '@I18n';
import { Notification } from '@Svg';
import { View, Text } from 'react-native';
import styles from './styles';

const Empty = () => {
    return (
        <View style={styles.container}>
            <Notification style={styles.image} />
            <Text style={styles.header}>{i18n.t('notificationEmpty')}</Text>
            <Text style={styles.subHeader}>{i18n.t('notificationEmpty1')}</Text>
        </View>
    );
};

export default Empty;
