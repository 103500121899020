import IconList from '@Components/IconList';
import i18n from '@I18n';
import SvgCalendar from '@Icon/Calendar';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { Text, View } from 'react-native';
import styles from '../styles';
import EventIcon from '@Components/EventIcon';
import { CalendarEvent } from '@Redux/services/CalendarApi';
import SvgBuildingGovernment from '@Icon/BuildingGovernment';

interface IProps {
    EventName: string;
    DueDate: string;
    ActualStart: string;
    ActualEnd: string;
    event?: CalendarEvent;
}

const Header: React.FC<IProps> = ({ event, EventName, DueDate, ActualEnd, ActualStart }) => {
    const start = parseISO(ActualStart);
    const end = parseISO(ActualEnd);
    const isSingleDay = format(start, 'yyyyMMdd') === format(end, 'yyyyMMdd');

    return (
        <View style={styles.sectionMargin}>
            <IconList
                icon={
                    event ? (
                        <EventIcon event={{ isFirstInDailySeries: false, isLastInDailySeries: false, ...event }} />
                    ) : (
                        <SvgBuildingGovernment />
                    )
                }
                header={EventName}
            >
                <View style={[styles.iconContainer, { alignItems: 'center' }]}>
                    <SvgCalendar width={15} style={{ marginRight: 12 }} />
                    <Text style={styles.dueDate}>
                        {i18n.t('replyBy')} {format(parseISO(DueDate), 'E MMM dd, yyyy')}
                    </Text>
                </View>
                <View>
                    <Text style={styles.body}>
                        {format(start, 'E MMM dd, yyyy')} {!isSingleDay && <>- {format(end, 'E MMM dd, yyyy')}</>}
                    </Text>
                    <Text style={styles.body}>
                        {format(start, 'hh:mm:aaa')} - {format(end, 'hh:mm:aaa')}
                    </Text>
                </View>
            </IconList>
        </View>
    );
};

export default Header;
