import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgFingerprint2 = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            d='M10.166 6.601c1.546-.892 3.731-1.345 6.017-1.253 2.28.09 4.549.72 6.26 1.865a1 1 0 1 0 1.113-1.662c-2.085-1.394-4.732-2.1-7.293-2.202-2.556-.102-5.14.39-7.097 1.52a1 1 0 0 0 1 1.732Zm6.973.596a1 1 0 0 0-.278 1.98c3.793.533 6.047 2.686 7.403 4.897 1.372 2.237 1.8 4.5 1.892 5.083a1 1 0 0 0 1.976-.31c-.11-.702-.594-3.261-2.164-5.82-1.585-2.583-4.304-5.195-8.829-5.83Zm-4.585 7.001c-2.44 1.965-3.881 6.216-1.234 11.7a1 1 0 1 1-1.802.87C6.554 20.627 7.99 15.306 11.3 12.641c1.637-1.319 3.708-1.95 5.807-1.65 2.106.303 4.147 1.53 5.753 3.756a1 1 0 0 1-1.621 1.17c-1.332-1.845-2.915-2.73-4.416-2.946-1.51-.216-3.032.232-4.268 1.227Zm3.098 13.029c-1.648-3.39-2.126-6.222-1.814-8.07.153-.913.486-1.515.884-1.868.38-.337.916-.533 1.684-.46 1.034.098 1.493.43 1.772.8.334.445.522 1.084.736 2.048.027.118.054.24.08.364.392 1.805.921 4.242 3.399 6.128a1 1 0 0 0 1.211-1.591c-1.877-1.429-2.266-3.198-2.67-5.028v-.002l-.067-.306c-.205-.92-.461-1.977-1.091-2.814-.686-.912-1.712-1.45-3.181-1.59-1.236-.117-2.345.198-3.199.955-.836.74-1.325 1.813-1.531 3.032-.408 2.419.245 5.692 1.989 9.277a1 1 0 1 0 1.798-.875ZM7.74 7.306a1 1 0 0 1-.062 1.412c-.872.81-1.62 1.745-2.216 2.775a1 1 0 1 1-1.74-.987 13.97 13.97 0 0 1 2.605-3.263 1 1 0 0 1 1.414.062h-.001ZM24.316 18.98a1.002 1.002 0 0 0-1.531-.655 1 1 0 0 0-.434 1.026c.373 1.98 1.289 3.634 2.957 4.665a1 1 0 0 0 1.051-1.701c-1.07-.66-1.744-1.75-2.043-3.334Zm-8.22.02a1 1 0 0 1 1.08.913c.302 3.594 2.095 5.864 2.586 6.423a1 1 0 1 1-1.502 1.32c-.643-.731-2.726-3.392-3.077-7.576a.999.999 0 0 1 .913-1.08Zm-2.7-9.512a1 1 0 0 0-.795-1.834c-4.577 1.984-6.674 5.365-7.579 8.409-.895 3.008-.632 5.684-.537 6.403a1 1 0 0 0 1.983-.262c-.078-.586-.31-2.945.471-5.57.77-2.59 2.521-5.439 6.457-7.146Z'
            fill='#72BEFB'
        />
    </Svg>
);
export default SvgFingerprint2;
