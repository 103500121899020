import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    dateDayOfMonth: {
        ...typography.body3,
    },
    dateDayOfWeek: {
        ...typography.body3,
    },
    dateIndicator: {
        marginTop: 8,
    },
    periodSelector: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
        paddingLeft: 15,
        paddingRight: 15,
    },
    dateIndicatorCurrentDay: {
        borderBottomWidth: 4,
        borderBottomColor: defaultTheme.colors.watermelon,
        marginTop: 4,
    },
    dateIndicatorHasEvents: {
        borderBottomWidth: 2,
        borderBottomColor: defaultTheme.colors.textPrimary,
        marginTop: 6,
        width: 8,
    },
});
