import { Pressable, StyleSheet, Text, View } from 'react-native';
import {
    useEditNotificationPreferencesMutation,
    useEditStudentNotificationPreferencesMutation,
    useGetNotificationPreferencesQuery,
    useGetParentQuery,
    useGetStudentsQuery,
} from '@Redux/services/parent';
import { useContext, useEffect, useState } from 'react';
import ContactPreferencesModal, {
    ContactPreferencesModalData,
    ContactPreferencesModalFieldOptions,
} from '../ContactPreferencesModal';
import useTheme, { typography } from '@Hooks/useTheme';
import i18n from '@I18n';
import { IconButton } from 'react-native-paper';
import Avatar from '@Components/Avatar';
import {
    getNotificationPreferencesDescription,
    getPreferenceString,
    MobileValues,
    NotificationPreferencesData,
    prepareContactPreferenceData,
    prepareStudentContactPreferenceData,
} from '../index';
import { getPreferenceType } from '../index';
import ContactOptionsModal from '../OptionsModal';
import ContactPreferencesForm from '../ContactPreferencesForm';
import SvgBrush from '@Icon/Brush';
import { useStudentListEmpty } from '@Hooks/useStudentListEmpty';
import { isUndefined } from 'lodash';
import ErrorContext from '@Contexts/ErrorContext';

type Data = {
    studentId?: string;
    firstName: string;
    photo: string;
    hasHomeNumber: boolean;
    hasMobileNumber: boolean;
    hasWorkNumber: boolean;
    hasEmail: boolean;
    prefs: NotificationPreferencesData[];
};

type Preferences = {
    parent: Data;
    students: Data[];
};

type EdittableProps = {
    edittable: boolean;
    setEdittable: (val: boolean) => void;
};

type TableRowProps = {
    data: Data;
    types: string[];
    onSubmit: (value: ContactPreferencesModalData, prefType: string, studentId: string) => void;
} & EdittableProps;

type TableCellProps = {
    initialValue: ContactPreferencesModalData;
    prefType: string;
    onSave: (newValue: ContactPreferencesModalData, prefType: string, onFinishCallback: () => void) => void;
    disabled: ContactPreferencesModalFieldOptions[];
} & EdittableProps;

function getDisabledOptions(data: Data, type: string) {
    const disabledOpts: ContactPreferencesModalFieldOptions[] = [];
    if (['absences', 'urgentNotifications', 'busCancellations'].includes(type)) {
        if (!data.hasHomeNumber) {
            disabledOpts.push('Home');
        }
        if (!data.hasMobileNumber) {
            disabledOpts.push('Mobile');
        }
        if (!data.hasWorkNumber) {
            disabledOpts.push('Work');
        }
    } else {
        disabledOpts.push('Home', 'Mobile', 'Work');
    }
    if (!data.hasEmail) {
        disabledOpts.push('Email');
    }
    return disabledOpts;
}

function TableCell({ initialValue, prefType, onSave, disabled, edittable, setEdittable }: TableCellProps) {
    const [isEdit, setIsEdit] = useState(false);

    const theme = useTheme();

    function onFinish() {
        setIsEdit(false);
        setEdittable(true);
    }

    function handleSave(newValue: ContactPreferencesModalData) {
        onSave(newValue, prefType, onFinish);
    }

    function handleEdit() {
        setIsEdit(true);
        setEdittable(false);
    }

    return isEdit ? (
        <ContactPreferencesForm
            initialValue={initialValue}
            disabled={disabled}
            onSave={handleSave}
            onCancel={onFinish}
            required={prefType === 'urgentNotifications' || prefType === 'absences'}
        />
    ) : (
        <View style={styles.tableCellInnerContainer}>
            <Text style={typography.b2} numberOfLines={1}>
                {getNotificationPreferencesDescription(initialValue)}
            </Text>
            <Pressable onPress={handleEdit} style={({ pressed }) => pressed && styles.pressed} disabled={!edittable}>
                <View style={styles.brush && { marginLeft: 5 }}>
                    <SvgBrush fill={theme.colors.watermelon} width={20} />
                </View>
            </Pressable>
        </View>
    );
}

type HeaderComponentProps = {
    onOptions: () => void;
};

function HeaderComponent({ onOptions }: HeaderComponentProps) {
    const theme = useTheme();
    const { hasStudents, isLoadingRegistrations, isLoadingStudents } = useStudentListEmpty();

    return (
        <View
            style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexGrow: 0,
                minHeight: 80,
                borderBottomColor: theme.colors.textPrimary,
                borderBottomWidth: 2,
                marginBottom: 16,
            }}
        >
            <Text style={typography.h2}>{i18n.t('contactPrefs')}</Text>
            {!isUndefined(hasStudents) && (!isLoadingRegistrations || !isLoadingStudents) && hasStudents && (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {onOptions && <IconButton icon='dots-vertical' onPress={onOptions} style={{ marginLeft: 20 }} />}
                </View>
            )}
        </View>
    );
}

function TableHeaderRow({ headers }: { headers: string[] }) {
    return (
        <View style={{ flexDirection: 'row', paddingVertical: 12.5 }}>
            <View style={{ flexBasis: '10%', marginRight: 16 }} />
            {headers.map((header) => (
                <View style={{ flexDirection: 'row', flexBasis: '20%', marginRight: 16 }} key={`t-head-${header}`}>
                    <Text style={typography.h5}>{i18n.t(header)}</Text>
                </View>
            ))}
        </View>
    );
}

function TableRow({ data, edittable, setEdittable, types, onSubmit }: TableRowProps) {
    function handleSave(newValue: ContactPreferencesModalData, prefType: string, onFinishCallback: () => void) {
        if (data?.studentId) {
            onSubmit(newValue, prefType, data.studentId);
        } else {
            onSubmit(newValue, prefType, '');
        }
        onFinishCallback();
    }

    return (
        <View style={{ flexDirection: 'row', marginBottom: 16 }}>
            <View style={{ flexDirection: 'row', flexBasis: '10%', marginRight: 16 }}>
                <Avatar picture={data.photo} label={data.firstName} size={48} />
            </View>
            {types.map((prefType, index) => {
                const pref = data.prefs.find((i) => i.type === prefType);
                if (!pref) {
                    return (
                        <View
                            key={`${data?.studentId || data.firstName}-${index}`}
                            style={{ flexBasis: '20%', marginRight: 16 }}
                        />
                    );
                }
                return (
                    <View key={`${data?.studentId || data.firstName}-${index}`} style={styles.tableCellContainer}>
                        <TableCell
                            initialValue={pref}
                            prefType={prefType}
                            onSave={handleSave}
                            disabled={getDisabledOptions(data, prefType)}
                            edittable={edittable}
                            setEdittable={setEdittable}
                        />
                    </View>
                );
            })}
        </View>
    );
}

const studentHeaders = ['urgentNotifications', 'absences', 'busCancellations', 'schoolAndBoardNews'];
const parentHeaders = ['studentRegistrations', 'eventNotifications'];

const defaultValue: ContactPreferencesModalData = {
    home: false,
    email: false,
    work: false,
    mobile: 'None',
};

export function ContactPreferencesDesktop() {
    const { setMessage, setVisible } = useContext(ErrorContext);
    const { data: notificationPreferences, refetch } = useGetNotificationPreferencesQuery();
    const { data: students } = useGetStudentsQuery();
    const { data: parent } = useGetParentQuery();
    const [preferences, setPreferences] = useState<Preferences>();
    const [edittable, setEdittable] = useState(true);
    const [editNotificationPreferences] = useEditNotificationPreferencesMutation();
    const [editStudentNotificationPreferences] = useEditStudentNotificationPreferencesMutation();
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalValue, setModalValue] = useState<ContactPreferencesModalData>(defaultValue);
    const [modalPrefType, setModalPrefType] = useState('');
    const [modalDisabledOptions, setModalDisabledOptions] = useState<ContactPreferencesModalFieldOptions[]>([]);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (!notificationPreferences || !students || !parent) {
            return;
        }

        const parentPrefData = notificationPreferences.NotificationPreferences.PreferenceRows.map((i) => {
            return {
                type: getPreferenceType(i.Type),
                home: i.HomePhone || false,
                email: i.Email || false,
                work: i.WorkPhone || false,
                mobile: (i.MobilePhone || 'None') as MobileValues,
            };
        });
        const studentsPrefData = notificationPreferences.Students.map((i) => {
            const student = students.find((j) => j.StudentId === i.StudentId);
            const prefs = i.NotificationPreferences.PreferenceRows.map((k) => {
                return {
                    type: getPreferenceType(k.Type),
                    home: k.HomePhone || false,
                    email: k.Email || false,
                    work: k.WorkPhone || false,
                    mobile: (k.MobilePhone || 'None') as MobileValues,
                };
            });
            return {
                studentId: student?.StudentId || '',
                firstName: student?.FirstName || '',
                photo: student?.PhotoBase64 || '',
                hasHomeNumber: Boolean(i.NotificationPreferences.HomePhone),
                hasMobileNumber: Boolean(i.NotificationPreferences.MobilePhone),
                hasWorkNumber: Boolean(i.NotificationPreferences.WorkPhone),
                hasEmail: i.NotificationPreferences.PrimaryEmailSet || i.NotificationPreferences.AlternativeEmailSet,
                prefs: prefs,
            };
        });

        const data = {
            parent: {
                firstName: parent.FirstName,
                photo: parent.Photo,
                hasHomeNumber: Boolean(notificationPreferences.NotificationPreferences.HomePhone),
                hasMobileNumber: Boolean(notificationPreferences.NotificationPreferences.MobilePhone),
                hasWorkNumber: Boolean(notificationPreferences.NotificationPreferences.WorkPhone),
                hasEmail:
                    notificationPreferences.NotificationPreferences.PrimaryEmailSet ||
                    notificationPreferences.NotificationPreferences.AlternativeEmailSet,
                prefs: parentPrefData,
            },
            students: studentsPrefData,
        };
        setPreferences(data);
    }, [notificationPreferences, students, parent]);

    async function editStudentPref(value: ContactPreferencesModalData, prefType: string, studentId: string) {
        if (!notificationPreferences) {
            return;
        }
        const pref = prepareStudentContactPreferenceData(
            notificationPreferences,
            value,
            getPreferenceString(prefType),
            studentId
        );
        try {
            await editStudentNotificationPreferences(pref).unwrap();
            refetch();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackEditNotificationPreferenceErrorMessage');;
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);

        }
    }

    async function editParentPref(value: ContactPreferencesModalData, prefType: string) {
        if (!notificationPreferences) {
            return;
        }
        const pref = prepareContactPreferenceData(notificationPreferences, value, getPreferenceString(prefType));
        try {
            await editNotificationPreferences(pref).unwrap();
            refetch();
        } catch (e) {
            const defaultErrorMessage = i18n.t('fallbackEditNotificationPreferenceErrorMessage');;
            const errMessage = e?.data?.Message || defaultErrorMessage;
            setMessage(errMessage);
            setVisible(true);
        }
    }

    function handleEditAll(prefType: string) {
        if (!preferences) {
            setShowOptionsModal(false);
            return;
        }
        const pref = preferences.parent.prefs.find((i) => i.type === prefType);
        if (pref) {
            setModalValue(pref);
            setModalPrefType(prefType);
            setShowModal(true);
            setModalDisabledOptions(getDisabledOptions(preferences.parent, prefType));
        }
        setShowOptionsModal(false);
    }

    function handleSubmit(newValue: ContactPreferencesModalData, prefType: string) {
        editParentPref(newValue, prefType);
        setShowModal(false);
    }

    return (
        <>
            <ContactOptionsModal
                visible={showOptionsModal}
                onCancel={() => setShowOptionsModal(false)}
                onSubmit={handleEditAll}
            />
            <ContactPreferencesModal
                show={showModal}
                onDismiss={() => setShowModal(false)}
                initialValue={modalValue}
                required={modalPrefType === 'urgentNotifications' || modalPrefType === 'absences'}
                onSubmit={handleSubmit}
                disabled={modalDisabledOptions}
                prefType={modalPrefType}
            />

            <HeaderComponent onOptions={() => setShowOptionsModal(true)} />
            <View>
                <Text>{i18n.t('contactPreferencesDescription')}</Text>
                <TableHeaderRow headers={studentHeaders} />
                {preferences &&
                    preferences.students.map((student, index) => (
                        <TableRow
                            key={`row-${student.studentId}-${index}`}
                            data={student}
                            edittable={edittable}
                            setEdittable={setEdittable}
                            types={studentHeaders}
                            onSubmit={editStudentPref}
                        />
                    ))}
                <TableHeaderRow headers={parentHeaders} />
                {preferences && (
                    <TableRow
                        key={`row-parent`}
                        data={preferences.parent}
                        edittable={edittable}
                        setEdittable={setEdittable}
                        types={parentHeaders}
                        onSubmit={editParentPref}
                    />
                )}
            </View>
        </>
    );
}

const styles = StyleSheet.create({
    checkboxContainer: {
        flexDirection: 'column',
        marginBottom: 16,
        width: '100%',
    },
    child: {
        padding: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkboxLabel: {
        ...typography.body2,
        marginLeft: 16,
        alignSelf: 'center',
    },
    pressed: {
        opacity: 0.75,
    },
    brush: {
        width: 20,
    },
    tableCellContainer: {
        flexBasis: '20%',
        marginRight: 16,
    },
    tableCellInnerContainer: {
        // justifyContent: 'space-between',
        flexDirection: 'row',
        height: 48,
        alignItems: 'center',
    },
});
