import { defaultTheme } from '@Hooks/useTheme';
import React from 'react';
import { Pressable } from 'react-native';
import { styles } from './styles';
import { Done } from '@Icon/index';

type CheckboxProps = {
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({ checked, disabled, onChange }) => (
    <Pressable
        style={[styles.checkboxBase, checked && styles.checkboxChecked]}
        onPress={() => !disabled && onChange(!checked)}
    >
        {checked && <Done fill={defaultTheme.colors.background} width={18} height={18} />}
    </Pressable>
);

export default Checkbox;
