import { View, Text, Pressable, Platform, Linking, ViewStyle, StyleProp } from 'react-native';

import { store } from '@Redux';
import { loginWithSocial } from '@Redux/auth';
import { useSocialLoginMutation } from '@Redux/services/account';
import i18n from '@I18n';
import { styles } from './styles';
import { GoogleLogo, FacebookLogo, MicrosoftLogo, AppleLogo } from '@Icon';

import SignInReminder from '@Components/SignInReminder';
import useSocialAuth from '@Hooks/useSocialAuth';
import { useMemo } from 'react';

type SocialLoginIconsProps = {
    socialProviderReminder?: string | boolean;
    errorCallback?: () => void;
    style?: StyleProp<ViewStyle>;
};

function SocialLoginIcons({ socialProviderReminder, errorCallback, style }: SocialLoginIconsProps) {
    const [socialLogin] = useSocialLoginMutation();
    const { googleLogin, facebookLogin, outlookLogin, appleLogin, googleURL, facebookURL, microsoftURL, appleURL } =
        useSocialAuth();
    const isMobile = useMemo(() => Platform.OS !== 'web', [Platform.OS]);
    async function socialLoginCallback(providerUserId: string, provider: string) {
        const response = await socialLogin({ provider, providerUserId }).unwrap();
        store.dispatch(
            loginWithSocial({ userId: response.UserId, email: response.UserName, socialProvider: provider })
        );
    }

    async function handleGoogleLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(googleURL);
            } else {
                await googleLogin(socialLoginCallback);
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    async function handleFacebookLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(facebookURL);
            } else {
                await facebookLogin(socialLoginCallback);
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    async function handleMicrosoftLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(microsoftURL);
            } else {
                await outlookLogin(socialLoginCallback);
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    async function handleAppleLogin() {
        try {
            if (isMobile) {
                await Linking.openURL(appleURL);
            } else {
                await appleLogin();
            }
        } catch (e) {
            console.log(e);
            errorCallback && errorCallback();
        }
    }

    return (
        <View style={[styles.socialContainer, style]}>
            <Text style={styles.socialText} accessibilityLabel={i18n.t('orLoginWith')}>
                {i18n.t('orLoginWith')}
            </Text>
            <View style={styles.socialInnerContainer}>
                <View>
                    <View style={[styles.socialIconContainer, { marginLeft: 0 }]}>
                        <Pressable onPress={handleGoogleLogin}>
                            <View style={[styles.socialIcon, { paddingLeft: 0 }]}>
                                <GoogleLogo width={32} height={32} />
                            </View>
                        </Pressable>
                    </View>
                    {socialProviderReminder === 'Google' && <SignInReminder first />}
                </View>
                <View>
                    <View style={styles.socialIconContainer}>
                        <Pressable onPress={handleFacebookLogin}>
                            <View style={styles.socialIcon}>
                                <FacebookLogo width={32} height={32} />
                            </View>
                        </Pressable>
                    </View>
                    {socialProviderReminder === 'Facebook' && <SignInReminder />}
                </View>
                <View>
                    <View style={styles.socialIconContainer}>
                        <Pressable onPress={handleMicrosoftLogin}>
                            <View style={styles.socialIcon}>
                                <MicrosoftLogo width={32} height={32} />
                            </View>
                        </Pressable>
                    </View>
                    {socialProviderReminder === 'Microsoft' && <SignInReminder reversed />}
                </View>
                <View>
                    <View style={styles.socialIconContainer}>
                        <Pressable onPress={handleAppleLogin}>
                            <View style={styles.socialIcon}>
                                <AppleLogo width={32} height={32} />
                            </View>
                        </Pressable>
                    </View>
                    {socialProviderReminder === 'Apple' && <SignInReminder reversed />}
                </View>
            </View>
        </View>
    );
}

export default SocialLoginIcons;
