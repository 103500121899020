import { View, Text, Pressable, Linking } from 'react-native';
import i18n from '@I18n';
import { styles } from './styles';
import { navigationRef } from '@App/rootNavigation';
import Constants from 'expo-constants';
import DeviceContext from '@Contexts/DeviceContext';
import { useContext } from 'react';
import { HELP_URL } from '@App/constants';

function Footer() {
    const { isDesktop } = useContext(DeviceContext);

    const appVersion = Constants.manifest?.version;

    function handleTerms() {
        navigationRef.current?.navigate('terms');
    }

    function handlePrivacy() {
        navigationRef.current?.navigate('privacy');
    }

    function handleHelp() {
        Linking.openURL(HELP_URL);
    }

    const CopyrightLine = () => (
        <Text>
            ©{new Date().getFullYear() + ' '}
            <Text
                onPress={() => {
                    Linking.openURL('https://www.ucdsb.on.ca');
                }}
                style={styles.underlined}
            >
                {i18n.t('ucdsb')}
            </Text>
            {' ' + i18n.t('copyright')}
        </Text>
    );

    const footerText = isDesktop ? <CopyrightLine /> : `${i18n.t('version')} ${appVersion}`;

    return (
        <View style={[styles.base, isDesktop && styles.baseDesktop]}>
            <View style={styles.buttonContainer}>
                <Pressable onPress={handleTerms}>
                    <View style={styles.buttonTextContainer}>
                        <Text style={styles.buttonText} accessibilityLabel={i18n.t('terms')}>
                            {i18n.t('terms')}
                        </Text>
                    </View>
                </Pressable>
                <Pressable onPress={handlePrivacy}>
                    <View style={styles.buttonTextContainer}>
                        <Text style={styles.buttonText} accessibilityLabel={i18n.t('privacy')}>
                            {i18n.t('privacy')}
                        </Text>
                    </View>
                </Pressable>
                <Pressable onPress={handleHelp}>
                    <View style={styles.buttonTextContainer}>
                        <Text style={styles.buttonText} accessibilityLabel={i18n.t('help')}>
                            {i18n.t('help')}
                        </Text>
                    </View>
                </Pressable>
            </View>
            <Text style={styles.version} accessibilityLabel={footerText}>
                {footerText}
            </Text>
        </View>
    );
}

export default Footer;
