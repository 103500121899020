import React, { useCallback, useEffect, useState, useContext } from 'react';
import { View, TextStyle } from 'react-native';
import type { PartialDeep } from 'type-fest';
import { cloneDeep, isEqual } from 'lodash';
import { ActivityIndicator } from 'react-native-paper';

import Button from '@Components/Button';
import i18n from '@I18n/index';
import ModalHeader from '@Components/ModalHeader';
import {
    useGetSchoolsLocationsQuery,
    CreateStudentRegistrationBody,
    useGetStudentRegistrationQuery,
} from '@Redux/services/StudentRegistrationApi';
import { getMutableForAccessor, Accessor } from '@Utilities';

import { ManageFieldPropFactory, TextFieldPropFactory } from './types';
import { styles } from './styles';

import {
    AddressInfo,
    AdmissionInfo,
    AddressInfoMasks,
    AddressInfoValidator,
    CitizenshipInfo,
    CitizenshipInfoValidator,
    EmergencyContactsInfo,
    EmergencyContactsInfoValidator,
    EmergencyContactsInfoMasks,
    Footer,
    Header,
    Intro,
    SchoolInfo,
    SchoolInfoValidator,
    StudentInfoMasks,
    StudentInfo,
    StudentInfoValidator,
    Summary,
    TransportationInfo,
    TransportationInfoValidator,
    TransportationInfoMasks,
} from './components';
import Modal from '@Components/Modal';
import DeviceContext from '@Contexts/DeviceContext';
import { useGetCountriesQuery, useGetProvincesQuery } from '@Redux/services/LookupApi';
import AlertBox from '@Components/AlertBox';
import Help from './components/Help';
import WarningDialog from './components/Help/components/Dialog';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

interface IProps {
    registrationId?: string;
    buttonLabel: string;
    buttonLabelStyle?: TextStyle;
    buttonColor?: string;
    buttonLeftIcon?: React.ReactNode;
    onPress?: () => void;
    onDismiss?: () => void;
    reverseIcon?: boolean;
}

const steps = [
    { component: Intro, title: i18n.t('studentRegisterTitle') },
    {
        component: StudentInfo,
        title: i18n.t('studentRegisterTitleStudentInfo'),
        masks: StudentInfoMasks,
        validator: StudentInfoValidator,
    },
    {
        component: AddressInfo,
        title: i18n.t('studentRegisterTitleAddressInfo'),
        masks: AddressInfoMasks,
        validator: AddressInfoValidator,
    },
    {
        component: TransportationInfo,
        title: i18n.t('studentRegisterTitleTransportationInfo'),
        validator: TransportationInfoValidator,
        masks: TransportationInfoMasks,
    },
    { component: AdmissionInfo, title: i18n.t('studentRegisterTitleAdmissionInfo') },
    {
        component: CitizenshipInfo,
        title: i18n.t('studentRegisterTitleCitizenshipInfo'),
        validator: CitizenshipInfoValidator,
    },
    { component: SchoolInfo, title: i18n.t('studentRegisterTitleSchoolInfo'), validator: SchoolInfoValidator },
    {
        component: EmergencyContactsInfo,
        title: i18n.t('studentRegisterTitleEmergencyContactsInfo'),
        validator: EmergencyContactsInfoValidator,
        masks: EmergencyContactsInfoMasks,
    },
    { component: Summary, title: i18n.t('studentRegisterTitleSummary') },
];
const stepTitles = steps.map(({ title }) => title);

function RegisterChild({
    buttonLabel,
    registrationId,
    buttonLabelStyle,
    buttonColor,
    buttonLeftIcon,
    onPress,
    onDismiss,
    reverseIcon,
}: IProps) {
    const { data: schoolsLocations } = useGetSchoolsLocationsQuery();
    const [activeStepIdx, setActiveStepIdx] = useState(0);
    const [updateError, setUpdateError] = useState(false);
    const [visible, setVisible] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const ActiveStep = steps[activeStepIdx].component;
    const [studentRegistration, setStudentRegistration] = useState<PartialDeep<CreateStudentRegistrationBody>>({});
    const [errors, setErrors] = useState<Accessor[]>([]);
    const [contentChange, setContentChange] = useState(false);
    const { isDesktop } = useContext(DeviceContext);

    const { data, isLoading, refetch } = useGetStudentRegistrationQuery(
        {
            registrationId: registrationId ? registrationId : '',
        },
        {
            skip: !visible,
        }
    );

    const { data: countries } = useGetCountriesQuery();
    const { data: provinces } = useGetProvincesQuery();

    useEffect(() => {
        if (!countries || !provinces) {
            return;
        }
        const countryDefaultValue = countries.find((i) => i.Value === 'Canada')?.Key;
        const provinceDefaultValue = provinces.find((i) => i.Abbreviation === 'ON')?.Key;
        if (data) {
            setStudentRegistration({
                ...data,
                AddressInformation: {
                    ...data?.AddressInformation,
                    ChildMailingAddress: {
                        ...data?.AddressInformation?.ChildMailingAddress,
                        Country: data?.AddressInformation?.ChildMailingAddress?.Country || countryDefaultValue,
                        Province: data?.AddressInformation?.ChildMailingAddress?.Province || provinceDefaultValue,
                    },
                },
            });
        } else {
            setStudentRegistration({
                AddressInformation: {
                    ChildMailingAddress: { Country: countryDefaultValue, Province: provinceDefaultValue },
                },
            });
        }
    }, [data, countries, provinces]);

    useEffect(() => {
        if (registrationId && visible) {
            setActiveStepIdx(steps.length - 1);
            refetch();
        }
    }, [registrationId, visible]);

    const validators = steps.map(({ validator }) => (validator ? validator(studentRegistration) : []));
    const masks = steps.map(({ masks }) => (masks ? masks(studentRegistration) : []));

    const handleNext = useCallback(() => {
        const nextErrors: Accessor[] = [];
        const validationRules = validators[activeStepIdx];

        validationRules.forEach(({ accessorPath, validator }) => {
            const finalMutable = getMutableForAccessor(studentRegistration, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];
            if (!validator(finalMutable[finalProperty])) {
                nextErrors.push(accessorPath);
            }
        });

        if (nextErrors.length) {
            setErrors(nextErrors);
            return;
        }

        setActiveStepIdx(Math.min(activeStepIdx + 1, steps.length - 1));
    }, [activeStepIdx, studentRegistration, setErrors, validators]);

    const handlePrev = useCallback(() => {
        setActiveStepIdx(Math.max(activeStepIdx - 1, 0));
    }, [activeStepIdx]);

    const handleCtaClick = useCallback(() => {
        setVisible(true);
        onPress && onPress();
    }, [setVisible]);

    const handleDismiss = useCallback(() => {
        setShowWarning(false);
        setVisible(false);
        setContentChange(false);
        onDismiss && onDismiss();
        setStudentRegistration({});
        setActiveStepIdx(0);
    }, [setVisible]);

    const manageFieldPropFactory = useCallback<ManageFieldPropFactory>(
        <T,>(accessorPath: Accessor, handlerName = 'onChange', valueName = 'value') => {
            const newStudentRegistration = cloneDeep(studentRegistration);
            const finalProperty = accessorPath[accessorPath.length - 1];
            const finalMutable = getMutableForAccessor(newStudentRegistration, accessorPath);
            const accessorMasks = masks[activeStepIdx]?.filter(({ accessorPath: validatorAccessorPath }) =>
                isEqual(validatorAccessorPath, accessorPath)
            );

            const hasError = errors.findIndex((errorPath) =>
                errorPath.reduce((memo, segment, i) => memo && segment === accessorPath[i], true)
            );
            const nextErrors = [...errors];
            const inputValue = Number.isSafeInteger(finalMutable[finalProperty])
                ? `${finalMutable[finalProperty]}`
                : finalMutable[finalProperty];

            return {
                [handlerName]: function handleSubpropertyChange(value: typeof valueName) {
                    if (hasError > -1) {
                        nextErrors.splice(hasError, 1);
                        setErrors(nextErrors);
                    }

                    if (accessorMasks) {
                        finalMutable[finalProperty] = accessorMasks.reduce((memo, { mask }) => mask(memo), value);
                    } else {
                        finalMutable[finalProperty] = value;
                    }
                    setContentChange(true);
                    setStudentRegistration(newStudentRegistration);
                },
                [valueName]: inputValue,
                error: hasError > -1,
            } as T;
        },
        [activeStepIdx, studentRegistration, errors, setErrors]
    );

    const textFieldPropFactory = useCallback<TextFieldPropFactory>(
        (label) => ({
            accessibilityLabel: label,
            autoCorrect: false,
            mode: 'outlined',
            style: styles.textInput,
            label: label,
        }),
        []
    );

    return (
        <>
            <Modal
                header={
                    <ModalHeader
                        header={<Header activeTitleIdx={activeStepIdx} titles={stepTitles} />}
                        onDismiss={contentChange ? () => setShowWarning(true) : handleDismiss}
                        rightIcon={<Help />}
                    />
                }
                visible={visible}
                animationType='none'
                style={isDesktop ? styles.desktopModal : { height: '100%', display: 'flex' }}
            >
                <View style={isDesktop ? { height: 'calc(100% - 40px)' } : { height: '100%' }}>
                    <View style={{ paddingHorizontal: 24 }}>
                        <AlertBox isError={true} show={updateError} message={i18n.t('registrationUpdateError')} />
                    </View>
                    <KeyboardAwareScrollView
                        contentContainerStyle={{ flexGrow: 1, paddingBottom: 130 }}
                        keyboardShouldPersistTaps='handled'
                        extraScrollHeight={30}
                    >
                        <View style={styles.screen}>
                            {isLoading && <ActivityIndicator />}
                            {!isLoading && (
                                <>
                                    <View style={styles.inner}>
                                        <ActiveStep
                                            errors={errors}
                                            manageFieldPropFactory={manageFieldPropFactory}
                                            schoolsLocations={schoolsLocations}
                                            setActiveStepIdx={setActiveStepIdx}
                                            setStudentRegistration={setStudentRegistration}
                                            studentRegistration={studentRegistration}
                                            textFieldPropFactory={textFieldPropFactory}
                                        />
                                    </View>
                                </>
                            )}
                        </View>
                    </KeyboardAwareScrollView>
                </View>
                <Footer
                    studentRegistration={studentRegistration}
                    lastStepIdx={steps.length - 1}
                    activeStepIdx={activeStepIdx}
                    onNext={handleNext}
                    onPrev={handlePrev}
                    onSubmit={handleDismiss}
                    onError={() => setUpdateError(true)}
                />
                {showWarning && contentChange && (
                    <WarningDialog
                        show={showWarning}
                        onLeavePress={handleDismiss}
                        onDismiss={() => setShowWarning(false)}
                        onStayPress={() => setShowWarning(false)}
                        message={i18n.t('studentRegisterWarningMessage')}
                    />
                )}
            </Modal>
            <Button
                label={buttonLabel}
                underlineColor={buttonColor}
                leftIcon={!reverseIcon && buttonLeftIcon}
                rightIcon={reverseIcon && buttonLeftIcon}
                onPress={handleCtaClick}
                labelStyle={buttonLabelStyle ? buttonLabelStyle : styles.cta}
            />
        </>
    );
}

export default RegisterChild;
