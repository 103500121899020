import { configureFonts, DefaultTheme as PaperDefaultTheme } from 'react-native-paper';
import { DefaultTheme as NavigationDefaultTheme } from '@react-navigation/native';
import { TextStyle } from 'react-native';
import { FontDisplay } from 'expo-font';

export const fonts = {
    MontserratThin: 'Montserrat_100Thin',
    MontserratLight: 'Montserrat_300Light',
    MontserratRegular: 'Montserrat_400Regular',
    MontserratMedium: 'Montserrat_500Medium',
    MontserratSemiBold: 'Montserrat_600SemiBold',
    MontserratBold: 'Montserrat_700Bold',
    MontserratExtraBold: 'Montserrat_800ExtraBold',
    MontserratBlack: 'Montserrat_900Black',
};

const fontConfig = {
    web: {
        thin: {
            fontFamily: fonts.MontserratThin,
            display: FontDisplay.SWAP,
        },
        light: {
            fontFamily: fonts.MontserratLight,
            display: FontDisplay.SWAP,
        },
        regular: {
            fontFamily: fonts.MontserratRegular,
            display: FontDisplay.SWAP,
        },
        medium: {
            fontFamily: fonts.MontserratMedium,
            display: FontDisplay.SWAP,
        },
        semiBold: {
            fontFamily: fonts.MontserratSemiBold,
            display: FontDisplay.SWAP,
        },
        bold: {
            fontFamily: fonts.MontserratBold,
            display: FontDisplay.SWAP,
        },
        bolder: {
            fontFamily: fonts.MontserratExtraBold,
            display: FontDisplay.SWAP,
        },
        black: {
            fontFamily: fonts.MontserratBlack,
            display: FontDisplay.SWAP,
        },
    },
    ios: {
        thin: {
            fontFamily: fonts.MontserratThin,
        },
        light: {
            fontFamily: fonts.MontserratLight,
        },
        regular: {
            fontFamily: fonts.MontserratRegular,
        },
        medium: {
            fontFamily: fonts.MontserratMedium,
        },
        semiBold: {
            fontFamily: fonts.MontserratSemiBold,
        },
        bold: {
            fontFamily: fonts.MontserratBold,
        },
        bolder: {
            fontFamily: fonts.MontserratExtraBold,
        },
        black: {
            fontFamily: fonts.MontserratBlack,
        },
    },
    android: {
        thin: {
            fontFamily: fonts.MontserratThin,
        },
        light: {
            fontFamily: fonts.MontserratLight,
        },
        regular: {
            fontFamily: fonts.MontserratRegular,
        },
        medium: {
            fontFamily: fonts.MontserratMedium,
        },
        semiBold: {
            fontFamily: fonts.MontserratSemiBold,
        },
        bold: {
            fontFamily: fonts.MontserratBold,
        },
        bolder: {
            fontFamily: fonts.MontserratExtraBold,
        },
        black: {
            fontFamily: fonts.MontserratBlack,
        },
    },
};

export const defaultTheme = {
    ...NavigationDefaultTheme,
    ...PaperDefaultTheme,
    colors: {
        ...NavigationDefaultTheme.colors,
        ...PaperDefaultTheme.colors,
        background: '#FFFFFF',
        grape: '#A28AC7',
        watermelon: '#5DA638',
        raspberry: '#D77A7F',
        kiwi: '#73CC49',
        blueberry: '#72BEFB',
        blueberryDark: '#4D7A9E',
        persimmon: '#EEAB00',
        lychee: '#FF5F4D',
        lycheeDark: '#C35347',
        textPrimary: '#373639',
        inputOutline: '#37363987',
        error: '#F44336',
    },
};

export const typography: Record<string, TextStyle> = {
    h0: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 60,
        lineHeight: 60,
        fontFamily: fonts.MontserratExtraBold,
    },
    h1: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 48,
        lineHeight: 48,
        fontFamily: fonts.MontserratExtraBold,
    },
    h2: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 32,
        lineHeight: 32,
        fontFamily: fonts.MontserratExtraBold,
    },
    h3: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 24,
        lineHeight: 27,
        fontFamily: fonts.MontserratExtraBold,
    },
    h4: {
        fontFamily: fonts.MontserratExtraBold,
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: 20,
        color: defaultTheme.colors.textPrimary,
    },
    h5: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 18,
        fontFamily: fonts.MontserratExtraBold,
    },
    h6: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 16,
        fontFamily: fonts.MontserratExtraBold,
    },
    body: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 16,
        lineHeight: 24,
        fontFamily: fonts.MontserratSemiBold,
    },
    body2: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 14,
        lineHeight: 21,
        fontFamily: fonts.MontserratSemiBold,
    },
    body3: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 12,
        fontFamily: fonts.MontserratSemiBold,
    },
    buttonSmall: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 12,
        fontFamily: fonts.MontserratMedium,
    },
    buttonMedium: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 14,
        fontFamily: fonts.MontserratBold,
    },
    buttonLarge: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 16,
        fontFamily: fonts.MontserratBlack,
    },
    smallListItem: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 14,
        fontFamily: fonts.MontserratBold,
    },
    introSubtitle: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 19,
        lineHeight: 28.5,
        fontFamily: fonts.MontserratMedium,
    },
    calendarDate: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 12,
        lineHeight: 18,
        fontFamily: fonts.MontserratMedium,
    },
    calendarEvent: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 14,
        lineHeight: 21,
        fontFamily: fonts.MontserratMedium,
    },
    consentForm: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 12,
        fontFamily: fonts.MontserratBold,
    },
    dashboardHeader: {
        color: defaultTheme.colors.textPrimary,
        fontSize: 14,
        fontFamily: fonts.MontserratBold,
    },
};

export default function useTheme() {
    return {
        ...defaultTheme,
        fonts: configureFonts(fontConfig),
    };
}
