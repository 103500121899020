import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    filtersModal: {
        margin: 0,
    },
    filtersModalInner: {
        backgroundColor: 'white',
        flex: 1,
        padding: 15,
        width: '85%',
    },
});
