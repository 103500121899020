import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconHome = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.203.37H5.797L.972 5.991h22.056L18.203.371ZM3.45 8.018H20.6v11.813h-9.807V13.89H7.112v5.942H3.449V8.018Zm13.911 2.973h-2.35l-1.175 2.035 1.175 2.035h2.35l1.175-2.035-1.175-2.035Z'
            fill='#73CC49'
        />
    </Svg>
);
export default SvgIconHome;
