import { StyleSheet } from 'react-native';
import { defaultTheme, typography } from '@Hooks/useTheme';

export const styles = StyleSheet.create({
    container: {
        marginTop: 8,
        position: 'relative',
        paddingTop: 64,
        zIndex: 10,
    },
    autoCompleteContainer: {
        flex: 1,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        height: 64,
    },
    inputContainer: {
        borderColor: defaultTheme.colors.inputOutline,
        borderWidth: 1,
        minHeight: 56,
    },
    input: {
        ...typography.body2,
        padding: 10,
        height: 56,
        fontSize: 16,
    },
    labelContainer: {
        position: 'absolute',
        top: -10,
        left: 0,
        backgroundColor: defaultTheme.colors.background,
        paddingHorizontal: 4,
        transform: [{ scale: 0.75 }],
        zIndex: 20,
    },
    label: {
        ...typography.body2,
        fontSize: 16,
    },
});
