import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconCalendar9 = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.189.17h-3.21V4.2H.032v19.643h19.644V4.2h-2.988V.17h-3.21V4.2h-7.29V.17ZM18.47 9.4H1.196v10.7H18.47V9.4Zm-6.746 2.803c-.527-.6-1.27-.9-2.23-.9-.48 0-.914.093-1.3.28a2.238 2.238 0 0 0-.92.8c-.227.34-.34.736-.34 1.19 0 .426.103.803.31 1.13.206.326.493.58.86.76.366.173.78.26 1.24.26.4 0 .76-.06 1.08-.18.32-.127.583-.31.79-.55v.01c0 .78-.19 1.376-.57 1.79-.38.413-.907.62-1.58.62-.54 0-.984-.114-1.33-.34l-.48.98c.233.146.506.26.82.34.32.073.66.11 1.02.11.68 0 1.276-.144 1.79-.43a3.01 3.01 0 0 0 1.21-1.27c.287-.56.43-1.234.43-2.02 0-1.12-.267-1.98-.8-2.58Zm-1.11 2.16c-.26.226-.6.34-1.02.34-.427 0-.767-.107-1.02-.32-.254-.214-.38-.504-.38-.87 0-.36.126-.647.38-.86.253-.22.583-.33.99-.33.453 0 .806.113 1.06.34.26.22.39.5.39.84 0 .346-.133.633-.4.86Z'
            fill='#5DA638'
        />
    </Svg>
);
export default SvgIconCalendar9;
