import { defaultTheme, typography } from '@Hooks/useTheme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    containerInside: {
        paddingHorizontal: 24,
    },
    callSchool: {
        marginTop: 8,
    },
    actions: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        marginHorizontal: 16,
    },
    screen: {
        height: '100%',
    },
    infoContaner: {
        flexDirection: 'row',
    },
    infoIcon: {
        marginRight: 8,
    },
    infoLabel: {
        ...typography.body2,
    },
    buttonContainer: {
        paddingTop: 16,
        flexDirection: 'row',
    },
    buttonLabel: {
        ...typography.buttonMedium,
    },
    desktopContainer: {
        marginBottom: 32,
        paddingRight: 16,
        width: '100%',
    },
    avatarContainer: {
        flexDirection: 'row',
        width: '100%',
    },
    avatar: {
        marginRight: 24,
    },
    name: {
        ...typography.h1,
        marginBottom: 4,
    },
    school: {
        ...typography.body,
    },
    subhead: {
        ...typography.h6,
        marginTop: 20,
        marginBottom: 40,
    },
    actionContainer: {
        borderTopColor: defaultTheme.colors.backdrop,
        borderTopWidth: 1,
        paddingVertical: 16,
    },
});

export default styles;
