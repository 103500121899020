import React from 'react';
import { View, ViewStyle } from 'react-native';
import { keyBy } from 'lodash';

import { charsum } from '@Utilities';

import Avatar from '@Components/Avatar';
import { allStudentsLabel, DailySeriesEvent } from '@Redux/services/CalendarApi';
import { useGetStudentsQuery } from '@Redux/services/parent';

import { styles } from './styles';
interface OwnerAvatarProps {
    event: DailySeriesEvent;
    size?: number;
    style?: ViewStyle;
}

function OwnerAvatar({ event, size, style }: OwnerAvatarProps) {
    const { data: students } = useGetStudentsQuery();
    const studentsFullNameMap = keyBy(students, 'FullName');

    const avatarStyle: ViewStyle[] = [styles.avatar];
    if (event.offerStatus === 'expired') {
        avatarStyle.push(styles.avatarExpired);
    }

    return (
        <View style={styles.eventAvatarContainer}>
            {event.owner === allStudentsLabel ? (
                <>
                    {students?.map((student) => (
                        <Avatar
                            key={`student-avatar-${charsum(student.FullName)}`}
                            label={student.FirstName}
                            picture={student.PhotoBase64}
                            size={size}
                            style={[avatarStyle, style]}
                        />
                    ))}
                </>
            ) : (
                event.owner.map((owner) => (
                    <Avatar
                        key={`student-avatar-${charsum(owner)}`}
                        label={studentsFullNameMap[owner]?.FirstName}
                        picture={studentsFullNameMap[owner]?.PhotoBase64}
                        size={size}
                        style={[avatarStyle, style]}
                    />
                ))
            )}
        </View>
    );
}

export default OwnerAvatar;
