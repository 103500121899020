import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const SvgIconRegistration = (props: SvgProps) => (
    <Svg width={24} height={24} viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.86.56h4.28v5.373h9.72V.56h4.28v5.373h3.985v26.191H2.934V5.934H6.86V.56ZM4.484 10.2h23.033v17.333H24v-7.866H8v7.866H4.484V10.2ZM18 11.667h-4L12 15l2 3.333h4L20 15l-2-3.333Z'
            fill='#A28AC7'
        />
    </Svg>
);
export default SvgIconRegistration;
